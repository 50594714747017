import React, { useState, useCallback } from 'react';

type Toggle = { state: boolean; toggle: () => void; setTrue: () => void; setFalse: () => void };

export function useToggle<T extends boolean>(initialState: T): Toggle {
  const [state, setState] = useState<boolean>(initialState);

  return {
    toggle: useCallback(() => setState((state) => !state), []),
    setTrue: useCallback(() => setState(true), []),
    setFalse: useCallback(() => setState(false), []),
    state,
  };
}
