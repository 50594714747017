export const DownloadIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M16.3997 15.7999H10.3997M5.59975 15.8901V15.7999M13.3997 4.95733L10.9455 7.3999M10.9455 7.3999L8.59975 5.06774M10.9455 7.3999V1.3999M15.8001 10.9999H6.1999C3.54887 10.9999 1.39982 13.149 1.3999 15.8001C1.39999 18.451 3.549 20.5999 6.1999 20.5999H15.8001C18.451 20.5999 20.6001 18.4509 20.6001 15.7999C20.6001 13.1489 18.451 10.9999 15.8001 10.9999Z"
      stroke="#2D89EF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
