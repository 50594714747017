import { mapTransactionsHistory, mapWalletInfo } from '../mappers/wallet/wallet';
import { rootAPI } from '../store/rootApi';
import { getPageFromLink } from '../utils';
import {
  TransactionsHistoryResponse,
  TransactionsHistoryResponseTransformed,
  WalletInfo,
  WalletInfoResponse,
} from './wallet/types';

export const walletApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getTransactionsHistory: build.mutation<TransactionsHistoryResponseTransformed, number>({
      query(page) {
        return {
          url: `/wallet/income-history/?page=${page}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: TransactionsHistoryResponse) => ({
        nextPage: Number(getPageFromLink(response.links.next, 'page')),
        history: mapTransactionsHistory(response.results),
      }),
    }),
    getWalletInfo: build.query<WalletInfo, void>({
      query() {
        return {
          url: '/wallet-info/',
          credentials: 'include',
        };
      },
      transformResponse: (response: WalletInfoResponse) => {
        return mapWalletInfo(response);
      },
      providesTags: () => ['WalletInfo'],
    }),
  }),
});
