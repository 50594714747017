export const getPercentWidgetInfo = (percent: string | undefined) => {
  if (!percent) return { isShowPercent: false, isPositive: false, sign: '', value: 0 };

  let isShowPercent = false;
  let sign = '-';
  let isPositive = false;
  let value = Number(percent.replace(/[+-]/, ''));

  if (value !== 0) isShowPercent = true;
  if (value > 0) {
    isPositive = true;
    sign = '+';
  }
  return { isShowPercent, value, sign, isPositive };
};
