export type TCurrency = 'RUB' | 'BYN' | 'UAH' | 'USD' | 'EUR';
export type PeriodType = 'MONTH' | 'HALF_A_YEAR' | 'YEAR';
export type AppealStateType = 'open' | 'in_process' | 'suspended' | 'resolved';
export type AppealMessageType =
  | 'regular'
  | 'support'
  | 'start_processing'
  | 'suspend'
  | 'back_to_processing'
  | 'resolve';
export type UserRoleType = 'developer' | 'ba' | 'default';
export type EmployeeStateType = 'waiting' | 'declined' | 'verified';
export type ParamDictionaryType = Record<string, string | number | boolean>;

export type MetricChartItem = { name: string; value: number[] };

export type ModelPopupType = {
  id: number;
  name: string;
  task: string;
  type: string;
  category: string;
  count: number;
  isDisabled?: boolean;
  isPaid: boolean;
  isJointMl: boolean;
  weights: AvailableModelWeights[];
};

export type AvailableModelWeights = {
  id: number;
  name: string;
  size: number;
  description: string;
  date: string;
};

export type RequestCreatorType = {
  user: {
    fullName: string;
    position?: string | null;
    companyName?: string;
    INN?: string;
    industry?: string;
    email: string;
  };
};

export type CompanyDataResponse = {
  id: number;
  name: string;
  inn: string;
  industry: string;
};

export enum AllianceUserStatus {
  OwnerWaiting = 'waiting_for_owner',
  OwnerApproved = 'approved_by_owner',
  OwnerRejected = 'rejected_by_owner',
  OwnerLeft = 'left_by_owner',
  Deleted = 'removed_by_owner',
  Banned = 'banned',
  UserWaiting = 'waiting_for_user',
  UserApproved = 'approved_by_user',
  UserRejected = 'rejected_by_user',
  UserLeft = 'left_by_user',
  Waiting = 'waiting_to_approve',
}
