export const button = {
  delete: 'Удалить',
  cancel: 'Отмена',
  leave: 'Выйти',
  verify: 'Верифицировать',
  continue: 'Продолжить',
  yes: 'Да, хочу',
  no: 'Отказаться',
  confirm: 'Подтвердить',
  back: 'Назад',
  deactivate: 'Деактивировать',
  activate: 'Активировать',
};

export const text = {
  deleteAllianceML: {
    title: 'Удалить ML-модель?',
    prev: 'Вы подтверждаете, что хотите удалить ',
    middle: ' из альянса  ',
    next: '? Работа с этой ML-моделью будет приостановлена.',
  },
  deleteML: {
    title: 'Удалить ML-модель?',
    prev: 'Вы подтверждаете, что хотите удалить ',
    next: '? Работа с этой ML-моделью и её версиями весов будет невозможна. Если вы добавляли в альянсы эту модель, то там останется её копия.',
  },
  deleteWeight: {
    title: 'Удалить версию весов ML-модели?',
    prev: 'Вы подтверждаете, что хотите удалить веса ',
    middle: ' ML-модели  ',
    next: '? Работа с этой версией весов будет невозможна. Если вы добавляли в альянсы эту версию, то там останется её копия.',
  },
  deleteAttachment: {
    title: 'Удалить приложение?',
    prev: 'Вы подтверждаете, что хотите удалить ',
    alliance: ' из альянса  ',
    model: ' из модели ',
    next: '?',
  },
  logout: {
    title: 'Выйти из аккаунта?',
    prev: 'Вы подтверждаете, что хотите выйти из аккаунта ',
    next: '?',
  },
  leaveOpen: {
    title: 'Выйти из альянса?',
    prev: 'Вы подтверждаете, что хотите выйти из альянса ',
  },
  leaveClose: {
    title: 'Выйти из закрытого альянса?',
    prev: 'Вы подтверждаете, что хотите выйти из альянса ',
    next: '? Просмотр альянса и доступ к ML-модели будет приостановлен, если вы её не скачали.',
  },
  verification: {
    title: 'Верифицировать пользователей ',
    subtitle: 'Вы подтверждаете, что эти люди являются сотрудниками вашей организации?',
  },
  baConfirm: {
    title: 'Вы хотите выполнять роль бизнес-администратора?',
    info: { prev: 'У ', next: ' нет бизнес-администратора' },
    prev: 'Вы можете стать бизнес-администратором этой компании или указать другую.',
    next: 'В роли бизнес-администратора вы будете верифицировать сотрудников вашей компании и предоставлять им доступ к функциям JointML. Также у вас будут права на заключение договоров и проведение платежей',
  },
  cancelOrganizationAdd: {
    title: 'Отменить заполнение данных?',
    subtitle:
      'Вы подтверждаете, что хотите отменить заполнение данных об организации / ИП? Введенные данные будут утеряны',
  },
  cancelDataEdit: {
    title: 'Внесённые изменения не сохранятся',
    subtitle: 'Если вы вносили изменения, они не будут сохранены',
  },
  deleteProject: {
    title: 'Удалить проект ',
    subtitle: 'Введённые данные будут утеряны',
  },
  deactivateAlliance: {
    title: 'Деактивировать альянс?',
    subtitle: {
      prev: 'Вы хотите деактивировать альянс ',
      next: '? Работа с этим альянсом будет приостановлена, и он будет скрыт от участников этого альянса',
    },
  },
  deleteAlliance: {
    title: 'Удалить альянс?',
    subtitle: {
      prev: 'Вы хотите удалить альянс ',
      next: '? Работа с этим альянсом будет невозможна, и он будет скрыт от участников этого альянса',
    },
  },
  deleteFLFile: {
    title: 'Удалить файл?',
    subtitle: 'Вы уверены, что хотите удалить файл?',
  },
};
