import { request } from '../utils/fetchHandler';

export const getUpload = async (
  upload: File,
  id: number,
  to: string,
): Promise<{
  payload: {
    id: number;
    file_data: {
      url: string;
      file_name: string;
      size: string;
      data_type: string;
      uuid: string;
    };
  };
  status: string;
}> => {
  const formData = new FormData();
  formData.append('file', upload);
  formData.append(`${to === 'mlmodel' ? 'ml_model_id' : 'alliance_id'}`, id.toString());

  const response = await fetch(`${process.env.REACT_APP_BACK_END}/upload/${to}/`, {
    method: 'POST',
    body: formData,
    credentials: 'include',
  });
  if (!response.ok) {
    return {
      status: 'ERROR',
      payload: {
        id: 0,
        file_data: {
          url: '',
          file_name: '',
          size: '',
          data_type: '',
          uuid: '',
        },
      },
    };
  } else {
    const result = response.json();

    return result.then(
      (res: {
        id: number;
        file_data: {
          url: string;
          file_name: string;
          size: string;
          data_type: string;
          uuid: string;
        };
      }) => ({
        payload: res,
        status: 'SUCCESS',
      }),
    );
  }
};

export const uploadDelete = async (id: number, to: string): Promise<void> => {
  await fetch(`${process.env.REACT_APP_BACK_END}/upload/${to}/attachment/${id}/`, {
    method: 'DELETE',
    credentials: 'include',
  });
};

export const getLink = async (
  id: number,
  to: string,
): Promise<{ status: string; payload: { file: string } }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/upload/${to}/${id}/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};
