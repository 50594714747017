import {
  AvailableSubscriptionsResponse,
  SubscriptionsHistoryResponse,
} from '../../api/subscriptions/types';

export const mapAvailableSubscriptions = (response: AvailableSubscriptionsResponse) =>
  response.map(subscription => ({
    id: subscription.id,
    type: subscription.type,
    discount: subscription.discount,
    currency: subscription.currency,
    cost: subscription.price,
  }));

export const mapSubscriptionsHistory = (response: SubscriptionsHistoryResponse) =>
  response.map(subscription => ({
    id: subscription.id,
    type: subscription.type_name,
    startDate: subscription.start_date,
    endDate: subscription.end_date,
    isActive: subscription.is_active,
  }));
