import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { CrossBtn, EditBtn } from '../../../common';
import { ParamProps } from '../types';

import styles from './Param.module.scss';

export const Param = ({
  param,
  isReadOnly,
  isDisabled,
  onDelete,
  onEdit,
  isEditOnly,
}: ParamProps) => {
  const { t } = useTranslation();
  const { name, value } = param;
  const paramStyles = cn(styles.container, isDisabled && styles.disabled);

  return (
    <>
      <li className={paramStyles}>
        <p>
          {name}: <span>{value}</span>
        </p>
        {!isReadOnly && (
          <div className={styles.btnsContainer}>
            <CrossBtn
              isSmall={true}
              description={t('pages.modelFormPage.paramsBtns.deleteDescription')}
              onClick={onDelete}
            />
            <EditBtn
              isSmall={true}
              onClick={onEdit}
              description={t('pages.modelFormPage.paramsBtns.editDescription')}
            />
          </div>
        )}
        {isEditOnly && (
          <div className={styles.btnsContainer}>
            <EditBtn
              isSmall={true}
              onClick={onEdit}
              description={t('pages.modelFormPage.paramsBtns.editDescription')}
            />
          </div>
        )}
      </li>
    </>
  );
};
