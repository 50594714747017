import { OrganizationsListResponse } from '../../api/organization/types';

export const mapOrganizations = (payload: OrganizationsListResponse) =>
  payload.map(organization => ({
    id: organization.id,
    name: organization.name,
    baData: {
      firstName: organization.ba_data.first_name,
      lastName: organization.ba_data.last_name,
      middleName: organization.ba_data.middle_name,
      phone: organization.ba_data.phone,
      email: organization.ba_data.email,
    },
  }));
