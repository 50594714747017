import {
  MembersIcon,
  ModelsIcon,
  AlliancesIcon,
  SandboxIcon,
  DirectoryIcon,
  SupportIcon,
} from '../../assets/svg';
import {
  MODELS_PATH,
  ALLIANCES_PATH,
  SANDBOX_PATH,
  DIRECTORY_PATH,
  APPEALS_PATH,
  USERS_PATH,
  ADMIN_PATH,
} from '../routeConstants';

export const systemAdminNavBar = [
  {
    name: 'Служба поддержки',
    path: `${ADMIN_PATH}/${APPEALS_PATH}`,
    to: `${ADMIN_PATH}/${APPEALS_PATH}`,
    image: <SupportIcon />,
  },
  {
    name: 'Участники',
    path: `${ADMIN_PATH}/${USERS_PATH}`,
    to: `${ADMIN_PATH}/${USERS_PATH}`,
    image: <MembersIcon />,
  },
  {
    name: 'ML-модели',
    path: `${ADMIN_PATH}/${MODELS_PATH}`,
    to: `${ADMIN_PATH}/${MODELS_PATH}`,
    image: <ModelsIcon />,
    onboardingClass: 'navLinkToMLModels',
  },
  {
    name: 'Альянсы',
    path: `${ADMIN_PATH}/${ALLIANCES_PATH}`,
    to: `${ADMIN_PATH}/${ALLIANCES_PATH}`,
    image: <AlliancesIcon />,
    onboardingClass: 'navLinkToAlliances',
  },
  // { name: 'Песочницы', path: SANDBOX_PATH, to: SANDBOX_PATH, image: <SandboxIcon /> },
  // {
  //   name: 'Справочник',
  //   path: DIRECTORY_PATH,
  //   to: DIRECTORY_PATH,
  //   image: <DirectoryIcon />,
  //   isCounter: true,
  // },
];
