export const ProcessIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none">
    <path
      d="M2.33992 12.8136C2.17993 12.8136 2.02327 12.7536 1.90327 12.6303C0.676644 11.387 0 9.74369 0 7.99709C0 4.77053 2.30659 2.04395 5.47982 1.51063C5.81647 1.45397 6.13313 1.68063 6.18979 2.01728C6.24646 2.35394 6.0198 2.6706 5.68314 2.72726C3.10323 3.16058 1.22996 5.37717 1.22996 8.00042C1.22996 9.41704 1.77994 10.757 2.77991 11.767C3.0199 12.0103 3.01657 12.3969 2.77324 12.6369C2.65324 12.7536 2.49658 12.8136 2.33992 12.8136Z"
      fill="#0A58E0"
    />
    <path
      d="M3.94625 5.21036C3.81959 5.21036 3.69293 5.17036 3.58293 5.09036C3.30961 4.89037 3.24961 4.50372 3.45294 4.23039L4.82289 2.36712L2.86629 1.13716C2.57963 0.957167 2.49297 0.577179 2.67296 0.287189C2.85296 0.000531914 3.23295 -0.0861319 3.52294 0.0938622L6.04285 1.67714C6.18618 1.76714 6.28951 1.9138 6.31951 2.0838C6.35284 2.25046 6.31284 2.42712 6.20951 2.56378L4.44624 4.96037C4.32291 5.12369 4.13625 5.21036 3.94625 5.21036Z"
      fill="#0A58E0"
    />
    <path
      d="M7.5831 14.4964C7.28645 14.4964 7.02646 14.2831 6.97646 13.9831C6.91979 13.6464 7.14645 13.3298 7.47978 13.2731C10.0597 12.8364 11.9296 10.6198 11.9296 7.9966C11.9296 6.52999 11.3463 5.16003 10.2897 4.1434C10.0464 3.90674 10.0397 3.51675 10.273 3.27343C10.5097 3.0301 10.8997 3.0201 11.143 3.25676C12.4429 4.51005 13.1563 6.19333 13.1563 7.9966C13.1563 11.2198 10.853 13.9497 7.67977 14.4864C7.64977 14.4931 7.61644 14.4964 7.5831 14.4964Z"
      fill="#0A58E0"
    />
    <path
      d="M9.96967 15.9997C9.85634 15.9997 9.74301 15.9697 9.64302 15.9064L7.1231 14.3231C6.97977 14.2331 6.87644 14.0864 6.84644 13.9164C6.81311 13.7498 6.85311 13.5731 6.95644 13.4364L8.71972 11.0399C8.91971 10.7665 9.30636 10.7065 9.57969 10.9099C9.85301 11.1099 9.91301 11.4965 9.70968 11.7698L8.33973 13.6331L10.2963 14.8631C10.583 15.0431 10.6697 15.423 10.4897 15.713C10.373 15.8964 10.173 15.9997 9.96967 15.9997Z"
      fill="#0A58E0"
    />
  </svg>
);
