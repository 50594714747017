import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { SearchIcon } from '../../../../assets/svg';
import { useSearch } from '../../../../hooks';
import { addCustomTag, getPopularTags } from '../../../../api';
import { Button } from '../../../../ui';
import { Tag } from '../Tag/Tag';
import { text } from './constants';
import { ITag, PropTypes } from './types';

import styles from './SearchTagsController.module.scss';

export const SearchTagsController = ({ selectedTags, setSelectedTags, to }: PropTypes) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [displayingTags, setDisplayingTags] = useState<ITag[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAddButtonActive, setIsAddButtonActive] = useState<boolean>(false);
  const { value, handleChange, handleResetSearch } = useSearch();

  const handleClickOutside = (e: MouseEvent) => {
    const { id } = e.target as HTMLElement;
    if (id !== 'search' && id !== 'tags' && id !== 'tag' && id !== 'add') {
      setIsOpen(false);
    }
  };

  const handleSelect = (tag: ITag) => {
    if (selectedTags.some(selected => selected.id === tag.id)) {
      setSelectedTags(selectedTags.filter(selected => selected.id !== tag.id));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleAddNewTag = async () => {
    const { payload } = await addCustomTag(value, to);
    setSelectedTags([...selectedTags, payload]);
    handleResetSearch();
    inputRef.current && inputRef.current.focus();
  };

  const handleAddSameTag = async () => {
    if (displayingTags.some(tag => tag.name === value)) {
      handleSelect(displayingTags.find(tag => tag.name === value) ?? { id: 2, name: 'asd' });
      handleResetSearch();
      inputRef.current && inputRef.current.focus();
    } else handleAddNewTag();
  };

  const fetchTags = async (value: string | undefined = undefined) => {
    const { payload } = await getPopularTags(value, to);
    setDisplayingTags(payload);
  };

  useEffect(() => {
    if (value.length > 1) {
      fetchTags(value);
    } else fetchTags();
  }, [value]);

  useEffect(() => {
    if (value.length > 1 && displayingTags.length > 0) {
      setIsAddButtonActive(true);
      if (selectedTags.some(selected => selected.name === value)) {
        setIsAddButtonActive(false);
      }
    } else setIsAddButtonActive(false);
  }, [value, displayingTags, selectedTags]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={cn(styles.container, isOpen && styles.containerActive)}>
      <span className={styles.icon}>
        <SearchIcon />
      </span>
      <input
        className={styles.input}
        value={value}
        onChange={handleChange}
        placeholder={to === 'summary' ? text.placeholder : text.modelPlaceholder}
        onFocus={() => setIsOpen(true)}
        id="search"
        ref={inputRef}
        maxLength={50}
      />
      <button
        className={cn(styles.button, isAddButtonActive && styles.buttonActive)}
        type="button"
        onClick={handleAddSameTag}
        id="add"
      >
        {to === 'summary' ? text.addSame : text.addSameModel}
      </button>
      <ul id="tags" className={cn(styles.wrapper, isOpen && styles.wrapperActive)}>
        {displayingTags.length > 0 ? (
          displayingTags
            .slice(0, 30)
            .map(tag => (
              <Tag
                id="tag"
                tag={tag}
                key={tag.id}
                isSelected={selectedTags.some(selected => selected.id === tag.id)}
                onSelect={handleSelect}
              />
            ))
        ) : (
          <div className={styles.info}>
            <p>{text.info}</p>
            <Button onClick={handleAddNewTag} id="add" iconPosition="left">
              {text.add}
            </Button>
          </div>
        )}
      </ul>
    </div>
  );
};
