import {
  FeedbackByIdResponse,
  FeedbacksResponse,
  MessagesResponse,
} from '../../api/feedbacks/types';
import { formatDate } from '../../utils';
import { getTimeFromDate } from '../../utils/formatTime';

export const mapFeedbackById = (payload: FeedbackByIdResponse) => {
  return {
    id: payload.id,
    state: payload.state,
    date: payload.created_at,
    currentUser: payload.curr_user,
    text: payload.text,
    needUserResponse: payload.need_user_response,
    hasRate: payload.has_rate,
    attachments: payload.attachments.map(attachment => ({
      name: attachment.file_name,
      size: Number(attachment.size),
      id: attachment.id,
      type: attachment.data_type,
      url: attachment.file,
      uuid: String(attachment.id),
    })),
  };
};

export const mapMessages = (payload: MessagesResponse) => {
  return payload.map(({ id, text, created_at, message_type, who }) => ({
    id,
    date: created_at,
    text,
    messageType: message_type,
    author: who,
  }));
};

export const mapFeedbacks = (payload: FeedbacksResponse['results']) => {
  return payload.map(({ id, text, created_at, state }) => ({
    id,
    date: formatDate(new Date(created_at)),
    time: getTimeFromDate(new Date(created_at)),
    text,
    state,
  }));
};
