import { Button, TextButton } from '../../../../ui';
import { PropTypes } from './types';

import styles from './ChooseOptionPopUp.module.scss';

export const ChooseOptionPopUp = ({ title, options }: PropTypes) => {
  const { firstOption, secondOption } = options;

  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.btnsWrapper}>
        <TextButton onClick={secondOption.onClick}>
          {secondOption.text}
        </TextButton>
        <Button size="small" onClick={firstOption.onClick}>
          {firstOption.text}
        </Button>
      </div>
    </>
  );
};
