import { Trans } from 'react-i18next';

import styles from './GuideStep.module.scss';

export const GuideStep = ({ count, text }: { count: number; text: string }) => {
  return (
    <div className={styles.stepTitle}>
      <div className={styles.stepCount}>{count}</div>
      <div className={styles.stepText}>
        <div>
          <Trans
            i18nKey={text}
            components={{
              span: <span />,
            }}
          />
        </div>
      </div>
    </div>
  );
};
