import { createContext, useContext, useMemo, useState } from 'react';

import { EMPLOYEES_ONBOARDING_STEPS } from './constants';
import { EmployeesState } from '../types';

const employeesState = {
  run: false,
  stepIndex: 0,
  steps: EMPLOYEES_ONBOARDING_STEPS,
  tourActive: false,
};

export const EmployeesContext = createContext({
  state: employeesState,
  setState: () => undefined,
});
EmployeesContext.displayName = 'EmployeesContext';

export function EmployeesProvider(props: any) {
  const [state, setState] = useState(employeesState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  );

  return <EmployeesContext.Provider value={value} {...props} />;
}

export function useEmployeesContext(): {
  setState: (
    patch: Partial<EmployeesState> | ((previousState: EmployeesState) => Partial<EmployeesState>),
  ) => void;
  state: EmployeesState;
} {
  const context = useContext(EmployeesContext);

  if (!context) {
    throw new Error('useEmployeesContext must be used within a AppProvider');
  }

  return context;
}
