import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getLink } from '../../../../api/upload';
import { FileIcon, ImageIcon } from '../../../../assets/svg';
import { AttachmentTypes } from '../types';
import { filesTypesHelper, formatSize, makeFileName } from '../../../../utils';

import styles from '../Attachments.module.scss';

export const Attachment = ({
  file,
  to = '',
  isDisabled = false,
  isLink = false,
}: AttachmentTypes) => {
  const [link, setLink] = useState<string>('');
  const { t } = useTranslation();
  const { isImage, format } = filesTypesHelper(file.name);

  const fetchLink = async () => {
    const { status, payload } = await getLink(file.id, to);
    if (status === 'success') {
      setLink(payload.file);
    }
  };

  useEffect(() => {
    !isDisabled && !isLink && fetchLink();
  }, []);

  return (
    <a
      className={cn(styles.attachment, isDisabled && styles.attachmentDisabled)}
      href={isLink ? file.url || file.uuid : link}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.info}>
        <div className={styles.icon}>{isImage ? <ImageIcon /> : <FileIcon />}</div>
        <p className={styles.name}>{makeFileName(file.name)}</p>
        <p className={styles.format}>
          {t('files.typeAndSize', { type: format, size: formatSize(file.size) })}
        </p>
      </div>
    </a>
  );
};
