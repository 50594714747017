import { SyntheticEvent } from 'react';

import { alliancesApi } from '../../../api/alliancesApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { NotificationTypes } from '../../../store/types';
import { adminUserInfoSelector, setNotification } from '../../../store';
import { CheckMarkIcon } from '../../../assets/svg';
import { CrossBtn, MessageBtn, TickBtn, CrossIcon } from '../../../ui';
import { text } from './constants';
import { PropTypes } from './types';
import { adminAllianceApi } from '../../../api/adminAllianceApi';
import { UserRoleTypes } from '../../../api/alliances/types';

import styles from './ControlButtons.module.scss';

export const ControlButtons = ({
  data,
  onClick,
  onTrigger,
  isControlsDisabled,
  isAdminControls = false,
  userRole,
  notification,
}: PropTypes) => {
  const dispatch = useAppDispatch();
  const { fullName } = useAppSelector(adminUserInfoSelector);
  const [allianceAction] = isAdminControls
    ? adminAllianceApi.usePatchAllianceActionAsAdminMutation()
    : alliancesApi.usePatchAllianceActionMutation();

  const confirmNotification = {
    type: isAdminControls
      ? NotificationTypes.UserIncludedToAlliance
      : userRole === UserRoleTypes.Owner
      ? NotificationTypes.AllianceApprove
      : NotificationTypes.UserJoinedAlliance,
    data: isAdminControls
      ? { allianceName: notification, name: fullName }
      : {
          name: notification,
        },
  };

  const declineNotification = {
    type: isAdminControls
      ? NotificationTypes.UserExcludedFromAlliance
      : userRole === UserRoleTypes.Owner
      ? NotificationTypes.AllianceDecline
      : NotificationTypes.UserLeftAlliance,
    data: isAdminControls
      ? { allianceName: notification, name: fullName }
      : {
          name: notification,
        },
  };

  const handleConfirm = (e: SyntheticEvent) => {
    e.stopPropagation();
    allianceAction({
      action: `approved_by_${userRole}`,
      id: data.id,
      userRole: isAdminControls ? UserRoleTypes.Admin : userRole,
    })
      .unwrap()
      .then(() => {
        dispatch(setNotification(confirmNotification));
        onTrigger && onTrigger(`approved_by_${userRole}`);
      });
  };

  const handleDecline = (e: SyntheticEvent) => {
    e.stopPropagation();
    allianceAction({
      action: `rejected_by_${userRole}`,
      id: data.id,
      userRole: isAdminControls ? UserRoleTypes.Admin : userRole,
    })
      .unwrap()
      .then(() => {
        dispatch(setNotification(declineNotification));
        onTrigger && onTrigger(`rejected_by_${userRole}`);
      });
  };

  const handleMessageOpen = (e: SyntheticEvent) => {
    e.stopPropagation();
    onClick();
  };

  const ControlDisplay = () => {
    switch (data.status) {
      case 'approved_by_owner':
      case 'approved_by_user':
        return (
          <div className={styles.confirm}>
            <CheckMarkIcon />
            {text.confirm}
          </div>
        );
      case 'rejected_by_owner':
      case 'rejected_by_user':
        return (
          <div className={styles.reject}>
            <CrossIcon color="RED" />
            {text.reject}
          </div>
        );
      default:
        return (
          <>
            <CrossBtn
              isDisabled={isControlsDisabled}
              description={text.rejectBtn}
              onClick={handleDecline}
            />
            <TickBtn
              isDisabled={isControlsDisabled}
              description={text.confirmBtn}
              onClick={handleConfirm}
            />
          </>
        );
    }
  };

  return (
    <>
      <div className={styles.buttons}>
        {ControlDisplay()}
        {data.isMessage && <MessageBtn description={text.message} onClick={handleMessageOpen} />}
      </div>
    </>
  );
};
