import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { StarIcon } from './assets/StarIcon';
import { rating } from './constants';

import styles from './Estimation.module.scss';

type PropsType = {
  count: number;
  onClick?: Dispatch<SetStateAction<number>>;
};

export const Estimation = ({ count, onClick }: PropsType) => {
  const { t } = useTranslation();

  const iconClassName = onClick ? styles.iconActive : '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.starsWrapper}>
        {rating.map((value: number) => (
          <span className={iconClassName} onClick={() => onClick?.(value)} key={value}>
            <StarIcon isFilled={value <= count} />
          </span>
        ))}
      </div>
      {count > 0 && (
        <div className={styles.text}>
          {t('estimatedFeedback.estimation', { postProcess: 'interval', count })}
        </div>
      )}
    </div>
  );
};
