type QueriesKeysType = 'page' | 'search' | 'tab' | 'state' | 'tag' | string;

type QueriesType = {
  [key in QueriesKeysType]: string | string[] | number | boolean;
};

const decodeSymbols = (filter: string) => filter.replace(/\+/g, '%2B');

export const formatQueriesToString = (queries?: QueriesType) => {
  if (!queries) {
    return '';
  }

  const result: string[] = [];

  for (let category in queries) {
    const value = queries[category];

    if (typeof value === 'number' && value !== 0) {
      result.push(`${category}=${value}`);
    }
    if (typeof value === 'boolean') {
      result.push(`${category}=${value}`);
    }
    if (typeof value === 'string' && value.length > 1) {
      result.push(`${category}=${value}`);
    }
    if (Array.isArray(value) && value.length > 0) {
      result.push(`${category}=${value.map(value => decodeSymbols(value)).join(',')}`);
    }
  }

  if (result.length > 0) {
    return `?${result.join('&')}`;
  }

  return '';
};
