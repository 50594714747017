import { ReactNode } from 'react';

export type PropTypes = {
  columns: string[];
  type: TableTypes;
  children: ReactNode;
  isShowTable?: boolean;
  isSearchResult?: boolean;
  isWithoutBorder?: boolean;
  isColored?: boolean;
  helpText?: string;
  button?: {
    text: string;
    isDisabled?: boolean;
    onAction: () => void;
  };
  isNextPage?: boolean;
  nextPageResponse?: string | null;
  handleNextPage?: () => void;
  pagesCount?: number;
  handleSwitchPage?: (page: number) => void;
};

export enum TableTypes {
  DefaultAlliance = 0,
  AllianceRequests = 1,
  DefaultMembers = 2,
  MembersInvites = 3,
  DefaultModels = 4,
  MyModels = 5,
  AddedModels = 6,
  AdminModels = 7,
  AdminWeight = 8,
  AllianceModels = 9,
  AddedWeights = 10,
  AllianceModelsRequests = 11,
  AllianceWeightsRequests = 12,
  ControlledAlliances = 13,
  AllianceInvitations = 14,
  AllianceMembersRequests = 15,
  Employees = 16,
  FavoriteAlliances = 17,
  ModelWeights = 18,
  FLEmployees = 19,
  ControlledFLEmployees = 20,
  MyAlliances = 21,
  AdminAllAlliances = 22,
  AdminMyAlliances = 23,
  AdminUsers = 24
}
