import { SummaryResponse } from '../../api/summary/types';

export const mapSummary = (payload: SummaryResponse) => {
  return {
    id: payload.id,
    specialization: payload.specialization,
    experience: payload.experience,
    country: payload.country,
    region: payload.region,
    skills: payload.skills,
    about: payload.about,
    link: { link: payload.socials[0].link || '', name: payload.socials[0].link_name || '' },
    isSummaryHidden: false,
    isFavorite: payload.is_favorite
  };
};
