import { request } from '../utils/fetchHandler';

import { ITag, PopularTagsResponse } from './tags/types';

export const getPopularTags = async (
  value = '',
  to: 'summary' | 'model',
): Promise<{
  status: string;
  payload: PopularTagsResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/${to === 'summary' ? 'developer/skills' : 'mlmodels/tags'}/${
      value.length > 1 ? `?search=${value}` : ''
    }`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const addCustomTag = async (
  value: string,
  to: 'summary' | 'model',
): Promise<{
  status: string;
  payload: ITag;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/${to === 'summary' ? 'developer/skills' : 'mlmodels/tags'}/`,
    {
      credentials: 'include',
    },
    'POST',
    JSON.stringify({ name: value }),
  );

  return { status, payload };
};
