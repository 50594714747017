import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { authApi } from '../../../../api/authApi';
import { Button, Input } from '../../../../ui';
import { FormNotice } from '../FormNotice/FormNotice';
import { CloseIcon } from '../../../../assets/svg';
import { emailSchema } from '../../../../schemas/validationSchemas';
import { useAppSelector, useToggle } from '../../../../hooks';
import { LOG_IN_PATH } from '../../../../router';
import { accountSelector } from '../../../../store';
import { isError } from '../../../../utils/formatData';

import styles from '../Form.module.scss';

export const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const { state: isLetterSent, setTrue: setLetterSent } = useToggle<boolean>(false);
  const {
    handleSubmit,
    setError,
    getValues,
    formState: { isValid, errors },
    register,
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: 'onTouched',
  });

  const { isCookieAccepted } = useAppSelector(accountSelector);

  const [resetPassword] = authApi.useResetPasswordMutation();

  const onSubmit: SubmitHandler<FieldValues> = async ({ email }) => {
    if (!isCookieAccepted) {
      return;
    }
    resetPassword(email)
      .unwrap()
      .then(() => setLetterSent())
      .catch(error => {
        setError('email', { type: 'custom', message: error.data.detail });
      });
  };

  return (
    <>
      {!isLetterSent ? (
        <form
          className={styles.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          <NavLink to={`/${LOG_IN_PATH}`} className={styles.closeBtn}>
            <CloseIcon />
          </NavLink>
          <h2 className={styles.title}>{t('pages.authPage.resetPasswordForm.title')}</h2>
          <div className={cn(styles.subtitle, styles.subtitleBright)}>
            {t('pages.authPage.resetPasswordForm.subtitle')}
          </div>
          <div className={styles.container}>
            <Input
              id="email"
              label={t('pages.authPage.resetPasswordForm.label')}
              register={register}
              helperText={errors.email?.message}
              isDisabled={false}
              maxLength={50}
            />
          </div>
          <Button isDisabled={isError(errors) || !isValid} type="submit">
            {t('buttons.resetPassword')}
          </Button>
        </form>
      ) : (
        <FormNotice
          noticeType="reset"
          email={getValues('email')}
          canBeClosed={true}
          isRecovery={true}
        />
      )}
    </>
  );
};
