import cn from 'classnames';

import styles from './TitledIcon.module.scss';

type TitledIconProps = {
  icon: JSX.Element;
  title: string;
  isPrimary?: boolean;
};

export const TitledIcon = ({ icon, title, isPrimary = false }: TitledIconProps) => {
  const containerClassName = cn(styles.container, { [styles.containerPrimary]: isPrimary });
  return (
    <div className={containerClassName}>
      {icon}
      <p className={styles.title}>{title}</p>
    </div>
  );
};
