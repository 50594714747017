import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { authApi } from '../../../api/authApi';
import { Roles } from '../../../store/types';
import { useAppDispatch } from '../../../hooks';
import { setRole } from '../../../store';
import { LOG_IN_PATH } from '../../../router';
import { Button } from '../../../ui';
import { formatTime } from '../../../utils/formatTime';
import { DEFAULT_TIME, resendText } from './constants';

import styles from './ResendControl.module.scss';

export const ResendControl = ({ text }: { text: string }) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const dispatch = useAppDispatch();

  const [tokenCheck] = authApi.useTokenCheckMutation();
  const [resendTime] = authApi.useResendTimeCheckMutation();
  const [resendMail] = authApi.useResendMailMutation();

  const handleTokenCheck = async (token: string) => {
    tokenCheck(token)
      .unwrap()
      .then(() => dispatch(setRole(Roles.Unknown)))
      .catch(() => navigate(LOG_IN_PATH));
  };

  const handleResend = async () => {
    resendMail()
      .unwrap()
      .then(() => setTimeLeft(DEFAULT_TIME));
  };

  const handleCheckResend = async () => {
    resendTime()
      .unwrap()
      .then(time => (time.length > 4 ? time.substring(5, 7) : time));
  };

  useEffect(() => {
    const TIME_OUT_ID: NodeJS.Timeout | null =
      timeLeft > 0 ? setTimeout(() => setTimeLeft(timeLeft - 1), 1000) : null;
    return () => {
      if (TIME_OUT_ID) {
        clearTimeout(TIME_OUT_ID);
      }
    };
  }, [timeLeft]);

  useEffect(() => {
    if (token) {
      handleTokenCheck(token);
      localStorage.clear();
      return;
    }
    handleCheckResend();
  }, []);

  return timeLeft === 0 ? (
    <Button onClick={handleResend} style="secondary" size="small" isResizing>
      {text}
    </Button>
  ) : (
    <span className={styles.text}>
      {resendText}
      {formatTime(timeLeft)}
    </span>
  );
};
