import cn from 'classnames';

import { InformationIcon, WarningIcon } from '../../../assets/svg';

import styles from './InfoBlock.module.scss';

type PropsType = {
  text: string;
  button?: JSX.Element;
  style?: 'primary' | 'secondary' | 'bold';
  type?: 'info' | 'warning';
};

export const InfoBlock = ({ text, button, style = 'primary', type = 'info' }: PropsType) => {
  const containerClassName = cn(
    styles.container,
    style === 'secondary' && styles.containerSecondary,
    style === 'bold' && styles.containerBold,
    type === 'warning' && styles.containerWarning,
  );

  return (
    <div className={containerClassName}>
      <div className={styles.info}>
        <span className={styles.icon}>
          {type === 'info' ? <InformationIcon isBlue={true} /> : <WarningIcon />}
        </span>
        <p className={styles.text}>{text}</p>
      </div>
      {button}
    </div>
  );
};
