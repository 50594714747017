import cn from 'classnames';

import { SearchTagsController } from './SearchTagsController/SearchTagsController';
import { Tag } from './Tag/Tag';
import { text } from './constants';
import { PropTypes } from './types';

import styles from './Tags.module.scss';

export const Tags = ({ selectedTags, setSelectedTags, to = 'summary' }: PropTypes) => {
  const handleDelete = (tag: { name: string; id: number }) => {
    setSelectedTags(selectedTags.filter(selected => selected.id !== tag.id));
  };

  return (
    <div className={styles.container}>
      <p className={cn(styles.title, styles.titleRequired, to !== 'summary' && styles.titleBold)}>
        {to === 'summary' ? text.title : text.modelTitle}
      </p>
      {selectedTags.length > 0 && (
        <ul className={styles.tags}>
          {selectedTags.map(tag => (
            <Tag
              tag={tag}
              key={tag.id}
              isSelected={true}
              onSelect={handleDelete}
              id="tag"
              isCross={true}
            />
          ))}
        </ul>
      )}
      <SearchTagsController selectedTags={selectedTags} setSelectedTags={setSelectedTags} to={to} />
    </div>
  );
};
