import { request } from '../utils/fetchHandler';
import {
  SummaryData,
  EditSummaryResponse,
  SummaryResponse,
  AddSummaryResponse,
  SpecializationsResponse,
} from './summary/types';

export const getSummary = async (
  id: number | null | string | undefined,
): Promise<{
  status: string;
  payload: SummaryResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/${id}/resume/`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const newSummary = async (
  data: SummaryData,
): Promise<{
  status: string;
  payload: AddSummaryResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/create-resume/`,
    {
      credentials: 'include',
    },
    'POST',
    JSON.stringify(data),
  );

  return { status, payload };
};

export const editSummary = async (
  data: SummaryData,
  id: number | null,
): Promise<{
  status: string;
  payload: EditSummaryResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/${id}/update-resume/`,
    {
      credentials: 'include',
    },
    'PUT',
    JSON.stringify(data),
  );

  return { status, payload };
};

export const hideSummary = async (isHide: boolean, id: number | null) => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/${id}/switch-cv-visibility/`,
    { credentials: 'include' },
    'PATCH',
    JSON.stringify({ is_hidden: isHide }),
  );

  return { status };
};

export const getSpecializationList = async (): Promise<{
  status: string;
  payload: SpecializationsResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/specialization/`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};
