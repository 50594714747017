import * as yup from 'yup';

const name = yup
  .string()
  .required('Название ML-модели обязательно к заполнению')
  .min(2, 'Название ML-модели не может быть короче 2-х символов')
  .test(
    'Название ML-модели не может быть короче 2-х символов',
    'Название ML-модели не может быть короче 2-х символов',
    (value): boolean => Boolean(value && value?.trim().length >= 2),
  );

const tasksType = yup.string().required('Выберите тип задач');
const category = yup.string().required('Выберите категорию');

const modelType = yup
  .string()
  .required('Тип ML-модели обязателен к заполнению')
  .min(2, 'Тип ML-модели не может быть короче 2-х символов')
  .test(
    'Тип ML-модели не может быть короче 2-х символов',
    'Тип ML-модели не может быть короче 2-х символов',
    (value): boolean => Boolean(value && value?.trim().length >= 2),
  );

const customTag = yup
  .string()
  .nullable()
  .notRequired()
  .when('customTag', {
    is: (value: string) => value?.length,
    then: rule =>
      rule
        .min(2, 'Тег не может быть короче 2-х символов')
        .test('Введите корректные символы', 'Введите корректные символы', (value): boolean =>
          Boolean(value && !/[^A-zА-яё0-9\s]/.test(value)),
        ),
  });

const description = yup
  .string()
  .required('Описание ML-модели обязательно к заполнению')
  .min(50, 'Описание ML-модели не может быть короче 50-ти символов')
  .test(
    'Описание ML-модели не может быть короче 50-ти символов',
    'Описание ML-модели не может быть короче 50-ти символов',
    (value): boolean => Boolean(value && value?.trim().length >= 50),
  );

const roundDuration = yup
  .string()
  .required('Длительность раунда обязательна к заполнению')
  .test('Неверный формат', 'Неверный формат', (value): boolean =>
    Boolean(value && /^[0-9]+$/.test(value)),
  )
  .test(
    'Минимальная длительность раунда — 2 часа, максимальная — 168 часов',
    'Минимальная длительность раунда — 2 часа, максимальная — 168 часов',
    (value): boolean => Boolean(value && Number(value) >= 2 && Number(value) <= 168),
  );

export const modelSchema = yup.object().shape(
  {
    name,
    tasksType,
    modelType,
    description,
    customTag,
    category,
  },
  [['customTag', 'customTag']],
);

export const firstStepModelSchema = yup.object().shape({
  name,
  tasksType,
  modelType,
  description,
  category,
});

export const startFLSchema = yup.object().shape({
  roundDuration,
});
