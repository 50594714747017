import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { isAdminSelector, openPopup } from '../../../../../../../store';
import { PopupTypes } from '../../../../../../../store/types';
import { modelsApi } from '../../../../../../../api/ModelsApi';
import { adminModelApi } from '../../../../../../../api/adminModelApi';
import { Button, TextButton } from '../../../../../../../ui';
import { ErrorIcon } from '../../../../../../AlliancePage/AllianceFL/ModelFLCard/CardHead/RoundState/assets';

import styles from './DockerImageError.module.scss';

export const DockerImageError = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isAdmin = useAppSelector(isAdminSelector);
  const [rebuildImage] = isAdmin
    ? adminModelApi.usePostRebuildImageAsAdminMutation()
    : modelsApi.usePostRebuildImageMutation();

  const handleFeedbackClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.Feedback,
      }),
    );
  };

  const handleRebuildImage = () => {
    rebuildImage({ id: Number(id) });
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.titleWrapper}>
          <ErrorIcon />
          <div className={styles.title}>
            {t('pages.FLPage.roundState.buildingImageWasFailed.title')}
          </div>
        </div>
        <div className={styles.subtitle}>
          {t('pages.FLPage.roundState.buildingImageWasFailed.subtitle.owner')}
        </div>
      </div>
      <div className={styles.btnWrapper}>
        <Button size="small" onClick={handleRebuildImage}>
          {t('pages.FLPage.roundReTry')}
        </Button>
        {!isAdmin && (
          <TextButton size="small" onClick={handleFeedbackClick}>
            {t('pages.FLPage.reportAboutError')}
          </TextButton>
        )}
      </div>
    </div>
  );
};
