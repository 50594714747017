import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ADMIN_PATH, APPEALS_PATH, SUPPORT_PATH } from '../../../../router';
import { useNavigation } from '../../../../hooks';
import { Status } from '../../../../ui';
import { IApeal } from '../types';
import { AppealStatuses } from '../../../AppealPage/constants';

import styles from './AppealRow.module.scss';

type PropsType = {
  role: 'user' | 'admin';
  appeal: IApeal | Omit<IApeal, 'user' | 'company' | 'email' | 'position'>;
};

export const AppealRow = ({ appeal, role }: PropsType) => {
  const { t } = useTranslation();
  const { onNavigate } = useNavigation();

  const { id, user, position, email, company, date, time, state } = appeal as IApeal;

  const appealClassname = cn(styles.appeal, {
    [styles.appealUser]: role === 'user',
    [styles.appealAdmin]: role === 'admin',
  });

  return (
    <li
      className={appealClassname}
      onClick={() =>
        onNavigate(
          `/${role === 'admin' ? `${ADMIN_PATH}/${APPEALS_PATH}` : SUPPORT_PATH}/${id}`,
          true,
        )
      }
    >
      <div className={styles.column}>
        <p className={styles.number}>№{id}</p>
      </div>
      {role === 'admin' && (
        <>
          <div className={styles.column}>
            <p className={styles.primary}>{user}</p>
            <p className={styles.position}>{position ?? '—'}</p>
          </div>
          <div className={styles.column}>
            <p className={styles.email}>{email}</p>
          </div>
          <div className={styles.column}>
            {company ? (
              <>
                <p className={styles.primary}>{company.name}</p>
                <p className={styles.secondary}>{company.inn}</p>
              </>
            ) : (
              <p className={styles.primary}>—</p>
            )}
          </div>
        </>
      )}
      <div className={styles.text}>
        <p>{appeal.text}</p>
      </div>
      <div className={styles.column}>
        <p className={styles.date}>{date}</p>
        <p className={styles.secondary}>{time}</p>
      </div>
      <div className={styles.column}>
        <Status state={AppealStatuses[state]} text={t(`pages.appealsPage.statuses.${state}`)} />
      </div>
    </li>
  );
};
