import cn from 'classnames';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { modelsApi } from '../../../api/ModelsApi';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { isAdminSelector, openPopup, openReloadErrorPopup, setNotification } from '../../../store';
import { NotificationTypes, PopupTypes } from '../../../store/types';
import { formatSize } from '../../../utils';
import { formatDate } from '../../../utils/formatData';
import { MB } from '../Upload/constants';
import { DeleteBtn, DownloadBtn, EditBtn, MetricTag, Status } from '../../common';
import { TitledInfo } from '../TitledInfo/TitledInfo';
import { Param } from '../Params/Param/Param';
import { WeightPropTypes } from './types';
import { adminModelApi } from '../../../api/adminModelApi';

import styles from './WeightCard.module.scss';

export const WeightCard = ({
  isOwner,
  modelName,
  weight,
  allianceName,
  selectedTags,
  isDisable = false,
}: WeightPropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAdmin = useAppSelector(isAdminSelector);
  const [getDownloadWeightLink] = modelsApi.useGetDownloadWeightLinkMutation();
  const [deleteWeight] = isAdmin
    ? adminModelApi.useDeleteWeightAsAdminMutation()
    : modelsApi.useDeleteWeightMutation();

  const { model, test, train } = weight.params;

  const paramsList = [...model, ...test, ...train].map((elem, id) => (
    <Param key={id} isReadOnly param={elem} />
  ));

  const metricsList = weight.metrics.map(elem => (
    <MetricTag
      key={elem.name}
      metric={elem}
      isReadOnly
      isSelected={selectedTags.some((selected: string) => selected === elem.name)}
    />
  ));

  const handleDownloadClick = async (e: SyntheticEvent) => {
    e.stopPropagation();
    getDownloadWeightLink(weight.id)
      .unwrap()
      .catch(() => dispatch(openReloadErrorPopup()));
  };

  const handleEditClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      openPopup({
        type: PopupTypes.AddOrEditWeight,
        data: {
          weightData: { ...weight },
          weightId: weight.id,
          isOwner: true,
          paramsData: weight.params,
          metricsData: weight.metrics,
          fileData: new File(['some data'], `${weight.fileName}`),
          isPending: weight.state === 'pending',
        },
      }),
    );
  };

  const handleDelete = () => {
    deleteWeight({ id: weight.id })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: allianceName
              ? NotificationTypes.WeightDeletedFromAlliance
              : NotificationTypes.WeightDeletedFromModel,
            data: {
              weightName: weight.name,
            },
          }),
        );
      });
  };

  const handleDeleteOpen = (e: SyntheticEvent) => {
    e.stopPropagation();
    dispatch(
      openPopup({
        type: PopupTypes.DeleteWeight,
        data: { modelName, weight: weight.name },
        onAction: handleDelete,
      }),
    );
  };

  const handleRowClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DetailedWeight,
        data: {
          weightData: weight,
          title: t('popups.weight.title', { model: modelName }),
          modelName,
          controlsType: isOwner ? 'all' : 'readOnly',
          isPaid: isOwner ? false : isDisable,
          onDownloadClick: handleDownloadClick,
          onDeleteClick: handleDeleteOpen,
        },
      }),
    );
  };

  const StatusDisplay = () => {
    if (!isOwner || !weight.state) {
      return <></>;
    }
    if (weight.state === 'valid') {
      return <Status state={'confirmed'} text={t('statuses.confirm')} />;
    }
    if (weight.state === 'invalid') {
      return <Status state={'canceled'} text={t('statuses.cancel')} />;
    }
    if (weight.state === 'pending') {
      return <Status state={'waiting'} text={t('statuses.waiting')} />;
    }
  };

  const downloadBtnDescr = t(
    `pages.singleModelPage.btns.downloadWeights${isDisable ? 'Paid' : ''}`,
  );
  const cardStyles = cn(styles.card, {
    [styles.cardValidating]: weight.state === 'pending',
  });

  return (
    <li className={cardStyles} onClick={handleRowClick}>
      <div className={styles.mainInfo}>
        <div className={styles.title}>
          <h3 className={styles.name}>{weight.name}</h3>
          <TitledInfo
            title={t('pages.singleModelPage.date')}
            text={formatDate(new Date(weight.date))}
            isRow
            isMonochrome
          />
        </div>
        {StatusDisplay()}
      </div>
      <div className={styles.divider} />
      <div className={styles.secondaryInfo}>
        <p className={styles.description}>{weight.description}</p>
        {metricsList.length > 0 && (
          <div className={styles.characteristicsBlock}>
            <h3 className={styles.subtitle}>Метрики</h3>
            <ul className={styles.characteristicsList}>{metricsList}</ul>
          </div>
        )}
        {paramsList.length > 0 && (
          <div className={styles.characteristicsBlock}>
            <h3 className={styles.subtitle}>Параметры</h3>
            <ul className={styles.characteristicsList}>{paramsList}</ul>
          </div>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.controlsPanned}>
        <div className={styles.controlsBlock}>
          {isOwner && <EditBtn description={t('buttons.edit')} onClick={handleEditClick} />}
          {isOwner && <DeleteBtn description={t('buttons.delete')} onClick={handleDeleteOpen} />}
        </div>
        <div className={styles.downloadBlock}>
          <p className={styles.size}>
            {formatSize(weight.size)}
            <span className={styles.secondary}>{MB}</span>
          </p>
          <DownloadBtn
            description={downloadBtnDescr}
            onClick={handleDownloadClick}
            disabled={isDisable}
          />
        </div>
      </div>
    </li>
  );
};
