import { useTranslation } from 'react-i18next';

import { Button } from '../../../common';

import styles from './ErrorPopup.module.scss';

export const ErrorPopup = ({ isReload }: { isReload?: boolean }) => {
  const { t } = useTranslation();

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon} />
      <h3 className={styles.title}>{t('popups.error.title')}</h3>
      <p className={styles.subtitle}>{t('popups.error.subtitle')}</p>
      {isReload && <Button onClick={handleReload}>{t('buttons.reload')}</Button>}
    </div>
  );
};
