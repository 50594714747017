import { NavLink } from 'react-router-dom';
import { ChangeHandler } from 'react-hook-form';
import cn from 'classnames';

import { EyeClosed, EyeOpen } from '../../../../assets/svg';
import { RESET_PASSWORD_PATH } from '../../../../router';

import styles from '../Input/Input.module.scss';

type PropsType = {
  id: string;
  label: string;
  type: string;
  register: (name: string) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
  helperText?: any;
  value?: string | undefined;
  onClick?: () => void;
  isForgotten?: boolean;
};

export const PasswordInput = ({
  id,
  label,
  type,
  register,
  helperText,
  value,
  onClick,
  isForgotten,
}: PropsType) => {
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <input
          className={inputClassName}
          type={type}
          id={id}
          required
          {...register?.(id)}
          placeholder=" "
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {value && (
          <div className={styles.icon} onClick={onClick}>
            {type === 'text' ? <EyeOpen /> : <EyeClosed />}
          </div>
        )}
      </div>
      <div className={cn(styles.error, isForgotten && styles.grid)}>
        {isForgotten ? (
          <>
            <span>{helperText}</span>
            <NavLink to={RESET_PASSWORD_PATH} className={styles.forgotten}>
              Забыли пароль?
            </NavLink>
          </>
        ) : (
          <>{helperText}</>
        )}
      </div>
    </div>
  );
};
