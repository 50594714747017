export const ChangeIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M10.1043 3.12321L13.582 6.60089M1.41016 15.2951L5.62779 14.4453C5.85169 14.4001 6.05728 14.2899 6.21873 14.1283L15.6603 4.68158C16.113 4.22866 16.1127 3.49449 15.6596 3.04195L13.6596 1.04414C13.2067 0.591788 12.4729 0.592096 12.0204 1.04483L2.57785 10.4926C2.4167 10.6538 2.30667 10.859 2.26151 11.0824L1.41016 15.2951Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
