import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { RequestCreatorType } from '../../../types';

import styles from './RequestCreatorInfo.module.scss';

export const RequestCreatorInfo = ({ user }: RequestCreatorType) => {
  const { fullName, position, companyName, INN, industry, email } = user;
  const { t } = useTranslation();

  const wrapperClassName = cn(styles.wrapper, {
    [styles.wrapperFlex]: companyName,
    [styles.wrapperGrid]: !companyName,
  });

  return (
    <div className={wrapperClassName}>
      <div className={styles.infoBlock}>
        <h3 className={styles.title}>{t('headings.fullName')}</h3>
        <p className={styles.textPrimary}>{fullName}</p>
        <p className={styles.textSecondary}>{position}</p>
      </div>
      {companyName && (
        <>
          <div className={styles.infoBlock}>
            <h3 className={styles.title}>{t('headings.company')}</h3>
            <p className={styles.textPrimary}>{companyName}</p>
            <p className={styles.textSecondary}>{INN}</p>
          </div>
          <div className={styles.infoBlock}>
            <h3 className={styles.title}>{t('headings.industry')}</h3>
            <p className={styles.textPrimary}>{industry}</p>
          </div>
        </>
      )}
      <div className={styles.infoBlock}>
        <h3 className={styles.title}>{t('headings.email')}</h3>
        <a href={`mailto:${email}`} className={cn(styles.textPrimary, styles.email)}>
          {email}
        </a>
      </div>
    </div>
  );
};
