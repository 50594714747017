import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MODELS_PATH } from '../../../../router';
import { useNavigation } from '../../../../hooks';
import { transformObjectToArray } from '../../../../utils/formatData';
import {
  TextButton,
  TitledInfo,
  Upload,
  Params,
  AccordionItem,
  ProvidedTitle,
} from '../../../../ui';
import { AccordionHead } from '../../../../ui/components/AccordionList/AccordionHead/AccordionHead';
import { ChangeIcon } from '../../../../assets/svg';
import { accordionData } from '../../constants';
import { ModelContentProps } from '../../types';
import { AccordionBody } from './AccordionBody/AccordionBody';

import styles from './ModelContent.module.scss';

export const ModelContent: FC<ModelContentProps> = ({ data, id, isControlled, isInPopup }) => {
  const { t } = useTranslation();
  const { onNavigate } = useNavigation();
  const params = {
    model: transformObjectToArray(data.modelParams),
    dataset: transformObjectToArray(data.datasetParams),
    test: transformObjectToArray(data.testParams),
    train: transformObjectToArray(data.trainParams),
  };
  const isParamsHidden =
    !params.model.length && !params.dataset.length && !params.test.length && !params.train.length;
  const isAttachmentHidden = isInPopup && !data.attachments.length;
  const isAttachmentTitleShown = isControlled || (!isControlled && data.attachments.length > 0);
  const { content, title } = accordionData;

  return (
    <div className={styles.col}>
      <div className={styles.contentBlock}>
        {data.isJointMl && <ProvidedTitle title={t('headings.providedModel')} />}
        <div className={styles.modelType}>
          <TitledInfo title={t('pages.singleModelPage.modelType')} text={data.type} isSmallGaps />
        </div>
        <div>
          <TitledInfo
            title={t('pages.singleModelPage.description')}
            text={data.description}
            isSmallGaps
          />
        </div>
        {isControlled && (
          <div className={styles.addButtonWrap}>
            <TextButton
              iconProperty={{ position: 'right', icon: <ChangeIcon /> }}
              onClick={() => onNavigate(`/${MODELS_PATH}/${id}/edit`, true)}
            >
              {t('pages.singleModelPage.editInfo')}
            </TextButton>
          </div>
        )}
      </div>
      <div className={`${styles.contentBlock} paramsBlock`}>
        {!isParamsHidden && (
          <div>
            <Params params={params} setParams={() => ({})} isReadOnly />
          </div>
        )}
        {isControlled && (
          <div className={styles.addButtonWrap}>
            <TextButton
              iconProperty={{ position: 'right', icon: <ChangeIcon /> }}
              onClick={() => onNavigate(`/${MODELS_PATH}/${id}/edit-params`, true)}
            >
              {t('pages.singleModelPage.editParams')}
            </TextButton>
          </div>
        )}
      </div>
      {!isAttachmentHidden && (
        <div>
          {isAttachmentTitleShown && (
            <h3 className={styles.subTitle}>{t('pages.singleModelPage.attachments')}</h3>
          )}
          <div className={styles.contentBlock}>
            {isControlled && (
              <AccordionItem
                head={<AccordionHead title={title} />}
                body={<AccordionBody content={content} />}
              />
            )}
            <Upload
              attachments={data.attachments}
              data={{ name: data.name, id, to: 'mlmodel' }}
              isControlled={isControlled}
            />
          </div>
        </div>
      )}
    </div>
  );
};
