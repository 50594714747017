export const StarIcon = ({ isFilled }: { isFilled: boolean }) => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill={isFilled ? '#FAAD39' : 'none'}>
    <path
      d="M14.3248 1.61874C14.6003 1.06068 15.396 1.06068 15.6715 1.61874L19.2368 8.84291C19.3462 9.06451 19.5576 9.21811 19.8021 9.25365L27.7745 10.4121C28.3903 10.5016 28.6362 11.2584 28.1906 11.6928L22.4218 17.316C22.2448 17.4885 22.164 17.7371 22.2058 17.9806L23.5677 25.9208C23.6729 26.5341 23.0291 27.0019 22.4782 26.7123L15.3475 22.9635C15.1288 22.8485 14.8675 22.8485 14.6487 22.9635L7.51807 26.7123C6.96723 27.0019 6.32343 26.5341 6.42863 25.9208L7.79047 17.9806C7.83225 17.7371 7.75149 17.4885 7.57453 17.316L1.80569 11.6928C1.36005 11.2584 1.60596 10.5016 2.22182 10.4121L10.1942 9.25365C10.4387 9.21811 10.6501 9.06451 10.7595 8.84291L14.3248 1.61874Z"
      stroke="#FAAD39"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </svg>
);
