import * as yup from 'yup';

const name = yup
  .string()
  .required('Название версии обязательно к заполнению')
  .min(2, 'Название версии не может быть короче 2-х символов');

const description = yup
  .string()
  .required('Данные, на которых обучалась модель, обязательны к заполнению')
  .min(2, 'Данные, на которых обучалась модель, не могут быть короче 2-х символов');

const metricName = yup
  .string()
  .required('Это поле обязательно для заполнения')
  .min(2, 'Название метрики не может быть короче 2-х символов');

const metricValue = yup
  .string()
  .required('Это поле обязательно для заполнения')
  .test(
    'Значение метрики должно быть числом',
    'Значение метрики должно быть числом',
    (value): any => value && Number(value),
  );

export const weightSchema = yup.object().shape({
  name,
  description,
});

export const nameSchema = yup.object().shape({
  name,
});

export const metricsSchema = yup.object().shape({
  metricName,
  metricValue,
});
