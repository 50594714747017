import { ChangeEvent, useState, useEffect } from 'react';

import { useDebounce } from '../../../hooks/useDebounce';
import { appealsApi } from '../../../api/appealsApi';
import { useAppSelector } from '../../../hooks';
import { roleSelector } from '../../../store';
import { Roles } from '../../../store/types';
import { feedbacksApi } from '../../../api/FeedbacksApi';

interface IQueries {
  search: string;
  page: number;
  state: string;
}

const defaultQueries = {
  search: '',
  page: 0,
  state: '',
};

export const useAppealsData = (tab: string, extraParams?: Record<string, string> | null) => {
  const [search, setSearch] = useState<string>('');
  const [queries, setQueries] = useState<IQueries>(defaultQueries);
  const role = useAppSelector(roleSelector);

  useEffect(() => {
    setQueries({
      ...defaultQueries,
      ...extraParams,
      state: tab === 'all' || tab === 'current' ? '' : tab,
    });
  }, [tab]);

  const { isError, isLoading, data, isFetching } =
    role === Roles.SystemAdministrator
      ? appealsApi.useGetAppealsListQuery(queries)
      : feedbacksApi.useGetFeedbacksListQuery(queries);

  const handleSearch = (search: string) => {
    if (search.length > 1) {
      setQueries(prev => ({ ...prev, search, page: 0 }));
    } else {
      setQueries(prev => ({ ...prev, search }));
    }
  };
  const debouncedSearch = useDebounce(handleSearch, 500);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    debouncedSearch(value);
  };

  const handleReset = () => {
    setSearch('');
    setQueries(prev => ({ ...prev, search: '', page: 0 }));
  };

  const handleSwitchPage = (page: number) => {
    setQueries(prev => ({ ...prev, page }));
  };

  const isEmpty = search.length === 0 && data?.results.length === 0;
  const isSearching = isFetching && search.length > 1;

  return {
    data: data?.results ?? [],
    pages: Math.ceil((data?.count ?? 0) / 10),
    isLoading,
    isEmpty,
    isSearching,
    value: search,
    isError,
    handleChange,
    handleReset,
    handleSwitchPage,
  };
};
