import ReactInputMask from 'react-input-mask';
import cn from 'classnames';

import styles from '../Input/Input.module.scss';

type PropsType = {
  id: string;
  label: string;
  register?: any;
  helperText?: any;
  isDisabled?: boolean;
};

export const PhoneInput = ({ isDisabled = false, id, label, register, helperText }: PropsType) => {
  const wrapperClassName = cn(styles.wrapper, {
    [styles.wrapperDisable]: isDisabled,
  });
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
    [styles.inputDisable]: isDisabled,
  });

  return (
    <div className={wrapperClassName}>
      <div className={styles.container}>
        <ReactInputMask
          className={inputClassName}
          id={id}
          required
          mask="+\7 (999) 999-99-99"
          maskChar="x"
          placeholder=" "
          disabled={isDisabled}
          {...register?.(id)}
        />
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      </div>
      <div className={styles.error}>{helperText}</div>
    </div>
  );
};
