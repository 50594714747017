import { Tag } from '../../../../ui';

import styles from './Tags.module.scss';

export const Tags = ({ tags }: { tags: string[] }) => {
  return (
    <div className={styles.tags}>
      {tags.map((tag, i) => (
        <Tag key={i} style="disabled">
          {tag}
        </Tag>
      ))}
    </div>
  );
};
