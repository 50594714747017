import { useEffect } from 'react';

export const useClickOutside = (idList: string[], onClose: () => void) => {
  const handleClickOutside = (e: MouseEvent) => {
    const { id } = e.target as HTMLElement;
    if (idList.includes(id)) {
      return;
    }
    onClose();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};
