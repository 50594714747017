import { CheckMarkIcon, DeleteIcon, WaitingIcon } from '../../../assets/svg';
import { AllianceUserStatus } from '../../../types';
import { CrossIcon } from '../../../ui';
import { text } from './constants';

import styles from './StatusWithDate.module.scss';

export const StatusWithDate = ({ status, date }: { status: string; date: string }) => {
  const useStatus = () => {
    let statusIcon = null;
    let statusText = null;
    switch (status) {
      case AllianceUserStatus.OwnerApproved:
      case AllianceUserStatus.UserApproved:
        statusText = text.approved;
        statusIcon = <CheckMarkIcon />;
        break;
      case AllianceUserStatus.UserWaiting:
      case AllianceUserStatus.OwnerWaiting:
      case AllianceUserStatus.Waiting:
        statusText = text.waiting;
        statusIcon = <WaitingIcon />;
        break;
      case AllianceUserStatus.Deleted:
        statusText = text.deleted;
        statusIcon = <DeleteIcon />;
        break;
      default:
        statusText = text.rejected;
        statusIcon = <CrossIcon color="RED" />;
    }

    return { statusIcon, statusText };
  };

  const { statusIcon, statusText } = useStatus();
  return (
    <div className={styles.container}>
      {statusIcon}
      <div className={styles.wrapper}>
        <p className={styles.status}>{statusText}</p>
        <p className={styles.date}>{date}</p>
      </div>
    </div>
  );
};
