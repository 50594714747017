import { ArrowDownIcon } from '../../../../assets/svg';

import styles from './PaginationButton.module.scss';

interface PropTypes {
  onClick: () => void;
}

export const PaginationButton = ({ onClick }: PropTypes) => {
  return (
    <button className={styles.btn} onClick={onClick} type="button">
      <span>Показать еще</span>
      <span className={styles.btnIcon}>
        <ArrowDownIcon />
      </span>
    </button>
  );
};
