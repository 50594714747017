import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import {
  HOW_TO_ADD_MODEL_PATH,
  HOW_TO_START_FL_PATH,
  START_USE_MODEL_PATH,
  WHERE_TO_START_PATH,
} from '../routeConstants';
import { NewUserProtection } from '../HOC/RoutesProtection';

const GetStartedPage = lazy(() => import('../../pages/GuidePage/GetStartedPage/GetStartedPage'));
const StartFLGuide = lazy(() => import('../../pages/GuidePage/StartFLGuide/StartFLGuide'));
const AddModelManualPage = lazy(() =>
  import('../../pages/GuidePage/AddModelManualPage/AddModelManualPage'),
);
const WhereToStartPage = lazy(() =>
  import('../../pages/GuidePage/WhereToStartPage/WhereToStartPage'),
);
const StartUseModelPage = lazy(() =>
  import('../../pages/GuidePage/StartUseModelPage/StartUseModelPage'),
);
const NewProfile = lazy(() => import('../../pages/NewProfilePage/NewProfilePage'));

export const aboutRoutes = {
  path: '',
  element: (
    <NewUserProtection protectedPage={suspense(<Outlet />)} publicPage={suspense(<NewProfile />)} />
  ),
  children: [
    {
      index: true,
      element: suspense(<GetStartedPage />),
    },
    {
      path: WHERE_TO_START_PATH,
      element: suspense(<WhereToStartPage />),
    },
    {
      path: HOW_TO_ADD_MODEL_PATH,
      element: suspense(<AddModelManualPage />),
    },
    {
      path: HOW_TO_START_FL_PATH,
      element: suspense(<StartFLGuide />),
    },
    {
      path: START_USE_MODEL_PATH,
      element: suspense(<StartUseModelPage />),
    },
  ],
};
