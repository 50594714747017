import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { CrossBtn, EditBtn } from '..';
import { ParamType as MetricType } from '../../components/Params/types';

import styles from './MetricTag.module.scss';

type PropsType = {
  metric: MetricType;
  isReadOnly: boolean;
  isNameOnly?: boolean;
  isSelected?: boolean;
  onDelete?: () => void;
  onEdit?: () => void;
  onSelect?: (name: string) => void;
  isDisabled?: boolean;
};

export const MetricTag = ({
  metric,
  isReadOnly,
  onDelete,
  onEdit,
  isDisabled,
  isNameOnly,
  onSelect,
  isSelected,
}: PropsType) => {
  const { t } = useTranslation();
  const { name, value } = metric;

  const tagClassName = cn(styles.container, {
    [styles.cursor]: onSelect,
    [styles.blue]: name === 'Accuracy',
    [styles.pink]: name === 'Precision',
    [styles.red]: name === 'Recall',
    [styles.teal]: name === 'F1',
    [styles.green]: name === 'MSE',
    [styles.orange]: name === 'RMSE',
    [styles.violet]: name === 'MAE',
    [styles.blueSelected]: name === 'Accuracy' && isSelected,
    [styles.pinkSelected]: name === 'Precision' && isSelected,
    [styles.redSelected]: name === 'Recall' && isSelected,
    [styles.tealSelected]: name === 'F1' && isSelected,
    [styles.greenSelected]: name === 'MSE' && isSelected,
    [styles.orangeSelected]: name === 'RMSE' && isSelected,
    [styles.violetSelected]: name === 'MAE' && isSelected,
    [styles.containerSelected]: isSelected,
    [styles.disabled]: isDisabled,
  });

  return (
    <li className={tagClassName} onClick={() => onSelect?.(name ?? '')}>
      <p>
        {name}
        {!isNameOnly && <span> : {value}</span>}
      </p>
      {!isReadOnly && (
        <div className={styles.btnsContainer}>
          <CrossBtn
            isSmall={true}
            description={t('pages.modelFormPage.paramsBtns.deleteDescription')}
            onClick={isDisabled ? () => null : onDelete}
            isDisabled={isDisabled}
          />
          <EditBtn
            isSmall={true}
            description={t('pages.modelFormPage.paramsBtns.editDescription')}
            onClick={isDisabled ? () => null : onEdit}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </li>
  );
};
