import { useEffect, useState } from 'react';

import { userApi } from '../api/userApi';
import { businessAdminApi } from '../api/businessAdminApi';
import { getSummary } from '../api/summary';
import {
  accountSelector,
  addBaOrganizationInfo,
  addOrganizationInfo,
  addSubscriptionInfo,
  addSummary,
  addUser,
  openErrorPopup,
  setRole,
} from '../store';
import { Roles } from '../store/types';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { mapDeveloperResume } from '../mappers/developer/developer';

export const useUserData = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isConfirmed, isStaff } = useAppSelector(accountSelector);
  const dispatch = useAppDispatch();

  const [getRoles] = userApi.useGetUserRolesMutation();
  const [getUserData] = userApi.useGetUserDataMutation();
  const [getUserCompanyData] = userApi.useGetUserCompanyDataMutation();
  const [getBACompanyData] = businessAdminApi.useGetBACompanyDataMutation();

  const setUserData = async (userId: number) => {
    const user = await getUserData(userId).unwrap();

    dispatch(addUser({ ...user, id: userId }));

    const { subscription } = user;

    if (subscription) {
      dispatch(addSubscriptionInfo(subscription));
    }
    setIsLoading(false);
  };

  const setUserCompanyData = async (userId: number) => {
    const company = await getUserCompanyData(userId).unwrap();

    if (!company?.name) {
      return;
    }

    dispatch(addOrganizationInfo(company));
    setIsLoading(false);
  };

  const setBaCompanyData = async () => {
    const company = await getBACompanyData().unwrap();
    dispatch(addBaOrganizationInfo(company));
    setIsLoading(false);
  };

  const setSummaryData = async (id: number | null) => {
    const { status, payload } = await getSummary(id);
    if (status === 'success') {
      dispatch(addSummary(mapDeveloperResume(payload)));
      setIsLoading(false);
    } else dispatch(openErrorPopup());
  };

  const setData = async () => {
    if (!isConfirmed) {
      dispatch(setRole(Roles.Unknown));
      setIsLoading(false);
      return;
    }

    getRoles()
      .unwrap()
      .then(roles => {
        const { userId, baId, developerId } = roles;
        if (userId) {
          dispatch(setRole(Roles.User));
          setUserData(userId);
          !baId && setUserCompanyData(userId);
        }
        if (isStaff) {
          dispatch(setRole(Roles.SystemAdministrator));
        }
        if (baId) {
          dispatch(setRole(Roles.BusinessAdministrator));
          setBaCompanyData();
        }
        if (developerId) {
          dispatch(setRole(Roles.Developer));
          setSummaryData(developerId);
        }
        if (baId && developerId) {
          dispatch(setRole(Roles.DeveloperBA));
        }
      })
      .catch(() => {
        dispatch(setRole(Roles.Unknown));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setData();
  }, []);

  return { isLoading };
};
