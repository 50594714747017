import { useTranslation } from 'react-i18next';

import { Spinner } from '../../../../../../../ui';

import styles from './DockerImageProcessing.module.scss';

export const DockerImageProcessing = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Spinner />
        <div className={styles.title}>{t('pages.FLPage.roundState.buildingImage.title')}</div>
      </div>
      <div className={styles.subtitle}>{t('pages.FLPage.roundState.buildingImage.subtitle')}</div>
    </div>
  );
};
