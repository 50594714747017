import { resultsKey } from './../constants';
import {
  ITransactionHistory,
  TransactionsHistoryResponse,
  WalletInfo,
  WalletInfoResponse,
} from '../../api/wallet/types';
import { formatDateToMonth, formatMonthsAndYears } from '../../utils/formatData';

const transactionsKey = 'transactions';

const mapTransactionData = (transactions: ITransactionHistory[typeof transactionsKey]) =>
  transactions.map(transaction => {
    const { id, amount, currency, created_at, weight_transactions, model_build_transactions } =
      transaction;

    const info = {
      modelName:
        (weight_transactions?.model_name ?? '') || (model_build_transactions?.model_name ?? ''),
      modelId:
        Number(weight_transactions?.model_id ?? 0) ||
        Number(model_build_transactions?.model_id ?? 0),
      weight: weight_transactions?.weight_name ?? '',
    };

    return {
      id,
      amount,
      currency,
      info,
      date: formatDateToMonth(created_at, true, false) ?? '',
    };
  });

export const mapTransactionsHistory = (response: TransactionsHistoryResponse[typeof resultsKey]) =>
  response.map(history => ({
    month: formatMonthsAndYears(history.month),
    transactions: mapTransactionData(history.transactions),
  }));

export const mapWalletInfo = (payload: WalletInfoResponse): WalletInfo => ({
  id: payload.id,
  monthSum: payload.month_sum,
  totalSum: payload.total_sum,
  percent: payload.percent,
  currentAmount: payload.current_amount,
  currency: payload.currency,
});
