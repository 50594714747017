import cn from 'classnames';

import styles from './Label.module.scss';

export const Label = ({ state }: { state: string }) => {
  const labelClassName = cn(styles.label, {
    [styles.labelNew]: state === 'Новая',
    [styles.labelSoon]: state === 'Скоро',
    [styles.labelEmpty]: !state,
  });

  return <span className={labelClassName}>{state}</span>;
};
