import { ProjectCard } from '../ProjectCard/ProjectCard';
import { ProjectType } from '../types';

import styles from './ProjectCardList.module.scss';

type PropsType = {
  projects: ProjectType[];
  onAction?: () => void;
  editable?: boolean;
};

export const ProjectCardList = ({ projects, onAction, editable }: PropsType) => {
  return (
    <div className={styles.projectList}>
      {projects.map(({ name, description, link, files, id }, i: number) => (
        <ProjectCard
          number={i + 1}
          name={name}
          description={description}
          link={link}
          files={files}
          key={id}
          id={id}
          onAction={onAction}
          editable={editable}
        />
      ))}
    </div>
  );
};
