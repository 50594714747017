import { mapAvailableWeights, mapWeightsApplication } from '../mappers/models/models';
import { mapApplicationUser } from '../mappers/user/user';
import { rootAPI } from '../store/rootApi';
import { downloadFile, formatQueriesToString } from '../utils';
import {
  AvailableWeightsRequest,
  AvailableWeightsResponse,
  ChangeEntityNameInRequest,
  ChangeEntityStatusInRequest,
  RequestId,
  TransformedAvailableWeightsResponse,
  TransformedWeightRequestResponse,
  WeightRequestResponse,
} from './models/types';

export const weightsApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    // GET
    getAvailableWeights: build.query<TransformedAvailableWeightsResponse, AvailableWeightsRequest>({
      query({ fromModelId, isOwner, queries, toModelId }) {
        return {
          url: `/mlmodels/${
            isOwner ? 'owner' : 'user'
          }/model/${fromModelId}/model-to-apply/${toModelId}/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AvailableWeightsResponse) => {
        return {
          result: mapAvailableWeights(response.results),
          count: response.count,
        };
      },
    }),
    getWeightRequest: build.mutation<TransformedWeightRequestResponse, RequestId>({
      query(id) {
        return {
          url: `/mlmodels/weights/applications/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightRequestResponse) => {
        return {
          weight: mapWeightsApplication(response),
          user: mapApplicationUser(response),
        };
      },
    }),
    getDownloadWeightLinkApplication: build.mutation<Blob, number | undefined>({
      query(weightId) {
        return {
          url: `/mlmodels/weights/applications/${weightId}/download-weight/`,
          credentials: 'include',
          responseHandler: async response => {
            downloadFile(await response.blob(), 'weight');
          },
          cache: 'no-cache',
        };
      },
    }),

    // INVALIDATE

    patchChangeWeightNameInRequest: build.mutation<string, ChangeEntityNameInRequest>({
      query({ id, name }) {
        return {
          url: `/mlmodels/weights/applications/${id}/`,
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify({ name }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['AllianceWeightsRequests'],
    }),
    patchChangeWeightStatusInRequest: build.mutation<string, ChangeEntityStatusInRequest>({
      query({ id, state }) {
        return {
          url: `/mlmodels/weights/applications/${id}/respond/`,
          method: 'PATCH',
          body: JSON.stringify({ state }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceModel', 'AllianceWeightsRequestsCount'],
    }),
  }),
});
