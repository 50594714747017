import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openErrorPopup, roleSelector } from '../../../store';
import { Empty, Loader, Pagination, TableSearch } from '../../../ui';
import { AppealRow } from './AppealRow/AppealRow';
import { useAppealsData } from './useAppealsData';
import { Roles } from '../../../store/types';

import styles from './AppealsTable.module.scss';

export default function AppealsTable({ tab }: { tab: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const role = useAppSelector(roleSelector) === Roles.SystemAdministrator ? 'admin' : 'user';
  const extraParam = tab === 'current' ? { current: 'true' } : null;
  const columns: string[] = t(`pages.appealsPage.columns.${role}`, { returnObjects: true });

  const {
    data,
    pages,
    value,
    isLoading,
    isError,
    handleChange,
    handleSwitchPage,
    handleReset,
    isSearching,
    isEmpty,
  } = useAppealsData(tab, extraParam);

  if (isError) {
    dispatch(openErrorPopup());
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty) {
    return <Empty text={t(`pages.appealsPage.emptyInfo.${role}.${tab}`)} />;
  }

  const columnsClassname = cn(styles.columns, {
    [styles.columnsUser]: role === 'user',
    [styles.columnsAdmin]: role === 'admin',
  });

  return (
    <>
      {role === 'admin' && (
        <TableSearch
          value={value}
          handleChange={handleChange}
          handleReset={handleReset}
          placeholder={t('pages.appealsPage.searchPlaceholder')}
          isSearching={isSearching}
        />
      )}
      {data.length > 0 ? (
        <div className={styles.container}>
          <div className={styles.table}>
            <ul className={columnsClassname}>
              {columns.map(column => (
                <li key={column}>{column}</li>
              ))}
            </ul>
            <ul className={styles.rows}>
              {data.map(appeal => (
                <AppealRow key={appeal.id} appeal={appeal} role={role} />
              ))}
            </ul>
            {pages > 1 && <Pagination pagesCount={pages} handleSwitchPage={handleSwitchPage} />}
          </div>
        </div>
      ) : (
        <Empty text={t(`pages.appealsPage.emptyInfo.search`)} />
      )}
    </>
  );
}
