import styles from './IconWithHint.module.scss';

type PropTypes = {
  icon: JSX.Element;
  description?: string;
};
export const IconWithHint = ({ icon, description }: PropTypes) => {
  return (
    <div className={styles.container}>
      {icon}
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
};
