import { useTranslation } from 'react-i18next';

import { IconWithHint } from './IconWithHint/IconWithHint';
import { FLIcon, PaidIcon } from '../../../../assets/svg';

import styles from './ModelIconsBar.module.scss';

type PropTypes = {
  isPaid: boolean;
  hasFl: boolean;
  isVerified: boolean;
};

export const ModelIconsBar = ({ hasFl, isPaid, isVerified }: PropTypes) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      {hasFl && isVerified && (
        <IconWithHint icon={<FLIcon />} description={t('pages.modelsPage.modelHasPassFL')} />
      )}
      {isPaid && (
        <IconWithHint
          icon={<PaidIcon isDisabled={!isVerified} />}
          description={isVerified ? t('pages.modelsPage.paidModelHint') : ''}
        />
      )}
    </div>
  );
};
