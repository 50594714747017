import {
  AdminControlledUserAlliancesResponse,
  AdminMyAlliancesResponse,
  AdminUserAllianceInvitationsResponse,
  AllAdminAlliancesResponse,
  AllianceDataResponse,
  AllianceMembersResponse,
  AllianceModelsRequestsResponse,
  AllianceModelsResponse,
  AllianceWeightsRequestsResponse,
  AvailableAlliancesResponse,
  ControlledAdminAlliancesResponse,
  ControlledAlliancesResponse,
  IAdminUserAllianceInvitations,
  InvitationsToAlliancesAdminResponse,
  InvitationsToAlliancesResponse,
  MyAlliancesResponse,
  OpenAlliancesResponse,
  RequestsToAlliancesAdminResponse,
  RequestsToAlliancesResponse,
} from '../../api/alliances/types';
import { FavoriteAlliancesResponse } from '../../api/favorites/types';
import { FLEmployeesResponse } from '../../api/flearning/types';
import {
  IAdminMyAlliances,
  IAdminRequestsToAlliances,
} from '../../pages/Admin/AlliancesPage/types';
import { AllianceUserStatus } from '../../types';
import { makeFullName, transformObjectToArray } from '../../utils/formatData';
import { mapAttachments } from '../attachments/attachments';
import { resultsKey } from '../constants';

export const mapAllianceById = (payloadData: AllianceDataResponse) => ({
  id: payloadData.id,
  owner: {
    id: payloadData.alliance_owner.id,
    fullName: makeFullName({
      first_name: payloadData.alliance_owner.first_name,
      last_name: payloadData.alliance_owner.last_name,
      middle_name: payloadData.alliance_owner.middle_name,
    }),
    email: payloadData.alliance_owner.email,
    companyName: payloadData.alliance_owner.company?.name ?? '',
    industry: payloadData.alliance_owner.company?.industry ?? '',
    INN: payloadData.alliance_owner.company?.inn ?? '',
    position: payloadData.alliance_owner.position ?? '',
  },
  industry: payloadData.industry,
  members: payloadData.members,
  attachments: mapAttachments(payloadData.alliance_attachments),
  applicationId: payloadData.last_application_id,
  date: payloadData.created_at,
  name: payloadData.name,
  description: payloadData.description,
  isClosed: payloadData.is_closed,
  isActive: payloadData.is_active,
  role: payloadData.current_user,
  isHidden: payloadData.is_hidden,
  isFavorite: payloadData.is_favorite,
  isJointMl: payloadData.is_jointml,
  currentRound: payloadData.current_round
    ? { state: payloadData.current_round.state, id: payloadData.current_round.id }
    : null,
  notAllMembersSubscribed: payloadData.not_all_members_subscribed,
});

export const mapInvitations = (payload: InvitationsToAlliancesResponse[typeof resultsKey]) =>
  payload.map(invitation => ({
    id: invitation.alliance.id,
    name: invitation.alliance.name,
    industry: invitation.alliance.industry.name,
    members: invitation.members,
    response: invitation.id,
    message: invitation.has_comment,
    status: invitation.application_state as AllianceUserStatus,
    isJointMl: invitation.alliance.is_jointml,
  }));

export const mapAdminInvitations = (
  payload: InvitationsToAlliancesAdminResponse[typeof resultsKey],
) =>
  payload.map(invitation => ({
    id: invitation.alliance.id,
    name: invitation.alliance.name,
    industry: invitation.alliance.industry.name,
    members: invitation.members,
    response: invitation.id,
    message: invitation.has_comment,
    status: invitation.application_state as AllianceUserStatus,
    isJointMl: invitation.alliance.is_jointml,
  }));

export const mapUserInvitationsForAdmin = (
  payload: InvitationsToAlliancesAdminResponse[typeof resultsKey],
) =>
  payload.map(invitation => ({
    id: invitation.alliance.id,
    name: invitation.alliance.name,
    industry: invitation.alliance.industry.name,
    members: invitation.members,
    response: invitation.id,
    message: invitation.has_comment,
    status: invitation.application_state as AllianceUserStatus,
    isJointMl: invitation.alliance.is_jointml,
    isMember: invitation?.is_member,
  }));

export const mapAdminUserAllianceInvitations = (
  payload: AdminUserAllianceInvitationsResponse[typeof resultsKey],
): IAdminUserAllianceInvitations[] =>
  payload.map(invitation => ({
    id: invitation.alliance.id,
    name: invitation.alliance.name,
    industry: invitation.alliance.industry.name,
    members: invitation.members,
    response: invitation.id,
    message: invitation.has_comment,
    status: invitation.application_state as AllianceUserStatus,
    isJointMl: invitation.alliance.is_jointml,
    isMember: invitation.is_member ?? false,
  }));

export const mapControlledAlliances = (payload: ControlledAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry,
    members: alliance.count_members,
    requests: alliance.count_requests,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
  }));

export const mapControlledAdminAlliances = (
  payload: ControlledAdminAlliancesResponse[typeof resultsKey],
) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry,
    members: alliance.count_members,
    requests: alliance.count_requests,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
  }));

export const mapAdminControlledUserAlliances = (
  payload: AdminControlledUserAlliancesResponse[typeof resultsKey],
) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry,
    members: alliance.count_members,
    requests: alliance.count_requests,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    isMember: alliance.is_member,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
  }));

export const mapMyAlliances = (payload: MyAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.alliance_id,
    name: alliance.alliance,
    industry: alliance.industry,
    members: alliance.members,
    response: alliance.id,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
  }));

export const mapUserMyAlliancesForAdmin = (payload: MyAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.alliance_id,
    name: alliance.alliance,
    industry: alliance.industry,
    members: alliance.members,
    response: alliance.id,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    isMember: alliance?.is_member,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
  }));

export const mapAdminMyAlliances = (
  payload: AdminMyAlliancesResponse[typeof resultsKey],
): IAdminMyAlliances[] =>
  payload.map(alliance => ({
    id: alliance.alliance.id,
    name: alliance.alliance.name,
    industry: alliance.alliance.industry.name,
    members: alliance.members,
    response: alliance.id,
    currentRound: alliance.current_round
      ? {
          id: alliance.current_round.id,
          duration: alliance.current_round.duration,
          startDate: alliance.current_round.start_date,
          state: alliance.current_round.state,
        }
      : null,
    owner: {
      id: alliance.alliance.alliance_owner.id,
      fullName: makeFullName({
        first_name: alliance.alliance.alliance_owner.first_name,
        last_name: alliance.alliance.alliance_owner.last_name,
        middle_name: alliance.alliance.alliance_owner.middle_name,
      }),
      position: alliance.alliance.alliance_owner.position,
    },
    isClosed: alliance.alliance.is_closed,
    isJointMl: alliance.alliance.is_jointml,
    isHidden: alliance.alliance.is_hidden ?? false,
  }));

export const mapAvailableAlliances = (payload: AvailableAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry,
    isAvailable: alliance.is_free,
  }));

export const mapOpenAlliances = (payload: OpenAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry.name,
    members: alliance.members,
    isHidden: alliance.is_hidden,
    isJointMl: alliance.is_jointml ?? false,
    response: alliance.last_user_application
      ? {
          id: alliance.last_user_application.id,
          status: alliance.last_user_application.application_state as AllianceUserStatus,
          user: alliance.last_user_application.user_state,
          date: alliance.last_user_application.updated_at,
        }
      : null,
  }));

export const mapAdminAllAlliances = (payload: AllAdminAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.id,
    name: alliance.name,
    industry: alliance.industry.name,
    members: alliance.members,
    isHidden: alliance.is_hidden ?? false,
    isClosed: alliance.is_closed,
    isJointMl: alliance.is_jointml ?? false,
    response: alliance.last_user_application
      ? {
          id: alliance.last_user_application.id,
          status: alliance.last_user_application.application_state as AllianceUserStatus,
          user: alliance.last_user_application.user_state,
          date: alliance.last_user_application.updated_at,
        }
      : null,
    owner: {
      id: alliance.alliance_owner.id,
      fullName: makeFullName({
        first_name: alliance.alliance_owner.first_name,
        last_name: alliance.alliance_owner.last_name,
        middle_name: alliance.alliance_owner.middle_name,
      }),
      position: alliance.alliance_owner.position,
    },
  }));

export const mapFavoriteAlliances = (payload: FavoriteAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    favoriteId: alliance.id,
    allianceId: alliance.alliance.id,
    applicationId: alliance.alliance.last_user_application?.id || null,
    name: alliance.alliance.name,
    industry: alliance.alliance.industry,
    members: alliance.alliance.members,
    status: alliance.alliance.current_user_state,
    applicationDate: alliance.alliance.last_user_application?.created_at || '',
    isHidden: alliance.alliance.is_hidden,
  }));

export const mapRequests = (payload: RequestsToAlliancesResponse[typeof resultsKey]) =>
  payload.map(alliance => ({
    id: alliance.alliance.id,
    name: alliance.alliance.name,
    industry: alliance.alliance.industry.name,
    members: alliance.members,
    response: alliance.id,
    message: alliance.has_comment,
    startDate: alliance.created_at,
    endDate: alliance.updated_at,
    status: alliance.application_state as AllianceUserStatus,
    isJointMl: alliance.alliance.is_jointml,
  }));

export const mapAdminRequests = (
  payload: RequestsToAlliancesAdminResponse[typeof resultsKey],
): IAdminRequestsToAlliances[] =>
  payload.map(alliance => ({
    id: alliance.alliance.id,
    name: alliance.alliance.name,
    industry: alliance.alliance.industry.name,
    members: alliance.members,
    response: alliance.id,
    message: alliance.has_comment,
    startDate: alliance.created_at,
    endDate: alliance.updated_at,
    status: alliance.application_state as AllianceUserStatus,
    isJointMl: alliance.alliance.is_jointml,
  }));

export const mapMembers = (payload: AllianceMembersResponse[typeof resultsKey]) =>
  payload.map(member => ({
    email: member.user.email,
    firstName: member.user.first_name,
    lastName: member.user.last_name,
    middleName: member.user.middle_name,
    position: member.user.position,
    company: member.user?.company?.name || '',
    inn: member.user?.company?.inn || '',
    industry: member.user?.company?.industry || '',
    id: member.id,
    isComment: member.has_comment,
    isBanned: member.isBanned,
    state: member.application_state,
    startDate: member.created_at,
    currentDate: member.updated_at,
    isAdmin: member.is_admin,
  }));

export const mapAllianceModels = (payload: AllianceModelsResponse[typeof resultsKey]) =>
  payload.map(model => ({
    id: model.ml_model.id,
    type: model.ml_model.model_type,
    task: model.ml_model.task,
    name: model.ml_model.name,
    modelParams: transformObjectToArray(model.ml_model.model_params),
    datasetParams: transformObjectToArray(model.ml_model.dataset_params),
    testParams: transformObjectToArray(model.ml_model.test_params),
    trainParams: transformObjectToArray(model.ml_model.train_params),
    weightsCount: model.weights_number,
    isPaid: model.ml_model.is_required_payment,
    isJointMl: model.ml_model.provided_by_platform,
    hasFl: model.has_fl,
  }));

export const mapAllianceModelsRequests = (
  payload: AllianceModelsRequestsResponse[typeof resultsKey],
) =>
  payload.map(model => ({
    id: model.id,
    userName: `${model.user.last_name} ${model.user.first_name} ${model.user.middle_name || ''}`,
    modelName: model.ml_model?.name,
    modelId: model.ml_model?.id,
    date: model.created_at,
    isValid: model.is_free,
  }));

export const mapAllianceWeightsRequests = (
  payload: AllianceWeightsRequestsResponse[typeof resultsKey],
) =>
  payload.map(model => ({
    id: model.id,
    userName: `${model.user.last_name} ${model.user.first_name} ${model.user.middle_name || ''}`,
    modelName: model.ml_model.name,
    modelId: model.ml_model.id,
    weightName: model.weight_name,
    date: model.created_at,
    isValid: model.is_free,
  }));

const transformFLMetrics = (metrics: Record<string, string | number> | null | undefined) => {
  if (!metrics) {
    return null;
  }

  const result = [];
  for (let metric in metrics) {
    result.push({ name: metric, value: metrics[metric] });
  }
  return result;
};

export const mapFLEmployees = (payload: FLEmployeesResponse[typeof resultsKey]) =>
  payload.map(employee => ({
    id: employee.id,
    isCurrent: employee.is_current_user,
    name: makeFullName(employee.joint_user),
    organization: employee.joint_user.company,
    state: employee.state,
    isWeightAdded: employee.weight && employee.weight.state === 'valid',
    metrics: transformFLMetrics(employee.weight?.metrics),
  }));
