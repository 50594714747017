import { useEffect, useRef } from 'react';
import cn from 'classnames';

import { LinkIcon } from '../../../../assets/svg';
import { useValidation } from './useValidation';
import { text } from './constants';
import { PropTypes } from './types';

import styles from './EditPanel.module.scss';
import { CrossIcon } from '../../../common/Icons/CrossIcon/CrossIcon';

export const EditPanel = ({ link, onSave, onClose, isOpen }: PropTypes) => {
  const { values, isValid, errorText, handleChange } = useValidation(link);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `28px`;
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [values.link]);

  return (
    <div className={cn(styles.container, isOpen && styles.containerActive)}>
      <button className={styles.close} type="button" onClick={onClose}>
        <CrossIcon color="GRAY" />
      </button>
      <input
        className={styles.input}
        value={values.name ?? ''}
        name="name"
        onChange={handleChange}
        placeholder={text.name}
        maxLength={50}
      />
      <div className={styles.divider} />
      <div className={styles.link}>
        <span className={styles.icon}>
          <LinkIcon />
        </span>
        <textarea
          className={styles.textArea}
          value={values.link}
          name="link"
          onChange={handleChange}
          ref={textAreaRef}
          maxLength={400}
          placeholder={text.link}
        />
      </div>
      {errorText && <span className={styles.error}>{errorText}</span>}
      {isValid && (
        <button className={styles.button} onClick={() => onSave(values)} type="button">
          {text.save}
        </button>
      )}
    </div>
  );
};
