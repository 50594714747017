import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { PaidIcon, ProvidedIcon } from '../../../assets/svg';
import { WeightsTable } from './WeightsTable/WeightsTable';
import { PropsType } from './types';

import styles from './ModelWithWeightsList.module.scss';

export const ModelWithWeightsList = ({
  model,
  defaultWeights,
  isOwner,
  setCheckedModel,
  setCheckedWeight,
  isSingle = false,
  isDisabled = false,
  isChecked = false,
}: PropsType) => {
  const { t } = useTranslation();
  const cardClassName = cn(styles.card, {
    [styles.cardActive]: isChecked,
    [styles.cardSingle]: isSingle,
    [styles.cardDisabled]: isDisabled,
  });
  const handleChoose = () => {
    if (isDisabled) return;
    setCheckedModel?.(model.id, model.name);
    setCheckedWeight(null, '');
  };

  return (
    <li className={cardClassName} onClick={handleChoose}>
      {isDisabled && (
        <span className={styles.hint}>
          {t(`popups.addAnyModelToSpecificAlliance.${isOwner ? 'owner' : 'user'}ModelHint`)}
        </span>
      )}
      <div className={styles.clickableWrap}>
        <div className={styles.mainInfo}>
          <div className={styles.title}>
            <div className={styles.nameWrap}>
              {model.isJointMl && <ProvidedIcon />}
              <h3 className={styles.name}>{model.name}</h3>
            </div>
            <div className={styles.info}>
              <p>{model.task}</p>
              <span className={styles.dot} />
              <p className={styles.category}>{model.category}</p>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.secondaryInfo}>
          <p className={styles.type}>{model.type}</p>
          <p className={styles.weights}>
            <span className={styles.count}>{model.count}</span>
            {t('pages.singleModelPage.weights')}
          </p>
        </div>
        <div className={styles.badges}>{model.isPaid && <PaidIcon />}</div>
      </div>
      {isChecked && (
        <WeightsTable
          weights={defaultWeights ?? []}
          modelName={model.name}
          setCheckedWeight={setCheckedWeight}
        />
      )}
    </li>
  );
};
