import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { EmployeeIcon, SupportIcon } from '../../../assets/svg';

import styles from './Author.module.scss';

type PropsType = {
  role: 'user' | 'admin';
  name: string;
};

export const Author = ({ role, name }: PropsType) => {
  const { t } = useTranslation();

  const authorClassName = cn(styles.author, {
    [styles.authorUser]: role === 'user',
    [styles.authorAdmin]: role === 'admin',
  });

  return (
    <div className={styles.wrapper}>
      <div className={authorClassName}>
        {role === 'user' ? <EmployeeIcon /> : <SupportIcon />}
        <div className={styles.authorName}>{name}</div>
      </div>
      {role === 'admin' && <div className={styles.description}>{t('headings.support')}</div>}
    </div>
  );
};
