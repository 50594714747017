import { createSlice } from '@reduxjs/toolkit';
import { SummaryType } from '../types';

export const summaryInitialState: SummaryType = {
  id: null,
  specialization: null,
  experience: null,
  country: null,
  region: null,
  skills: null,
  about: null,
  link: null,
  isSummaryHidden: false,
  isFavorite: false
};

const summarySlice = createSlice({
  name: 'summary',
  initialState: summaryInitialState,
  reducers: {
    addSummary(state, action) {
      state.id = action.payload.id;
      state.specialization = action.payload.specialization;
      state.experience = action.payload.experience;
      state.country = action.payload.country;
      state.region = action.payload.region;
      state.skills = action.payload.skills;
      state.about = action.payload.about;
      state.link = action.payload.link;
      state.isSummaryHidden = action.payload.isSummaryHidden;
    },
    editSummary(state, action) {
      state.id = action.payload.id;
      state.specialization = action.payload.specialization;
      state.experience = action.payload.experience;
      state.country = action.payload.country;
      state.region = action.payload.region;
      state.skills = action.payload.skills;
      state.about = action.payload.about;
      state.link = action.payload.link;
    },
    editSummaryHidden(state, action) {
      state.isSummaryHidden = action.payload;
    },
    clearSummaryState(state) {
      state.id = null;
      state.specialization = null;
      state.experience = null;
      state.country = null;
      state.region = null;
      state.skills = null;
      state.about = null;
      state.link = null;
      state.isSummaryHidden = false;
    },
  },
});

export const { addSummary, editSummary, clearSummaryState, editSummaryHidden } =
  summarySlice.actions;
export default summarySlice.reducer;
