import { ChangeHandler } from 'react-hook-form';
import cn from 'classnames';

import styles from './Select.module.scss';

type PropsType = {
  id: string;
  label: string;
  register: (
    name: any,
  ) => {
    onChange: ChangeHandler;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
  helperText?: any;
  isDisabled?: boolean;
  setValue: (id: any, value: string) => void;
  value: string | null;
  options: { id: number; name: string }[] | null;
  setItemId?: (id: number) => void;
  placeholder?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
};

export const Select = ({
  id,
  label,
  register,
  helperText,
  isDisabled = false,
  setValue,
  value,
  options,
  setItemId = () => null,
  placeholder,
  isRequired = false,
  isReadOnly = true,
}: PropsType) => {
  const wrapperClassName = cn(styles.wrapper, {
    [styles.wrapperDisable]: isDisabled,
  });
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
    [styles.inputDisable]: isDisabled,
  });
  const labelClassName = cn(styles.label, {
    [styles.labelRequired]: isRequired,
  });

  return (
    <div className={wrapperClassName}>
      <div className={styles.container}>
        <input
          className={inputClassName}
          id={id}
          {...register?.(id)}
          value={value ?? ''}
          required
          readOnly={isReadOnly}
          disabled={isDisabled}
          placeholder={placeholder || ' '}
          autoComplete="off"
        />
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
        <label className={styles.btn} htmlFor={id} />
        <button className={styles.button} type="button" />
        <ul className={styles.options}>
          {options?.map((option: { id: number; name: string }) => (
            <li
              key={option.id ?? 0}
              className={styles.option}
              onMouseDown={() => {
                setValue(id, option.name);
                setItemId(option.id);
              }}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.error}>{helperText}</div>
    </div>
  );
};
