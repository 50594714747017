interface IResponse {
  body: ReadableStream<Uint8Array> | null;
  bodyUsed: boolean;
  headers: Headers;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: string;
  url: string;
  json: () => Promise<any>;
  text: () => Promise<string>;
  blob: () => Promise<Blob>;
}

interface Idata {
  data: string;
  code: number;
}

interface IRequestResponse {
  status: 'success' | 'failure';
  payload: any;
  code?: number;
}

interface IConfig {
  headers?: Record<string, string>;
  [key: string]: string | Record<string, string> | undefined;
}

const requestHeaders = ({ headers, ...other }: IConfig, method: string, body: string | null) => ({
  method,
  body,
  headers: {
    'Content-Type': 'application/json',
    ...headers,
  },
  ...other,
});

export const SUCCESS = 'success';
export const FAILURE = 'failure';

const isError = (response: IResponse) => !response.ok;

const extractResponse = (response: IResponse) => {
  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType.startsWith('application/json')) {
    return response.json();
  }

  if (contentType && contentType.startsWith('text/')) {
    return response.text();
  }

  return response.blob();
};

const api = (url: string, config: IConfig, method: string, body: string | null) =>
  fetch(url, requestHeaders(config, method, body)).then(response => {
    const result = extractResponse(response);

    if (isError(response)) {
      return result.then((data: Idata) => Promise.reject({ data, code: response.status }));
    }
    return result.then((data: Idata) => Promise.resolve(data));
  });

export const request = (
  url: string,
  config = {},
  method = 'GET',
  body: string | null = null,
): Promise<IRequestResponse> =>
  api(url, config, method, body).then(
    res => ({
      payload: res,
      status: SUCCESS,
    }),
    err => ({
      payload: err.data,
      code: err.code,
      status: FAILURE,
    }),
  );
