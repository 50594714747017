import { CrossedIcon } from '../../../../assets/svg';
import { passwordRegExp } from '../../../../schemas/validationSchemas';
import { text } from './constants';

import popUpStyles from './CreatePassword.module.scss';

type PropsType = {
  value: string;
};

export const ErrorPopUp = ({ value }: PropsType) => {
  const { errorPopup } = text;
  const { lengthLong, lengthShort, specialCharacters } = errorPopup;

  return (
    <div className={popUpStyles.popUp}>
      <ul>
        {value.length < 8 && (
          <li>
            <span className={popUpStyles.icon}>
              <CrossedIcon />
            </span>
            <span>{lengthShort}</span>
          </li>
        )}
        {value.length > 20 && (
          <li>
            <span className={popUpStyles.icon}>
              <CrossedIcon />
            </span>
            <span>{lengthLong}</span>
          </li>
        )}
        {!value.match(passwordRegExp) && (
          <li>
            <span className={popUpStyles.icon}>
              <CrossedIcon />
            </span>
            <span>{specialCharacters}</span>
          </li>
        )}
      </ul>
    </div>
  );
};
