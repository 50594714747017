import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { weightsApi } from '../../../../api/weightsApi';
import { adminWeightsApi } from '../../../../api/adminWeightsApi';
import { OwnerActionStatuses } from '../../../../api/types';
import { useAppDispatch, useAppSelector, useToggle } from '../../../../hooks';
import { formatDate } from '../../../../utils/formatData';
import { nameSchema } from '../../../../schemas/weightSchema';
import { allianceSelector, isAdminSelector, setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { Button, EditName, RequestCreatorInfo, Spinner, WeightPopup } from '../../../../ui';
import { TitledInfo } from '../../TitledInfo/TitledInfo';
import { PropTypes, UserType, WeightType, userInitialState, weightInitialState } from './types';
import { RoleInAlliance } from '../../../../pages/AlliancePage/types';

import styles from './WeightToAllianceApplicationPopUp.module.scss';

export const WeightToAllianceApplicationPopUp = ({
  modelName,
  id,
  isReadOnly,
  onStateChange,
  onClose,
}: PropTypes) => {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(isAdminSelector);
  const isAllianceOwner = useAppSelector(allianceSelector).role === RoleInAlliance.Owner;
  const [weight, setWeight] = useState<WeightType>(weightInitialState);
  const [user, setUser] = useState<UserType>(userInitialState);
  const { isNameTaken, name, applicationDate } = weight;
  const {
    state: isInputShown,
    setTrue: setInput,
    setFalse: unsetInput,
  } = useToggle<boolean>(false);
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(nameSchema),
    mode: 'onChange',
  });
  const inputValue = watch('name');
  const dispatch = useAppDispatch();
  const isAdminApi = isAdmin && !isAllianceOwner;

  const weightRequest = isAdminApi
    ? adminWeightsApi.useGetWeightRequestAsAdminMutation
    : weightsApi.useGetWeightRequestMutation;
  const changeWeightNameRequest = isAdminApi
    ? adminWeightsApi.usePatchChangeWeightNameInRequestAsAdminMutation
    : weightsApi.usePatchChangeWeightNameInRequestMutation;
  const changeWeightStatusRequest = isAdminApi
    ? adminWeightsApi.usePatchChangeWeightStatusInRequestAsAdminMutation
    : weightsApi.usePatchChangeWeightStatusInRequestMutation;

  const [getWeight, { isLoading }] = weightRequest();
  const [getDownloadWeightLinkApplication] =
    weightsApi.useGetDownloadWeightLinkApplicationMutation();
  const [changeWeightName] = changeWeightNameRequest();
  const [changeWeightStatus] = changeWeightStatusRequest();

  useEffect(() => {
    getWeight(id)
      .unwrap()
      .then(payload => {
        setWeight(payload.weight);
        setUser(payload.user);
      });
  }, []);

  const handleDownloadWeight = async () => {
    getDownloadWeightLinkApplication(id);
  };

  useEffect(() => {
    setValue('name', name);
  }, [name]);

  useEffect(() => {
    inputValue && setValue('name', inputValue.replace(/\s+/g, ' '));
    if (!inputValue?.trim()) {
      setValue('name', '');
    }
  }, [inputValue]);

  const handleNameChange = () => {
    if (name === inputValue) {
      unsetInput();
      return;
    }
    changeWeightName({ id, name: inputValue })
      .unwrap()
      .then(() => {
        setWeight({ ...weight, name: inputValue, isNameTaken: false });
        unsetInput();
      })
      .catch(err => {
        setError('name', { type: 'custom', message: err.data });
      });
  };

  const handleStatusChange = (state: OwnerActionStatuses) => {
    changeWeightStatus({ id, state })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type:
              state === t('actionStatuses.owner.approvedState')
                ? NotificationTypes.WeightToAllianceModelAccept
                : NotificationTypes.WeightToAllianceModelDecline,
            data: {
              modelName,
              weightName: weight.name,
            },
          }),
        );
        onStateChange && onStateChange(state);
        onClose();
      });
  };

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {t('popups.weightToAllianceApplication.title')} {modelName}
      </h2>
      <div className={styles.topBlock}>
        <EditName
          name={name}
          isNameTaken={isNameTaken}
          onSubmit={handleNameChange}
          onNameChange={setInput}
          onInputClose={unsetInput}
          isInputShown={isInputShown}
          errors={errors}
          register={register}
          maxLength={20}
          isModel={false}
        />
      </div>
      <div className={styles.scrollContent}>
        <WeightPopup
          controlsType="readOnly"
          weight={weight}
          onDownloadClick={handleDownloadWeight}
        />
        <div className={styles.requestCreatorBlock}>
          <RequestCreatorInfo user={user} />
        </div>
      </div>
      <div className={styles.footer}>
        <TitledInfo
          isRow={true}
          title={t('popups.weightToAllianceApplication.dateTitle')}
          text={formatDate(new Date(applicationDate))}
        />
        <div className={styles.btnsWrapper}>
          <Button
            style="secondary"
            size="small"
            isDisabled={isReadOnly}
            onClick={() => handleStatusChange(t('actionStatuses.owner.rejectedState'))}
          >
            {t('buttons.decline')}
          </Button>
          <Button
            size="small"
            onClick={() => handleStatusChange(t('actionStatuses.owner.approvedState'))}
            isDisabled={isNameTaken || isInputShown || isReadOnly}
          >
            {t('buttons.approve')}
          </Button>
        </div>
      </div>
    </div>
  );
};
