import { mapWeightsApplication } from '../mappers/models/models';
import { mapApplicationUser } from '../mappers/user/user';
import { rootAPI } from '../store/rootApi';
import {
  ChangeEntityNameInRequest,
  ChangeEntityStatusInRequest,
  RequestId,
  TransformedWeightRequestResponse,
  WeightRequestResponse,
} from './models/types';

export const adminWeightsApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getWeightRequestAsAdmin: build.mutation<TransformedWeightRequestResponse, RequestId>({
      query(id) {
        return {
          url: `/admin/alliances/weight/applications/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightRequestResponse) => {
        return {
          weight: mapWeightsApplication(response),
          user: mapApplicationUser(response),
        };
      },
    }),
    patchChangeWeightNameInRequestAsAdmin: build.mutation<string, ChangeEntityNameInRequest>({
      query({ id, name }) {
        return {
          url: `/admin/alliances/weight/applications/${id}/`,
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify({ name }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['AllianceWeightsRequests'],
    }),
    patchChangeWeightStatusInRequestAsAdmin: build.mutation<string, ChangeEntityStatusInRequest>({
      query({ id, state }) {
        return {
          url: `/admin/alliances/weight/applications/${id}/respond/`,
          method: 'PATCH',
          body: JSON.stringify({ state }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceModel', 'AllianceModelsInfo'],
    }),
  }),
});
