import { SyntheticEvent } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { formatDate, formatSize } from '../../../../../utils';
import { RadioButton } from '../../../../common';
import { LabeledWeightItemProps } from '../../types';

import styles from './LabeledWeightItem.module.scss';

export const LabeledWeightItem = ({
  setCheckedWeight,
  weight,
  modelName,
}: LabeledWeightItemProps) => {
  const { t } = useTranslation();

  const containerClassName = cn(styles.weightLabel, weight.isDisabled && styles.weightDisabled);

  const handleCheckWeight = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (weight.isDisabled) return;
    setCheckedWeight(weight.id, weight.name);
  };

  return (
    <li onClick={handleCheckWeight} className={containerClassName}>
      {weight.isDisabled && (
        <span className={styles.hint}>
          {t('popups.addAnyModelToSpecificAlliance.weightHint', { modelName })}
        </span>
      )}
      <label htmlFor={weight.id.toString()} aria-label={weight.name}>
        <div className={styles.weight}>
          <RadioButton id={weight.id.toString()} name="weight" isDisabled={weight.isDisabled} />
          <div className={styles.weightName}>
            <h3>{weight.name}</h3>
            <p className={styles.weightSize}>
              {formatSize(weight.size)}
              <span className={styles.secondary}>{t('files.size')}</span>
            </p>
          </div>
        </div>
        <div className={styles.weightDescription}>
          <p>{weight.description}</p>
        </div>
        <div className={styles.weightDate}>
          <p>{formatDate(new Date(weight.date), true)}</p>
        </div>
      </label>
    </li>
  );
};
