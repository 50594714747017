export const LightningIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M8.51924 12.0741L7.92665 18L15.6304 9.7037L11.4822 7.33333L12.0748 2L4.37109 10.2963L8.51924 12.0741Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
