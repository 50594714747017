import { createSlice } from '@reduxjs/toolkit';
import { RoleType } from '../types';

const initialState: RoleType = null;

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRole(state, action) {
      return (state = action.payload);
    },
  },
});

export const { setRole } = roleSlice.actions;
export default roleSlice.reducer;
