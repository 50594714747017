import { mapFLEmployees } from '../mappers/alliances/alliances';
import { mapFlearningHistory, mapFlearningRoundData } from '../mappers/flearning/flearning';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString } from '../utils';
import {
  FLEmployeesRequest,
  FLEmployeesResponse,
  FLFileResponse,
  FLFileTransformedResponse,
  PostFLFilesRequest,
  RoundByIdResponse,
  RoundByIdResponseTransFormed,
  RoundHistoryResponse,
  RoundHistoryResponseTransformed,
  StartRoundRequest,
  StartRoundResponse,
  TransformedFLEmployeesResponse,
} from './flearning/types';
import { QueriesTypeWithIdRequest } from './models/types';
import { QueriesType } from './types';

export const flearningApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    postStartRound: build.mutation<{ roundCount: number }, StartRoundRequest>({
      query(data) {
        return {
          url: '/flearning/',
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      transformResponse: (response: StartRoundResponse) => {
        return { roundCount: response.round_number };
      },
      invalidatesTags: () => ['Alliance', 'FlearningHistory', 'ControlledAlliances'],
    }),
    patchRecreateImage: build.mutation<null, number>({
      query(id) {
        return {
          url: `/flearning/round/${id}/recreate-image/`,
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'FlearningHistory', 'FLRound'],
    }),
    getRoundData: build.query<RoundByIdResponseTransFormed, number>({
      query(id) {
        return {
          url: `flearning/round/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: RoundByIdResponse) => mapFlearningRoundData(response),
      providesTags: () => ['FLRound'],
    }),
    getFLEmployees: build.query<TransformedFLEmployeesResponse, FLEmployeesRequest>({
      query({ id, ...rest }) {
        return {
          url: `/flearning/${id}/members/${formatQueriesToString(rest as unknown as QueriesType)}`,
          credentials: 'include',
        };
      },
      transformResponse: (payload: FLEmployeesResponse) => ({
        count: payload.count,
        result: mapFLEmployees(payload.results),
      }),
      providesTags: () => ['FLClients'],
    }),
    getRoundsHistoryCount: build.query<number, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `flearning/alliance/${id}/round-history/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: RoundHistoryResponse) => response.count,
      providesTags: () => ['FlearningHistory'],
    }),
    getRoundsHistory: build.mutation<RoundHistoryResponseTransformed, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `flearning/alliance/${id}/round-history/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: RoundHistoryResponse) => {
        return {
          count: response.count,
          next: response.links.next,
          previous: response.links.previous,
          result: mapFlearningHistory(response.results),
        };
      },
    }),
    getFLFile: build.query<FLFileTransformedResponse, number>({
      query(id) {
        return {
          url: `/flearning/client-data/round/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (payload: FLFileResponse) => ({
        state: payload.state,
        name: payload.file_name,
        size: payload.file_size,
        format: payload.extension,
      }),
      providesTags: () => ['FLUserFiles'],
    }),
    postFLFiles: build.mutation<null, PostFLFilesRequest>({
      query({ file, id }) {
        const formData = new FormData();

        formData.append('flearning_round', id.toString());
        formData.append('zip_file', file);

        return {
          url: `/flearning/client-data/`,
          method: 'POST',
          body: formData,
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['FLUserFiles'],
    }),
    deleteFLFiles: build.mutation<null, number>({
      query(id) {
        return {
          url: `/flearning/client-data/round/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['FLUserFiles'],
    }),
    postStartAggregation: build.mutation<null, number>({
      query(id) {
        return {
          url: `/flearning/round/${id}/start-aggregation/`,
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'FlearningHistory', 'FLRound'],
    }),
  }),
});
