import cn from 'classnames';

import styles from './Tag.module.scss';

type PropsType = {
  style: 'contained' | 'outlined' | 'disabled';
  children: string;
  onClick?: () => void;
};

export const Tag = ({ style, children, onClick }: PropsType) => {
  const tagStyle = cn(styles.tag, {
    [styles.contained]: style === 'contained',
    [styles.outlined]: style === 'outlined',
    [styles.disabled]: style === 'disabled',
  });

  return <div className={tagStyle} onClick={onClick}>{children}</div>;
};
