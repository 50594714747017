import { lazy } from 'react';

import { ALLIANCES_TABS } from '../routeConstants';
import { suspense } from '../HOC/suspense';

const AlliancesPage = lazy(() => import('../../pages/Admin/AlliancesPage/AlliancesPage'));
const AllAlliancesTable = lazy(
  () => import('../../pages/Admin/AlliancesPage/AllAlliancesTable/AllAllianceTable'),
);
const ControlledAlliancesTable = lazy(
  () => import('../../pages/Admin/AlliancesPage/ControlledAlliancesTable/ControlledAlliancesTable'),
);
const InvitationsTable = lazy(
  () => import('../../pages/Admin/AlliancesPage/InvitationsTable/InvitationsTable'),
);
const RequestsTable = lazy(
  () => import('../../pages/Admin/AlliancesPage/RequestsTable/RequestsTable'),
);
const MyAlliancesTable = lazy(
  () => import('../../pages/Admin/AlliancesPage/MyAlliancesTable/MyAlliancesTable'),
);

const { MY, OPEN, CREATED, INVITATIONS, REQUESTS } = ALLIANCES_TABS;

export const adminAlliancesRoutes = [
  {
    path: '',
    element: suspense(<AlliancesPage />),
    children: [
      {
        index: true,
        element: suspense(<ControlledAlliancesTable />),
      },
      {
        path: MY,
        element: suspense(<MyAlliancesTable />),
      },
      {
        path: OPEN,
        element: suspense(<AllAlliancesTable />),
      },
      {
        path: CREATED,
        element: suspense(<ControlledAlliancesTable />),
      },
      {
        path: INVITATIONS,
        element: suspense(<InvitationsTable />),
      },
      {
        path: REQUESTS,
        element: suspense(<RequestsTable />),
      },
    ],
  },
];
