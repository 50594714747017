import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TickIcon } from '../../../../../../../assets/svg';
import { Button, CopyButton, TextButton } from '../../../../../../../ui';
import { useAppDispatch } from '../../../../../../../hooks';
import { PopupTypes } from '../../../../../../../store/types';
import { openPopup, openReloadErrorPopup } from '../../../../../../../store';
import { modelsApi } from '../../../../../../../api/ModelsApi';
import { ParamType } from '../../../../../../../ui/components/Params/types';

import styles from './DockerImageSuccess.module.scss';

type PropsType = {
  onBuildImageClick: () => void;
  imageName: string | null;
  weightMetaData: {
    date: string;
    description: string;
    id: number;
    size: number;
    name: string;
    params: { model: ParamType[]; train: ParamType[]; test: ParamType[] };
  };
  modelName: string;
};

export const DockerImageSuccess = ({
  onBuildImageClick,
  imageName,
  weightMetaData,
  modelName,
}: PropsType) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [getDownloadWeightLink] = modelsApi.useGetDownloadWeightLinkMutation();

  const handleGuideClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DockerImageGuide,
        data: { name: imageName },
      }),
    );
  };

  const handleDownloadClick = () => {
    getDownloadWeightLink(weightMetaData.id)
      .unwrap()
      .catch(() => dispatch(openReloadErrorPopup()));
  };

  const handleWeightClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DetailedWeight,
        data: {
          weightData: weightMetaData,
          title: t('popups.weight.title', { model: modelName }),
          modelName,
          controlsType: 'readOnly',
          isPaid: false,
          onDownloadClick: handleDownloadClick,
        },
      }),
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.dockerWrapper}>
        <div>
          <div className={styles.title}>{t('pages.FLPage.dockerTitle')}</div>
          <div className={styles.text}>{imageName}</div>
        </div>
        {isCopied ? (
          <span className={styles.info}>
            <TickIcon />
          </span>
        ) : (
          <CopyButton target={String(imageName)} onTrigger={setIsCopied} />
        )}
      </div>
      <TextButton size="small" onClick={handleGuideClick}>
        {t('pages.FLPage.dockerGuideTitle')}
      </TextButton>
      <div className={styles.weightWrapper} onClick={handleWeightClick}>
        <div className={styles.title}>{t('pages.FLPage.weightTitle')}</div>
        <div className={styles.text}>{weightMetaData.name}</div>
      </div>
      <Button size="small" onClick={onBuildImageClick}>
        {t('pages.singleModelPage.btns.buildNewImage')}
      </Button>
    </div>
  );
};
