import { createSlice } from '@reduxjs/toolkit';
import { PopupType, PopupTypes } from '../types';

const initialState: PopupType = {
  isOpen: false,
  onAction: () => null,
  type: PopupTypes.None,
  data: {},
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openPopup(state, action) {
      state.isOpen = true;
      state.onAction = action.payload.onAction || (() => null);
      state.onCancel = action.payload.onCancel || (() => null);
      state.type = action.payload.type;
      state.data = action.payload.data || {};
    },
    openErrorPopup(state) {
      state.isOpen = true;
      state.type = PopupTypes.Error;
    },
    openReloadErrorPopup(state) {
      state.isOpen = true;
      state.type = PopupTypes.Error;
      state.data = { isReload: true };
    },
    closePopup(state) {
      state.isOpen = false;
      state.data = {};
    },
  },
});

export const { openPopup, closePopup, openErrorPopup, openReloadErrorPopup } = popupSlice.actions;
export default popupSlice.reducer;
