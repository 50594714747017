import { mapDeveloperResume, mapProjects } from '../mappers/developer/developer';
import { mapUserForAdmin, mapEmployee, mapUsersList } from '../mappers/user/user';
import { rootAPI } from '../store/rootApi';
import { EmployeeStateType } from '../types';
import { formatQueriesToString } from '../utils';
import { ProjectsResponse, ProjectsResponseTransformed } from './developer/types';
import { QueriesTypeWithIdRequest } from './models/types';
import { SummaryForAdminResponseTransformed, SummaryResponse } from './summary/types';
import { QueriesType } from './types';
import {
  UserForAdmin,
  UserForAdminResponse,
  UsersCountResponse,
  UsersCountResponseTransformed,
  UsersListResponse,
  UsersListResponseTransformed,
} from './user/types';
import { EmployeeResponse, EmployeeResponseTransformed } from './verification/types';

export const adminUsersApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getDeveloperResumeAsAdmin: build.query<
      {
        result: SummaryForAdminResponseTransformed;
      },
      number
    >({
      query(id) {
        return {
          url: `/admin/users/${id}/developer/resume/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: SummaryResponse) => {
        return {
          result: mapDeveloperResume(response),
        };
      },
    }),
    getDeveloperProjectsAsAdmin: build.mutation<ProjectsResponseTransformed, number>({
      query(id) {
        return {
          url: `/admin/users/${id}/developer/projects/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ProjectsResponse) => {
        return mapProjects(response);
      },
    }),
    getUsersAsAdmin: build.query<UsersListResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/admin/users/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: UsersListResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapUsersList(response.results),
          count: response.count,
        };
      },
    }),
    getUserAsAdmin: build.query<UserForAdmin, string | undefined>({
      query(id) {
        return {
          url: `/admin/users/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: UserForAdminResponse) => {
        return mapUserForAdmin(response);
      },
    }),
    getUsersCountAsAdmin: build.query<UsersCountResponseTransformed, void>({
      query() {
        return {
          url: `/admin/users/aggregate-info/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: UsersCountResponse) => {
        return {
          all: response.all_users,
          user: response.default,
          developer: response.developer,
          ba: response.ba,
        };
      },
    }),
    getEmployeesAsAdmin: build.query<EmployeeResponseTransformed, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/admin/users/${id}/business-admin/users/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: EmployeeResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapEmployee(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['Employees'],
    }),
    getBAEmployeesCountAsAdmin: build.query<number, number>({
      query(id) {
        return {
          url: `/admin/users/${id}/business-admin/aggregate-all-workers/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: { all_workers: number }) => {
        return response.all_workers;
      },
    }),
    patchEmployeeStateAsAdmin: build.mutation<null, { id: number; state: EmployeeStateType }>({
      query({ state, id }) {
        return {
          url: `/admin/business-admin/users/${id}/`,
          method: 'PATCH',
          body: JSON.stringify({ state }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Employees'],
    }),
  }),
});
