import { useRef, useState, DragEvent, ChangeEvent } from 'react';
import cn from 'classnames';

import { DragIcon, UploadIcon } from '../../../../assets/svg';
import { attachmentTextHelper, filesSizeSum, filesTypesHelper } from '../../../../utils';
import { text } from './constants';
import { PropTypes } from './types';
import { BYTES_IN_MB } from '../constants';

import styles from './UploadArea.module.scss';

export const UploadArea = ({ onUpload, quantityLimit, sizeLimit }: PropTypes) => {
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const containerClassName = cn(styles.container, {
    [styles.containerActive]: isDrag,
    [styles.containerError]: isError,
  });
  const infoClassName = cn(styles.info, {
    [styles.infoError]: isError,
  });

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDrag(false);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDrag(true);
    setIsError(false);
  };

  const handleUploadByDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDrag(false);

    const uploaded = Array.from(event.dataTransfer.files);
    const size = filesSizeSum(uploaded);
    const isValid = uploaded.every((file: File) => {
      const { isFormat } = filesTypesHelper(file.name);
      if (!isFormat) {
        return false;
      } else return true;
    });

    if (sizeLimit - size / BYTES_IN_MB < 0 || !isValid) {
      setIsError(true);
    } else {
      setIsError(false);
      onUpload(uploaded);
    }
  };

  const handleUploadByBtn = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsError(false);

    const { files } = event.target as HTMLInputElement;
    if (files) {
      const uploaded = Array.from(files);
      const size = filesSizeSum(uploaded);

      if (sizeLimit - size / BYTES_IN_MB < 0) {
        setIsError(true);
      } else {
        setIsError(false);
        onUpload(uploaded);
      }
    }
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div
      className={containerClassName}
      onDragOver={handleDragOver}
      onDrop={handleUploadByDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {isDrag ? (
        <div className={styles.area}>
          <DragIcon />
          <h3 className={styles.title}>{text.dragTitle}</h3>
        </div>
      ) : (
        <>
          <UploadIcon />
          <h3 className={styles.title}>
            {text.title}
            <button className={styles.button} onClick={handleUpload}>
              {text.button}
            </button>
          </h3>
          <p className={infoClassName}>{text.format}</p>
          <p className={infoClassName}>
            {text.application}
            <span className={styles.quantity}>{quantityLimit}</span>
            {attachmentTextHelper(quantityLimit)}
            {text.prev}
            <span>{sizeLimit.toFixed(1)} MB</span>
            {text.next}
          </p>
          <input
            type="file"
            multiple
            onChange={handleUploadByBtn}
            hidden
            accept=".png, .jpg, .jpeg, .txt, .doc, .docx, .pdf, .svg, .gif"
            ref={inputRef}
          />
        </>
      )}
    </div>
  );
};
