import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { CookieArea, Loader, PopupArea } from './ui';
import { useCookieCheck, useRedirect } from './hooks';
import './i18n';

import './assets/scss/app.scss';

export default function App() {
  useCookieCheck();
  useRedirect();

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
      <CookieArea />
      <PopupArea />
    </Suspense>
  );
}
