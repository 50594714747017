import cn from 'classnames';

import { CrossedIcon, EyeClosed, EyeOpen, TickIcon } from '../../../../assets/svg';
import { ErrorPopUp } from './ErrorPopUp';
import { text } from './constants';

import styles from '../Input/Input.module.scss';
import popUpStyles from './CreatePassword.module.scss';

type PropsType = {
  id: string;
  label: string;
  type: string;
  isPassword?: boolean;
  onClick?: any;
  register?: any;
  helperText?: any;
  value?: any;
  rating?: any;
  checkedValue?: any;
  isHintShown?: boolean;
  setHintShown: (isHintShown: boolean) => void;
  handleChange: () => void;
};

export const CreatePasswordInput = ({
  id,
  label,
  type,
  onClick,
  register,
  helperText,
  value,
  rating,
  checkedValue,
  isHintShown,
  setHintShown,
  handleChange,
}: PropsType) => {
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
  });
  const { infoPopup, weak, strong } = text;
  const { length, capitalLetters, smallLetters, numbers, specialCharacters } = infoPopup;

  return (
    <div className={styles.wrapper} onBlur={() => setHintShown(false)}>
      <div className={styles.container}>
        <input
          className={inputClassName}
          type={type}
          id={id}
          required
          onKeyUp={handleChange}
          onFocus={() => setHintShown(true)}
          onBlur={() => setHintShown(false)}
          placeholder=" "
          {...register?.(id)}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {value && (
          <div className={styles.icon} onClick={() => onClick(type, id)}>
            {type === 'text' ? <EyeOpen /> : <EyeClosed />}
          </div>
        )}
      </div>
      <div className={helperText ? styles.error : rating === strong ? styles.ok : styles.warning}>
        <span>{helperText || rating}</span>
        <span className={popUpStyles.hintWrapper}>
          {(helperText || rating) && (
            <span className={popUpStyles.hintIcon} onClick={() => setHintShown(!isHintShown)}>
              ?
            </span>
          )}
          {value && isHintShown && (
            <span className={popUpStyles.hintList}>
              {helperText && helperText !== weak && <ErrorPopUp value={value} />}
              {(helperText === weak || (!helperText && value)) && (
                <div className={popUpStyles.popUp}>
                  <ul>
                    <li>
                      <span className={popUpStyles.icon}>
                        <TickIcon />
                      </span>
                      <span className={popUpStyles.liActive}>{length}</span>
                    </li>
                    <li>
                      <span className={popUpStyles.icon}>
                        {checkedValue.is_b ? <TickIcon /> : <CrossedIcon />}
                      </span>
                      <span className={checkedValue.is_b ? popUpStyles.liActive : ''}>
                        {capitalLetters}
                      </span>
                    </li>
                    <li>
                      <span className={popUpStyles.icon}>
                        {checkedValue.is_s ? <TickIcon /> : <CrossedIcon />}
                      </span>
                      <span className={checkedValue.is_s ? popUpStyles.liActive : ''}>
                        {smallLetters}
                      </span>
                    </li>
                    <li>
                      <span className={popUpStyles.icon}>
                        {checkedValue.is_d ? <TickIcon /> : <CrossedIcon />}
                      </span>
                      <span className={checkedValue.is_d ? popUpStyles.liActive : ''}>
                        {numbers}
                      </span>
                    </li>
                    <li>
                      <span className={popUpStyles.icon}>
                        {checkedValue.is_sp ? <TickIcon /> : <CrossedIcon />}
                      </span>
                      <span className={checkedValue.is_sp ? popUpStyles.liActive : ''}>
                        {specialCharacters}
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </span>
          )}
        </span>
      </div>
    </div>
  );
};
