import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { authApi } from '../../../../api/authApi';
import { setAccount } from '../../../../store';
import { useAppDispatch } from '../../../../hooks';
import { CloseIcon } from '../../../../assets/svg';
import { HOME_PATH, LOG_IN_PATH } from '../../../../router';
import { Button } from '../../../../ui';

import styles from '../Form.module.scss';

interface PropsType {
  noticeType: 'reset' | 'singIn';
  email: string;
  canBeClosed: boolean;
  isRecovery: boolean;
}

export const FormNotice = ({ email, canBeClosed, isRecovery, noticeType }: PropsType) => {
  const [seconds, setSeconds] = useState<number>(30);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loginCheck] = authApi.useLoginCheckMutation();
  const [resendMail] = authApi.useResendMailMutation();
  const [resetPassword] = authApi.useResetPasswordMutation();

  useEffect(() => {
    const TIME_OUT_ID: NodeJS.Timeout | null =
      seconds > 0 ? setTimeout(() => setSeconds(seconds - 1), 1000) : null;
    return () => {
      if (TIME_OUT_ID) {
        clearTimeout(TIME_OUT_ID);
      }
    };
  }, [seconds]);

  const handleClick = async () => {
    isRecovery ? await resetPassword(email) : await resendMail();
    setSeconds(30);
  };

  const handleContinue = async () => {
    loginCheck()
      .unwrap()
      .then(accountData => {
        dispatch(setAccount(accountData));
        navigate(HOME_PATH);
      })
      .catch(() => {
        dispatch(setAccount({ isAuth: false }));
      });
  };

  return (
    <div className={styles.notice}>
      {canBeClosed && (
        <NavLink to={`/${LOG_IN_PATH}`} className={styles.closeBtn}>
          <CloseIcon />
        </NavLink>
      )}
      <h2 className={styles.title}>{t(`pages.authPage.formNoticeData.${noticeType}Title`)}</h2>
      <div className={cn(styles.paragraph, styles.paragraphTop)}>
        <Trans
          i18nKey="pages.authPage.formNoticeData.declaration"
          values={{ email }}
          components={{
            TargetEmail: <span />,
          }}
        />
      </div>
      <div className={styles.paragraph}>
        {t(`pages.authPage.formNoticeData.${noticeType}Request`)}
        <p>{t('pages.authPage.formNoticeData.spam')}</p>
      </div>
      <div className={styles.footer}>
        {seconds ? (
          <span>{t('pages.authPage.formNoticeData.timeLeft', { seconds })}</span>
        ) : (
          <>
            <span>{t('pages.authPage.formNoticeData.question')}</span>
            <button onClick={handleClick}>{t('buttons.reSendRequest')}</button>
          </>
        )}
      </div>
      {!canBeClosed && (
        <div className={styles.continueBtn}>
          <Button onClick={handleContinue}>{t('buttons.continue')}</Button>
        </div>
      )}
    </div>
  );
};
