import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import {
  ALLIANCES_PATH,
  NEW_ALLIANCE_PATH,
  MODEL_PATH,
  EDIT,
  ALLIANCES_TABS,
  ALLIANCE_OWNER_TABS,
  ALLIANCE_EMPLOYEE_TABS,
  ALLIANCE_FL_TABS,
  ALLIANCE_MODEL_TABS,
} from '../routeConstants';
import { ContentProtection } from '../HOC/RoutesProtection';
import { modelRoutes } from './modelRoutes';

// TOP LEVEL
const AlliancePage = lazy(() => import('../../pages/AlliancePage/AlliancePage'));
const AlliancesPage = lazy(() => import('../../pages/AlliancesPage/AlliancesPage'));
const EditAlliancePage = lazy(() => import('../../pages/EditAlliancePage/EditAlliancePage'));
const NewAlliancePage = lazy(() => import('../../pages/NewAlliancePage/NewAlliancePage'));
// ALLIANCES
const OpenAlliancesTable = lazy(
  () => import('../../pages/AlliancesPage/OpenAlliancesTable/OpenAllianceTable'),
);
const ControlledAlliancesTable = lazy(
  () => import('../../pages/AlliancesPage/ControlledAlliancesTable/ControlledAlliancesTable'),
);
const InvitationsTable = lazy(
  () => import('../../pages/AlliancesPage/InvitationsTable/InvitationsTable'),
);
const RequestsTable = lazy(() => import('../../pages/AlliancesPage/RequestsTable/RequestsTable'));
const MyAlliancesTable = lazy(
  () => import('../../pages/AlliancesPage/MyAlliancesTable/MyAlliancesTable'),
);
// ALLIANCE
const AllianceModel = lazy(() => import('../../pages/AlliancePage/AllianceModel/AllianceModel'));
const AllianceInfo = lazy(() => import('../../pages/AlliancePage/AllianceInfo/AllianceInfo'));
const AllianceEmployee = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/AllianceEmployee'),
);
const AllianceFL = lazy(() => import('../../pages/AlliancePage/AllianceFL/AllianceFL'));
// Model
const ModelsRequestsTableOwner = lazy(
  () =>
    import(
      '../../pages/AlliancePage/AllianceModel/AllianceModelOwner/ModelRequestsTable/ModelsRequestsTable'
    ),
);
const ModelTableOwner = lazy(
  () => import('../../pages/AlliancePage/AllianceModel/AllianceModelOwner/ModelTable/ModelTable'),
);
const WeightsRequestsTableOwner = lazy(
  () =>
    import(
      '../../pages/AlliancePage/AllianceModel/AllianceModelOwner/WeightsRequestsTable/WeightsRequestsTable'
    ),
);
// FL
const CurrentRound = lazy(
  () => import('../../pages/AlliancePage/AllianceFL/CurrentRound/CurrentRound'),
);
const RoundsHistory = lazy(
  () => import('../../pages/AlliancePage/AllianceFL/RoundsHistory/RoundsHistory'),
);
// Employee
const MembersTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/MembersTable/MembersTable'),
);
const MembersRequestsTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/RequestsTable/RequestsTable'),
);
const MembersInvitationsTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/InvitationsTable/InvitationsTable'),
);
const BannedTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/BannedTable/BannedTable'),
);

const { MY, CREATED, INVITATIONS, OPEN, REQUESTS } = ALLIANCES_TABS;
const { ALLIANCE_MODEL, INFO, MEMBERS, FL } = ALLIANCE_OWNER_TABS;
const { SUB_MODEL, ML_REQUESTS, WEIGHT_REQUESTS } = ALLIANCE_MODEL_TABS;
const { ALL, JOIN_REQUESTS, JOIN_INVITATIONS, BLOCKED } = ALLIANCE_EMPLOYEE_TABS;
const { CURRENT, HISTORY } = ALLIANCE_FL_TABS;

export const alliancesRoutes = {
  path: ALLIANCES_PATH,
  element: <ContentProtection />,
  children: [
    {
      path: '',
      element: suspense(<AlliancesPage />),
      children: [
        {
          index: true,
          element: suspense(<ControlledAlliancesTable />),
        },
        {
          path: MY,
          element: suspense(<MyAlliancesTable />),
        },
        {
          path: OPEN,
          element: suspense(<OpenAlliancesTable />),
        },
        {
          path: CREATED,
          element: suspense(<ControlledAlliancesTable />),
        },
        {
          path: INVITATIONS,
          element: suspense(<InvitationsTable />),
        },
        {
          path: REQUESTS,
          element: suspense(<RequestsTable />),
        },
      ],
    },
    {
      path: NEW_ALLIANCE_PATH,
      element: suspense(<NewAlliancePage />),
    },
    {
      path: ':id',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: suspense(<AlliancePage />),
          children: [
            {
              path: ALLIANCE_MODEL,
              element: suspense(<AllianceModel />),
              children: [
                {
                  index: true,
                  element: suspense(<ModelTableOwner />),
                },
                {
                  path: SUB_MODEL,
                  element: suspense(<ModelTableOwner />),
                },
                {
                  path: ML_REQUESTS,
                  element: suspense(<ModelsRequestsTableOwner />),
                },
                {
                  path: WEIGHT_REQUESTS,
                  element: suspense(<WeightsRequestsTableOwner />),
                },
              ],
            },
            {
              path: INFO,
              element: suspense(<AllianceInfo />),
            },
            {
              path: MEMBERS,
              element: suspense(<AllianceEmployee />),
              children: [
                {
                  index: true,
                  element: suspense(<MembersTable />),
                },
                {
                  path: ALL,
                  element: suspense(<MembersTable />),
                },
                {
                  path: JOIN_REQUESTS,
                  element: suspense(<MembersRequestsTable />),
                },
                {
                  path: JOIN_INVITATIONS,
                  element: suspense(<MembersInvitationsTable />),
                },
                {
                  path: BLOCKED,
                  element: suspense(<BannedTable />),
                },
              ],
            },
            {
              path: FL,
              element: suspense(<AllianceFL />),
              children: [
                {
                  index: true,
                  element: suspense(<CurrentRound />),
                },
                {
                  path: CURRENT,
                  element: suspense(<CurrentRound />),
                },
                {
                  path: HISTORY,
                  element: suspense(<RoundsHistory />),
                },
              ],
            },
          ],
        },
        {
          path: EDIT,
          element: suspense(<EditAlliancePage />),
        },
        {
          path: MODEL_PATH,
          element: <Outlet />,
          children: [...modelRoutes],
        },
      ],
    },
  ],
};
