import { useRef, useState, DragEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { DragIcon, UploadIcon } from '../../../../assets/svg';
import {
  filesFormatHelper,
  attachmentTextHelper,
  filesSizeSum,
  filesTypesHelper,
  isValidFormat,
} from '../../../../utils';
import { BYTES_IN_MB } from '../constants';
import { PropTypes } from './types';

import styles from './UploadArea.module.scss';

export const UploadArea = ({
  setFiles,
  formats,
  sizeLimit,
  quantityLimit,
  isMulti,
  isDisabled,
}: PropTypes) => {
  const [isDrag, setIsDrag] = useState<boolean>(false);
  const [isError, setIsError] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();

  const containerClassName = cn(styles.container, {
    [styles.containerActive]: isDrag,
    [styles.containerError]: isError,
    [styles.containerDisabled]: isDisabled,
  });

  const infoClassName = (error: number | null) =>
    cn(styles.info, {
      [styles.infoError]: isError === error,
    });

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDrag(false);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsError(null);
    setIsDrag(true);
  };

  const handleUploadByDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDrag(false);

    const uploaded = Array.from(event.dataTransfer.files);

    const size = filesSizeSum(uploaded);
    const isValid = uploaded.every((file: File) => {
      const isFormat = isValidFormat(formats, file.name);
      if (!isFormat) {
        return false;
      } else return true;
    });

    if (!isMulti && uploaded.length > 1) {
      return;
    }
    if (!isValid) {
      setIsError(1);
      return;
    }
    if (sizeLimit - size / BYTES_IN_MB < 0) {
      setIsError(2);
      return;
    }
    if (uploaded.some(file => file.size < 0.1)) {
      setIsError(3);
      return;
    }
    setIsError(null);
    setFiles(uploaded);
  };

  const handleUploadByBtn = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { files } = event.target as HTMLInputElement;

    if (files) {
      const uploaded = Array.from(files);
      const size = filesSizeSum(uploaded);
      if (sizeLimit - size / BYTES_IN_MB < 0) {
        setIsError(2);
        return;
      }
      if (uploaded.some(file => file.size < 0.1)) {
        setIsError(3);
        return;
      }
      setIsError(null);
      setFiles(uploaded);
    }
  };

  const handleUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div
      className={containerClassName}
      onDragOver={handleDragOver}
      onDrop={handleUploadByDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {isDrag ? (
        <div className={styles.area}>
          <DragIcon />
          <h3 className={styles.title}>{t('upload.dragInfo')}</h3>
        </div>
      ) : (
        <>
          <UploadIcon />
          <h3 className={styles.title}>
            {isMulti ? t('upload.applications.title') : t('upload.file.title')}
            <button className={styles.button} onClick={handleUpload} type="button">
              {t('upload.button')}
            </button>
          </h3>
          <p className={infoClassName(1)}>{t('upload.formats', { formats: formats.join(', ') })}</p>
          {isError === 3 ? (
            <p className={styles.error}> {t('upload.error')}</p>
          ) : (
            isMulti && (
              <p className={infoClassName(2)}>
                {t('upload.helper.prev')}
                <span className={styles.quantity}>{quantityLimit}</span>
                {t('upload.helper.next', {
                  size: sizeLimit.toFixed(1),
                  quantity: attachmentTextHelper(quantityLimit),
                })}
              </p>
            )
          )}
          <input
            type="file"
            onChange={handleUploadByBtn}
            hidden
            ref={inputRef}
            accept={filesFormatHelper(formats)}
            multiple={isMulti}
          />
        </>
      )}
    </div>
  );
};
