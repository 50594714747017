export const TickBtnIcon = () => (
  <svg width="17" height="13" viewBox="0 0 17 13" fill="none">
    <path
      d="M15.9333 1L4.79631 12.2L1 8.38223"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
