import { useTranslation } from 'react-i18next';

import { allianceApi } from '../../../api/allianceApi';
import { adminAllianceApi } from '../../../api/adminAllianceApi';
import { ALLIANCES_PATH, EDIT, MODELS_PATH, MODEL_PATH } from '../../../router';
import { useAppDispatch, useAppSelector, useNavigation } from '../../../hooks';
import {
  allianceSelector,
  isAdminSelector,
  openPopup,
  setNotification,
  userSelector,
} from '../../../store';
import { NotificationTypes, PopupTypes } from '../../../store/types';
import { DeleteIcon, PlusIcon } from '../../../assets/svg';
import { formatDate } from '../../../utils/formatData';
import { Button, ModelTitleIcon, TextButton } from '../../common';
import { ModelIconsBar } from '../../common/Icons/ModelIconsBar/ModelIconsBar';
import { PropTypes } from './types';

import styles from './AllianceModelCard.module.scss';

export const AllianceModelCard = ({
  name,
  type,
  task,
  weights,
  date,
  allianceId,
  allianceName,
  modelId,
  params = {
    model: [],
    dataset: [],
    test: [],
    train: [],
  },
  isPaid,
  isJointMl,
  userState,
  currentRound,
  hasFl,
}: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onNavigate } = useNavigation();
  const { isSubscriptionActive } = useAppSelector(userSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isAdminMember = isAdmin && userState === 'inAlliance';
  const isOwnerView = userState === 'owner' || isAdminMember;
  const isDisableAddWeights = isPaid && !isSubscriptionActive && userState !== 'owner' && !isAdmin;
  const { notAllMembersSubscribed, isHidden } = useAppSelector(allianceSelector);

  const deleteModelApi = isAdminMember
    ? adminAllianceApi.useDeleteAllianceModelAsAdminMutation
    : allianceApi.useDeleteAllianceModelMutation;
  const [deleteModel] = deleteModelApi();

  const handleDelete = async () => {
    deleteModel({ id: modelId })
      .unwrap()
      .then(() =>
        dispatch(
          setNotification({
            type: NotificationTypes.ModelDeletedFromAlliance,
            data: { modelName: name },
          }),
        ),
      );
  };

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DeleteAllianceML,
        data: { model: name, allianceName },
        onAction: handleDelete,
      }),
    );
  };

  const handleAddWeight = () => {
    dispatch(
      openPopup({
        type: PopupTypes.AddWeight,
        data: {
          isOwner: userState === 'owner',
          modelName: name,
          weightData: { name: null, params: null, description: null },
          allianceId,
          modelId,
        },
      }),
    );
  };
  const handleMembersCheck = () => {
    if (isPaid && notAllMembersSubscribed) {
      dispatch(
        openPopup({
          type: PopupTypes.PaidFLWarning,
          data: {
            link: `/${MODELS_PATH}/${modelId}/${EDIT}/`,
          },
          onAction: handleStartFL,
        }),
      );
    } else {
      handleStartFL();
    }
  };

  const handleStartFL = () => {
    dispatch(
      openPopup({
        type: PopupTypes.StartFL,
        data: {
          paramsData: params,
          modelId,
          weightsCount: weights,
          allianceId,
          modelName: name,
          isAdmin: isAdminMember,
        },
      }),
    );
  };

  return (
    <div
      className={styles.card}
      onClick={() => onNavigate(`/${ALLIANCES_PATH}/${allianceId}/${MODEL_PATH}/${modelId}`, true)}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <ModelTitleIcon isPaid={false} isJointMl={isJointMl} />
          <div className={styles.title}>
            <h3>{name}</h3>
            <p>{task}</p>
          </div>
        </div>
        {userState === 'outOfAlliance' && (
          <p className={styles.date}>
            {t('pages.alliancePage.modelCard.updated', { date: formatDate(new Date(date)) })}
          </p>
        )}
        {userState !== 'outOfAlliance' && (
          <TextButton
            onClick={handleAddWeight}
            iconProperty={{ icon: <PlusIcon />, position: 'left' }}
            isDisabled={isDisableAddWeights}
          >
            {t('buttons.addWeights')}
          </TextButton>
        )}
        {isOwnerView && (
          <TextButton
            onClick={handleDeleteOpen}
            style="canceled"
            isDisabled={Boolean(currentRound)}
            iconProperty={{ icon: <DeleteIcon />, position: 'left' }}
          >
            {t('buttons.delete')}
          </TextButton>
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.learning}>
        <div className={styles.info}>
          <p className={styles.type}>{type}</p>
          <div className={styles.weights}>
            <span>{weights}</span>
            <p>{t('pages.alliancePage.modelCard.versions')}</p>
          </div>
        </div>
        <div className={styles.iconsWrap}>
          <ModelIconsBar hasFl={hasFl} isPaid={isPaid} isVerified />
        </div>
        {isOwnerView && !currentRound && (
          <Button
            size="small"
            onClick={handleMembersCheck}
            isDisabled={isHidden}
            description={isHidden ? t('pages.alliancePage.modelCard.hint') : ''}
          >
            {t('buttons.startFL')}
          </Button>
        )}
      </div>
    </div>
  );
};
