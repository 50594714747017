import { Line } from 'react-chartjs-2';
import { getMetricCharOptions } from '../../../../../utils';
import { MetricChartItem } from '../../../../../types';

import styles from './MetricChart.module.scss';

export const MetricChart = ({ metric }: { metric: MetricChartItem }) => {
  const { options, data } = getMetricCharOptions(metric);

  return (
    <div className={styles.container}>
      <Line options={options} data={data} className={styles.metric} />
    </div>
  );
};
