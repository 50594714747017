import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { modelsApi } from '../../api/ModelsApi';
import { IModel } from '../../pages/ModelPage/types';
import { initialModelData } from '../../pages/ModelPage/constants';
import { TransformedModelApplicationInfoResponse } from '../../api/models/types';
import { adminModelApi } from '../../api/adminModelApi';

const initialState: IModel = initialModelData;
const defaultModelOwnerData = {
  companyName: '',
  email: '',
  fullName: '',
  id: 0,
  industry: '',
  INN: '',
  position: '',
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      modelsApi.endpoints.getModelById.matchFulfilled,
      (state: IModel, { payload }: PayloadAction<IModel>) => (state = payload),
    );
    builder.addMatcher(
      adminModelApi.endpoints.getModelByIdAsAdmin.matchFulfilled,
      (state: IModel, { payload }: PayloadAction<IModel>) => (state = payload),
    );
    builder.addMatcher(
      modelsApi.endpoints.getModelApplicationInfo.matchFulfilled,
      (state: IModel, { payload }: PayloadAction<TransformedModelApplicationInfoResponse>) =>
        (state = {
          ...state,
          ...payload.model,
          owner: { ...(state.owner ? state.owner : defaultModelOwnerData), ...payload.user },
        }),
    );
    builder.addMatcher(
      adminModelApi.endpoints.getModelApplicationInfoAsAdmin.matchFulfilled,
      (state: IModel, { payload }: PayloadAction<TransformedModelApplicationInfoResponse>) =>
        (state = {
          ...state,
          ...payload.model,
          owner: { ...(state.owner ? state.owner : defaultModelOwnerData), ...payload.user },
        }),
    );
  },
});

export default modelSlice.reducer;
