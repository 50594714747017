import { rootAPI } from '../store/rootApi';
import {
  AuthByTokenDataResponse,
  LoginCheckDataResponse,
  LoginData,
  LoginDataResponse,
  AccountData,
  RegistrationData,
  ValidateTokenTransformedResponse,
  ValidateTokenResponse,
  ResetPasswordData,
} from './auth/types';

export const authApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    login: build.mutation<AccountData, LoginData>({
      query(data) {
        return {
          url: `/auth/login/`,
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      transformResponse: (response: LoginDataResponse) => ({
        isAuth: true,
        id: response.id,
        email: response.email,
        confirmed: response.is_confirmed,
        isStaff: response.is_staff,
      }),
    }),
    registration: build.mutation<void, RegistrationData>({
      query(data) {
        return {
          url: `/auth/register/`,
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    resetPassword: build.mutation<void, string>({
      query(email) {
        return {
          url: `/auth/reset-password-request/`,
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify({ email }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    resetPasswordConfirm: build.mutation<void, ResetPasswordData>({
      query(data) {
        return {
          url: `/auth/reset-password-confirm/?token=${data.token}`,
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
    }),
    validateToken: build.mutation<ValidateTokenTransformedResponse, string>({
      query(token) {
        return {
          url: `/auth/validate-token/${token}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ValidateTokenResponse) => ({
        name: response.first_name,
        email: response.email,
      }),
    }),
    loginCheck: build.mutation<AccountData, void>({
      query() {
        return {
          url: `/auth/login-check/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: LoginCheckDataResponse) => ({
        isAuth: true,
        id: response.id,
        email: response.email,
        confirmed: response.confirmed,
        isStaff: response.is_staff,
      }),
    }),
    tokenCheck: build.mutation<AccountData, string>({
      query(token) {
        return {
          url: `/auth/?token=${token}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AuthByTokenDataResponse) => ({
        isAuth: true,
        id: 0,
        email: response.email,
        confirmed: response.is_confirmed,
        isStaff: false,
      }),
    }),
    resendMail: build.mutation<void, void>({
      query() {
        return {
          url: `/auth/resend/`,
          credentials: 'include',
          method: 'POST',
        };
      },
    }),
    resendTimeCheck: build.mutation<string, void>({
      query() {
        return {
          url: `/auth/resend/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: { remaining: string }) => response.remaining,
    }),
  }),
});
