import { useRef, useEffect, ReactNode } from 'react';
import { ChangeHandler } from 'react-hook-form';
import cn from 'classnames';

import styles from './TextArea.module.scss';

type PropsType = {
  id: string;
  label: string;
  placeholder?: string;
  register: (name: any) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: any;
  };
  helperText?: any;
  isDisabled?: boolean;
  value: string | null;
  maxLength: number;
  icon?: ReactNode;
  onClick?: () => void;
  ignoreEnter?: boolean;
  isRequired?: boolean;
  isHideLabel?: boolean;
};

export const TextArea = ({
  id,
  label,
  placeholder,
  register,
  helperText,
  isDisabled = false,
  value,
  maxLength,
  icon,
  onClick,
  ignoreEnter = false,
  isRequired = false,
  isHideLabel = false,
}: PropsType) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { ref, ...rest } = register(id);

  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
    [styles.inputDisable]: isDisabled,
    [styles.inputHasIcon]: icon,
  });
  const labelClassName = cn(styles.label, {
    [styles.labelRequired]: isRequired,
    [styles.labelTransparent]: isHideLabel,
  });

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `38px`;
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <textarea
        className={inputClassName}
        id={id}
        {...rest}
        ref={e => {
          ref(e);
          textAreaRef.current = e;
        }}
        required
        maxLength={maxLength}
        disabled={isDisabled}
        value={value ?? ''}
        placeholder={placeholder || ' '}
        onKeyDown={ignoreEnter ? handleEnter : undefined}
      />
      {icon && (
        <span className={styles.icon} onClick={onClick}>
          {icon}
        </span>
      )}
      <label className={labelClassName} htmlFor={id}>
        {label}
      </label>
      {!isDisabled && helperText && <div className={styles.error}>{helperText}</div>}
      {!isDisabled && (
        <span className={styles.counter}>
          {value ? value.length : 0}/{maxLength}
        </span>
      )}
    </div>
  );
};
