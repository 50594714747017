import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { allianceApi } from '../../api/allianceApi';
import { adminAllianceApi } from '../../api/adminAllianceApi';
import { IAlliance } from '../../pages/AlliancePage/types';
import { TransformedAllianceModelsResponse } from '../../api/alliances/types';

const initialState: IAlliance = {
  id: 0,
  owner: {
    id: 0,
    email: '',
    fullName: '',
    position: '',
    companyName: '',
    INN: '',
    industry: '',
  },
  industry: '',
  members: 0,
  attachments: [],
  applicationId: 0,
  date: '',
  name: '',
  description: '',
  isClosed: false,
  isActive: false,
  isHidden: false,
  isFavorite: false,
  isPaidModel: false,
  isJointMl: false,
  notAllMembersSubscribed: false,
  role: '',
  currentRound: null,
  modelId: 0,
};

const allianceSlice = createSlice({
  name: 'alliance',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      allianceApi.endpoints.getAllianceById.matchFulfilled,
      (state: IAlliance, { payload }: PayloadAction<IAlliance>) =>
        (state = { ...state, ...payload }),
    );
    builder.addMatcher(
      adminAllianceApi.endpoints.getAllianceByIdAsAdmin.matchFulfilled,
      (state: IAlliance, { payload }: PayloadAction<IAlliance>) =>
        (state = { ...state, ...payload }),
    );
    builder.addMatcher(
      allianceApi.endpoints.getAllianceModel.matchFulfilled,
      (state: IAlliance, { payload }: PayloadAction<TransformedAllianceModelsResponse>) => {
        if (payload.result.length) {
          state.isPaidModel = payload.result[0].isPaid;
          state.modelId = payload.result[0].id;
        }
      },
    );
  },
});

export default allianceSlice.reducer;
