import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { suspense } from '../HOC/suspense';
import { MODEL_TABS, EDIT, EDIT_PARAMS } from '../routeConstants';

const SeparateModelPage = lazy(() => import('../../pages/ModelPage/ModelPage'));
const EditModelPage = lazy(() => import('../../pages/EditModelPage/EditModelPage'));
const EditModelParamsPage = lazy(
  () => import('../../pages/EditModelParamsPage/EditModelParamsPage'),
);
const ModelInfo = lazy(() => import('../../pages/ModelPage/ModelInfo/ModelInfo'));
const ModelWeights = lazy(() => import('../../pages/ModelPage/ModelWeights/ModelWeights'));
const ModelCode = lazy(() => import('../../pages/ModelPage/ModelCode/ModelCode'));

const { INFO, MODEL_WEIGHTS, SOURCES } = MODEL_TABS;

export const modelRoutes = [
  {
    path: ':id',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: suspense(<SeparateModelPage />),
        children: [
          {
            index: true,
            element: suspense(<ModelInfo />),
          },
          {
            path: INFO,
            element: suspense(<ModelInfo />),
          },
          {
            path: MODEL_WEIGHTS,
            element: suspense(<ModelWeights />),
          },
          {
            path: SOURCES,
            element: suspense(<ModelCode />),
          },
        ],
      },
      {
        path: EDIT,
        element: suspense(<EditModelPage />),
      },
      {
        path: EDIT_PARAMS,
        element: suspense(<EditModelParamsPage />),
      },
    ],
  },
];
