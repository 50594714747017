import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { adminModelApi } from '../../../../api/adminModelApi';
import { modelsApi } from '../../../../api/ModelsApi';
import { OwnerActionStatuses } from '../../../../api/types';
import { useAppDispatch, useAppSelector, useToggle } from '../../../../hooks';
import { allianceSelector, isAdminSelector, setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { nameSchema } from '../../../../schemas/weightSchema';
import { formatDate } from '../../../../utils/formatData';
import { Button, Spinner, EditName, WeightPopup, RequestCreatorInfo } from '../../../../ui';
import { TitledInfo } from '../../TitledInfo/TitledInfo';
import { ModelInfo } from '../../../../pages/ModelPage/ModelInfo/ModelInfo';
import { RoleInAlliance } from '../../../../pages/AlliancePage/types';
import {
  ModelType,
  PropTypes,
  UserType,
  WeightType,
  modelInitialState,
  userInitialState,
} from './types';

import styles from './ModelToAllianceApplicationPopUp.module.scss';

export const ModelToAllianceApplicationPopUp = ({
  id,
  isReadOnly,
  onClose,
  onStateChange,
}: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [model, setModel] = useState<ModelType>(modelInitialState);
  const [weight, setWeight] = useState<WeightType | null>(null);
  const [user, setUser] = useState<UserType>(userInitialState);
  const {
    state: isInputShown,
    setTrue: setInput,
    setFalse: unsetInput,
  } = useToggle<boolean>(false);
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(nameSchema),
    mode: 'onChange',
  });
  const inputValue = watch('name');

  const isAdmin = useAppSelector(isAdminSelector);
  const { role } = useAppSelector(allianceSelector);
  const isAdminMember = role === RoleInAlliance.Member && isAdmin;

  const modelInfoRequest = isAdminMember
    ? adminModelApi.useGetModelApplicationInfoAsAdminMutation
    : modelsApi.useGetModelApplicationInfoMutation;
  const changeModelNameRequest = isAdminMember
    ? adminModelApi.usePatchChangeModelNameInRequestAsAdminMutation
    : modelsApi.usePatchChangeModelNameInRequestMutation;
  const changeModelStatusRequest = isAdminMember
    ? adminModelApi.usePatchChangeModelStatusInRequestAsAdminMutation
    : modelsApi.usePatchChangeModelStatusInRequestMutation;

  const [getModelInfo, { isLoading }] = modelInfoRequest();
  const [changeModelName] = changeModelNameRequest();
  const [getDownloadWeightLink] = modelsApi.useGetDownloadWeightLinkMutation();
  const [changeModelStatus] = changeModelStatusRequest();

  useEffect(() => {
    getModelInfo(id)
      .unwrap()
      .then(payload => {
        setWeight(payload.weight);
        setUser(payload.user);
        setModel(payload.model);
      });
  }, []);

  const handleDownloadWeight = () => getDownloadWeightLink(weight?.id);

  const handleNameChange = () => {
    if (model.data.name === inputValue) {
      unsetInput();
      return;
    }
    changeModelName({ id, name: inputValue })
      .then(() => {
        setModel(prev => {
          const newData = {
            ...prev,
            alliance: prev.alliance ? { ...prev.alliance } : null,
            data: { ...prev.data, name: inputValue },
          };
          return newData;
        });
        unsetInput();
      })
      .catch(err => {
        setError('name', { type: 'custom', message: err.data });
      });
  };

  const handleStatusChange = (state: OwnerActionStatuses) => {
    changeModelStatus({ id: model.applicationId, state })
      .unwrap()
      .then(() => {
        if (weight) {
          dispatch(
            setNotification({
              type:
                state === t('actionStatuses.owner.approvedState')
                  ? NotificationTypes.ModelAndWeightToAllianceAccept
                  : NotificationTypes.ModelAndWeightToAllianceDecline,
              data: {
                modelName: model.data.name,
                weightName: weight.name,
              },
            }),
          );
        } else {
          dispatch(
            setNotification({
              type:
                state === t('actionStatuses.owner.approvedState')
                  ? NotificationTypes.ModelToAllianceAccept
                  : NotificationTypes.ModelToAllianceDecline,
              data: {
                modelName: model.data.name,
              },
            }),
          );
        }
        onStateChange && onStateChange(state);
        onClose();
      });
  };

  useEffect(() => {
    setValue('name', model?.data?.name);
  }, [model?.data?.name]);

  useEffect(() => {
    inputValue && setValue('name', inputValue.replace(/\s+/g, ' '));
    if (!inputValue?.trim()) {
      setValue('name', '');
    }
  }, [inputValue]);

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.modelToAllianceApplication.title')}</h2>
      <div className={styles.topBlock}>
        <EditName
          name={model.data.name}
          isNameTaken={model.isNameTaken}
          onSubmit={handleNameChange}
          onNameChange={setInput}
          onInputClose={unsetInput}
          isInputShown={isInputShown}
          errors={errors}
          register={register}
          maxLength={50}
          isModel={true}
        />
      </div>
      <div className={styles.scrollContent}>
        <ModelInfo isInPopup={true} />
        {weight && Object.keys(weight)?.length > 0 && (
          <WeightPopup
            controlsType="readOnly"
            weight={weight}
            onDownloadClick={handleDownloadWeight}
          />
        )}
        <div className={styles.requestCreatorBlock}>
          <RequestCreatorInfo user={user} />
        </div>
      </div>
      <div className={styles.bottomInfo}>
        <TitledInfo
          isRow={true}
          title={t('popups.modelToAllianceApplication.dateTitle')}
          text={formatDate(new Date(model.createdDate))}
        />
        <div className={styles.btnsWrapper}>
          <Button
            style="secondary"
            size="small"
            onClick={() => handleStatusChange(t('actionStatuses.owner.rejectedState'))}
            isDisabled={isReadOnly}
          >
            {t('buttons.decline')}
          </Button>
          <Button
            size="small"
            onClick={() => handleStatusChange(t('actionStatuses.owner.approvedState'))}
            isDisabled={model.isNameTaken || isInputShown || isReadOnly}
          >
            {t('buttons.approve')}
          </Button>
        </div>
      </div>
    </div>
  );
};
