import { request } from '../utils/fetchHandler';
import { NotificationsResponse } from './notifications/types';

export const getNotifications = async (
  next: null | string = null,
): Promise<{ status: string; payload: NotificationsResponse }> => {
  const { status, payload } = await request(
    next ?? `${process.env.REACT_APP_BACK_END}/notifications/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const readNotifications = async (id: number | null): Promise<{ status: string }> => {
  const { status } = await request(
    id
      ? `${process.env.REACT_APP_BACK_END}/notifications/${id}/read/`
      : `${process.env.REACT_APP_BACK_END}/notifications/read/`,
    { credentials: 'include' },
    'PATCH',
  );

  return { status };
};

export const deleteNotifications = async (): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/notifications/remove/`,
    { credentials: 'include' },
    'DELETE',
  );

  return { status };
};
