export const ConfirmIcon = () => (
  <svg width="58" height="58" viewBox="0 0 58 58" fill="none">
    <path
      d="M57 29C57 44.464 44.464 57 29 57C13.536 57 1 44.464 1 29C1 13.536 13.536 1 29 1C33.3931 1 37.5498 2.0117 41.25 3.81482M51.75 11.5L27.25 36L20.25 29"
      stroke="#2D89EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
