import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivateIcon, DeactivateIcon } from '../../../../../assets/svg';

import styles from './AllianceChangeStatusBtn.module.scss';

interface PropsType {
  isActivateBtn: boolean;
  onClick?: (e: SyntheticEvent) => void;
  isDisable?: boolean;
}

export const AllianceChangeStatusBtn = ({
  onClick,
  isActivateBtn,
  isDisable = false,
}: PropsType) => {
  const { t } = useTranslation();
  const description = isDisable
    ? 'pages.alliancesPage.hints.FLIsStarted'
    : `buttons.${isActivateBtn ? 'activate' : 'deactivate'}Alliance`;
  return (
    <button
      className={isActivateBtn ? styles.activateButton : styles.deactivateButton}
      data-descr={t(description)}
      onClick={onClick}
      disabled={isDisable}
      type="button"
    >
      {isActivateBtn && <ActivateIcon />}
      {!isActivateBtn && <DeactivateIcon />}
      {description && <span className={styles.description}>{t(description)}</span>}
    </button>
  );
};
