import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DockerImageIcon } from './assets/svg';
import { TickIcon } from '../../../assets/svg';
import { CopyButton } from '../../common';
import { COMMAND_STEP, DOCKER_PATH_IN_TERMINAL, FOLDER_STEP } from './constants';

import styles from './DockerImageGuide.module.scss';
// инструкция на странице ФО
export const DockerImageGuide = () => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const { t } = useTranslation();
  const listItems: string[] = t('pages.alliancePage.dockerImageGuide', { returnObjects: true });

  return (
    <ul className={styles.wrapper}>
      {listItems.map((item, i: number) => (
        <Fragment key={item}>
          <li className={styles.item} key={item}>
            <div>{i + 1}.</div>
            <div>
              {i === FOLDER_STEP ? (
                <Trans
                  i18nKey={`${listItems[i]}`}
                  components={{ span: <span className={styles.bold} /> }}
                />
              ) : (
                listItems[i]
              )}
            </div>
          </li>
          {i === COMMAND_STEP && (
            <li className={styles.dockerImage}>
              <DockerImageIcon />
              <div className={styles.btnWrapper}>
                {isCopied ? (
                  <span className={styles.info}>
                    <TickIcon />
                  </span>
                ) : (
                  <CopyButton target={DOCKER_PATH_IN_TERMINAL} onTrigger={setIsCopied} />
                )}
              </div>
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  );
};
