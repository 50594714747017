import { useTranslation } from 'react-i18next';

import { Spinner, WeightPopup } from '../../../../ui';
import { EmptyIcon } from '../../../../assets/svg';
import { modelsApi } from '../../../../api/ModelsApi';
import { weightsApi } from '../../../../api/weightsApi';
import { WeightsHistoryByIdResponseTransformed as WeightType } from '../../../../api/models/types';

import styles from './WeightInAlliancePreviewPopUp.module.scss';

export type PropTypes = {
  modelName: string | undefined;
  id: number | undefined;
};

export const WeightInAlliancePreviewPopUp = ({ modelName, id }: PropTypes) => {
  const { t } = useTranslation();
  const { data, isError, isLoading } = modelsApi.useGetWeightsHistoryByIdQuery(Number(id));
  const [getDownloadWeightLink] = weightsApi.useGetDownloadWeightLinkApplicationMutation();

  const handleDownloadClick = () => getDownloadWeightLink(id);

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.error}>
        <EmptyIcon />
        <p className={styles.text}>{t('popups.weight.fetchHelperText')}</p>
      </div>
    );
  }

  return (
    <WeightPopup
      title={t('popups.weight.requestTitle', { model: modelName })}
      weight={data || ({} as WeightType)}
      onDownloadClick={handleDownloadClick}
      controlsType="readOnly"
    />
  );
};
