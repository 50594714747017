import { FieldErrorsImpl, FieldValues, UseFormRegister } from 'react-hook-form';

import { ChangeIcon, WarningIcon } from '../../../../assets/svg';
import { Input } from './Input/Input';
import { text } from './constants';

import styles from './EditName.module.scss';

type PropsType = {
  name: string;
  isNameTaken: boolean;
  onSubmit: () => void;
  onNameChange: () => void;
  onInputClose: () => void;
  isInputShown: boolean;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  register: UseFormRegister<FieldValues>;
  maxLength: number;
  isModel: boolean;
};

export const EditName = ({
  name,
  isNameTaken,
  onSubmit,
  onNameChange,
  onInputClose,
  isInputShown,
  errors,
  register,
  maxLength,
  isModel,
}: PropsType) => {
  const { modelErrorText, weightErrorText } = text;

  return (
    <>
      {isInputShown ? (
        <Input
          helperText={errors.name?.message}
          register={register}
          id="name"
          onClose={onInputClose}
          onSubmit={onSubmit}
          maxLength={maxLength}
        />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.icons}>
            {isNameTaken && (
              <div
                className={styles.warnIcon}
                data-descr={`${isModel ? modelErrorText : weightErrorText}`}
              >
                <WarningIcon />
              </div>
            )}
            <div className={styles.editIcon} onClick={onNameChange}>
              <ChangeIcon />
            </div>
          </div>
          <div className={styles.name}>{name}</div>
        </div>
      )}
    </>
  );
};
