import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getRoundEndTimeText, getTimeRemaining } from '../../../utils';

export const RoundTimer = ({ endRoundTime }: { endRoundTime: Date }) => {
  const { t } = useTranslation();
  const [time, setTime] = useState<string>('');
  const [isRoundEnded, setIsRoundEnded] = useState<boolean>(false);

  useEffect(() => {
    let interval = null as unknown as NodeJS.Timeout;
    const renderTimer = () => {
      const { total, result } = getTimeRemaining(endRoundTime);
      if (total <= 0) {
        clearInterval(interval);
        setIsRoundEnded(true);
      } else {
        setTime(result);
      }
    };

    renderTimer();

    interval = setInterval(renderTimer, 1000);

    return () => clearTimeout(interval);
  });

  return (
    <>
      {isRoundEnded ? (
        t('pages.FLPage.roundEnded', { time: getRoundEndTimeText(endRoundTime) })
      ) : (
        <>
          {t('pages.FLPage.roundRemaining')} <span>{time}</span>
        </>
      )}
    </>
  );
};
