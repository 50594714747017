import * as yup from 'yup';
import { ParamType, ParamsCategory } from '../ui/components/Params/types';

const paramValue = yup
  .string()
  .required('Значение параметра обязательно к заполнению')
  .min(1, 'Значение параметра не может быть короче 1-х символа')
  .typeError('Допустимый формат: строка, целое/дробное число, true/false');

export const getParamSchema = (
  params: ParamType[],
  curParamName: string,
  category: ParamsCategory,
) =>
  yup.object().shape({
    [`paramName${category}`]: yup
      .string()
      .required('Название параметра обязательно к заполнению')
      .min(2, 'Наименование параметра не может быть короче 2-х символов')
      .test('valid-differ-names', 'Параметры с таким названием уже есть', name => {
        if (name) {
          if (name === curParamName) return true;
          return Boolean(!params.find(elem => elem.name === name));
        }
        return false;
      }),
    [`paramValue${category}`]: paramValue,
  });
