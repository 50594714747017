import { SyntheticEvent } from 'react';
import cn from 'classnames';

import { InfoIcon } from '../../../../../assets/svg';

import styles from './InfoBtn.module.scss';

interface PropsType {
  description?: string;
  onClick?: (e: SyntheticEvent) => void;
}

export const InfoBtn = ({ description, onClick }: PropsType) => {
  return (
    <button className={cn(styles.button, styles.info)} onClick={onClick}>
      <InfoIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
