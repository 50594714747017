export const EMPLOYEES_ONBOARDING_STEPS = [
  {
    target: '.employeesWrapper',
    title: 'Все участники',
    content: (
      <p>
        Здесь находятся пользователи, указавшие вашу организацию/ИП как место работы: пользователи,
        которые ожидают верификации, пользователи, которых вы верифицировали, а также пользователи,
        запросы которых вы отклонили
      </p>
    ),
    disableBeacon: true,
    floaterProps: { hideArrow: true },
    placement: 'top-end',
    offset: -475,
  },
  {
    target: '.employeesWrapper',
    title: 'Участники, ожидающие верификации',
    content: (
      <p>
        Здесь расположены запросы пользователей, указавших вашу организацию/ИП как место работы, и
        ожидающие верификации. Если человек не относится к вашей организации, отклоните его запрос
      </p>
    ),
    floaterProps: { hideArrow: true },
    placement: 'top-end',
    offset: -475,
  },
  {
    target: '.verifiedTab',
    title: 'Верифицированные пользователи',
    content: (
      <p>
        Здесь будут находиться пользователи, запросы которых вы верифицировали. Если вы допустили
        ошибку и верифицировали не того участника, вы можете отменить его верификацию
      </p>
    ),
    placement: 'right',

    spotlightPadding: 15,
  },
  {
    target: '.declinedTab',
    title: 'Отклонённые запросы',
    content: (
      <p>
        Здесь будут находиться пользователи, запросы которых вы верифицировали. Если вы допустили
        ошибку и отклонили не тот запрос, вы можете отменить свое решение и верифицировать запрос
      </p>
    ),
    placement: 'left',
    spotlightPadding: 15,
  },
];
