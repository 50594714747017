import styles from './InfoButton.module.scss';

export const InfoButton = ({ info }: { info: any }) => {
  return (
    <div className={styles.helper}>
      <button className={styles.button} type="button">
        ?
      </button>
      <div className={styles.popup}>{info}</div>
    </div>
  );
};
