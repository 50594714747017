import { QuestionMarkIcon } from '../assets/QuestionMarkIcon';

import styles from './AccordionHead.module.scss';
// голубой header с иконкой вопроса
export const AccordionHead = ({ title }: { title: string }) => {
  return (
    <div className={styles.titleWrapper}>
      <QuestionMarkIcon />
      <h5 className={styles.title}>{title}</h5>
    </div>
  );
};
