import { ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { AccordionIcon } from '../../../../assets/svg';

import styles from './AccordionItem.module.scss';

type PropTypes = {
  head: ReactNode;
  body: ReactNode;
  isPrimary?: boolean;
  isOpen?: boolean;
  isColoredHead?: boolean;
  isColoredBody?: boolean;
  iconPosition?: 'static' | 'absolute';
  isCard?: boolean;
  onboardingClassName?: string;
};

export const AccordionItem = ({
  head,
  body,
  isPrimary = false,
  isOpen = false,
  isColoredHead = false,
  isColoredBody = false,
  iconPosition = 'static',
  isCard = false,
  onboardingClassName = ''
}: PropTypes) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(isOpen);

  const headerClassName = cn(styles.header, {
    [styles.active]: active,
    [styles.withBackground]: isColoredHead,
    [styles.headerStyled]: isPrimary,
  });

  const contentWrapClassName = cn(styles.contentWrap, {
    [styles.show]: active,
    [styles.contentWrapStyled]: isPrimary,
  });

  const btnClassName = cn(styles.icon, {
    [styles.iconStyled]: isPrimary,
    [styles.iconPositioned]: iconPosition === 'absolute',
  });

  const itemClassName = cn(styles.item, {
    [styles.itemStyled]: isPrimary,
    [styles.withBackground]: isColoredBody,
    [styles.itemCard]: isCard
  });

  const handleToggle = () => {
    setActive(st => !st);
    toggleContent();
  };

  const toggleContent = () => {
    if (!contentEl.current) return;

    const transitionEnd = () => {
      if (!contentEl.current) return;
      if (contentEl.current.style.maxHeight !== '0px') {
        contentEl.current.style.maxHeight = 'none';
      }
      contentEl.current.removeEventListener('transitionend', transitionEnd);
    };

    contentEl.current.addEventListener('transitionend', transitionEnd);

    if (contentEl.current.style.maxHeight === '0px') {
      contentEl.current.style.maxHeight = `${contentEl.current.scrollHeight}px`;
    } else {
      contentEl.current.style.maxHeight = `${contentEl.current.scrollHeight}px`;
      contentEl.current.style.maxHeight = `${contentEl.current.scrollHeight}px`;
      contentEl.current.style.maxHeight = '0px';
    }
  };

  useEffect(() => {
    if (!contentEl.current) return;
    contentEl.current.style.maxHeight = `${isOpen ? 'none' : '0px'}`;
  }, []);

  return (
    <ul className={onboardingClassName}>
      <li className={itemClassName}>
        <div className={headerClassName} onClick={handleToggle}>
          {head}
          <span className={btnClassName}>
            <AccordionIcon />
          </span>
        </div>
        <div ref={contentEl} className={contentWrapClassName}>
          <div>{body}</div>
        </div>
      </li>
    </ul>
  );
};
