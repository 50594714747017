import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import { SUPPORT_PATH, SUPPORT_TABS } from '../routeConstants';
import AppealsTable from '../../pages/AppealsPage/AppealsTable/AppealsTable';

const SupportUserPage = lazy(() =>
  import('../../pages/AppealsPage/UserAppealsPage/UserAppealsPage'),
);
const UserAppealPage = lazy(() => import('../../pages/AppealPage/UserAppealPage/UserAppealPage'));

export const { CURRENT, RESOLVED } = SUPPORT_TABS;

export const supportRoutes = {
  path: SUPPORT_PATH,
  element: <ContentProtection />,
  children: [
    {
      path: '',
      element: suspense(<SupportUserPage />),
      children: [
        {
          index: true,
          element: suspense(<AppealsTable tab={CURRENT} />),
        },
        {
          path: CURRENT,
          element: suspense(<AppealsTable tab={CURRENT} />),
        },
        {
          path: RESOLVED,
          element: suspense(<AppealsTable tab={RESOLVED} />),
        },
      ],
    },
    {
      path: ':id',
      element: suspense(<UserAppealPage />),
    },
  ],
};
