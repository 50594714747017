import cn from 'classnames';

import { Checkbox } from '..';

import styles from './LabeledCheckbox.module.scss';

type PropsType = {
  label: string;
  description?: string;
  onClick: () => void;
  id: string;
  isChecked: boolean;
  isDisabled?: boolean;
};

export const LabeledCheckbox = ({
  label,
  description,
  onClick,
  id,
  isChecked,
  isDisabled,
}: PropsType) => {
  return (
    <div className={styles.option}>
      <label
        className={cn(styles.label, isDisabled && styles.labelDisabled)}
        htmlFor={id}
        onClick={onClick}
      >
        <Checkbox isChecked={isChecked} id={id} />
        <span>{label}</span>
      </label>
      {description && (
        <div className={cn(styles.description, isDisabled && styles.descriptionDisabled)}>
          {description}
        </div>
      )}
    </div>
  );
};
