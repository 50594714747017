import {
  mapAllianceById,
  mapMembers,
  mapAllianceModelsRequests,
  mapAllianceWeightsRequests,
  mapAllianceModels,
} from '../mappers/alliances/alliances';
import { IAlliance } from '../pages/AlliancePage/types';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString } from '../utils';
import {
  AllianceDataResponse,
  AllianceModelsRequestsResponse,
  AllianceWeightsRequestsResponse,
  AllianceMemberAction,
  AllianceMembersResponse,
  ChangeAllianceStateRequest,
  EditAllianceRequest,
  GetAllianceMembersRequest,
  INewAllianceData,
  InviteToAllianceRequest,
  InviteToAllianceResponse,
  NewAllianceByAdminResponse,
  TransformedAllianceModelRequestsResponse,
  TransformedAllianceWeightsRequestsResponse,
  SetUserActionRequest,
  TransformedAllianceMembersResponse,
  AllianceMemberJoin,
  RequestToAllianceResponse,
  TransformedAllianceModelsResponse,
  AllianceModelsResponse,
} from './alliances/types';
import {
  AdminAllianceModelsAggregationResponse,
  AdminAllianceModelsAggregationTransformed,
  DeleteModelRequest,
  DeleteModelResponse,
  QueriesTypeWithIdRequest,
  RequestId,
} from './models/types';

export const adminAllianceApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getAllianceByIdAsAdmin: build.query<IAlliance, number | string | undefined>({
      query(alliance_id) {
        return {
          url: `/admin/alliances/${alliance_id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (payloadData: AllianceDataResponse) => mapAllianceById(payloadData),
      providesTags: () => ['Alliance'],
    }),
    getAllianceModelRequestsAsAdmin: build.query<
      TransformedAllianceModelRequestsResponse,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/admin/alliances/${id}/ml-applications/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceModelsRequestsResponse) => {
        return {
          result: mapAllianceModelsRequests(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceModelRequests'],
    }),
    getAllianceWeightsRequestsAsAdmin: build.query<
      TransformedAllianceWeightsRequestsResponse,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/admin/alliances/${id}/weights-applications/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceWeightsRequestsResponse) => {
        return {
          result: mapAllianceWeightsRequests(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceWeightsRequests'],
    }),
    getAllianceModelsAggregationAsAdmin: build.query<
      AdminAllianceModelsAggregationTransformed,
      number
    >({
      query(id) {
        return {
          url: `/admin/alliances/${id}/aggregate-info/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AdminAllianceModelsAggregationResponse) => {
        return {
          mlmodelsApplications: response.mlmodels_applications_count,
          weightsApplications: response.weights_applications_count,
        };
      },
    }),
    getAllianceModelAsAdmin: build.query<TransformedAllianceModelsResponse, RequestId>({
      query(id) {
        return {
          url: `/admin/alliances/${id}/models/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceModelsResponse) => {
        return {
          result: mapAllianceModels(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceModel'],
    }),
    deleteAllianceAsAdmin: build.mutation<null, number>({
      query(id) {
        return {
          url: `/admin/alliances/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
    patchSetAllianceStatusAsAdmin: build.mutation<null, ChangeAllianceStateRequest>({
      query({ id, isHidden }) {
        return {
          url: `/admin/alliances/${id}/hide/`,
          method: 'PATCH',
          body: JSON.stringify({ is_hidden: isHidden }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'AllAlliances', 'ControlledAlliances', 'MyAlliances'],
    }),
    patchEditAllianceAsAdmin: build.mutation<null, EditAllianceRequest>({
      query({ data, id }) {
        return {
          url: `/admin/alliances/${id}/`,
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
    postCreateAllianceAsAdmin: build.mutation<NewAllianceByAdminResponse, INewAllianceData>({
      query(newAlliance) {
        return {
          url: `/admin/alliances/`,
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(newAlliance),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
    deleteAllianceModelAsAdmin: build.mutation<DeleteModelResponse, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/admin/mlmodels/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['MyModels', 'OpenModels', 'AlliancesModels', 'AllianceModel'],
    }),
    getAllianceMembersCountAsAdmin: build.query<number, GetAllianceMembersRequest>({
      query({ id, type, state }) {
        return {
          url: `/admin/alliances/${id}/members/?${type}_state=${state}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceMembersResponse) => {
        return response.count;
      },
      providesTags: (result, error, arg) => {
        const { type, state } = arg;
        if (type === 'application') {
          if (state === 'invitations') return ['AllianceInvitationsCount'];
          return ['AllianceJoinRequestsCount'];
        }
        if (state === 'banned') return ['AllianceBannedCount'];
        return ['AllianceMembersCount'];
      },
    }),
    getAllianceMembersAsAdmin: build.query<
      TransformedAllianceMembersResponse,
      GetAllianceMembersRequest
    >({
      query({ id, type, state, page }) {
        return {
          url: `/admin/alliances/${id}/members/?${type}_state=${state}${
            page ? `&page=${page}` : ''
          }`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceMembersResponse) => {
        return {
          result: mapMembers(response.results),
          count: response.count,
        };
      },
      providesTags: (result, error, arg) => {
        const { type, state } = arg;
        if (type === 'application') {
          if (state === 'invitations') return ['AllianceInvitations'];
          return ['AllianceJoinRequests'];
        }
        if (state === 'banned') return ['AllianceBanned'];
        return ['AllianceMembers'];
      },
    }),
    patchSetUserActionAsAdmin: build.mutation<null, SetUserActionRequest>({
      query({ action, id, userRole }) {
        return {
          url: `/admin/application/${id}/alliance/change-state-by-${userRole}/?state=${action}`,
          method: 'PATCH',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'AllianceMembersCount', 'MyAlliances'],
    }),
    patchAllianceActionAsAdmin: build.mutation<null, AllianceMemberAction>({
      query({ action, id, userRole }) {
        return {
          url: `/admin/application/${id}/alliance/change-state-by-${userRole}/?state=${action}`,
          method: 'PATCH',
          credentials: 'include',
        };
      },
      invalidatesTags: () => [
        'MyAlliances',
        'OpenAlliances',
        'AllianceMembers',
        'AllianceMembersCount',
        'AllianceJoinRequestsCount',
        'InvitationsToAlliances',
      ],
    }),
    postJoinAllianceAsAdmin: build.mutation<RequestToAllianceResponse, AllianceMemberJoin>({
      query({ comment, id }) {
        return {
          url: `/admin/alliances/${id}/join/`,
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({ comment }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => [
        'MyAlliances',
        'OpenAlliances',
        'AllAlliances',
        'RequestsAlliance',
        'Alliance',
        'FLRound',
        'FlearningHistory',
      ],
    }),
    postInviteToAllianceAsAdmin: build.mutation<InviteToAllianceResponse, InviteToAllianceRequest>({
      query({ data, id }) {
        return {
          url: `/admin/alliances/${id}/invite/`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceInvitations', 'ControlledAlliances'],
    }),
  }),
});
