import Joyride, { CallBackProps, Step } from 'react-joyride';

import { OnboardingButton } from '../../common';
import { OnboardingToolTip } from '../OnboardingToolTip/OnboardingToolTip';
import { useAppSelector } from '../../../hooks';
import { isAdminSelector } from '../../../store';

type OnboardingTemplateTypes = {
  run: boolean;
  steps: Step[];
  handleJoyrideCallback: (data: CallBackProps) => void;
  startOnboardingCallback: () => void;
};

export const OnboardingTemplate = ({
  run,
  steps,
  handleJoyrideCallback,
  startOnboardingCallback,
}: OnboardingTemplateTypes) => {
  const isAdmin = useAppSelector(isAdminSelector);

  if (isAdmin) return null;

  return (
    <>
      <Joyride
        continuous
        tooltipComponent={OnboardingToolTip}
        callback={handleJoyrideCallback}
        hideCloseButton
        disableCloseOnEsc
        disableOverlayClose
        scrollOffset={76}
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={steps}
        floaterProps={{
          styles: {
            arrow: {
              length: 6,
              spread: 12,
            },
          },
        }}
      />
      <OnboardingButton onClick={startOnboardingCallback} />
    </>
  );
};
