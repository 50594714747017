import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import { SETTINGS_PATH, SETTINGS_TABS } from '../routeConstants';

const SettingsPage = lazy(() => import('../../pages/SettingsPage/SettingsPage'));
const Notifications = lazy(() => import('../../pages/SettingsPage/Notifications/Notifications'));
const Security = lazy(() => import('../../pages/SettingsPage/Security/Security'));

const { NOTIFICATIONS, SECURITY } = SETTINGS_TABS;

export const settingsRoutes = {
  path: SETTINGS_PATH,
  element: <ContentProtection />,
  children: [
    {
      path: '',
      element: <SettingsPage />,
      children: [
        {
          index: true,
          element: suspense(<Security />),
        },
        {
          path: SECURITY,
          element: suspense(<Security />),
        },
        {
          path: NOTIFICATIONS,
          element: suspense(<Notifications />),
        },
      ],
    },
  ],
};
