import { NotificationTypes } from '../../../../store/types';
import { text } from './constants';
import { PropTypes } from './types';

import styles from '../NotificationsArea.module.scss';

export const Notification = ({ type, data, onClick }: PropTypes) => {
  const NotificationDisplay = () => {
    switch (type) {
      case NotificationTypes.Approve:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.approve.one}
          </p>
        );
      case NotificationTypes.ApprovePlural:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.approve.some}
          </p>
        );
      case NotificationTypes.Block:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.blocked.one}
          </p>
        );
      case NotificationTypes.Decline:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.declined.one}
          </p>
        );
      case NotificationTypes.BlockPlural:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.blocked.some}
          </p>
        );
      case NotificationTypes.Unblock:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.unblocked.one}
          </p>
        );
      case NotificationTypes.UnblockPlural:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.unblocked.some}
          </p>
        );
      case NotificationTypes.AllianceApprove:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.allianceApprove.next}
          </p>
        );
      case NotificationTypes.AllianceDecline:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.allianceDecline.next}
          </p>
        );
      case NotificationTypes.InvitationInAlliance:
        return (
          <p className={styles.info}>
            {text.invitationInAlliance.prev}
            <span className={styles.name}>{data.name}</span>
          </p>
        );
      case NotificationTypes.ModelDeletedFromAlliance:
        return (
          <p className={styles.info}>
            {text.modelDeletedFromAlliance.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.modelDeletedFromAlliance.next}
          </p>
        );
      case NotificationTypes.JoinAllianceRequest:
        return (
          <p className={styles.info}>
            {text.joinAllianceRequest.prev}
            <span className={styles.name}>{data.allianceName}</span>
            {text.joinAllianceRequest.next}
          </p>
        );
      case NotificationTypes.AllianceActivate:
        return (
          <p className={styles.info}>
            {text.allianceActivate.prev}
            <span className={styles.name}>{data.allianceName}</span>
            {text.allianceActivate.next}
          </p>
        );
      case NotificationTypes.AllianceDeactivate:
        return (
          <p className={styles.info}>
            {text.allianceDeactivate.prev}
            <span className={styles.name}>{data.allianceName}</span>
            {text.allianceDeactivate.next}
          </p>
        );
      case NotificationTypes.AllianceDelete:
        return (
          <p className={styles.info}>
            {text.allianceDelete.prev}
            <span className={styles.name}>{data.allianceName}</span>
            {text.allianceDelete.next}
          </p>
        );
      case NotificationTypes.UserLeftAlliance:
        return (
          <p className={styles.info}>
            {text.userLeftAlliance.prev}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.UserJoinedAlliance:
        return (
          <p className={styles.info}>
            {text.userJoinedAlliance.prev}
            <span className={styles.name}>{data.name}</span>
          </p>
        );
      case NotificationTypes.ModelDeletedFromAccount:
        return (
          <p className={styles.info}>
            {text.modelDeletedFromAccount.prev}
            <span className={styles.name}>{data.modelName}</span>
            <span className={styles.name}>{text.modelDeletedFromAccount.middle}</span>
            {text.modelDeletedFromAccount.next}
          </p>
        );
      case NotificationTypes.WeightDeletedFromModel:
        return (
          <p className={styles.info}>
            {text.weightDeletedFromModel.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.weightDeletedFromModel.next}
          </p>
        );
      case NotificationTypes.WeightDeletedFromAlliance:
        return (
          <p className={styles.info}>
            {text.weightDeletedFromAlliance.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.weightDeletedFromAlliance.next}
          </p>
        );
      case NotificationTypes.ModelAndWeightAddedToAlliance:
        return (
          <p className={styles.info}>
            {text.modelAndWeightAddedToAlliance.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.modelAndWeightAddedToAlliance.middle}
            <span className={styles.name}>{data.weightName}</span>
            {text.modelAndWeightAddedToAlliance.next}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.ModelAddedToAlliance:
        return (
          <p className={styles.info}>
            {text.modelAddedToAlliance.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.modelAddedToAlliance.next}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.WeightAddedToModel:
        return (
          <p className={styles.info}>
            {text.weightAddedToModel.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.weightAddedToModel.next}
            <span className={styles.name}>{data.modelName}</span>
          </p>
        );
      case NotificationTypes.WeightAddedToModelInAlliance:
        return (
          <p className={styles.info}>
            {text.weightAddedToModelInAlliance.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.weightAddedToModelInAlliance.middle}
            <span className={styles.name}>{data.modelName}</span>
            {text.weightAddedToModelInAlliance.next}
          </p>
        );
      case NotificationTypes.AddModelToAllianceRequest:
        return (
          <p className={styles.info}>
            {text.addModelToAllianceRequest.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.addModelToAllianceRequest.next}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.AddModelAndWeightToAllianceRequest:
        return (
          <p className={styles.info}>
            {text.addModelAndWeightToAllianceRequest.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.addModelAndWeightToAllianceRequest.middle}
            <span className={styles.name}>{data.weightName}</span>
            {text.addModelAndWeightToAllianceRequest.next}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.AddWeightToModelRequest:
        return (
          <p className={styles.info}>
            {text.addWeightToModelRequest.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.addWeightToModelRequest.next}
          </p>
        );
      case NotificationTypes.ModelAddedToUserAccount:
        return (
          <p className={styles.info}>
            {text.modelAddedToUserAccount.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.modelAddedToUserAccount.next}
          </p>
        );
      case NotificationTypes.ModelAndWeightAddedToUserAccount:
        return (
          <p className={styles.info}>
            {text.modelAndWeightAddedToUserAccount.prev}
            <span className={styles.name}>{data.modelName}</span>
            {text.modelAndWeightAddedToUserAccount.middle}
            <span className={styles.name}>{data.weightName}</span>
            {text.modelAndWeightAddedToUserAccount.next}
          </p>
        );
      case NotificationTypes.ModelToAllianceAccept:
        return (
          <p className={styles.info}>
            {text.memberModelToAlliance.accept}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberModelToAlliance.next}
          </p>
        );
      case NotificationTypes.ModelToAllianceDecline:
        return (
          <p className={styles.info}>
            {text.memberModelToAlliance.decline}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberModelToAlliance.next}
          </p>
        );
      case NotificationTypes.ModelAndWeightToAllianceAccept:
        return (
          <p className={styles.info}>
            {text.memberModelAndWeightToAlliance.accept}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberModelAndWeightToAlliance.middle}
            <span className={styles.name}>{data.weightName}</span>
            {text.memberModelAndWeightToAlliance.next}
          </p>
        );
      case NotificationTypes.ModelAndWeightToAllianceDecline:
        return (
          <p className={styles.info}>
            {text.memberModelAndWeightToAlliance.decline}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberModelAndWeightToAlliance.middle}
            <span className={styles.name}>{data.weightName}</span>
            {text.memberModelAndWeightToAlliance.next}
          </p>
        );
      case NotificationTypes.WeightToAllianceModelAccept:
        return (
          <p className={styles.info}>
            {text.memberWeightToAllianceModel.accept}
            <span className={styles.name}>{data.weightName}</span>
            {text.memberWeightToAllianceModel.middle}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberWeightToAllianceModel.next}
          </p>
        );
      case NotificationTypes.WeightToAllianceModelDecline:
        return (
          <p className={styles.info}>
            {text.memberWeightToAllianceModel.decline}
            <span className={styles.name}>{data.weightName}</span>
            {text.memberWeightToAllianceModel.middle}
            <span className={styles.name}>{data.modelName}</span>
            {text.memberWeightToAllianceModel.next}
          </p>
        );
      case NotificationTypes.ProfileDataSave:
        return <p className={styles.info}>{text.profileDataSave.prev}</p>;
      case NotificationTypes.AddProjectToPortfolio:
        return (
          <p className={styles.info}>
            {text.addProjectToPortfolio.prev}
            <span className={styles.name}>{data.projectName}</span>
            {text.addProjectToPortfolio.next}
          </p>
        );
      case NotificationTypes.DeleteProject:
        return (
          <p className={styles.info}>
            {text.deleteProject.prev}
            <span className={styles.name}>{data.projectName}</span>
            {text.deleteProject.next}
          </p>
        );
      case NotificationTypes.AddToFavorites:
        return <p className={styles.info}>{data.textContent}</p>;
      case NotificationTypes.DeleteFromFavorites:
        return <p className={styles.info}>{data.textContent}</p>;
      case NotificationTypes.RoundHasStarted:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.roundName}</span>
            {text.roundHasStarted.middle}
            <span className={styles.name}>{data.modelName}</span>
            {text.roundHasStarted.next}
          </p>
        );
      case NotificationTypes.RoundHasEnded:
        return (
          <p className={styles.info}>
            {text.roundHasEnded.prev}
            <span className={styles.name}>{data.weightName}</span>
            {text.roundHasEnded.next}
            <span className={styles.name}>{data.roundName}</span>
          </p>
        );
      case NotificationTypes.AddSubscriptionToUserAccount:
        return <p className={styles.info}>{text.addSubscription}</p>;
      case NotificationTypes.AddAnotherSubscriptionToUserAccount:
        return <p className={styles.info}>{text.addAnotherSubscription}</p>;
      case NotificationTypes.UserExcludedFromAlliance:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.userExcludedFromAlliance}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      case NotificationTypes.UserIncludedToAlliance:
        return (
          <p className={styles.info}>
            <span className={styles.name}>{data.name}</span>
            {text.userIncludedToAlliance}
            <span className={styles.name}>{data.allianceName}</span>
          </p>
        );
      default:
        return <></>;
    }
  };
  return (
    <li className={styles.notification} onClick={onClick}>
      {NotificationDisplay()}
    </li>
  );
};
