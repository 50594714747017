import { ChangeHandler } from 'react-hook-form';
import { Trans } from 'react-i18next';

import styles from './LabeledCheckbox.module.scss';

interface PropTypes {
  register?: (name: string) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
}

export const LabeledCheckbox = ({ register }: PropTypes) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.checkbox}>
        <input className={styles.input} type="checkbox" id="checkBox" {...register?.('checkBox')} />
        <span className={styles.square}></span>
        <span className={styles.content}>
          <Trans
            i18nKey="pages.authPage.singInForm.checkboxLabel"
            components={{
              PolicyLink: (
                <a
                  href={process.env.REACT_APP_CREDENTIALS_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.agreement}
                />
              ),
            }}
          />
        </span>
      </label>
    </div>
  );
};
