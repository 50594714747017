import { RefObject, useEffect } from 'react';

type PropTypes = {
  ulRef: RefObject<unknown>;
  parentRef: RefObject<unknown>;
  callback: () => void;
};

export function useClickOutsideNotification({ ulRef, parentRef, callback }: PropTypes): void {
  const handleClickOutside = (event: Event) => {
    if (
      ulRef &&
      (ulRef as RefObject<HTMLDivElement>).current &&
      !(ulRef as RefObject<HTMLDivElement>).current?.contains(event.target as Element)
    ) {
      callback();
    }
    if (
      parentRef &&
      (parentRef as RefObject<HTMLButtonElement>).current &&
      (parentRef as RefObject<HTMLButtonElement>).current?.contains(event.target as Element)
    ) {
      event.stopPropagation();
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
}
