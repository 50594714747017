import { useEffect } from 'react';

import { getOnboardingData } from '../utils';
import { FlagTypes } from '../utils/onboardingDataHelper';

export const useOnboarding = (type: FlagTypes, callback: () => void) => {
  useEffect(() => {
    getOnboardingData(type) && callback();
  }, []);
};
