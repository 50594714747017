import { useEffect } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { checkCookie } from '../store';

export const useCookieCheck = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document.cookie = 'test=test; expires=-1';
    if (document.cookie) {
      dispatch(checkCookie(true));
    }
  }, []);
};
