export const TickIcon = () => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none">
    <path
      d="M10.8 1.3999L3.64043 8.5999L1.19995 6.14562"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
