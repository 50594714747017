import cn from 'classnames';

import { Checkbox } from '../../../../common';
import { PropTypes } from './types';

import styles from './ListItem.module.scss';

export const ListItem = ({ item, handleSelect, id, isChecked }: PropTypes) => {
  return (
    <li
      className={cn(styles.list, isChecked && styles.listSelected)}
      onClick={() => handleSelect(item)}
      id={id}
    >
      <div className={styles.wrapper} id={id}>
        <Checkbox isChecked={isChecked} id={id} />
        <p className={styles.name} id={id}>
          {item.name}
        </p>
      </div>
      <span className={styles.count} id={id}>
        {item.count}
      </span>
    </li>
  );
};
