import cn from 'classnames';

import styles from './Tabs.module.scss';
import { ReactNode } from 'react';

export const Tabs = ({
  style = 'secondary',
  children,
}: {
  style?: 'primary' | 'secondary';
  children?: ReactNode[];
}) => <ul className={cn(styles.container, style === 'primary' && styles.primary)}>{children}</ul>;
