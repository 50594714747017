import { TooltipRenderProps } from 'react-joyride';

import { CloseIcon } from '../TableSearch/assets/CloseIcon';
import { NavigateBtn } from '../../common';

import styles from './OnboardingToolTip.module.scss';

export const OnboardingToolTip = ({
  index,
  step,
  size,
  isLastStep,
  primaryProps,
  backProps,
  skipProps,
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <div {...tooltipProps} className={styles.container}>
      <button className={styles.closeBtn} {...skipProps} title="">
        <CloseIcon />
      </button>
      <div className={styles.content}>
        <h2 className={styles.title}>{step.title}</h2>
        <>{step.content}</>
      </div>
      <div className={styles.navBlock}>
        <div>{index > 0 && <NavigateBtn isPrev props={backProps} />}</div>
        <span className={styles.progress}>{`${index + 1} из ${size} `}</span>
        <div>{!isLastStep && <NavigateBtn props={primaryProps} />}</div>
      </div>
    </div>
  );
};
