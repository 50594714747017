import { ParamType } from '../../Params/types';

export type PropsType = {
  paramsData: { model: ParamType[]; train: ParamType[]; test: ParamType[] } | undefined;
  modelId: number | undefined;
  modelName: string | undefined;
  weightsCount: number | undefined;
  allianceId: number | undefined;
  isAdmin: boolean;
  onClose: () => void;
};

export type SelectOptionType = {
  name: string;
  id: number;
};

export const defaultParams = {
  model: [],
  dataset: [],
  test: [],
  train: [],
};
