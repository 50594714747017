import { useTranslation } from 'react-i18next';

import { formatDate } from '../../../../utils/formatData';
import { formatSize } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { openPopup, userSelector } from '../../../../store';
import { PopupTypes } from '../../../../store/types';
import { DeleteBtn, DownloadBtn, EditBtn, MetricTag } from '../../../common';
import { InvalidStateInfo } from './InvalidStateInfo/InvalidStateInfo';
import { TitledInfo } from '../../TitledInfo/TitledInfo';
import { Params } from '../../Params/Params';
import { ModelsPaidCard } from '../../ModelsPaidCard/ModelsPaidCard';
import { PropsType } from './types';
import { ParamType as MetricType } from '../../Params/types';

import styles from './WeightPopup.module.scss';

export const WeightPopup = ({
  title,
  controlsType = 'all',
  weight,
  modelName,
  isPaid = false,
  onDownloadClick,
  onDeleteClick,
  isAggregatedWeight = false,
}: PropsType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isSubscriptionActive } = useAppSelector(userSelector);

  const shouldShowParams = Boolean(Object.values(weight.params).flat().length);
  const isPaidWeight = isPaid && !isSubscriptionActive && controlsType === 'readOnly';
  const wrapperClassName = title ? styles.wrapper : styles.wrapperFullWidth;
  const contentClassName = title ? styles.content : '';
  const downloadDescr = isPaidWeight
    ? t('pages.singleModelPage.btns.downloadWeightsPaid')
    : t('buttons.download');

  const handleEditClick = () => {
    isAggregatedWeight
      ? dispatch(
          openPopup({
            type: PopupTypes.EditAggregatedWeight,
            data: {
              weightData: { ...weight },
              modelName,
            },
          }),
        )
      : dispatch(
          openPopup({
            type: PopupTypes.AddOrEditWeight,
            data: {
              weightData: { ...weight },
              weightId: 'id' in weight ? weight.id : null,
              isOwner: true,
              paramsData: weight.params,
              metricsData: 'metrics' in weight ? weight.metrics : [],
              fileData: new File(['some data'], `${'fileName' in weight ? weight.fileName : ''}`),
              isPending: 'state' in weight ? weight.state === 'pending' : false,
            },
          }),
        );
  };

  const DisplayControls = () => {
    switch (controlsType) {
      case 'readOnly':
        return null;
      case 'edit':
        return (
          <div className={styles.buttonsWrapper}>
            <EditBtn onClick={handleEditClick} description={t('buttons.edit')} />
          </div>
        );
      default:
        return (
          <div className={styles.buttonsWrapper}>
            <EditBtn onClick={handleEditClick} description={t('buttons.edit')} />
            <DeleteBtn onClick={onDeleteClick} description={t('buttons.delete')} />
          </div>
        );
    }
  };

  return (
    <div className={wrapperClassName}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={contentClassName}>
        <div className={styles.contentInner}>
          <div className={styles.contentHead}>
            <div className={styles.flex}>
              <div className={styles.leftSide}>
                <div className={styles.name}>
                  <div className={styles.nameText}>{weight.name}</div>
                  <div className={styles.nameTitle}>{t('popups.weight.nameTitle')}</div>
                </div>
              </div>
              <div className={styles.rightSide}>
                <div className={styles.date}>{formatDate(new Date(weight.date), true)}</div>
                {DisplayControls()}
              </div>
            </div>
            {'state' in weight && weight.state === 'invalid' && (
              <InvalidStateInfo modelName={modelName} />
            )}
          </div>
          <div className={styles.contentBody}>
            {'metrics' in weight && weight.metrics.length > 0 && (
              <div className={styles.metricsWrapper}>
                <h2 className={styles.metricsTitle}>{t('popups.weight.metricsTitle')}</h2>
                <div className={styles.metricsTags}>
                  {weight.metrics.map((metric: MetricType) => (
                    <MetricTag metric={metric} isReadOnly={true} key={metric.name} />
                  ))}
                </div>
              </div>
            )}
            {shouldShowParams && (
              <Params
                params={weight.params}
                isReadOnly={true}
                setParams={() => null}
                title={t('popups.weight.paramsTitle')}
              />
            )}
            <div className={styles.extra}>
              <div className={styles.description}>
                <TitledInfo title={t('popups.weight.descriptionTitle')} text={weight.description} />
              </div>
              <div className={styles.fileWrap}>
                <div className={styles.file}>
                  <TitledInfo
                    title={t('popups.weight.fileTitle')}
                    text={`${formatSize(weight.size)} ${t('files.size')}`}
                  />
                  <DownloadBtn
                    disabled={isPaidWeight}
                    description={downloadDescr}
                    onClick={onDownloadClick}
                    position={isPaidWeight ? 'bottom-end' : 'top'}
                  />
                </div>
                {isPaidWeight && <ModelsPaidCard />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
