export const CompanyIcon = () => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor">
    <path
      d="M17.4222 10.0389H11.4944V1.3111C11.4944 1.10569 11.3387 0.95 11.1333 0.95C4.22544 0.95 1.16823 4.42685 1.02868 4.58973L1.02841 4.5895L1.02507 4.5945C0.976198 4.66772 0.95 4.74387 0.95 4.82223V21V21.05H1H17.7333H17.7833V21L17.7833 10.4C17.7833 10.1946 17.6276 10.0389 17.4222 10.0389ZM3.86828 3.35506L3.86844 3.35498C5.23266 2.60687 7.54107 1.72573 10.7722 1.67304L10.7723 20.3278H8.69453L8.69438 16.9111C8.69438 16.7057 8.53868 16.55 8.33328 16.55H4.08883C3.88342 16.55 3.72773 16.7057 3.72773 16.9111V20.3278H1.67217V4.95406C1.90886 4.71851 2.63382 4.02635 3.86828 3.35506ZM7.97219 20.3278H4.45009V17.2722H7.97219V20.3278ZM17.0612 20.3278H11.4945V10.7611H17.0612V20.3278Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M15.1778 13.6716C15.3832 13.6716 15.5389 13.5159 15.5389 13.3105V12.4217C15.5389 12.2163 15.3832 12.0606 15.1778 12.0606C14.9724 12.0606 14.8167 12.2163 14.8167 12.4217V13.3105C14.8167 13.5159 14.9724 13.6716 15.1778 13.6716Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M13.2447 13.6716C13.4501 13.6716 13.6058 13.5159 13.6058 13.3105V12.4217C13.6058 12.2163 13.4501 12.0606 13.2447 12.0606C13.0393 12.0606 12.8836 12.2163 12.8836 12.4217V13.3105C12.8836 13.5159 13.0393 13.6716 13.2447 13.6716Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M15.1778 16.2058C15.3832 16.2058 15.5389 16.0501 15.5389 15.8447V14.9559C15.5389 14.7505 15.3832 14.5948 15.1778 14.5948C14.9724 14.5948 14.8167 14.7505 14.8167 14.9559V15.8447C14.8167 16.0499 14.9724 16.2058 15.1778 16.2058Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M13.2447 16.2058C13.4501 16.2058 13.6058 16.0501 13.6058 15.8447V14.9559C13.6058 14.7505 13.4501 14.5948 13.2447 14.5948C13.0393 14.5948 12.8836 14.7505 12.8836 14.9559V15.8447C12.8836 16.0499 13.0393 16.2058 13.2447 16.2058Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M15.1778 18.74C15.3832 18.74 15.5389 18.5843 15.5389 18.3789V17.4901C15.5389 17.2846 15.3832 17.129 15.1778 17.129C14.9724 17.129 14.8167 17.2846 14.8167 17.4901V18.3789C14.8167 18.5841 14.9724 18.74 15.1778 18.74Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M13.2447 18.74C13.4501 18.74 13.6058 18.5843 13.6058 18.3789V17.4901C13.6058 17.2846 13.4501 17.129 13.2447 17.129C13.0393 17.129 12.8836 17.2846 12.8836 17.4901V18.3789C12.8836 18.5841 13.0393 18.74 13.2447 18.74Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M4.00007 10.7612C4.20548 10.7612 4.36117 10.6055 4.36117 10.4001V9.57795C4.36117 9.37254 4.20548 9.21685 4.00007 9.21685C3.79466 9.21685 3.63896 9.37254 3.63896 9.57795V10.4001C3.63896 10.6078 3.81932 10.7612 4.00007 10.7612Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M6.11091 10.7612C6.31632 10.7612 6.47201 10.6055 6.47201 10.4001V9.57795C6.47201 9.37254 6.31631 9.21685 6.11091 9.21685C5.9055 9.21685 5.7498 9.37254 5.7498 9.57795V10.4001C5.7498 10.6056 5.90567 10.7612 6.11091 10.7612Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M4.00007 13.0942C4.20548 13.0942 4.36117 12.9385 4.36117 12.7331V11.911C4.36117 11.7055 4.20548 11.5499 4.00007 11.5499C3.79466 11.5499 3.63896 11.7055 3.63896 11.911V12.7331C3.63896 12.9408 3.81932 13.0942 4.00007 13.0942Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M6.11091 13.0942C6.31632 13.0942 6.47201 12.9385 6.47201 12.7331V11.911C6.47201 11.7055 6.31631 11.5499 6.11091 11.5499C5.9055 11.5499 5.7498 11.7055 5.7498 11.911V12.7331C5.7498 12.9386 5.90567 13.0942 6.11091 13.0942Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M8.28913 10.7612C8.49454 10.7612 8.65023 10.6055 8.65023 10.4001V9.57795C8.65023 9.37254 8.49454 9.21685 8.28913 9.21685C8.08372 9.21685 7.92803 9.37254 7.92803 9.57795V10.4001C7.92803 10.6055 8.08372 10.7612 8.28913 10.7612Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M8.28913 13.0942C8.49454 13.0942 8.65023 12.9385 8.65023 12.7331V11.911C8.65023 11.7055 8.49454 11.5499 8.28913 11.5499C8.08372 11.5499 7.92803 11.7055 7.92803 11.911V12.7331C7.92803 12.9386 8.08372 13.0942 8.28913 13.0942Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M4.00007 15.427C4.20548 15.427 4.36117 15.2713 4.36117 15.0659V14.2437C4.36117 14.0383 4.20548 13.8826 4.00007 13.8826C3.79466 13.8826 3.63896 14.0383 3.63896 14.2437V15.0659C3.63896 15.1599 3.68551 15.2498 3.75086 15.3151C3.81621 15.3805 3.90606 15.427 4.00007 15.427Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M6.11091 15.427C6.31632 15.427 6.47201 15.2713 6.47201 15.0659V14.2437C6.47201 14.0383 6.31631 13.8826 6.11091 13.8826C5.9055 13.8826 5.7498 14.0383 5.7498 14.2437V15.0659C5.7498 15.2467 5.90339 15.427 6.11091 15.427Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M8.28913 15.427C8.49454 15.427 8.65023 15.2713 8.65023 15.0659V14.2437C8.65023 14.0383 8.49454 13.8826 8.28913 13.8826C8.08372 13.8826 7.92803 14.0383 7.92803 14.2437V15.0659C7.92803 15.2467 8.08144 15.427 8.28913 15.427Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M6.11091 6.09546C6.31632 6.09546 6.47201 5.93977 6.47201 5.73436V4.91218C6.47201 4.70677 6.31631 4.55107 6.11091 4.55107C5.9055 4.55107 5.7498 4.70677 5.7498 4.91218V5.73436C5.7498 5.93979 5.90567 6.09546 6.11091 6.09546Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M8.28913 6.09546C8.49454 6.09546 8.65023 5.93977 8.65023 5.73436V4.91218C8.65023 4.70677 8.49454 4.55107 8.28913 4.55107C8.08372 4.55107 7.92803 4.70677 7.92803 4.91218V5.73436C7.92803 5.93977 8.08372 6.09546 8.28913 6.09546Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M4.00007 8.42847C4.20548 8.42847 4.36117 8.27278 4.36117 8.06737V7.24519C4.36117 7.03977 4.20548 6.88408 4.00007 6.88408C3.79466 6.88408 3.63896 7.03978 3.63896 7.24519V8.06737C3.63896 8.27489 3.8193 8.42847 4.00007 8.42847Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M6.11091 8.42847C6.31632 8.42847 6.47201 8.27278 6.47201 8.06737V7.24519C6.47201 7.03977 6.31631 6.88408 6.11091 6.88408C5.9055 6.88408 5.7498 7.03978 5.7498 7.24519V8.06737C5.7498 8.27263 5.90565 8.42847 6.11091 8.42847Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
    <path
      d="M8.28913 8.42847C8.49454 8.42847 8.65023 8.27278 8.65023 8.06737V7.24519C8.65023 7.03977 8.49454 6.88408 8.28913 6.88408C8.08372 6.88408 7.92803 7.03978 7.92803 7.24519V8.06737C7.92803 8.27261 8.08371 8.42847 8.28913 8.42847Z"
      fill="currentColor"
      stroke="#ADB3C2"
      strokeWidth="0.1"
    />
  </svg>
);
