import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';

import { useAppSelector } from '../../../hooks';
import { accountSelector } from '../../../store';
import { Button } from '../../../ui';
import { CONFIDENTIAL_DOCUMENT_LINK, COOKIE_DOCUMENT_LINK } from './constants';

import styles from './CookieArea.module.scss';

export const CookieArea = () => {
  const [isSeen, setIsSeen] = useState<string | null>(document.cookie);
  const { isCookieAccepted } = useAppSelector(accountSelector);
  const { t } = useTranslation();

  const handleAccept = () => {
    if (!isCookieAccepted) {
      return;
    }
    document.cookie = 'banner=seen';
    setIsSeen('seen');
  };

  return (
    <div className={cn(styles.wrapper, isSeen && styles.wrapperHidden)}>
      <div className={styles.container}>
        <p className={styles.text}>
          <Trans
            i18nKey="cookie.text"
            components={{
              link1: <a href={COOKIE_DOCUMENT_LINK} target="_blank" rel="noreferrer" />,
              link2: <a href={CONFIDENTIAL_DOCUMENT_LINK} target="_blank" rel="noreferrer" />,
            }}
          />
        </p>
        <Button onClick={handleAccept}>{t('cookie.btn')}</Button>
      </div>
    </div>
  );
};
