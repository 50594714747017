import {
  IAdminAllAlliances,
  IAdminControlledAlliance,
  IAdminInvitations,
  IAdminMyAlliances,
  IAdminRequestsToAlliances,
} from '../../pages/Admin/AlliancesPage/types';
import {
  IControlledAlliance,
  IInvitations,
  IMyAlliances,
  IOpenedAlliances,
  IRequestsToAlliances,
} from '../../pages/AlliancesPage/types';
import { CompanyDataResponse, ParamDictionaryType } from '../../types';
import { ParamType } from '../../ui/components/Params/types';
import { RoundStateType } from '../flearning/types';
import { QueriesType } from '../types';

export enum UserRoleTypes {
  Admin = 'admin',
  User = 'user',
  Owner = 'owner',
}

type IndustryType = {
  name: string;
  id: number;
  description: string;
};

type CurrentRoundResponse = {
  id: number;
  duration: string;
  start_date: string;
  state: RoundStateType;
} | null;

export type AttachmentType = {
  uuid_name: string;
  file_name: string;
  size: number;
  data_type: string;
  id: number;
  url?: string;
};

export type NewAllianceResponse = {
  id: number;
  name: string;
  description: string;
  industry: string;
  alliance_owner: number;
  is_closed: boolean;
  is_active: boolean;
  ml_models: number[];
};

export type NewAllianceByAdminResponse = {
  id: number;
  name: string;
  description: string;
  industry: string;
  is_closed: boolean;
  is_jointml: boolean;
};

export type OpenAllianceResponse = {
  id: number;
  name: string;
  industry: { id: number; name: string };
  members: number;
  last_user_application: {
    id: number;
    updated_at: string;
    created_at: string;
    application_state: string;
    user_state: string;
  } | null;
  is_hidden: boolean;
  is_jointml: boolean;
  is_closed: boolean;
};

export type AllianceOwnerResponseInfo = {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  position: string | null;
};

export type AllAdminAllianceResponse = OpenAllianceResponse & {
  alliance_owner: AllianceOwnerResponseInfo;
};

export type OpenAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: OpenAllianceResponse[];
};

export type AllAdminAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: AllAdminAllianceResponse[];
};

export type TransformedOpenAlliancesResponse = {
  count: number;
  result: IOpenedAlliances[];
};

export type TransformedAllAlliancesResponse = {
  count: number;
  result: IAdminAllAlliances[];
};

export type MyAllianceResponse = {
  id: number;
  alliance_id: number;
  alliance: string;
  industry: string;
  members: number;
  has_comment: boolean;
  application_state: string;
  user_state: string;
  is_hidden: boolean;
  current_round: CurrentRoundResponse;
  is_jointml: boolean;
  is_member?: boolean;
};

export type MyAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: MyAllianceResponse[];
};

export type AdminMyAllianceResponse = {
  id: number;
  alliance: {
    id: number;
    industry: { id: number; name: string };
    alliance_owner: AllianceOwnerResponseInfo;
    updated_at: string;
    created_at: string;
    name: string;
    description: string;
    is_closed: boolean;
    is_hidden: boolean;
    is_jointml: boolean;
  };
  members: number;
  application_state: string;
  user_state: string;
  current_round: CurrentRoundResponse;
};

export type AdminMyAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: AdminMyAllianceResponse[];
};

export type TransformedMyAlliancesResponse = {
  count: number;
  result: IMyAlliances[];
};

export type TransformedMyAlliancesAdminResponse = {
  count: number;
  result: IAdminMyAlliances[];
};

export type AvailableAlliancesCounterRequest = {
  state: 'user-alliances' | 'own-alliances';
};

export type AvailableAlliancesRequest = {
  state: 'user-alliances' | 'own-alliances';
  queries: QueriesType;
};

export type TransformedAvailableAlliancesRequest = {
  id: number;
  name: string;
  industry: string;
  isAvailable: boolean;
}[];

export type ControlledAllianceResponse = {
  id: number;
  name: string;
  industry: string;
  count_members: number;
  count_requests: number;
  is_hidden: boolean;
  is_jointml: boolean;
  current_round: CurrentRoundResponse;
};

export type ControlledAdminAllianceResponse = ControlledAllianceResponse;
export type AdminControlledUserAllianceResponse = ControlledAllianceResponse & {
  is_member: boolean;
};

export type ControlledAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: ControlledAllianceResponse[];
};

export type ControlledAdminAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: ControlledAdminAllianceResponse[];
};

export type AdminControlledUserAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: AdminControlledUserAllianceResponse[];
};

export type TransformedControlledUserAlliancesResponse = {
  count: number;
  result: IControlledAlliance[];
};

export interface IAdminControlledUserAlliances extends IControlledAlliance {
  isMember: boolean;
}

export type TransformedControlledAdminAlliancesResponse = {
  count: number;
  result: IAdminControlledAlliance[];
};

export type TransformedAdminControlledUserAlliancesResponse = {
  count: number;
  result: IAdminControlledUserAlliances[];
};

export type RequestToAlliancesResponse = {
  alliance: {
    id: number;
    industry: IndustryType;
    updated_at: string;
    created_at: string;
    name: string;
    description: string;
    alliance_owner: string;
    is_closed: boolean;
    is_jointml: boolean;
  };
  id: number;
  has_comment: boolean;
  members: number;
  updated_at: string;
  created_at: string;
  application_state: string;
};

export type RequestToAlliancesAdminResponse = RequestToAlliancesResponse;

export type RequestsToAlliancesAdminResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: RequestToAlliancesAdminResponse[];
};

export type RequestsToAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: RequestToAlliancesResponse[];
};

export type TransformedRequestsToAlliancesResponse = {
  count: number;
  result: IRequestsToAlliances[];
};

export type TransformedAdminRequestsToAlliancesResponse = {
  count: number;
  result: IAdminRequestsToAlliances[];
};

export type InvitationToAlliancesResponse = {
  alliance: {
    id: number;
    industry: IndustryType;
    updated_at: string;
    created_at: string;
    name: string;
    description: string;
    alliance_owner: number;
    is_closed: boolean;
    is_jointml: boolean;
  };
  id: number;
  has_comment: boolean;
  members: number;
  updated_at: string;
  created_at: string;
  application_state: string;
  is_member?: boolean;
};

export type InvitationToAlliancesAdminResponse = InvitationToAlliancesResponse;

export type InvitationsToAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: InvitationToAlliancesResponse[];
};

export type InvitationsToAlliancesAdminResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: InvitationToAlliancesAdminResponse[];
};

export type AdminUserAllianceInvitationsResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: InvitationToAlliancesAdminResponse[];
};

export type TransformedInvitationsToAlliancesResponse = {
  count: number;
  result: IInvitations[];
};

export interface IAdminUserAllianceInvitations extends IAdminInvitations {
  isMember: boolean;
}

export type TransformedAdminInvitationsToAlliancesResponse = {
  count: number;
  result: IAdminInvitations[];
};

export type TransformedAdminUserAllianceInvitations = {
  count: number;
  result: IAdminUserAllianceInvitations[];
};

export type AllianceMemberAction = {
  id: number | null | undefined;
  userRole: UserRoleTypes;
  action: string;
};

export type AllianceMemberJoin = {
  id: number | null;
  comment: string;
};

export type AllianceCommentResponse = {
  comment: string;
  created_at: string;
  updated_at: string;
};

export type AllianceCommentResponseTransformed = {
  text: string;
  createdAt: string;
};

export type RequestToAllianceResponse = {
  user: number;
  alliance: number;
  application_state: string;
  comment: string;
  created_at: string;
  updated_at: string;
};

export type EditAllianceResponse = {
  name: string;
  description: string;
  industry: number;
  is_closed: boolean;
};

export type AllianceDataResponse = {
  id: number;
  alliance_owner: {
    id: number;
    first_name: string;
    last_name: string;
    middle_name: string | null;
    position: string | null;
    company: CompanyDataResponse | null;
    email: string;
  };
  industry: string;
  current_user: string;
  members: number;
  alliance_attachments: { attachment_id: number; file_data: AttachmentType }[];
  last_application_id: number;
  created_at: string;
  name: string;
  description: string;
  current_round: { state: string; id: number } | null;
  is_closed: boolean;
  is_active: boolean;
  is_hidden: boolean;
  is_favorite: boolean;
  is_jointml: boolean;
  not_all_members_subscribed: boolean;
};

export type AllianceMembersResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: {
    user: {
      company: {
        inn: string;
        industry: string;
        name: string;
      };
      email: string;
      first_name: string;
      last_name: string;
      middle_name: string;
      position: string;
    };
    id: number;
    has_comment: boolean;
    isBanned: boolean;
    created_at: string;
    updated_at: string;
    application_state: string;
    is_admin: boolean;
  }[];
};

export type TransformedAllianceMembersResponse = {
  count: number;
  result: {
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
    company: string;
    inn: string;
    industry: string;
    id: number;
    isComment: boolean;
    isBanned: boolean;
    state: string;
    startDate: string;
    currentDate: string;
  }[];
};

export interface GetAllianceMembersRequest extends QueriesType {
  id: number;
  state: string;
  type: 'user' | 'application';
}

export type InviteToAllianceResponse = {
  user: string;
  alliance: number;
  application_state: string;
  comment: string;
};

export type AvailableAlliancesResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: {
    id: number;
    name: string;
    industry: string;
    is_free: boolean;
  }[];
};

export type AllianceModelsRequestsResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: {
    id: number;
    user: { first_name: string; middle_name: string | null; last_name: string };
    ml_model: { id: number; name: string };
    created_at: string;
    is_free: boolean;
  }[];
};

export type TransformedAllianceModelRequestsResponse = {
  count: number;
  result: {
    id: number;
    userName: string;
    modelName: string;
    modelId: number;
    date: string;
    isValid: boolean;
  }[];
};

export type AllianceWeightsRequestsResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: {
    id: number;
    user: { first_name: string; middle_name: string | null; last_name: string };
    ml_model: { id: number; name: string };
    weight_name: string;
    created_at: string;
    is_free: boolean;
  }[];
};

export type ChangeAllianceStateRequest = {
  isHidden: boolean;
  id: number;
};

export type SetJoinRequest = {
  id: number | null;
  comment: string;
};

export type SetUserActionRequest = {
  id: number | null | undefined;
  userRole: UserRoleTypes;
  action: string;
};

export type InviteToAllianceRequest = {
  id: string | undefined | number;
  data: IInvitationData;
};

export type EditAllianceRequest = {
  id: string | undefined | number;
  data: IEditAllianceData;
};

export type TransformedAllianceWeightsRequestsResponse = {
  count: number;
  result: {
    id: number;
    userName: string;
    modelName: string;
    modelId: number;
    weightName: string;
    date: string;
    isValid: boolean;
  }[];
};

export type AllianceModelsResponse = {
  count: number;
  links: { next: string | null; previous: string | null };
  results: {
    id: string;
    ml_model: {
      id: number;
      name: string;
      model_type: string;
      task: string;
      model_params: ParamDictionaryType | null;
      dataset_params: ParamDictionaryType | null;
      test_params: ParamDictionaryType | null;
      train_params: ParamDictionaryType | null;
      is_required_payment: boolean;
      provided_by_platform: boolean;
    };
    weights_number: number;
    has_fl: boolean;
  }[];
};

export type TransformedAllianceModelsResponse = {
  count: number;
  result: {
    id: number;
    type: string;
    task: string;
    name: string;
    modelParams: ParamType[];
    datasetParams: ParamType[];
    testParams: ParamType[];
    trainParams: ParamType[];
    weightsCount: number;
    isPaid: boolean;
    isJointMl: boolean;
    hasFl: boolean;
  }[];
};

export interface IEditAllianceData {
  name: string;
  description: string;
  industry: string | number | null;
  is_closed: boolean | null;
}

export interface INewAllianceData {
  industry: string | null;
  description: string;
  name: string;
  is_closed: boolean | null;
  is_jointml: boolean;
}

export interface IInvitationData {
  user: string;
  comment: string;
}

export type UserAlliancesAggregationResponse = {
  all: number;
  created: number;
  recieved_invitations: number;
  sent_invitations: number;
};

export type TransformedUserAlliancesAggregationResponse = {
  all: number;
  created: number;
  requests: number;
  invitations: number;
};
