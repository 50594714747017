import cn from 'classnames';

import { PropTypes } from './types';

import styles from './FilterButton.module.scss';

export const FilterButton = ({ name, count, isActive, setIsActive }: PropTypes) => {
  const buttonClassName = cn(styles.button, {
    [styles.buttonApplied]: count > 0,
    [styles.buttonActive]: isActive,
  });

  const iconClassName = cn(styles.icon, {
    [styles.iconApplied]: count > 0,
    [styles.iconActive]: isActive,
  });

  return (
    <button className={buttonClassName} id={name} onClick={setIsActive}>
      <p id={name}>{name}</p>
      {count > 0 && (
        <span className={styles.count} id={name}>
          {count}
        </span>
      )}
      <span className={iconClassName} id={name} />
    </button>
  );
};
