import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PROFILE_PATH } from '../../../router';
import { PaidIcon } from '../../../assets/svg';
import { Button } from '../../common';

import styles from './ModelsPaidCard.module.scss';

export const ModelsPaidCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.titleWrap}>
        <PaidIcon />
        <h2 className={styles.title}>{t('pages.singleModelPage.paidCard.title')}</h2>
      </div>
      <p className={styles.text}>{t('pages.singleModelPage.paidCard.text')}</p>
      <Button onClick={() => navigate(`/${PROFILE_PATH}`)}>{t('buttons.getSubscription')}</Button>
    </div>
  );
};
