import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { SystemAdminProtection } from '../HOC/RoutesProtection';
import { suspense } from '../HOC/suspense';
import { ADMIN_PATH, ALLIANCES_PATH, NEW_ALLIANCE_PATH } from '../routeConstants';
import { adminUsersRoutes } from './adminUsersRoutes';
import { appealsRoutes } from './appealsRoutes';
import { adminAlliancesRoutes } from './adminAlliancesRoutes';
import { adminAllianceRoutes } from './adminAllianceRoutes';
import { adminModelsRoutes } from './adminModelsRoutes';

const NewAlliancePage = lazy(() => import('../../pages/NewAlliancePage/NewAlliancePage'));

export const systemAdminRoutes = [
  {
    path: ADMIN_PATH,
    element: <SystemAdminProtection />,
    children: [
      {
        path: ALLIANCES_PATH,
        element: <Outlet />,
        children: [
          ...adminAlliancesRoutes,
          ...adminAllianceRoutes,
          {
            path: NEW_ALLIANCE_PATH,
            element: suspense(<NewAlliancePage />),
          },
        ],
      },
      ...adminUsersRoutes,
      ...appealsRoutes,
      ...adminModelsRoutes
    ],
  },
];
