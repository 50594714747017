import { request } from '../utils/fetchHandler';
import { IncomesResponse } from './payments/types';

export const getIncomes = async (
  next: null | string = null,
): Promise<{ status: string; payload: IncomesResponse }> => {
  const { status, payload } = await request(
    next ?? `${process.env.REACT_APP_BACK_END}/notifications/income/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const readIncomes = async (id: number): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/notifications/income/${id}/read/`,
    { credentials: 'include' },
    'PATCH',
    JSON.stringify({ is_new: false }),
  );

  return { status };
};
