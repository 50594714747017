import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getProjectFormData } from '../../../../utils/projectFormData';
import { projectSchema } from '../../../../schemas/projectSchema';
import { useAppDispatch } from '../../../../hooks';
import { deleteFileInProject, editProject } from '../../../../api/';
import { openErrorPopup, setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { LinkController } from '../../LinkController/LinkController';
import { Button, Input, TextArea } from '../../../common';
import { FormUpload } from '../../FormUpload/FormUpload';
import { IAttachment } from '../../Attachments/types';

import styles from '../AddProjectToPortfolio/AddProjectToPortfolio.module.scss';

export type PropTypes = {
  onClose: () => void;
  id: number | undefined;
  linkData: { link: string; name: string } | undefined;
  defaultValues: { name: string | undefined; description: string | undefined };
  filesData: IAttachment[] | undefined;
  onAction: () => void;
};
// TODO: fix any, change proptypes, works with errors
export const EditProject = ({ onClose, id, filesData, linkData, defaultValues, onAction }: any) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [deletedFiles, setDeletedFiles] = useState<number[]>([]);

  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(projectSchema),
    defaultValues,
    mode: 'onTouched',
  });

  const [files, setFiles] = useState<File[]>([]);
  const [link, setLink] = useState<{ name: string | null; link: string }>({
    name: '',
    link: '',
  });

  const [description, name] = watch(['description', 'name']);

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setIsDisabled(true);
    const formData = getProjectFormData({ inputValues: data, link, files });
    formData.append('id', id);
    const formDataResponse = await editProject({ formData, id });
    const { status: deleteFilesStatus } = await deleteFileInProject(id, deletedFiles);
    if (!formDataResponse.ok || deleteFilesStatus !== 'success') {
      dispatch(openErrorPopup());
    } else {
      onAction();
      onClose();
      dispatch(
        setNotification({
          type: NotificationTypes.ProfileDataSave,
        }),
      );
    }
  };

  useEffect(() => {
    name && setValue('name', name.replace(/\s+/g, ' '));
    if (name === ' ') {
      setValue('name', '');
    }
    if (description === ' ') {
      setValue('description', '');
    }
  }, [name, description]);

  useEffect(() => {
    setFiles([...filesData]);
    setLink({ ...linkData });
  }, []);

  const handleFileDelete = async (id: number) => {
    setDeletedFiles([...deletedFiles, id]);
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h2 className={styles.title}>{t('popups.addProjectToPortfolio.editTitle')}</h2>
        <div className={styles.description}>
          <span>{t('headings.requiredSymbol')}</span>
          {t('popups.addProjectToPortfolio.info')}
        </div>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.input}>
          <Input
            id="name"
            label={t('popups.addProjectToPortfolio.name')}
            register={register}
            helperText={errors.name?.message}
            maxLength={20}
            isRequired
          />
          <div className={styles.link}>
            <p>{t('popups.addProjectToPortfolio.link')}</p>
            <LinkController link={link} setLink={setLink} />
          </div>
        </div>
        <div className={styles.textArea}>
          <TextArea
            id="description"
            label={t('popups.addProjectToPortfolio.description')}
            register={register}
            helperText={errors.description?.message}
            value={description}
            maxLength={400}
            isRequired
          />
        </div>
        <FormUpload
          onDelete={handleFileDelete}
          files={files}
          setFiles={setFiles}
          maxQuantity={6}
          maxSize={50}
          isMulti
        />
        <div className={styles.buttons}>
          <Button style="secondary" onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" isDisabled={!isValid || isDisabled}>
            {t('buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};
