import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks';
import { accountSelector, organizationSelector, userSelector } from '../../../../store';
import { Button, InfoBlock, Input } from '../../../../ui';
import { PropTypes } from './types';

import styles from './JoinAlliancePopUp.module.scss';

export const JoinAlliancePopUp = ({ data, onClose }: PropTypes) => {
  const { t } = useTranslation();

  const { handleSubmit, register } = useForm({
    mode: 'onTouched',
  });

  const user = useAppSelector(userSelector);
  const organization = useAppSelector(organizationSelector);
  const { email } = useAppSelector(accountSelector);

  const onSubmit: SubmitHandler<FieldValues> = ({ comment }) => {
    data.onComment?.(comment);
    onClose();
  };

  return (
    <form
      className={styles.wrapper}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h2 className={styles.title}>{t('popups.joinAlliance.title')}</h2>
      <div className={styles.subtitle}>
        {t('popups.joinAlliance.subtitle', { alliance: data.name, industry: data.industry })}
      </div>
      <InfoBlock text={t('popups.joinAlliance.warnInfo')} />
      <div className={styles.grid}>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('headings.fullName')}</div>
          <div className={styles.textPrimary}>
            {user.lastName} {user.firstName} {user.middleName ?? ''}
          </div>
          {organization.name && <div className={styles.textSecondary}>{organization.position}</div>}
        </div>

        {organization.name && (
          <>
            <div className={styles.personalInfo}>
              <div className={styles.gridTitle}>{t('headings.company')}</div>
              <div className={styles.textPrimary}>{organization.name}</div>
              <div className={styles.textSecondary}>{organization.inn}</div>
            </div>
            <div className={styles.personalInfo}>
              <div className={styles.gridTitle}>{t('headings.industry')}</div>
              <div className={styles.textPrimary}>{organization.industry}</div>
            </div>
          </>
        )}
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('headings.email')}</div>
          <div className={styles.textPrimary}>{email}</div>
        </div>
      </div>
      <Input
        id="comment"
        label={t('labels.comment')}
        register={register}
        maxLength={200}
        isDisabled={false}
      />
      <Button type="submit">{t('buttons.send')}</Button>
    </form>
  );
};
