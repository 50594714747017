import { Trans, useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { weightSchema } from '../../../../schemas/weightSchema';
import { Button, Input, TextArea } from '../../../common';
import { modelsApi } from '../../../../api/ModelsApi';
import { useAppDispatch } from '../../../../hooks';
import { openErrorPopup } from '../../../../store';

import styles from './EditAggregatedWeightPopup.module.scss';

type PropsType = {
  onClose: () => void;
  modelName: string | undefined;
  weightData: { name: string; description: string; id: number } | undefined;
};

export const EditAggregatedWeightPopup = ({ onClose, modelName, weightData }: PropsType) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(weightSchema),
    defaultValues: weightData,
    mode: 'onTouched',
  });
  const [description, name] = watch(['description', 'name']);
  const [editWeight] = modelsApi.usePatchEditWeightMutation();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FieldValues> = async ({ name, description }) => {
    const patchData: { name?: string; description?: string } = { description };
    if (name !== weightData?.name) {
      patchData.name = name;
    }
    editWeight({ data: patchData, weightId: Number(weightData?.id), isAggregatedWeight: true })
      .unwrap()
      .then(() => onClose())
      .catch(({ status }) => {
        if (status === 400) {
          setError('name', {
            type: 'custom',
            message: t('popups.addWeightEditWeight.nameError'),
          });
        } else {
          dispatch(openErrorPopup());
        }
      });
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.editAggregatedWeight.title', { modelName })}</h2>
      <p className={styles.formSubtitle}>
        <Trans
          i18nKey="pages.allianceFormPage.subTitle"
          components={{
            span: <span />,
          }}
        />
      </p>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputsWrapper}>
          <Input
            id="name"
            label={t('popups.addWeightEditWeight.name')}
            register={register}
            helperText={errors.name?.message}
            maxLength={20}
            isRequired
          />
          <div className={styles.textAreaWrapper}>
            <TextArea
              id="description"
              label={t('popups.addWeightEditWeight.descriptionLabel')}
              register={register}
              helperText={errors.description?.message}
              value={description}
              maxLength={50}
              isRequired
            />
          </div>
        </div>
        <div className={styles.btnsWrapper}>
          <Button style="secondary" size="small" onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" isDisabled={!isValid} size="small">
            {t('buttons.save')}
          </Button>
        </div>
      </form>
    </div>
  );
};
