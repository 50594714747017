export const EmailIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M4.26677 6.1043L12.6668 12.7043L21.6668 6.1043M10.6669 11.5L4.26677 18.1043M21.0668 17.6043L14.6669 11.5M5.46677 19.0956C4.14129 19.0956 3.06677 18.0211 3.06677 16.6956V7.3043C3.06677 5.97882 4.14129 4.9043 5.46677 4.9043H19.8668C21.1923 4.9043 22.2668 5.97881 22.2668 7.3043V16.6956C22.2668 18.0211 21.1923 19.0956 19.8668 19.0956H5.46677Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
