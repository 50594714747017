import { useEffect, useState } from 'react';
import cn from 'classnames';

import { getUpload } from '../../../../api/upload';
import { FileIcon, ImageIcon } from '../../../../assets/svg';
import { CrossIcon } from '../../../common/Icons/CrossIcon/CrossIcon';
import { Spinner, Warning } from '../../../../ui';
import { makeFileName, formatSize, filesTypesHelper } from '../../../../utils';
import { PropTypes } from './types';
import { MB, warningText } from '../constants';

import styles from './UploadItem.module.scss';

export const UploadItem = ({ upload, onCancel, addUpload, data }: PropTypes) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const { isImage, format } = filesTypesHelper(upload.name);

  const containerClassName = cn(styles.container, {
    [styles.containerLoading]: isLoading,
    [styles.containerError]: isError,
  });
  const infoClassName = cn(styles.info, {
    [styles.infoDisabled]: isLoading,
  });

  const handleUpload = async () => {
    setIsLoading(true);
    const { status, payload } = await getUpload(upload, data.id, data.to);
    if (status === 'SUCCESS') {
      addUpload({
        name: upload.name,
        size: upload.size,
        id: payload.id,
        url: payload.file_data.url,
        uuid: payload.file_data.uuid,
        type: format,
      });
      onCancel(upload);
    } else {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const handleCancel = () => {
    onCancel(upload);
  };

  useEffect(() => {
    handleUpload();
  }, []);

  const ButtonDisplay = () => {
    if (isError) {
      return (
        <div className={styles.buttons}>
          <Warning description={warningText} />
          <button className={styles.cancel} onClick={handleCancel}>
            <CrossIcon color="GRAY" />
          </button>
        </div>
      );
    } else if (isLoading) {
      return (
        <div className={styles.buttons}>
          <Spinner />
        </div>
      );
    }
  };

  return (
    <li className={containerClassName}>
      <div className={infoClassName}>
        <div className={styles.icon}>{isImage ? <ImageIcon /> : <FileIcon />}</div>
        <p className={styles.name}>{makeFileName(upload.name)}</p>
        <p className={styles.format}>
          {format}, {formatSize(upload.size)} {MB}
        </p>
      </div>
      {ButtonDisplay()}
    </li>
  );
};
