import { lazy } from 'react';
import { AuthProtection } from '../HOC/RoutesProtection';
import { suspense } from '../HOC/suspense';
import { userRoutes } from './userRoutes';
import { profileRoutes } from './profileRoutes';
import { notFoundRoutes } from './notFoundRoutes';
import { CATCH_ALL_PATH } from '../routeConstants';
import { systemAdminRoutes } from './systemAdminRoutes';

const MainPage = lazy(() => import('../../pages/MainPage/MainPage'));

export const mainRoutes = [
  {
    path: CATCH_ALL_PATH,
    element: <AuthProtection protectedRoute={suspense(<MainPage />)} />,
    children: [...profileRoutes, ...systemAdminRoutes, ...userRoutes, ...notFoundRoutes],
  },
];
