import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MetricsTabs } from './MetricsTabs/MetricsTabs';
import { MetricChart } from './MetricChart/MetricChart';
import { MetricItem } from './MetricItem/MetricItem';
import { MetricChartItem } from '../../../../types';

import styles from './ShowEmployeeMetricsPopup.module.scss';

export const ShowEmployeeMetricsPopup = ({ metrics }: { metrics: MetricChartItem[] }) => {
  const { t } = useTranslation();
  const [activeId, setActiveId] = useState<number>(0);
  const metricTabs = useMemo(() => metrics.map((el, id) => ({ name: el.name, id })), [metrics]);
  const isChart = metrics[activeId].value.length > 1;

  return (
    <div className={styles.container}>
      <h2>{t('popups.showMetrics.title')}</h2>
      <MetricsTabs activeId={activeId} metricsList={metricTabs} onClick={setActiveId} />
      {isChart ? (
        <MetricChart metric={metrics[activeId]} />
      ) : (
        <MetricItem metric={metrics[activeId]} />
      )}
    </div>
  );
};
