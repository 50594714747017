import cn from 'classnames';
import { SyntheticEvent } from 'react';

import { DownloadIcon } from '../../../../../assets/svg';

import styles from './DownloadBtn.module.scss';

interface PropsType {
  description?: string;
  onClick?: (e: SyntheticEvent) => void;
  disabled?: boolean;
  position?: 'bottom' | 'bottom-end' | 'top' | 'top-end';
}

export const DownloadBtn = ({ description, onClick, disabled, position = 'bottom' }: PropsType) => {
  const descriptionClassName = cn(styles.description, styles[position]);
  return (
    <button
      className={cn(styles.button, styles.downloadButton)}
      onClick={onClick}
      disabled={disabled}
    >
      <DownloadIcon />
      {description && <span className={descriptionClassName}>{description}</span>}
    </button>
  );
};
