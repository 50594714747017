import { useTranslation } from 'react-i18next';

import { Attachment } from './Attachment/Attachment';
import { PropsType } from './types';

import styles from './Attachments.module.scss';

export const Attachments = ({
  attachments,
  to = '',
  isDisabled = false,
  isLink = false,
  isTitled = true,
}: PropsType) => {
  const { t } = useTranslation();

  return (
    <div>
      {isTitled && <div className={styles.title}>{t('headings.attachments')}</div>}
      <div className={styles.attachments}>
        {attachments.map((file, index) => (
          <Attachment
            file={file}
            to={to}
            key={`${file.uuid}_${index}`}
            isDisabled={isDisabled}
            isLink={isLink}
          />
        ))}
      </div>
    </div>
  );
};
