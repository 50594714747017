import { ProvidedIcon } from '../../../assets/svg';

import styles from './ProvidedTitle.module.scss';

export const ProvidedTitle = ({ title = '' }: { title?: string }) => {
  return (
    <div className={styles.container}>
      <ProvidedIcon />
      {title && <h3>{title}</h3>}
    </div>
  );
};
