import {
  mapAllianceModels,
  mapAllianceModelsRequests,
  mapAllianceWeightsRequests,
  mapMembers,
} from '../mappers';
import { mapAllianceById } from '../mappers/alliances/alliances';
import { IAlliance } from '../pages/AlliancePage/types';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString } from '../utils';
import {
  AllianceCommentResponse,
  AllianceCommentResponseTransformed,
  AllianceDataResponse,
  AllianceMembersResponse,
  AllianceModelsRequestsResponse,
  AllianceModelsResponse,
  AllianceWeightsRequestsResponse,
  ChangeAllianceStateRequest,
  EditAllianceRequest,
  GetAllianceMembersRequest,
  InviteToAllianceRequest,
  InviteToAllianceResponse,
  SetJoinRequest,
  SetUserActionRequest,
  TransformedAllianceMembersResponse,
  TransformedAllianceModelRequestsResponse,
  TransformedAllianceModelsResponse,
  TransformedAllianceWeightsRequestsResponse,
} from './alliances/types';
import {
  DeleteModelRequest,
  DeleteModelResponse,
  QueriesTypeWithIdRequest,
  RequestId,
} from './models/types';

export const allianceApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getAllianceById: build.query<IAlliance, number | string | undefined>({
      query(alliance_id) {
        return {
          url: `/alliances/${alliance_id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (payloadData: AllianceDataResponse) => {
        return mapAllianceById(payloadData);
      },
      providesTags: () => ['Alliance'],
    }),
    getAllianceModel: build.query<TransformedAllianceModelsResponse, RequestId>({
      query(id) {
        return {
          url: `/alliances/${id}/models/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceModelsResponse) => {
        return {
          result: mapAllianceModels(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceModel'],
    }),
    getAllianceModelRequests: build.query<
      TransformedAllianceModelRequestsResponse,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/applications/alliance/${id}/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceModelsRequestsResponse) => {
        return {
          result: mapAllianceModelsRequests(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceModelRequests'],
    }),
    getAllianceModelRequestsCount: build.query<number, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/applications/alliance/${id}/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceModelsRequestsResponse) => {
        return response.count;
      },
      providesTags: () => ['AllianceModelRequestsCount'],
    }),
    getAllianceWeightsRequests: build.query<
      TransformedAllianceWeightsRequestsResponse,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/weights/applications/alliance/${id}/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceWeightsRequestsResponse) => {
        return {
          result: mapAllianceWeightsRequests(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['AllianceWeightsRequests'],
    }),
    getAllianceWeightsRequestsCount: build.query<number, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/weights/applications/alliance/${id}/${formatQueriesToString(rest)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceWeightsRequestsResponse) => {
        return response.count;
      },
      providesTags: () => ['AllianceWeightsRequestsCount'],
    }),
    getAllianceMembers: build.query<TransformedAllianceMembersResponse, GetAllianceMembersRequest>({
      query({ id, type, state, page }) {
        return {
          url: `/alliances/${id}/members/?${type}_state=${state}${page ? `&page=${page}` : ''}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceMembersResponse) => {
        return {
          result: mapMembers(response.results),
          count: response.count,
        };
      },
      providesTags: (result, error, arg) => {
        const { type, state } = arg;
        if (type === 'application') {
          if (state === 'invitations') return ['AllianceInvitations'];
          return ['AllianceJoinRequests'];
        }
        if (state === 'banned') return ['AllianceBanned'];
        return ['AllianceMembers'];
      },
    }),
    getAllianceMembersCount: build.query<number, GetAllianceMembersRequest>({
      query({ id, type, state }) {
        return {
          url: `/alliances/${id}/members/?${type}_state=${state}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceMembersResponse) => {
        return response.count;
      },
      providesTags: (result, error, arg) => {
        const { type, state } = arg;
        if (type === 'application') {
          if (state === 'invitations') return ['AllianceInvitationsCount'];
          return ['AllianceJoinRequestsCount'];
        }
        if (state === 'banned') return ['AllianceBannedCount'];
        return ['AllianceMembersCount'];
      },
    }),
    getAllianceInvitationComment: build.query<AllianceCommentResponseTransformed, number>({
      query(id) {
        return {
          url: `application/${id}/alliance/comment/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AllianceCommentResponse) => {
        return { text: response.comment, createdAt: response.created_at };
      },
    }),
    deleteAllianceModel: build.mutation<DeleteModelResponse, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceModel', 'AlliancesModels', 'OpenModels'],
    }),
    deleteAlliance: build.mutation<null, number>({
      query(id) {
        return {
          url: `/alliances/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'ControlledAlliances'],
    }),
    patchSetAllianceStatus: build.mutation<null, ChangeAllianceStateRequest>({
      query({ id, isHidden }) {
        return {
          url: `/alliances/${id}/hide/`,
          method: 'PATCH',
          body: JSON.stringify({ is_hidden: isHidden }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'AllAlliances', 'ControlledAlliances', 'MyAlliances'],
    }),
    postSetJoinRequest: build.mutation<null, SetJoinRequest>({
      query({ id, comment }) {
        return {
          url: `/alliances/${id}/apply/`,
          method: 'POST',
          body: JSON.stringify({ comment }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance'],
    }),
    patchSetUserAction: build.mutation<null, SetUserActionRequest>({
      query({ action, id, userRole }) {
        return {
          url: `/application/${id}/alliance/change-state-by-${userRole}/?state=${action}`,
          method: 'PATCH',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'AllianceMembersCount', 'MyAlliances'],
    }),
    postInviteToAlliance: build.mutation<InviteToAllianceResponse, InviteToAllianceRequest>({
      query({ data, id }) {
        return {
          url: `/alliances/${id}/invite/`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceInvitations'],
    }),
    patchEditAlliance: build.mutation<null, EditAllianceRequest>({
      query({ data, id }) {
        return {
          url: `/alliances/${id}/`,
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Alliance', 'ControlledAlliances'],
    }),
  }),
});
