import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { PROFILE_PATH, START_USE_MODEL_PATH } from '../../../../../router';
import { modelsApi } from '../../../../../api/ModelsApi';
import { adminModelApi } from '../../../../../api/adminModelApi';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { isAdminSelector, openPopup, userSelector } from '../../../../../store';
import { PopupTypes } from '../../../../../store/types';
import { getMinutesRemaining } from '../../../../../utils';
import { Button, InfoButton } from '../../../../../ui';
import { DockerImageError, DockerImageProcessing, DockerImageSuccess } from './DockerImageState';

import styles from './DockerImage.module.scss';

type PropsType = {
  modelId: number;
  imageId: number | null | undefined;
  isDisabled: boolean;
};

export const DockerImage = ({ modelId, imageId, isDisabled }: PropsType) => {
  const isAdmin = useAppSelector(isAdminSelector);
  const getImageInfo = isAdmin
    ? adminModelApi.useGetImageInfoAsAdminQuery
    : modelsApi.useGetImageInfoQuery;
  const { data, isLoading, isError } = getImageInfo(Number(imageId), {
    skip: !imageId,
  });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { subscription } = useAppSelector(userSelector);

  const isSoonExpired = getMinutesRemaining(subscription.expirationDate) <= 20;

  const handleBuildImageClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.WeightForBuildingImage,
        data: { modelId },
      }),
    );
  };

  const handlePopupOpen = () => {
    if (isSoonExpired) {
      dispatch(
        openPopup({
          type: PopupTypes.DockerImageSubscriptionExpiredSoon,
          onAction: handleBuildImageClick,
          data: { link: `/${PROFILE_PATH}` },
        }),
      );
    } else handleBuildImageClick();
  };

  const displayContent = () => {
    if (isDisabled) {
      return <Button isDisabled={true}>{t('pages.singleModelPage.btns.buildImage')}</Button>;
    }
    if (!imageId) {
      return (
        <Button onClick={handlePopupOpen} size="small">
          {t('pages.singleModelPage.btns.buildImage')}
        </Button>
      );
    }
    if (!data) {
      return null;
    }
    if (data.state === 'processing') {
      return <DockerImageProcessing />;
    }
    if (data.state === 'error') {
      return <DockerImageError />;
    }
    if (data.state === 'success') {
      return (
        <DockerImageSuccess
          imageName={data.dockerImageName}
          weightMetaData={data.weightMetaData}
          modelName={data.modelName}
          onBuildImageClick={handlePopupOpen}
        />
      );
    }
  };

  if (isLoading || isError) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainTitleWrapper}>
        <h3 className={styles.mainTitle}>{t('pages.FLPage.dockerTitle')}</h3>
        <InfoButton
          info={
            <p>
              <Trans
                i18nKey={`pages.singleModelPage.hints.dockerImage`}
                components={{ a: <Link className={styles.link} to={`/${START_USE_MODEL_PATH}`} /> }}
              />
            </p>
          }
        />
      </div>
      {displayContent()}
    </div>
  );
};
