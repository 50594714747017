import styles from './AccordionBody.module.scss';

export const AccordionBody = ({ content }: { content: string[] }) => {
  return (
    <ul>
      {content.map((item: string, id: number) => (
        <li className={styles.contentItem} key={id}>
          <p>
            {id + 1}. {item}
          </p>
        </li>
      ))}
    </ul>
  );
};
