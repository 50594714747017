import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { modelsApi } from '../../../../api/ModelsApi';
import { setNotification } from '../../../../store';
import { useAppDispatch } from '../../../../hooks';
import { AddCreatedAlliance } from './AddCreatedAlliance/AddCreatedAlliance';
import { AddWeight } from '../AddModelToAnyAlliancePopup/AddWeight/AddWeight';
import { DataType, PropTypes } from './types';
import { NotificationTypes } from '../../../../store/types';

import styles from './AddModelToCreatedAlliancePopup.module.scss';

export const AddModelToCreatedAlliancePopup = ({ id, onClose }: PropTypes) => {
  const [data, setData] = useState<DataType>({
    modelId: id,
    modelName: '',
    allianceId: -1,
    weightId: null,
    weightName: '',
    allianceName: '',
  });
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [addModelCopyToAllianceOwner] = modelsApi.usePostAddModelCopyToAllianceAsOwnerMutation();

  const handleChangeStep = (action: 'next' | 'back') => {
    setCurrentStep(prev => (action === 'next' ? (prev += 1) : (prev -= 1)));
  };

  const handleModelName = (name: string) => {
    setData({ ...data, modelName: name });
  };

  const handleCheckWeight = (id: number | null, name: string | undefined) => {
    setData({ ...data, weightId: id, weightName: name });
  };

  const handleCheckAlliance = (id: number, name: string) => {
    setData({ ...data, allianceId: id, allianceName: name });
  };

  const handleSubmit = () => {
    const modelData = {
      allianceId: data.allianceId,
      checkedModelId: data.modelId,
      checkedWeightId: data.weightId,
    };
    addModelCopyToAllianceOwner(modelData)
      .unwrap()
      .then(res => {
        onClose();
        dispatch(
          setNotification({
            type: NotificationTypes[
              data.weightId ? 'ModelAndWeightAddedToAlliance' : 'ModelAddedToAlliance'
            ],
            data: data.weightId
              ? {
                  modelName: res.new_name,
                  weightName: data.weightName,
                  allianceName: data.allianceName,
                }
              : {
                  modelName: data.modelName,
                  allianceName: data.allianceName,
                },
          }),
        );
      });
  };

  const FormDisplay = () => {
    if (currentStep === 0) {
      return (
        <AddWeight
          id={id}
          onClose={onClose}
          onCheck={(id, name) => handleCheckWeight(id, name)}
          setModelName={name => handleModelName(name)}
          onNextStep={() => handleChangeStep('next')}
        />
      );
    }
    if (currentStep === 1) {
      return (
        <AddCreatedAlliance
          onBack={() => handleChangeStep('back')}
          onSubmit={handleSubmit}
          onCheck={(id: number, name: string) => handleCheckAlliance(id, name)}
          isValid={data.allianceId > 0}
        />
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h2 className={styles.title}>{t('popups.addSpecificModelToAnyAlliance.title')}</h2>
        {currentStep === 0 ? (
          <p className={styles.subtitle}>
            {t('popups.addSpecificModelToAnyAlliance.firstStep.subtitle')}
          </p>
        ) : (
          <p className={styles.subtitle}>
            <Trans
              i18nKey="popups.addSpecificModelToAnyAlliance.secondStep.subtitle"
              values={{ modelName: data.modelName, weightsName: data.weightName }}
              components={{ span: <span className={styles.bold} /> }}
            />
          </p>
        )}
      </div>
      {FormDisplay()}
    </div>
  );
};
