import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { PopupDataType } from '../../../../store/types';

import styles from './RequestToOwnerPopUp.module.scss';

export const RequestToOwnerPopUp = ({ data }: { data: PopupDataType }) => {
  const { firstName, lastName, middleName, position, email, inn, company, industry } = { ...data };
  const { t } = useTranslation();

  return (
    <>
      <h2 className={styles.title}>{t('popups.requestToOwner.title')}</h2>
      <div className={styles.text}>{data.message}</div>
      <div className={styles.grid}>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('headings.fullName')}</div>
          <div className={styles.textPrimary}>
            {lastName} {firstName} {middleName || ''}
          </div>
          <div className={styles.textSecondary}>{position}</div>
        </div>
        {company && (
          <div className={styles.personalInfo}>
            <div className={styles.gridTitle}>{t('headings.company')}</div>
            <div className={styles.textPrimary}>{company}</div>
            <div className={styles.textSecondary}>{inn}</div>
          </div>
        )}
        {company && (
          <div className={styles.personalInfo}>
            <div className={styles.gridTitle}>{t('headings.industry')}</div>
            <div className={styles.textPrimary}>{industry}</div>
          </div>
        )}
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('headings.email')}</div>
          <div className={cn(styles.textPrimary, styles.textDecorated)}>{email}</div>
        </div>
      </div>
    </>
  );
};
