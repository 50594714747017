import { useTranslation } from 'react-i18next';

import { DeleteIcon, FileIcon, ImageIcon, ModelUploadIcon } from '../../../../assets/svg';
import { formatSize, typeFormat, makeFileName, filesTypesHelper } from '../../../../utils';
import { PropTypes } from './types';

import styles from './UploadItem.module.scss';

export const UploadedItem = ({ file, handleDelete, type, isFreeze }: PropTypes) => {
  const { t } = useTranslation();
  const { isImage } = filesTypesHelper(file.name);

  const IconDisplay = () => {
    if (type === 'model') {
      return <ModelUploadIcon />;
    }
    if (isImage) {
      return <ImageIcon />;
    } else {
      return <FileIcon />;
    }
  };

  return (
    <li className={styles.container}>
      <div className={styles.info}>
        <div className={styles.icon}>{IconDisplay()}</div>
        <p className={styles.name}>{makeFileName(file.name)}</p>
        <p className={styles.format}>
          {t('files.typeAndSize', {
            type: typeFormat(file.name),
            size: formatSize(file.size),
          })}
        </p>
      </div>
      {!isFreeze && (
        <div className={styles.buttons}>
          <button className={styles.delete} onClick={() => handleDelete(file)} type="button">
            <DeleteIcon />
          </button>
        </div>
      )}
    </li>
  );
};
