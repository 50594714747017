import { mapOrganizations } from '../mappers';
import { rootAPI } from '../store/rootApi';
import { getExpirationDays } from '../utils';
import {
  AddUserData,
  AddUserResponse,
  AddUserTransformedResponse,
  CompanyListResponse,
  CompanyListTransformedResponse,
  EditUserData,
  EditUserResponse,
  EditUserTransformedResponse,
  GetRolesResponse,
  GetRolesTransformedResponse,
  GetUserCompanyDataResponse,
  GetUserCompanyDataTransformedResponse,
  HidePhoneRequest,
  IndustryListResponse,
  SetUserCompanyDataResponse,
  SetUserCompanyDataTransformedResponse,
  UserCompanyData,
  UserDataResponse,
  UserDataTransformedResponse,
} from './user/types';

export const userApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getUserRoles: build.mutation<GetRolesTransformedResponse, void>({
      query() {
        return {
          url: `/user/roles/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: GetRolesResponse) => ({
        userId: response.user_id,
        baId: response.ba_admin,
        developerId: response.developer,
      }),
    }),
    getUserData: build.mutation<UserDataTransformedResponse, number>({
      query(id) {
        return {
          url: `/user/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: UserDataResponse) => ({
        firstName: response.first_name,
        lastName: response.last_name,
        middleName: response.middle_name,
        phone: response.phone,
        id: response.account.id,
        date: response.account.created_at,
        isPhoneHidden: response.hide_phone,
        currentAmount: response.wallet.current_amount,
        currency: response.wallet.currency,
        subscription: response.active_subscription
          ? {
              date: response.active_subscription.start_date,
              expirationDate: response.active_subscription.end_date,
              type: response.active_subscription.type,
              days: getExpirationDays(
                response.active_subscription.start_date,
                response.active_subscription.type,
              ),
            }
          : null,
      }),
    }),
    setUserData: build.mutation<AddUserTransformedResponse, AddUserData>({
      query(data) {
        return {
          url: `/user/registration-completion/`,
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            first_name: data.firstName.trim(),
            last_name: data.lastName.trim(),
            middle_name: data.middleName.trim() ?? '',
            phone: data.phone.replace(/[^0-9]+/g, '').substring(1),
          }),
        };
      },
      transformResponse: (response: AddUserResponse) => ({
        firstName: response.first_name,
        lastName: response.last_name,
        middleName: response.middle_name,
        phone: response.phone,
        date: response.created_at,
        id: response.id,
      }),
    }),
    editUserData: build.mutation<EditUserTransformedResponse, EditUserData>({
      query({ id, data }) {
        return {
          url: `/user/${id}/`,
          credentials: 'include',
          method: 'PUT',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({
            first_name: data.firstName.trim(),
            last_name: data.lastName.trim(),
            middle_name: data.middleName.trim() ?? '',
            phone:
              data.phone.replace(/[^0-9]+/g, '').length > 10
                ? data.phone.replace(/[^0-9]+/g, '').substring(1)
                : data.phone.replace(/[^0-9]+/g, ''),
          }),
        };
      },
      transformResponse: (response: EditUserResponse) => ({
        firstName: response.first_name,
        lastName: response.last_name,
        middleName: response.middle_name,
        phone: response.phone,
      }),
    }),
    hidePhone: build.mutation<void, HidePhoneRequest>({
      query({ id, isHide }) {
        return {
          url: `/user/${id}/hide-phone/`,
          credentials: 'include',
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({ hide_phone: isHide }),
        };
      },
    }),
    getIndustryList: build.query<IndustryListResponse, void>({
      query() {
        return {
          url: '/industry/',
          credentials: 'include',
        };
      },
    }),
    getUserCompanyData: build.mutation<GetUserCompanyDataTransformedResponse | null, number>({
      query(id) {
        return {
          url: `/user/${id}/company-data/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: GetUserCompanyDataResponse) =>
        response.company_name
          ? {
              name: response.company_name,
              position: response.position,
              status: response.state,
              inn: response.inn,
              industry: response.industry,
              firstName: response.business_admin_data.first_name,
              lastName: response.business_admin_data.last_name,
              middleName: response.business_admin_data.middle_name,
              email: response.business_admin_data.email,
              phone: response.business_admin_data.phone,
            }
          : null,
    }),
    setUserCompanyData: build.mutation<SetUserCompanyDataTransformedResponse, UserCompanyData>({
      query(data) {
        return {
          url: `/user/add-company/`,
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({ ...data, company: data.id }),
        };
      },
      transformResponse: (response: SetUserCompanyDataResponse) => ({
        name: response.company_name,
        position: response.position,
        status: response.state,
        inn: response.inn,
        industry: response.industry,
        firstName: response.business_admin_data.first_name,
        lastName: response.business_admin_data.last_name,
        middleName: response.business_admin_data.middle_name,
        email: response.business_admin_data.email,
        phone: response.business_admin_data.phone,
      }),
    }),
  }),
});
