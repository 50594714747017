import cn from 'classnames';

import { ClockIcon, CrossIcon, ProcessIcon, StopIcon, TickIcon } from '../../../assets/svg';
import { ErrorIcon } from '../../../pages/AlliancePage/AllianceFL/ModelFLCard/CardHead/RoundState/assets';

import styles from './Status.module.scss';

export type StatusType =
  | 'confirmed'
  | 'canceled'
  | 'waiting'
  | 'processing'
  | 'default'
  | 'error'
  | 'warning'
  | 'paused';

type PropTypes = {
  state: StatusType;
  text: string;
  time?: string;
};

export const Status = ({ state, text, time }: PropTypes) => {
  const statusClassName = cn(styles.status, {
    [styles.confirmed]: state === 'confirmed',
    [styles.canceled]: state === 'canceled',
    [styles.waiting]: state === 'waiting',
    [styles.processing]: state === 'processing',
    [styles.default]: state === 'default',
    [styles.error]: state === 'error',
    [styles.warning]: state === 'warning',
    [styles.paused]: state === 'paused',
  });

  return (
    <div className={styles.container}>
      <div className={statusClassName}>
        {state === 'confirmed' && <TickIcon />}
        {state === 'canceled' && <CrossIcon />}
        {state === 'error' && <ErrorIcon />}
        {state === 'processing' && <ProcessIcon />}
        {state === 'warning' && <ClockIcon />}
        {state === 'paused' && <StopIcon />}
        <p>{text}</p>
      </div>
      {time && <span className={styles.warn}>{time}</span>}
    </div>
  );
};
