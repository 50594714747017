import cn from 'classnames';

import { PropTypes } from './types';

import styles from './Tag.module.scss';
import { TagCrossIcon } from '../../../../assets/svg';

export const Tag = ({
  isSelected,
  tag,
  onSelect,
  id,
  isDisabled = false,
  isCross = false,
}: PropTypes) => {
  const tagStyle = cn(styles.tag, {
    [styles.contained]: isSelected,
    [styles.outlined]: !isSelected,
    [styles.disabled]: isDisabled,
    [styles.crossed]: isCross,
  });

  const handleSelect = () => {
    onSelect(tag);
  };

  return (
    <li className={tagStyle} onMouseDown={handleSelect} id={id}>
      {tag.name}
      {isCross && <TagCrossIcon />}
    </li>
  );
};
