import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from './useAppSelector';
import { isAdminSelector } from '../store';
import { ADMIN_PATH } from '../router';

export const useAdminRedirect = () => {
  const isAdmin = useAppSelector(isAdminSelector);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAdmin) {
      navigate(`/${ADMIN_PATH}${pathname}`, { replace: true });
    }
  }, []);
};