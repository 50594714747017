import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InfoButton, TextButton } from '../../../common';
import { PlusIcon } from '../../../../assets/svg';
import { Param } from '../Param/Param';
import { EditParam } from '../EditParam/EditParam';
import { ParamType, ParamsBoardProps } from '../types';
import { defaultParam } from './constants';

import styles from './ParamsBoard.module.scss';

export const ParamsBoard = ({
  params,
  category,
  isReadOnly,
  isEditOnly,
  onAdd,
  onDelete,
  onEdit,
}: ParamsBoardProps) => {
  const { t } = useTranslation();
  const [isAddForm, setIsAddForm] = useState<boolean>(false);
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [editedParam, setEditedParam] = useState<ParamType>(defaultParam);

  const handleClose = () => {
    setEditedParam(defaultParam);
    setIsAddForm(false);
    setIsEditForm(false);
  };

  const handleOpenAddForm = () => {
    setIsAddForm(true);
    setIsEditForm(false);
    setEditedParam(defaultParam);
  };

  const handleOpenEditForm = () => {
    setIsAddForm(false);
    setIsEditForm(true);
  };

  const handleAdd = (newParam: ParamType) => {
    onAdd(category, newParam);
    handleClose();
  };

  const handleEditParam = (param: ParamType) => {
    setEditedParam(param);
    handleOpenEditForm();
  };

  const handleUpdate = (updatedParam: ParamType) => {
    onEdit(category, editedParam.name, updatedParam);
    handleClose();
  };

  const isDivider = params.length > 0 || isAddForm || isEditForm;
  const isAnyFormOpen = isAddForm || isEditForm;

  return (
    <div className={styles.container}>
      <div className={styles.topWrapper}>
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>
            {t(`pages.modelFormPage.paramsCategoryTitle.${category}`)}
          </h3>
          <InfoButton
            info={
              <Trans
                i18nKey={`pages.modelFormPage.${category}ParamsHint`}
                components={{ span: <span /> }}
              />
            }
          />
        </div>
        {!isReadOnly && (
          <div className={styles.addParamWrap}>
            <TextButton
              iconProperty={{ icon: <PlusIcon />, position: 'left' }}
              onClick={handleOpenAddForm}
              isDisabled={isAnyFormOpen}
            >
              {t('pages.modelFormPage.addParam')}
            </TextButton>
          </div>
        )}
      </div>
      {isDivider && <div className={styles.divider} />}
      {params.length > 0 && (
        <ul className={styles.paramsContainer}>
          {params.map(param => (
            <Param
              param={param}
              isReadOnly={isReadOnly}
              isDisabled={Boolean(editedParam.name && param.name !== editedParam.name)}
              onDelete={() => onDelete(category, param.name)}
              onEdit={() => handleEditParam(param)}
              key={param.name}
              isEditOnly={isEditOnly}
            />
          ))}
        </ul>
      )}
      {isAnyFormOpen && (
        <EditParam
          isEdit={isEditForm}
          param={isAddForm ? defaultParam : editedParam}
          params={params}
          category={category}
          onSave={isAddForm ? handleAdd : handleUpdate}
          onClose={handleClose}
          isDisabled={isEditOnly}
        />
      )}
    </div>
  );
};
