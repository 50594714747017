const s_letters = 'qwertyuiopasdfghjklzxcvbnm';
const b_letters = 'QWERTYUIOPLKJHGFDSAZXCVBNM';
const digits = '0123456789';
const specials = `\`"'~?!@#$%^&*()_-+=|/\\.,:;[]{}<>`;

const helper = (value = '') => {
  const password = value;

  let is_s = false;
  let is_b = false;
  let is_d = false;
  let is_sp = false;

  for (let i = 0; i < password.length; i++) {
    if (!is_s && s_letters.indexOf(password[i]) !== -1) is_s = true;
    else if (!is_b && b_letters.indexOf(password[i]) !== -1) is_b = true;
    else if (!is_d && digits.indexOf(password[i]) !== -1) is_d = true;
    else if (!is_sp && specials.indexOf(password[i]) !== -1) is_sp = true;
  }

  let rating = 0;
  let text = '';
  if (is_s) rating++;
  if (is_b) rating++;
  if (is_d) rating++;
  if (is_sp) rating++;

  if (rating === 2 || rating === 3) text = 'Средний пароль';
  if (rating === 4) text = 'Надёжный пароль';

  return { text, is_s, is_b, is_d, is_sp, rating };
};

export function getRating(value = '') {
  const { text } = helper(value);
  return text;
}

export function checkChars(value = '') {
  const { is_s, is_b, is_d, is_sp } = helper(value);
  return { is_s, is_b, is_d, is_sp };
}

export const isPasswordWeak = (value = '') => {
  const { rating } = helper(value);
  if (rating === 1) return false;
  else return true;
};
