import { useTranslation } from 'react-i18next';

import { deleteProject } from '../../../../api';
import { useAppDispatch } from '../../../../hooks';
import { openErrorPopup, openPopup, setNotification } from '../../../../store';
import { NotificationTypes, PopupTypes } from '../../../../store/types';
import { WebIcon } from '../../../../assets/svg';
import { TitledInfo } from '../../TitledInfo/TitledInfo';
import { Attachments } from '../../Attachments/Attachments';
import { DeleteBtn, EditBtn } from '../../../common';
import { PropTypes } from './types';

import styles from './ProjectDetailedInfo.module.scss';

export const ProjectDetailedInfo = ({ project, onAction }: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleEditProject = () => {
    dispatch(
      openPopup({
        type: PopupTypes.EditProject,
        data: { projectData: { ...project } },
        onAction,
      }),
    );
  };

  const handleDelete = async () => {
    const { status } = await deleteProject(project?.id);
    if (status === 'success') {
      onAction();
      dispatch(
        setNotification({
          type: NotificationTypes.DeleteProject,
          data: { projectName: project?.name },
        }),
      );
    } else dispatch(openErrorPopup());
  };

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DeleteProject,
        data: {
          name: project?.name,
        },
        onAction: handleDelete,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {t('popups.projectDetailedInfo.title', { name: project?.name })}
      </h2>
      <div className={styles.content}>
        <div className={styles.row}>
          <TitledInfo
            title={t('popups.projectDetailedInfo.nameLabel')}
            text={project?.name ?? ''}
          />
          <div className={styles.buttons}>
            <EditBtn
              description={t('popups.projectDetailedInfo.editButtonDescription')}
              onClick={handleEditProject}
            />
            <DeleteBtn
              description={t('popups.projectDetailedInfo.deleteButtonDescription')}
              onClick={handleDeleteOpen}
            />
          </div>
        </div>
        <div className={styles.description}>
          <TitledInfo
            title={t('popups.projectDetailedInfo.descriptionLabel')}
            text={project?.description ?? ''}
          />
        </div>
        {project?.link?.link && (
          <div className={styles.link}>
            <WebIcon />
            <a href={project.link.link} target="_blank" rel="noreferrer">
              {project.link.name ? project.link.name : project.link.link}
            </a>
          </div>
        )}
        {project?.files && project?.files.length > 0 && (
          <Attachments attachments={project.files} isLink />
        )}
      </div>
    </div>
  );
};
