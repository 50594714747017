import { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import { deleteNotification, notificationSelector, removeNotification } from '../../../store';
import { Notification } from './Notification/Notification';

import styles from './NotificationsArea.module.scss';

export const NotificationsArea = () => {
  const notifications = useAppSelector(notificationSelector);
  const dispatch = useAppDispatch();

  const handleDelete = (id: number) => {
    dispatch(deleteNotification(id));
  };

  useEffect(() => {
    const removeInterval =
      notifications.length &&
      setInterval(() => {
        dispatch(removeNotification());
      }, 5000);
    return () => clearInterval(removeInterval);
  }, [notifications]);

  return (
    <>
      <TransitionGroup component="ul" className={styles.containerFixed}>
        {notifications.map(notification => (
          <CSSTransition
            key={notification.id}
            timeout={500}
            classNames={{
              enterActive: styles.notificationEnter,
              enterDone: styles.notificationEnterActive,
              exitActive: styles.notificationExitActive,
              exitDone: styles.notificationExit,
            }}
          >
            <Notification
              type={notification.type}
              data={notification.data}
              onClick={() => handleDelete(notification.id)}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </>
  );
};
