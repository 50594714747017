export const LinkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id=".icon/link-angled_24px">
      <path
        id="Icon"
        d="M7.37705 10.1906L5.14359 12.424C4.30946 13.2582 3.82978 14.3931 3.83854 15.5859C3.84731 16.7787 4.31649 17.9206 5.19021 18.7674C6.03689 19.6411 7.17902 20.1103 8.37164 20.119C9.59146 20.128 10.6996 19.6753 11.5337 18.8412L13.7672 16.6077M16.62 13.8095L18.8535 11.576C19.6876 10.7419 20.1673 9.60693 20.1585 8.41412C20.1498 7.2213 19.6806 6.07942 18.8069 5.23268C17.9604 4.3862 16.8184 3.91699 15.6256 3.90822C14.4328 3.89946 13.2977 4.35191 12.4635 5.18607L10.2301 7.41952M8.61164 15.3272L15.312 8.62683"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
