import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CrossIcon } from '../../../../../assets/svg';

import { PropTypes } from './types';
import { HOW_TO_ADD_MODEL_PATH } from '../../../../../router';

import styles from './ModelErrorReport.module.scss';

export const ModelErrorReport = ({ reports }: PropTypes) => {
  const { t } = useTranslation();

  const DisplayError = (report: string) => {
    let text = '';

    if (report === 'invalid') {
      text = t('pages.singleModelPage.errorsText.invalid');
    }
    if (report === 'download_error') {
      text = t('pages.singleModelPage.errorsText.downloadError');
    }
    if (report === 'file_not_found') {
      text = t('pages.singleModelPage.errorsText.notFile');
    }
    if (report === 'class_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notClass');
    }
    if (report === 'method_test_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notTest');
    }
    if (report === 'method_train_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notTrain');
    }
    if (report === 'method_get_weights_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notGetWeight');
    }
    if (report === 'method_set_weights_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notSetWeight');
    }
    if (report === 'method_get_prediction_not_implemented') {
      text = t('pages.singleModelPage.errorsText.notGetPrediction');
    }
    return (
      <div className={styles.report} key={report}>
        {text}
      </div>
    );
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.status}>
        <CrossIcon />
        <p>{t('statuses.cancel')}</p>
      </div>
      <div>
        <p>{t('pages.singleModelPage.errorTitle')}</p>
        <ul className={styles.reports}>{reports.map(report => DisplayError(report))}</ul>
        <p>
          <Trans
            i18nKey="pages.singleModelPage.errorInfo"
            components={{
              a: <Link className={styles.link} to={HOW_TO_ADD_MODEL_PATH} onClick={handleClick} />,
            }}
          />
        </p>
        <p>{t('pages.singleModelPage.errorInstruction')}</p>
      </div>
    </div>
  );
};
