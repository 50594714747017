import { ChangeEvent, useState } from 'react';

export const useSearch = () => {
  const [value, setValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setValue(value);
  };

  const handleResetSearch = () => {
    setValue('');
  };

  return { value, handleChange, handleResetSearch };
};
