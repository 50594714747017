import { combineReducers, configureStore } from '@reduxjs/toolkit';

import accountReducer from './slices/accountSlice';
import roleReducer from './slices/roleSlice';
import userReducer from './slices/userSlice';
import organizationReducer from './slices/organizationSlice';
import summaryReducer from './slices/summarySlice';
import notificationReducer from './slices/notificationSlice';
import popupReducer from './slices/popupSlice';
import menuCounterReducer from './slices/menuCounterSlice';
import modelReducer from './slices/modelSlice';
import allianceReducer from './slices/allianceSlice';
import developerReducer from './slices/developerSlice';
import settingsReducer from './slices/settingsSlice';
import adminUserInfoReducer from './slices/adminUserInfoSlice';
import { rootAPI } from './rootApi';
import { errorHandling } from './errorHandling';

const rootReducer = combineReducers({
  accountReducer,
  roleReducer,
  userReducer,
  organizationReducer,
  summaryReducer,
  notificationReducer,
  popupReducer,
  menuCounterReducer,
  modelReducer,
  allianceReducer,
  developerReducer,
  settingsReducer,
  adminUserInfoReducer,
  [rootAPI.reducerPath]: rootAPI.reducer,
});

const setupStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat([rootAPI.middleware])
        .concat([errorHandling]),
  });

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatchState = AppStore['dispatch'];
