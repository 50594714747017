import { Trans, useTranslation } from 'react-i18next';

import { ConsoleCommand } from './ConsoleCommand/ConsoleCommand';

import styles from './DockerImageGuidePopup.module.scss';
import { LOCALHOST_LINK } from './constants';
// инструкция на странице модели
export const DockerImageGuidePopup = ({ name }: { name: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('pages.FLPage.dockerGuideTitle')}</h2>
      <ul className={styles.list}>
        <li>{t('popups.dockerImage.steps.0')}</li>
        <ConsoleCommand text={t('popups.dockerImage.commands.dockerRun', { name })} />
        <li>
          <Trans
            i18nKey="popups.dockerImage.steps.1"
            values={{ link: LOCALHOST_LINK }}
            components={{
              a: (
                <a className={styles.link} href={LOCALHOST_LINK} target="_blank" rel="noreferrer" />
              ),
            }}
          />
        </li>
        <li>
          {t('popups.dockerImage.steps.2.start')}
          <ConsoleCommand text={t('popups.dockerImage.commands.dockerPs')} />
          {t('popups.dockerImage.steps.2.end')}
        </li>
        <li>{t('popups.dockerImage.steps.3')}</li>
        <ConsoleCommand text={t('popups.dockerImage.commands.dockerStop')} />
      </ul>
    </div>
  );
};
