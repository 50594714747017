import { useTranslation } from 'react-i18next';

import { QuestionIcon } from './assets';

import styles from './OnboardingButton.module.scss';

export const OnboardingButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <button
      className={styles.button}
      onClick={onClick}
      type="button"
      aria-label={t('pages.profilePage.onboardingHint')}
    >
      <QuestionIcon />
      <span className={styles.description}>{t('onboarding.hint')}</span>
    </button>
  );
};
