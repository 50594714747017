import { useTranslation } from 'react-i18next';

import { useNavigation } from '../../../hooks';
import { EmptyIcon } from '../../../assets/svg';
import { Button } from '../../../ui';
import { PropTypes } from './types';

import styles from './RedirectBlock.module.scss';

export const RedirectBlock = ({ title, text, reservePath, notFound }: PropTypes) => {
  const { handleBack } = useNavigation();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <EmptyIcon />
      <div className={styles.text}>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <Button size="small" onClick={() => handleBack(reservePath)}>
        {notFound ? t('pages.notFoundPage.btn') : t('buttons.back')}
      </Button>
    </div>
  );
};
