import { ParamType } from '../../Params/types';

export type WeightType = {
  isNameTaken: boolean;
  name: string;
  description: string;
  size: number;
  params: { model: ParamType[]; test: ParamType[]; train: ParamType[] };
  date: string;
  applicationDate: string;
};

export const weightInitialState = {
  isNameTaken: false,
  name: '',
  description: '',
  size: 0,
  params: { model: [], test: [], train: [] },
  date: '',
  applicationDate: '',
};

export type UserType = {
  fullName: string;
  position: string;
  companyName: string;
  INN: string;
  industry: string;
  email: string;
};

export const userInitialState = {
  fullName: '',
  position: '',
  companyName: '',
  INN: '',
  industry: '',
  email: '',
};

export type PropTypes = {
  id: number | undefined;
  modelName: string | undefined;
  isReadOnly: boolean;
  onStateChange: ((state: string) => void) | undefined;
  onClose: () => void;
};
