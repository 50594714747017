import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { HOME_PATH, LOG_IN_PATH, RESET_PASSWORD_CONFIRM_PATH } from '../router';
import { accountSelector, setAccount, setRole } from '../store';
import { Roles } from '../store/types';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { authApi } from '../api/authApi';

export const useRedirect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { isAuth } = useAppSelector(accountSelector);
  const [loginCheck] = authApi.useLoginCheckMutation();
  const [tokenCheck] = authApi.useTokenCheckMutation();

  const checkToken = async (token: string) => {
    tokenCheck(token)
      .unwrap()
      .then(accountData => {
        dispatch(setAccount(accountData));
        dispatch(setRole(Roles.Unknown));
        navigate(HOME_PATH);
      })
      .catch(() => {
        dispatch(setAccount({ isAuth: false }));
        navigate(LOG_IN_PATH);
      });
  };

  const checkAuth = async () => {
    loginCheck()
      .unwrap()
      .then(accountData => {
        dispatch(setAccount(accountData));
      })
      .catch(() => {
        dispatch(setAccount({ isAuth: false }));
      });
  };

  useEffect(() => {
    if (pathname === `/${LOG_IN_PATH}/${RESET_PASSWORD_CONFIRM_PATH}/`) {
      return;
    }
    if (token) {
      localStorage.setItem('token', token);
      checkToken(token);
      return;
    }
    checkAuth();
  }, []);

  useEffect(() => {
    if (isAuth && pathname.includes(LOG_IN_PATH)) {
      navigate(HOME_PATH);
      return;
    }
    if (isAuth === false && !pathname.includes(LOG_IN_PATH)) {
      navigate(LOG_IN_PATH);
      return;
    }
    if (isAuth === false && pathname.includes(LOG_IN_PATH)) {
      return;
    }
    if (isAuth && !pathname.includes(LOG_IN_PATH)) {
      return;
    }
  }, [isAuth, pathname]);
};
