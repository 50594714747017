import { OwnerData } from '../AlliancePage/types';

export interface IModel {
  id: number;
  data: ModelDataType;
  state: string;
  role: string;
  alliance: { name: string; id: number; isHidden: boolean } | null;
  owner: OwnerData | null;
}

export type ModelDataType = {
  name: string;
  type: string;
  category: string;
  task: string;
  description: string;
  tags: string[];
  attachments: { name: string; id: number; type: string; size: number; uuid: string }[];
  modelParams: Record<string, string>;
  datasetParams: Record<string, string>;
  testParams: Record<string, string>;
  trainParams: Record<string, string>;
  link: { name: string | null; link: string };
  create: string;
  update: string;
  isFavorite: boolean;
  isPrivate: boolean;
  isJointMl: boolean;
  isPaid: boolean;
  hasFl: boolean;
  reports: ModelErrorsTypes[];
  hasWeights?: boolean;
  imageId?: number | null;
};

export type ModelErrorsTypes =
  | string
  | 'invalid'
  | 'download_error'
  | 'file_not_found'
  | 'class_not_implemented'
  | 'method_test_not_implemented'
  | 'method_train_not_implemented'
  | 'method_get_weights_not_implemented'
  | 'method_set_weights_not_implemented'
  | 'method_get_prediction_not_implemented';

export type ModelDataResponse = {
  name: string;
  model_type: string;
  task: string;
  category: string;
  tags: TagsDataResponse[];
  file_size: number;
  description: string;
  ml_model_attachments: {
    uuid_name: string;
    file_name: string;
    size: number;
    data_type: string;
    id: number;
  }[];
  is_member: string;
  is_favorite: boolean;
  alliance: {
    name: string;
    id: number;
  } | null;
  created_at: string;
  updated_at: string;
};

export type TagsDataResponse = {
  id: number;
  updated_at: string;
  created_at: string;
  name: string;
};

export type AttachmentsType = {
  name: string;
  id: number;
  type: string;
  size: number;
  uuid: string;
};

export interface ModelStatusListProps extends IModel {
  isControlled: boolean;
  isInPopup?: boolean;
}

export interface ModelContentProps extends IModel {
  isControlled: boolean;
  isInPopup?: boolean;
}

export enum RoleInModel {
  Owner = 'is_owner',
  Member = 'is_member',
  Waiting = 'waiting_to_approve',
  Banned = 'banned',
  Outside = 'out_of_alliance',
  RequestConsideration = 'request_consideration',
}
