import { useTranslation } from 'react-i18next';

import { ConfirmIcon } from '../../../../assets/svg';
import { Button } from '../../../../ui';
import { PropTypes } from './types';

import styles from './SuccessPopUp.module.scss';

export const SuccessPopUp = ({ onClose, title, info }: PropTypes) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <ConfirmIcon />
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.info}>{info}</p>
      <Button onClick={onClose}>{t('buttons.continue')}</Button>
    </div>
  );
};
