import { MetricChartItem } from '../../../../../types';

import styles from './MetricItem.module.scss';

export const MetricItem = ({ metric }: { metric: MetricChartItem }) => {
  const { name, value } = metric;
  return (
    <div className={styles.container}>
      <article className={styles.metric}>
        <h3>{name}</h3>
        <span>{value}</span>
      </article>
    </div>
  );
};
