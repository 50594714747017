import { AllianceDataResponse } from '../../api/alliances/types';
import { AppealAttachments } from '../../api/appeals/types';
import { ModelByIdResponse } from '../../api/models/types';
import { allianceAttachmentKey, modelAttachmentKey } from '../constants';

export const mapAttachments = (
  payload:
    | ModelByIdResponse[typeof modelAttachmentKey]
    | AllianceDataResponse[typeof allianceAttachmentKey],
) =>
  payload.map(attachment => ({
    name: attachment.file_data.file_name,
    uuid: attachment.file_data.uuid_name,
    size: attachment.file_data.size,
    id: attachment.attachment_id,
    type: attachment.file_data.data_type,
    url: attachment.file_data.url,
  }));

export const mapAppealsAttachments = (attachments: AppealAttachments[]) =>
  attachments.map(attachment => ({
    name: attachment.file_name,
    uuid: attachment.file,
    size: attachment.size,
    id: attachment.id,
    type: attachment.data_type,
  }));
