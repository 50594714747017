import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { getPopularTags } from '../../../../api';
import { useClickOutside, useSearch } from '../../../../hooks';
import { isArraysEqual } from '../../../../utils';
import { EmptyIcon, SearchIcon } from '../../../../assets/svg';
import { FilterButton, TextButton, Button } from '../../../common';
import { Tag } from '../../Tags/Tag/Tag';
import { ITag, PropTypes } from './types';

import styles from './TagsFilter.module.scss';

export const TagsFilter = ({
  name,
  id,
  appliedFilters,
  onActionFilters,
  resetTrigger,
  to = 'summary',
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>(appliedFilters);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { value, handleChange, handleResetSearch } = useSearch();
  const { t } = useTranslation();

  useClickOutside([name, 'tag'], () => setIsOpen(false));

  const fetchTags = async (value: string | undefined = undefined) => {
    const { payload } = await getPopularTags(value, to);
    setTags(payload);
  };

  const handleSelect = (tag: ITag) => {
    if (selectedTags.some(selected => selected.id === tag.id)) {
      setSelectedTags(selectedTags.filter(selected => selected.id !== tag.id));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleApply = () => {
    handleResetSearch();
    onActionFilters(id, selectedTags);
    setIsOpen(false);
  };

  const handleReset = () => {
    handleResetSearch();
    setSelectedTags([]);
    onActionFilters(id, []);
    setIsOpen(false);
  };

  useEffect(() => {
    if (value.length > 1) {
      fetchTags(value);
    } else fetchTags();
  }, [value]);

  useEffect(() => {
    if (isArraysEqual(appliedFilters, selectedTags)) {
      setIsShowButton(false);
    } else setIsShowButton(true);
  }, [appliedFilters, selectedTags]);

  useEffect(() => {
    setSelectedTags([]);
  }, [resetTrigger]);

  return (
    <div className={styles.container}>
      <FilterButton
        name={name}
        count={appliedFilters.length}
        isActive={isOpen}
        setIsActive={() => setIsOpen(!isOpen)}
      />
      <div className={cn(styles.wrapper, isOpen && styles.wrapperActive)} id={name}>
        <div className={styles.search} id={name}>
          <span className={styles.icon} id={name}>
            <SearchIcon id={name} />
          </span>
          <input
            className={styles.input}
            value={value}
            onChange={handleChange}
            placeholder={t('filters.placeholder')}
            id={name}
          />
        </div>
        {tags.length > 0 ? (
          <ul className={styles.skills} id={name}>
            {tags.slice(0, 30).map(tag => (
              <Tag
                id="tag"
                tag={tag}
                key={tag.id}
                isSelected={selectedTags.some(selected => selected.id === tag.id)}
                onSelect={handleSelect}
              />
            ))}
          </ul>
        ) : (
          <div className={styles.empty} id={name}>
            <EmptyIcon id={name} />
            <p id={name}>{t('filters.empty')}</p>
          </div>
        )}
        {isShowButton && (
          <div className={styles.buttons}>
            <TextButton size="small" id={name} onClick={handleReset}>
              {t('buttons.reset')}
            </TextButton>
            <Button size="small" id={name} onClick={handleApply}>
              {t('buttons.apply')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
