export const text = {
  blocked: {
    one: ' заблокирован(a)',
    some: ' заблокированы',
  },
  unblocked: {
    one: ' разблокирован',
    some: ' разблокированы',
  },
  approve: {
    one: ' верифицирован(a)',
    some: ' верифицированы',
  },
  declined: {
    one: ' отклонен(a)',
  },
  invitationInAlliance: {
    prev: 'Приглашение отправлено на почту ',
  },
  allianceApprove: {
    next: ' принят в альянс',
  },
  allianceDecline: {
    next: ' отклонён на вступление в альянс',
  },
  modelDeletedFromAlliance: {
    prev: 'ML-модель ',
    next: ' удалена из альянса',
  },
  userLeftAlliance: {
    prev: 'Вы больше не являетесь членом альянса ',
  },
  userJoinedAlliance: {
    prev: 'Вы вступили в альянс ',
  },
  modelDeletedFromAccount: {
    prev: 'ML-модель ',
    middle: ' и её версии весов',
    next: ' удалены из личного кабинета',
  },
  weightDeletedFromModel: {
    prev: 'Версия весов ',
    next: ' удалена',
  },
  weightDeletedFromAlliance: {
    prev: 'Версия весов ',
    next: ' удалена из альянса',
  },
  modelAndWeightAddedToAlliance: {
    prev: 'ML-модель ',
    middle: ' и версия весов ',
    next: ' добавлены в альянс ',
  },
  modelAddedToAlliance: {
    prev: 'ML-модель ',
    next: ' добавлена в альянс ',
  },
  weightAddedToModel: {
    prev: 'Версия весов ',
    next: ' добавлена к ML-модели ',
  },
  weightAddedToModelInAlliance: {
    prev: 'Версия весов ',
    middle: ' добавлена к ML-модели ',
    next: ' в альянс',
  },
  addModelToAllianceRequest: {
    prev: 'ML-модель ',
    next: ' отправлена на рассмотрение владельцу альянса ',
  },
  addModelAndWeightToAllianceRequest: {
    prev: 'ML-модель ',
    middle: ' и её версия весов ',
    next: ' отправлены на рассмотрение владельцу альянса ',
  },
  addWeightToModelRequest: {
    prev: 'Веса ',
    next: ' отправлены на рассмотрение владельцу альянса',
  },
  joinAllianceRequest: {
    prev: 'Запрос на вступление в альянс ',
    next: ' отправлен',
  },
  allianceActivate: {
    prev: 'Альянс ',
    next: ' активирован',
  },
  allianceDeactivate: {
    prev: 'Альянс ',
    next: ' деактивирован',
  },
  allianceDelete: {
    prev: 'Альянс ',
    next: ' удалён',
  },
  modelAddedToUserAccount: {
    prev: 'ML-модель ',
    next: ' добавлена в ваш личный кабинет во вкадку “Добавленные”',
  },
  modelAndWeightAddedToUserAccount: {
    prev: 'ML-модель ',
    middle: ' и версия весов ',
    next: ' добавлены в ваш личный кабинет во вкадку “Добавленные”',
  },
  memberModelToAlliance: {
    accept: 'Вы приняли запрос на добавление ML-модели ',
    decline: 'Вы отклонили запрос на добавление ML-модели ',
    next: ' в альянс',
  },
  memberModelAndWeightToAlliance: {
    accept: 'Вы приняли запрос на добавление ML-модели ',
    decline: 'Вы отклонили запрос на добавление ML-модели ',
    middle: ' и версии весов ',
    next: ' в альянс',
  },
  memberWeightToAllianceModel: {
    accept: 'Вы приняли запрос на добавление версии весов ',
    decline: 'Вы отклонили запрос на добавление версии весов ',
    middle: ' к  ML-модели ',
    next: ' в альянс',
  },
  profileDataSave: {
    prev: 'Изменения сохранены',
  },
  addProjectToPortfolio: {
    prev: 'Проект ',
    next: ' добавлен в портфолио',
  },
  deleteProject: {
    prev: 'Проект ',
    next: ' удален',
  },
  roundHasStarted: {
    middle: ' обучения модели ',
    next: ' запущен',
  },
  roundHasEnded: {
    prev: 'Агрегация данных завершена. Версия весов ',
    next: ' добавлена в историю раунда ',
  },
  addSubscription: 'Подписка успешно оформлена',
  addAnotherSubscription:
    'Оформленная подписка JointML начнет действовать с момента окончания текущей подписки JointML',
  userExcludedFromAlliance: ' исключён из альянса ',
  userIncludedToAlliance: ' становится участником альянса ',
};
