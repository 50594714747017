import { useState } from 'react';
import cn from 'classnames';

import { ChangeIcon, LinkIcon, PlusIcon } from '../../../assets/svg';
import { EditBtn, DeleteBtn } from '../../common';
import { EditPanel } from './EditPanel/EditPanel';
import { text } from './constants';
import { ILink, PropTypes } from './types';

import styles from './LinkController.module.scss';

export const LinkController = ({ link, setLink }: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDelete = () => {
    setLink({ name: '', link: '' });
  };

  const handleUpdate = (link: ILink) => {
    setLink(link);
    setIsOpen(false);
  };

  const PanelDisplay = () => {
    if (link?.link && link.link.length > 0 && isOpen) {
      return (
        <div className={styles.editTitle}>
          <ChangeIcon />
          <h4>{text.editTitle}</h4>
        </div>
      );
    }
    if (link?.link && link.link.length > 0) {
      return (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <LinkIcon />
            <a href={link.link} className={styles.link} target="_blank" rel="noreferrer">
              {link?.name?.length === 0 ? link.link : link.name}
            </a>
          </div>
          <div className={styles.buttons}>
            <EditBtn onClick={() => setIsOpen(true)} description={text.editDescription} />
            <DeleteBtn onClick={handleDelete} description={text.deleteDescription} />
          </div>
        </div>
      );
    }
    return (
      <button
        className={cn(styles.add, isOpen && styles.addActive)}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        <PlusIcon />
        {text.addButton}
      </button>
    );
  };

  return (
    <>
      {PanelDisplay()}
      {isOpen && (
        <EditPanel
          link={link}
          onSave={handleUpdate}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};
