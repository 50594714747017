import { lazy } from 'react';
import { WALLET_PATH, WALLET_TABS } from '../routeConstants';
import { ContentProtection } from '../HOC/RoutesProtection';
import { suspense } from '../HOC/suspense';

const { RECEIPTS, WITHDRAW } = WALLET_TABS;

const WalletPage = lazy(() => import('../../pages/WalletPage/WalletPage'));
const ReceiptsHistory = lazy(
  () => import('../../pages/WalletPage/ReceiptsHistory/ReceiptsHistory'),
);
const WithdrawHistory = lazy(
  () => import('../../pages/WalletPage/WithdrawHistory/WithdrawHistory'),
);

export const walletRoutes = {
  path: WALLET_PATH,
  element: <ContentProtection />,
  children: [
    {
      path: '',
      element: <WalletPage />,
      children: [
        {
          index: true,
          element: suspense(<ReceiptsHistory />),
        },
        {
          path: RECEIPTS,
          element: suspense(<ReceiptsHistory />),
        },
        {
          path: WITHDRAW,
          element: suspense(<WithdrawHistory />),
        },
      ],
    },
  ],
};
