import { ReactNode } from 'react';
import { Swiper } from 'swiper/react';
import { Navigation, Keyboard, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

type SwipedListProps = {
  children: ReactNode[];
  wrapperClass: string;
  parentClass: string;
};

export const SwipedList = ({ children, parentClass, wrapperClass }: SwipedListProps) => (
  <Swiper
    navigation={{
      nextEl: `.buttonNext`,
      prevEl: `.buttonPrev`,
    }}
    grabCursor
    mousewheel
    keyboard
    breakpoints={{
      320: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    }}
    autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
    modules={[Navigation, Keyboard, Autoplay]}
    className={parentClass}
    wrapperClass={wrapperClass}
  >
    {children}
  </Swiper>
);
