import cn from 'classnames';
import { ChangeHandler } from 'react-hook-form';

import styles from './Input.module.scss';

type PropsType = {
  id: string;
  label: string;
  placeholder?: string;
  register: (name: any) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
  helperText?: any;
  isDisabled?: boolean;
  isRequired?: boolean;
  maxLength: number;
};

export const Input = ({
  isDisabled = false,
  isRequired = false,
  id,
  label,
  placeholder,
  register,
  helperText,
  maxLength,
}: PropsType) => {
  const wrapperClassName = cn(styles.wrapper, {
    [styles.wrapperDisable]: isDisabled,
  });
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
    [styles.inputDisable]: isDisabled,
  });
  const labelClassName = cn(styles.label, {
    [styles.labelHidden]: placeholder,
    [styles.labelRequired]: isRequired,
  });
  return (
    <div className={wrapperClassName}>
      <div className={styles.container}>
        <input
          className={inputClassName}
          id={id}
          {...register?.(id)}
          required
          maxLength={maxLength}
          disabled={isDisabled}
          placeholder={placeholder || ' '}
        />
        <label className={labelClassName} htmlFor={id}>
          {label}
        </label>
      </div>
      <div className={styles.error}>{helperText}</div>
    </div>
  );
};
