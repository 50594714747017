export const InnIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <path
      d="M21.8683 5.14551H2.14395C0.943338 5.14551 0 6.09029 0 7.29275V16.7406C0 17.9431 0.943338 18.8878 2.14395 18.8878H21.8683C23.0689 18.8878 24.0123 17.9431 24.0123 16.7406V7.29275C24.0123 6.09029 23.0689 5.14551 21.8683 5.14551ZM23.1547 16.7406C23.1547 17.4707 22.5972 18.0289 21.8683 18.0289H2.14395C1.41501 18.0289 0.85758 17.4707 0.85758 16.7406V7.29275C0.85758 6.56269 1.41501 6.0044 2.14395 6.0044H21.8683C22.5972 6.0044 23.1547 6.56269 23.1547 7.29275V16.7406Z"
      fill="currentColor"
    />
    <path
      d="M7.71817 11.5872C8.91878 11.5872 9.86212 10.6424 9.86212 9.43996C9.86212 8.23751 8.91878 7.29272 7.71817 7.29272C6.51756 7.29272 5.57422 8.23751 5.57422 9.43996C5.57422 10.6424 6.51756 11.5872 7.71817 11.5872ZM7.71817 8.15162C8.44711 8.15162 9.00454 8.7099 9.00454 9.43996C9.00454 10.17 8.44711 10.7283 7.71817 10.7283C6.98923 10.7283 6.4318 10.17 6.4318 9.43996C6.4318 8.7099 6.98923 8.15162 7.71817 8.15162Z"
      fill="currentColor"
    />
    <path
      d="M7.71759 12.446C5.35924 12.446 3.42969 14.1638 3.42969 16.3111C3.42969 16.5687 3.6012 16.7405 3.85848 16.7405C4.11575 16.7405 4.28727 16.5687 4.28727 16.3111C4.28727 14.6362 5.83091 13.3049 7.71759 13.3049C9.60427 13.3049 11.1479 14.6362 11.1479 16.3111C11.1479 16.5687 11.3194 16.7405 11.5767 16.7405C11.834 16.7405 12.0055 16.5687 12.0055 16.3111C12.0055 14.1638 10.0759 12.446 7.71759 12.446Z"
      fill="currentColor"
    />
    <path
      d="M20.1526 10.2988H14.1495C13.8922 10.2988 13.7207 10.4706 13.7207 10.7283C13.7207 10.9859 13.8922 11.1577 14.1495 11.1577H20.1526C20.4098 11.1577 20.5813 10.9859 20.5813 10.7283C20.5813 10.4706 20.4098 10.2988 20.1526 10.2988Z"
      fill="currentColor"
    />
    <path
      d="M20.1526 12.8755H14.1495C13.8922 12.8755 13.7207 13.0473 13.7207 13.3049C13.7207 13.5626 13.8922 13.7344 14.1495 13.7344H20.1526C20.4098 13.7344 20.5813 13.5626 20.5813 13.3049C20.5813 13.0473 20.4098 12.8755 20.1526 12.8755Z"
      fill="currentColor"
    />
  </svg>
);
