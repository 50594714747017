import { ModelByIdResponse } from '../../api/models/types';
import { mapAttachments } from '../../mappers';
import { makeFullName } from '../../utils/formatData';
import { IModel } from './types';

export const modelPageAdapter = (response: ModelByIdResponse, id: number): IModel => {
  return {
    id,
    data: {
      name: response.name,
      type: response.model_type,
      category: response.category,
      task: response.task,
      description: response.description,
      tags: response.tags.map(tag => tag.name),
      attachments: mapAttachments(response.ml_model_attachments),
      modelParams: response.model_params,
      datasetParams: response.dataset_params,
      testParams: response.test_params,
      trainParams: response.train_params,
      link: { name: response.link_name, link: response.link },
      create: response.created_at,
      update: response.updated_at,
      isFavorite: response.is_favorite,
      isPrivate: response.is_private,
      isPaid: response.is_required_payment,
      isJointMl: response.provided_by_platform,
      hasFl: response.has_fl ?? false,
      reports: response.error_report ?? [],
      hasWeights: response.has_weights,
      imageId: response.current_user_usage,
    },
    state: response.state,
    role: response.is_member,
    alliance: response.alliance
      ? {
          name: response.alliance.alliance,
          id: response.alliance.alliance_id,
          isHidden: response.alliance.is_hidden,
        }
      : null,
    owner: response.owner
      ? {
          id: response.owner.id,
          fullName: makeFullName({
            first_name: response.owner.first_name,
            last_name: response.owner.last_name,
            middle_name: response.owner.middle_name,
          }),
          email: response.owner.email,
          companyName: response.owner.company?.name ?? '',
          industry: response.owner.company?.industry ?? '',
          INN: response.owner.company?.inn ?? '',
          position: response.owner.position ?? '',
        }
      : null,
  };
};
