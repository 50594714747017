import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { HELP_PATH } from '../routeConstants';
import { ContentProtection } from '../HOC/RoutesProtection';

const HelpPage = lazy(() => import('../../pages/GuidePage/HelpPage/HelpPage'));

export const helpRoutes = {
  path: HELP_PATH,
  element: <ContentProtection />,
  children: [
    {
      index: true,
      element: suspense(<HelpPage />),
    },
  ],
};
