import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { allianceApi } from '../../../../api/allianceApi';
import { adminAllianceApi } from '../../../../api/adminAllianceApi';
import { useAppDispatch } from '../../../../hooks';
import { emailSchema } from '../../../../schemas/validationSchemas';
import { isError } from '../../../../utils/formatData';
import { NotificationTypes } from '../../../../store/types';
import { setNotification } from '../../../../store';
import { Button, Input } from '../../../../ui';
import { text } from './constants';
import { PropTypes } from './types';

import styles from './InvitationPopUp.module.scss';

export const InvitationPopUp = ({ data, onClose }: PropTypes) => {
  const dispatch = useAppDispatch();
  const { labels, title, btnText } = text;
  const {
    handleSubmit,
    setError,
    formState: { isValid, errors },
    register,
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: 'onTouched',
  });
  const [inviteToAlliance] = data.isAdmin
    ? adminAllianceApi.usePostInviteToAllianceAsAdminMutation()
    : allianceApi.usePostInviteToAllianceMutation();

  const onSubmit: SubmitHandler<FieldValues> = ({ email, comment }) => {
    inviteToAlliance({ id: data.id, data: { user: email, comment } })
      .unwrap()
      .then(() => {
        dispatch(
          setNotification({
            type: NotificationTypes.InvitationInAlliance,
            data: {
              name: email,
            },
          }),
        );
        onClose();
      })
      .catch(err => {
        if (err.status === 400 && String(err.data)) {
          setError('email', { type: 'custom', message: err.data.toString() });
        }
      });
  };

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.inputWrapper}>
        <Input
          id="email"
          label={labels.email}
          register={register}
          maxLength={200}
          isDisabled={false}
          helperText={errors.email?.message}
        />
        <Input
          id="comment"
          label={labels.comment}
          register={register}
          maxLength={200}
          isDisabled={false}
          helperText={errors.comment?.message}
        />
      </div>
      <Button isDisabled={isError(errors) || !isValid} type="submit">
        {btnText}
      </Button>
    </form>
  );
};
