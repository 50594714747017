import { mapFeedbackById, mapFeedbacks } from '../mappers/feedback/feedback';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString } from '../utils';
import {
  AppealStateRequest,
  FeedbackByIdResponse,
  FeedbackByIdResponseTransformed,
  FeedbackRateRequest,
  FeedbackRateResponse,
  FeedbackRequest,
  FeedbacksAmountResponse,
  FeedbacksResponse,
  FeedbacksResponseTransformed,
} from './feedbacks/types';
import { QueriesType } from './types';

export const feedbacksApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    postFeedback: build.mutation<null, FeedbackRequest>({
      query({ files, text }) {
        const filesObj: Record<string, File> = {};
        for (let i = 0; i < files.length; i++) {
          filesObj[`file_${i + 1}`] = files[i];
        }
        const feedbackData: Record<string, string | File> = {
          text,
          ...filesObj,
        };
        const formData = new FormData();
        Object.keys(feedbackData).forEach((key: string) => {
          formData.append(key, feedbackData[key]);
        });
        return {
          url: '/feedbacks/',
          method: 'POST',
          body: formData,
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['FeedbacksList'],
    }),
    getFeedbackById: build.query<FeedbackByIdResponseTransformed, number>({
      query(id) {
        return {
          url: `feedbacks/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: FeedbackByIdResponse) => {
        return mapFeedbackById(response);
      },
      providesTags: ['Feedback'],
    }),
    patchStateByUser: build.mutation<null, AppealStateRequest>({
      query({ id, state }) {
        return {
          url: `/feedbacks/${id}/approve/`,
          method: 'PATCH',
          body: { state },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Feedback'],
    }),
    postFeedbackRate: build.mutation<null, FeedbackRateRequest>({
      query(data) {
        return {
          url: '/feedbacks/rate/',
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Feedback'],
    }),
    getFeedbackRate: build.query<FeedbackRateResponse, number>({
      query(id) {
        return {
          url: `/feedbacks/rate/${id}/`,
          credentials: 'include',
        };
      },
    }),
    getFeedbacksAmount: build.query<FeedbacksAmountResponse, void>({
      query() {
        return {
          url: `/feedbacks/common-info/`,
          credentials: 'include',
        };
      },
    }),
    getFeedbacksList: build.query<FeedbacksResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/feedbacks/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: FeedbacksResponse) => {
        return {
          nextPageLink: response.links.next,
          results: mapFeedbacks(response.results),
          count: response.count,
        };
      },
      providesTags: ['FeedbacksList'],
    }),
  }),
});
