import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { modelsApi } from '../../../../api/ModelsApi';
import { useAppDispatch, useScrollShadow } from '../../../../hooks';
import { setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { EmptyIcon } from '../../../../assets/svg';
import { Button, InfoBlock, Spinner } from '../../../../ui';
import { ModelWithWeightsList } from '../../ModelWithWeightsList/ModelWithWeightsList';
import { useScrollData } from './useScrollData';
import { CheckedType, PropTypes } from './types';
import { ModelPopupType } from '../../../../types';

import styles from './AddAnyModelToSpecificAlliancePopUp.module.scss';

export const AddAnyModelToSpecificAlliancePopUp = ({
  isOwner,
  allianceName,
  allianceId,
  onClose,
}: PropTypes) => {
  const [checkedModel, setCheckedModel] = useState<CheckedType>({ name: '', id: null });
  const [checkedWeight, setCheckedWeight] = useState<CheckedType>({ name: '', id: null });
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [addModelCopyToAllianceOwner] = modelsApi.usePostAddModelCopyToAllianceAsOwnerMutation();
  const [addModelCopyToAllianceUser] = modelsApi.usePostAddModelCopyToAllianceAsUserMutation();
  const { borderPosition, onScroll } = useScrollShadow();
  const { data, isLoading } = useScrollData(borderPosition, allianceId ?? 0);

  const listContainerClassName = cn(styles.listContainer, {
    [styles.listContainerTopBorder]: borderPosition === 'top' || borderPosition === 'both',
    [styles.listContainerBottomBorder]: borderPosition === 'bottom' || borderPosition === 'both',
  });

  const handleModelClick = (id: number, name: string | undefined) => {
    if (id === checkedModel.id) {
      setCheckedModel({ name: '', id: null });
    } else {
      setCheckedModel({ ...checkedModel, id, name });
    }
  };

  const handleWeightClick = (id: number | null, name: string | undefined) => {
    setCheckedWeight({ ...checkedWeight, id, name });
  };

  const handleSubmit = () => {
    const modelData = {
      allianceId,
      checkedModelId: checkedModel.id,
      checkedWeightId: checkedWeight.id,
      isOwner,
    };
    const request = isOwner ? addModelCopyToAllianceOwner : addModelCopyToAllianceUser;

    request(modelData)
      .unwrap()
      .then(res => {
        onClose();
        if (isOwner && checkedWeight.id) {
          dispatch(
            setNotification({
              type: NotificationTypes.ModelAndWeightAddedToAlliance,
              data: {
                modelName: res.new_name,
                weightName: checkedWeight.name,
                allianceName,
              },
            }),
          );
        }
        if (isOwner && !checkedWeight.id) {
          dispatch(
            setNotification({
              type: NotificationTypes.ModelAddedToAlliance,
              data: {
                modelName: res.new_name,
                allianceName: checkedWeight.name,
              },
            }),
          );
        }
        if (!isOwner && checkedWeight.id) {
          dispatch(
            setNotification({
              type: NotificationTypes.AddModelAndWeightToAllianceRequest,
              data: {
                modelName: res.new_name,
                weightName: checkedWeight.name,
                allianceName,
              },
            }),
          );
        }
        if (!isOwner && !checkedWeight.id) {
          dispatch(
            setNotification({
              type: NotificationTypes.AddModelToAllianceRequest,
              data: {
                modelName: res.new_name,
                allianceName,
              },
            }),
          );
        }
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titlesWrapper}>
        <h2 className={styles.title}>
          {t('popups.addAnyModelToSpecificAlliance.title', { allianceName })}
        </h2>
        <h3 className={styles.subtitle}>{t('popups.addAnyModelToSpecificAlliance.subTitle')}</h3>
      </div>
      {!isOwner && (
        <div>
          <InfoBlock text={t('popups.addAnyModelToSpecificAlliance.warnInfo')} />
        </div>
      )}
      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={listContainerClassName}>
          <ul className={styles.modelsList} onScroll={onScroll}>
            {data.length === 0 && (
              <div className={styles.search}>
                <EmptyIcon />
                <p className={styles.text}>
                  {t(`popups.addAnyModelToSpecificAlliance.userDoNotHaveAnyModel`)}
                </p>
              </div>
            )}
            {data.map((model: ModelPopupType) => (
              <ModelWithWeightsList
                model={model}
                isChecked={Boolean(model.id === checkedModel.id)}
                setCheckedModel={(id, name) => handleModelClick(id, name)}
                setCheckedWeight={(id, name) => handleWeightClick(id, name)}
                key={model.id}
                isOwner={isOwner}
                isDisabled={model.isDisabled}
                defaultWeights={model.weights}
              />
            ))}
          </ul>
        </div>
      )}
      <div className={styles.buttonsWrapper}>
        <Button style="secondary" size="small" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button style="primary" size="small" isDisabled={!checkedModel.id} onClick={handleSubmit}>
          {isOwner ? t('buttons.add') : t('buttons.sendRequest')}
        </Button>
      </div>
    </div>
  );
};
