import { useTranslation } from 'react-i18next';

import { modelsApi } from '../../../../../api/ModelsApi';
import { Button, Loader, ModelWithWeightsList } from '../../../../../ui';
import { PropTypes } from './types';

import styles from './AddWeight.module.scss';

export const AddWeight = ({ id, setModelName, onClose, onCheck, onNextStep }: PropTypes) => {
  const { t } = useTranslation();
  const getModelData = modelsApi.useGetAvailableModelDataQuery;

  const { data, isLoading } = getModelData({ id });

  if (isLoading || !data) {
    return <Loader />;
  }

  const onContinue = () => {
    setModelName(data.name);
    onNextStep();
  };

  return (
    <form className={styles.form}>
      <div className={styles.model}>
        <ModelWithWeightsList
          model={data}
          defaultWeights={data.weights}
          isChecked
          isSingle
          setCheckedWeight={(id: number | null, name: string | undefined) => onCheck(id, name)}
        />
      </div>
      <div className={styles.buttons}>
        <Button style="secondary" size="small" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button size="small" onClick={onContinue}>
          {t('popups.addSpecificModelToAnyAlliance.firstStep.button')}
        </Button>
      </div>
    </form>
  );
};
