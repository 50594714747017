import { SyntheticEvent } from 'react';
import cn from 'classnames';

import styles from './TextButton.module.scss';

type PropsType = {
  children: string;
  onClick?: () => void;
  id?: string;
  isDisabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: 'primary' | 'canceled';
  size?: 'normal' | 'small';
  iconProperty?: { icon: JSX.Element; position: 'left' | 'right' };
  propClassName?: string;
  description?: string;
};

export const TextButton = ({
  children,
  isDisabled,
  onClick,
  id,
  iconProperty,
  type = 'button',
  style = 'primary',
  size = 'normal',
  propClassName,
  description,
}: PropsType) => {
  const buttonClassName = cn(
    styles.button,
    {
      [styles.normal]: size === 'normal',
      [styles.small]: size === 'small',
      [styles.primary]: style === 'primary',
      [styles.canceled]: style === 'canceled',
    },
    propClassName && propClassName,
  );

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const IconDisplay = () => {
    switch (iconProperty?.position) {
      case 'right':
        return (
          <>
            {children}
            {iconProperty.icon}
          </>
        );
      case 'left':
        return (
          <>
            {iconProperty.icon}
            {children}
          </>
        );
      default:
        return <>{children}</>;
    }
  };

  return (
    <button
      className={buttonClassName}
      disabled={isDisabled}
      onClick={handleClick}
      type={type}
      id={id}
      data-descr={description}
    >
      {IconDisplay()}
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
