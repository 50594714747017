export const StopIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M0.9 8C0.9 11.913 4.08699 15.1 8 15.1C11.913 15.1 15.1 11.913 15.1 8C15.1 4.08699 11.913 0.9 8 0.9C4.08699 0.9 0.9 4.08699 0.9 8ZM1.97111 8C1.97111 4.67967 4.67967 1.97111 8 1.97111C11.3203 1.97111 14.0289 4.67967 14.0289 8C14.0289 11.3203 11.3203 14.0289 8 14.0289C4.67967 14.0289 1.97111 11.3203 1.97111 8ZM9.92 6.49111C9.92 6.26477 9.73523 6.08 9.50889 6.08H6.49111C6.26477 6.08 6.08 6.26477 6.08 6.49111V9.50889C6.08 9.73523 6.26477 9.92 6.49111 9.92H9.50889C9.73523 9.92 9.92 9.73523 9.92 9.50889V6.49111Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);
