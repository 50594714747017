import { lazy } from 'react';

import {
  ADD_ORGANIZATION_PATH,
  ADD_SUMMARY_PATH,
  EDIT_PROFILE_PATH,
  EDIT_SUMMARY_PATH,
} from '../routeConstants';
import { alliancesRoutes } from './alliancesRoutes';
import { baRoutes } from './baRoutes';
import { directoryRoutes } from './directoryRoutes';
import { modelsRoutes } from './modelsRoutes';
import { sandboxRoutes } from './sandboxRoutes';
import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import { favoritesRoutes } from './favoritesRoutes';
import { aboutRoutes } from './aboutRoutes';
import { helpRoutes } from './helpRoutes';
import { walletRoutes } from './walletRoutes';
import { supportRoutes } from './supportRoutes';
import { settingsRoutes } from './settingsRoutes';

const NewOrganizationPage = lazy(
  () => import('../../pages/NewOrganizationPage/NewOrganizationPage'),
);
const EditProfilePage = lazy(() => import('../../pages/EditProfilePage/EditProfilePage'));
const NewSummaryPage = lazy(() => import('../../pages/NewSummaryPage/NewSummaryPage'));
const EditSummaryPage = lazy(() => import('../../pages/EditSummaryPage/EditSummaryPage'));

export const userRoutes = [
  alliancesRoutes,
  modelsRoutes,
  // sandboxRoutes,
  // directoryRoutes,
  helpRoutes,
  aboutRoutes,
  favoritesRoutes,
  walletRoutes,
  supportRoutes,
  settingsRoutes,
  ...baRoutes,
  {
    path: ADD_ORGANIZATION_PATH,
    element: <ContentProtection />,
    children: [
      {
        path: '',
        element: suspense(<NewOrganizationPage />),
      },
    ],
  },
  {
    path: EDIT_PROFILE_PATH,
    element: <ContentProtection />,
    children: [
      {
        path: '',
        element: suspense(<EditProfilePage />),
      },
    ],
  },
  // {
  //   path: ADD_SUMMARY_PATH,
  //   element: <ContentProtection />,
  //   children: [
  //     {
  //       path: '',
  //       element: suspense(<NewSummaryPage />),
  //     },
  //   ],
  // },
  // {
  //   path: EDIT_SUMMARY_PATH,
  //   element: <ContentProtection />,
  //   children: [
  //     {
  //       path: '',
  //       element: suspense(<EditSummaryPage />),
  //     },
  //   ],
  // },
];
