export const TickIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
    <path
      d="M14.8569 1.85742L4.62898 12.1431L1.14258 8.63702"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
