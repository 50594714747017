export const DragIcon = () => (
  <svg width="52" height="55" viewBox="0 0 52 55" fill="none">
    <path
      d="M50.5 18.1088L50.5 7.3C50.5 5.6619 49.8547 4.09089 48.706 2.93258C47.5574 1.77427 45.9995 1.12353 44.375 1.12353L7.62501 1.12353C6.00056 1.12353 4.44264 1.77426 3.29398 2.93257C2.14532 4.09089 1.50001 5.6619 1.50001 7.3L1.50001 18.1088M25.9966 53.8765L25.9966 18.8765M25.9966 18.8765L11.9966 32.2498M25.9966 18.8765L39.9966 32.2498"
      stroke="#2D89EF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
