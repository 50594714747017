import {
  mapAddedModels,
  mapAddedWeights,
  mapMyModels,
  mapOpenModels,
  mapWeights,
} from '../mappers';
import { ValuesType } from '../pages/NewModelPage/NewModelForm/constants';
import { rootAPI } from '../store/rootApi';
import { downloadFile, formatQueriesToString } from '../utils';
import { QueriesType } from './types';
import {
  AddExistentWeightToModelRequest,
  AddExistentWeightToModelResponse,
  AddModelCopyToAllianceRequest,
  AddModelCopyToAllianceResponse,
  AddModelToUserAccountRequest,
  AddModelToUserAccountResponseObj,
  AddWeightByOwnerRequest,
  AddWeightByUserRequest,
  AddedModelsResponse,
  AddedModelsResponseTransformed,
  AvailableModelsResponse,
  AvailableModelsResponseObj,
  DeleteModelRequest,
  DeleteModelResponse,
  DeleteWeightRequest,
  DeleteWeightResponse,
  EditModelRequest,
  EditModelResponseObj,
  EditWeightRequest,
  EditWeightResponse,
  ModelApplicationInfoResponse,
  ModelAttachmentInfoResponse,
  ModelByIdRequest,
  ModelByIdResponse,
  ModelCategoriesResponse,
  ModelCodeRequest,
  ModelFiltersTypeResponse,
  ModelTasksResponse,
  QueriesTypeWithIdRequest,
  ModelWeightsResponse,
  ModelWeightsResponseTransformed,
  ModelsFilterListRequest,
  ModelsFilterListRequestTransformed,
  MyModelsResponse,
  MyModelsResponseTransformed,
  OpenModelsResponse,
  OpenModelsResponseTransformed,
  WeightsHistoryResponse,
  WeightsHistoryResponseTransformed,
  WeightsMetricsTypeRequest,
  WeightsMetricsTypeResponse,
  EditNameResponse,
  TransformedModelApplicationInfoResponse,
  RequestId,
  ChangeEntityStatusInRequest,
  ChangeEntityNameInRequest,
  WeightsHistoryByIdResponse,
  WeightsHistoryByIdResponseTransformed,
  AvailableModelDataResponse,
  ImageInfoResponse,
  ImageInfoResponseTransformed,
} from './models/types';
import {
  mapAvailableModelWeights,
  mapAvailableModels,
  mapCreateModelData,
  mapImageInfo,
  mapWeightToFormData,
  mapWeightsToAlliance,
} from '../mappers/models/models';
import {
  mapModel,
  mapUser,
  mapWeight,
} from '../ui/components/Popups/ModelToAllianceApplicationPopUp/mapper';
import { modelPageAdapter } from '../pages/ModelPage/modelPageAdapter';
import { IModel } from '../pages/ModelPage/types';
import { ModelPopupType } from '../types';

export const modelsApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getMyModels: build.query<MyModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/mlmodels/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: MyModelsResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapMyModels(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['MyModels'],
    }),
    getOpenModels: build.query<OpenModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/mlmodels/list-open/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: OpenModelsResponse) => {
        return {
          count: response.count,
          result: mapOpenModels(response.results),
        };
      },
      providesTags: () => ['OpenModels'],
    }),
    getAddedModels: build.query<AddedModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/mlmodels/applied-to-alliance/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AddedModelsResponse) => {
        return {
          count: response.count,
          nextPageLink: response.links.next,
          result: mapAddedModels(response.results),
        };
      },
      providesTags: () => ['AlliancesModels'],
    }),
    getWeightsHistory: build.query<WeightsHistoryResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/application/weights/history/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightsHistoryResponse) => {
        return {
          count: response.count,
          nextPageLink: response.links.next,
          result: mapAddedWeights(response.results),
        };
      },
      providesTags: () => ['WeightsOfModels'],
    }),
    getWeightsHistoryById: build.query<WeightsHistoryByIdResponseTransformed, number>({
      query(id) {
        return {
          url: `/application/${id}/weights/history/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightsHistoryByIdResponse) => {
        return mapWeightsToAlliance(response);
      },
    }),
    getFiltersList: build.query<ModelsFilterListRequestTransformed, ModelFiltersTypeResponse>({
      query(ModelFiltersTypeResponse) {
        return {
          url: `/mlmodels/aggregation/${ModelFiltersTypeResponse}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelsFilterListRequest) => {
        return response.map(filter => ({ name: filter.aggregation_name, count: filter.count }));
      },
    }),
    getModelById: build.query<IModel, ModelByIdRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelByIdResponse, errors, args) => {
        return modelPageAdapter(response, Number(args.id) || 0);
      },
      providesTags: () => ['Model'],
    }),
    getModelWeights: build.query<ModelWeightsResponseTransformed, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/${id}/weights/${formatQueriesToString(rest as unknown as QueriesType)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelWeightsResponse) => {
        return { count: response.count, result: mapWeights(response.results) };
      },
      providesTags: () => ['ModelWeights'],
    }),
    getMetricsList: build.query<WeightsMetricsTypeResponse, WeightsMetricsTypeRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/${id}/metrics/`,
          credentials: 'include',
        };
      },
      providesTags: () => ['ModelMetrics'],
    }),
    getModelCode: build.query<string, ModelCodeRequest>({
      query({ user, repository }) {
        return {
          url: `https://raw.githubusercontent.com/${user}/${repository}/master/client_methods.py`,
          responseHandler: response => response.text(),
        };
      },
    }),
    getImageInfo: build.query<ImageInfoResponseTransformed, number>({
      query(id) {
        return {
          url: `/mlmodels/built-image-info/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ImageInfoResponse) => {
        return mapImageInfo(response);
      },
      providesTags: () => ['Docker'],
    }),
    getTasksList: build.query<ModelTasksResponse, null>({
      query() {
        return {
          url: '/mlmodels/task-type/',
          credentials: 'include',
        };
      },
    }),
    postCreateModel: build.mutation<null, ValuesType>({
      query(values) {
        return {
          url: '/mlmodels/',
          method: 'POST',
          body: mapCreateModelData(values),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['MyModels', 'OpenModels'],
    }),
    postBuildImage: build.mutation<null, { modelId: number; weightId: number | null }>({
      query({ modelId, weightId }) {
        return {
          url: `mlmodels/${modelId}/build-image/`,
          method: 'POST',
          body: { weight_id: weightId },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model'],
    }),
    postRebuildImage: build.mutation<null, { id: number }>({
      query({ id }) {
        return {
          url: `mlmodels/rebuild-image/`,
          method: 'POST',
          body: { id },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Docker'],
    }),
    postAddWeightByOwner: build.mutation<null, AddWeightByOwnerRequest>({
      query({ data, modelId }) {
        return {
          url: `/mlmodels/${modelId}/weights/`,
          method: 'POST',
          body: mapWeightToFormData(data),
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['ModelWeights', 'ModelMetrics', 'AllianceModel'],
    }),
    postAddWeightByUser: build.mutation<null, AddWeightByUserRequest>({
      query({ data, modelId, allianceId }) {
        return {
          url: `/mlmodels/user/add-weights/alliance/${allianceId}/model/${modelId}/`,
          method: 'POST',
          body: mapWeightToFormData(data),
          credentials: 'include',
        };
      },
    }),
    postAddExistentWeightToModel: build.mutation<
      AddExistentWeightToModelResponse,
      AddExistentWeightToModelRequest
    >({
      query({ data, isOwner }) {
        return {
          url: `/mlmodels/${isOwner ? 'owner' : 'user'}/add-weights/`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['ModelWeights'],
    }),
    patchEditWeight: build.mutation<EditWeightResponse, EditWeightRequest>({
      query({ data, weightId, isAggregatedWeight = false }) {
        return {
          url: `/mlmodels/weights/${weightId}/`,
          method: 'PATCH',
          body: mapWeightToFormData(data),
          credentials: 'include',
        };
      },
      invalidatesTags: (results, errors, args) =>
        args.isAggregatedWeight ? ['FLRound'] : ['ModelWeights', 'ModelMetrics'],
    }),
    deleteModel: build.mutation<DeleteModelResponse, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['MyModels', 'OpenModels', 'AlliancesModels', 'AllianceModel'],
    }),
    deleteWeight: build.mutation<DeleteWeightResponse, DeleteWeightRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/weights/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['WeightsOfModels', 'ModelWeights', 'ModelMetrics', 'Model'],
    }),
    patchEditModel: build.mutation<EditModelResponseObj, EditModelRequest>({
      query({ modelData, modelId }) {
        return {
          url: `/mlmodels/${modelId}/`,
          method: 'PATCH',
          body: JSON.stringify(modelData),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model', 'AllianceModel', 'MyModels'],
    }),
    patchModelRevalidation: build.mutation<null, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/mlmodels/revalidation/${id}/`,
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model'],
    }),
    postAddModelCopyToAllianceAsOwner: build.mutation<
      AddModelCopyToAllianceResponse,
      AddModelCopyToAllianceRequest
    >({
      query({ allianceId, checkedModelId, checkedWeightId }) {
        return {
          url: '/mlmodels/owner/add-model/',
          method: 'POST',
          body: JSON.stringify({
            ml_model: checkedModelId,
            alliance: allianceId,
            weights: checkedWeightId ? [checkedWeightId] : [],
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AlliancesModels', 'OpenModels', 'AllianceModel'],
    }),
    postAddModelCopyToAllianceAsUser: build.mutation<
      AddModelCopyToAllianceResponse,
      AddModelCopyToAllianceRequest
    >({
      query({ allianceId, checkedModelId, checkedWeightId }) {
        return {
          url: '/mlmodels/user/add-model/',
          method: 'POST',
          body: JSON.stringify({
            ml_model: checkedModelId,
            alliance: allianceId,
            weights: checkedWeightId ? [checkedWeightId] : [],
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AlliancesModels'],
    }),
    postAddModelToUserAccount: build.mutation<
      AddModelToUserAccountResponseObj,
      AddModelToUserAccountRequest
    >({
      query(mlData) {
        return {
          url: '/mlmodels/add-to-me/',
          method: 'POST',
          body: JSON.stringify(mlData),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
    }),
    getAttachInfoForLoadingModel: build.query<ModelAttachmentInfoResponse, number | undefined>({
      query(modelId) {
        return {
          url: `/mlmodels/${modelId}/attachments-info/`,
          credentials: 'include',
        };
      },
    }),
    getDownloadWeightLink: build.mutation<Blob, number | undefined>({
      query(weightId) {
        return {
          url: `/mlmodels/weights/${weightId}/download/`,
          credentials: 'include',
          responseHandler: async response => {
            downloadFile(await response.blob(), 'weight');
          },
          cache: 'no-cache',
        };
      },
    }),
    getModelApplicationInfo: build.mutation<TransformedModelApplicationInfoResponse, RequestId>({
      query(id) {
        return {
          url: `/mlmodels/model-in-application/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelApplicationInfoResponse) => {
        return {
          user: mapUser(response),
          weight: mapWeight(response),
          model: mapModel(response),
        };
      },
    }),
    patchChangeModelNameInRequest: build.mutation<EditNameResponse, ChangeEntityNameInRequest>({
      query({ id, name }) {
        return {
          url: `/mlmodels/editing-model-in-application/${id}/`,
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify({ name }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['AllianceModelRequests'],
    }),
    patchChangeModelStatusInRequest: build.mutation<string, ChangeEntityStatusInRequest>({
      query({ id, state }) {
        return {
          url: `/application/${id}/model/change-state-by-owner/?state=${state}`,
          method: 'PATCH',
          body: JSON.stringify({ state }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceModel', 'AllianceModelRequestsCount'],
    }),
    postIsNameTaken: build.mutation<boolean, string>({
      query(name) {
        return {
          url: `/mlmodels/is-name-taken/`,
          method: 'POST',
          body: JSON.stringify({ model_name: name }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      transformResponse: (response: { is_name_taken: boolean }) => response.is_name_taken,
    }),
    getCategories: build.query<ModelCategoriesResponse, undefined>({
      query() {
        return {
          url: '/mls-book/categories/',
          credentials: 'include',
        };
      },
    }),
    getAvailableModelData: build.query<ModelPopupType, { id: number }>({
      query({ id }) {
        return {
          url: `/mlmodels/${id}/available-weights/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AvailableModelDataResponse) => {
        return {
          name: response.name,
          id: response.id,
          category: response.category,
          count: response.weights_count,
          task: response.task,
          type: response.model_type,
          weights: mapAvailableModelWeights(response.weights),
          isPaid: response.is_required_payment,
          isJointMl: response.provided_by_platform,
        };
      },
    }),
    getAvailableModels: build.mutation<AvailableModelsResponseObj, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/mlmodels/available-models/alliance/${id}/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AvailableModelsResponse) => {
        return { count: response.count, result: mapAvailableModels(response.results) };
      },
    }),
  }),
});
