import { useTranslation } from 'react-i18next';

import { deleteProject } from '../../../../../api';
import { WebIcon } from '../../../../../assets/svg';
import { DeleteBtn, EditBtn } from '../../../../../ui';
import { Attachment } from '../../../../../ui/components/Attachments/Attachment/Attachment';
import { IAttachment } from '../../../../../ui/components/Attachments/types';
import { useAppDispatch } from '../../../../../hooks';
import { openErrorPopup, openPopup, setNotification } from '../../../../../store';
import { NotificationTypes, PopupTypes } from '../../../../../store/types';

import styles from './ProjectCard.module.scss';

type PropsType = {
  number: number;
  name: string;
  description: string;
  id: number;
  link: { name: string; link: string };
  files: IAttachment[];
  onAction?: () => void;
  editable?: boolean;
};

export const ProjectCard = ({
  name,
  description,
  link,
  files,
  number,
  id,
  onAction,
  editable = true,
}: PropsType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(
      openPopup({
        type: PopupTypes.EditProject,
        data: { projectData: { name, description, link, files, id } },
        onAction,
      }),
    );
  };

  const handleDelete = async () => {
    const { status } = await deleteProject(id);
    if (status === 'success') {
      onAction?.();
      dispatch(
        setNotification({
          type: NotificationTypes.DeleteProject,
          data: { projectName: name },
        }),
      );
    } else dispatch(openErrorPopup());
  };

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DeleteProject,
        data: {
          name,
        },
        onAction: handleDelete,
      }),
    );
  };

  const handleDetailedInfoOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.ProjectDetailedInfo,
        data: { projectData: { name, description, link, files, id } },
        onAction,
      }),
    );
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('pages.portfolioPage.projectNumber', { number })}</h2>
      <h3 className={styles.name}>{name}</h3>
      <div className={styles.descr}>
        <h3 className={styles.descrTitle}>{t('headings.desc')}</h3>
        <p className={styles.descrText}>{description}</p>
      </div>
      {link.link && (
        <div className={styles.link} data-descr={t('pages.portfolioPage.linkHint')}>
          <span className={styles.linkIcon}>
            <WebIcon />
          </span>
          <a target="_blank" rel="noreferrer" href={link.link} className={styles.linkText}>
            {link.name || link.link}
          </a>
        </div>
      )}
      {files.length > 0 && (
        <div className={styles.attachments}>
          <div className={styles.attachmentsHeader}>
            <h3 className={styles.attachmentsTitle}>{t('headings.attachments')}</h3>
            {files.length > 3 && (
              <button className={styles.attachmentsBtn} onClick={handleDetailedInfoOpen}>
                {t('pages.portfolioPage.watchAllBtn')}
              </button>
            )}
          </div>
          <div className={styles.attachmentsList}>
            {files.slice(0, 3).map(file => (
              <Attachment file={file} key={file.id} isLink />
            ))}
          </div>
        </div>
      )}
      {editable && (
        <div className={styles.btns}>
          <EditBtn description={t('buttons.edit')} onClick={handleEdit} />
          <DeleteBtn description={t('buttons.delete')} onClick={handleDeleteOpen} />
        </div>
      )}
    </div>
  );
};
