import { PeriodType } from '../types/types';

export const getExpirationDays = (date: string, type: PeriodType) => {
  const startDate = new Date(date);
  const currentDate = Date.now();

  let durationTime = 0;

  switch (type) {
    case 'MONTH':
      durationTime = 2629700000;
      break;
    case 'HALF_A_YEAR':
      durationTime = 15778200000;
      break;
    case 'YEAR':
      durationTime = 31556400000;
      break;
    default:
      break;
  }

  const expirationDays = (durationTime - (currentDate - startDate.getTime())) / 86400000;

  return Math.ceil(expirationDays);
};

export const getMinutesRemaining = (date: string) => {
  const endDate = new Date(date);
  const currentDate = Date.now();

  const expirationMinutes = ((endDate.getTime() - currentDate) / 86400000) * 1440;

  return Math.floor(expirationMinutes);
};
