export const InformationIcon = ({ isBlue = false }: { isBlue?: boolean }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 11.9999L12 16.7999M12 8.44209V8.3999M2.40002 11.9999C2.40002 6.69797 6.69809 2.3999 12 2.3999C17.302 2.3999 21.6 6.69797 21.6 11.9999C21.6 17.3018 17.302 21.5999 12 21.5999C6.69809 21.5999 2.40002 17.3018 2.40002 11.9999Z"
      stroke={isBlue ? '#137CF0' : '#454A54'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
