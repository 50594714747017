import { ChangeEvent, useEffect, useState } from 'react';
import {
  IOpenedAlliances,
  IInvitations,
  IControlledAlliance,
  IMyAlliances,
  IRequestsToAlliances,
} from '../pages/AlliancesPage/types';

type GetDataType = (
  next?: string | null,
  value?: string,
) => Promise<{ nextPageLink: any; result: any }>;

type Data =
  | IOpenedAlliances
  | IInvitations
  | IControlledAlliance
  | IMyAlliances
  | IRequestsToAlliances;

// Типизировать после рефакторинга

interface IList {
  name: string;
  count: number;
}

interface ITag {
  id: number;
  name: string;
}

interface IFilters {
  tags: ITag[];
  skills: ITag[];
  experience: IList[];
  specializations: IList[];
  category: IList[];
  task: IList[];
}

interface IQueries extends IFilters {
  search: string;
  page: number;
}

type DataType = {
  isLoading: boolean;
  count: number;
  data: any;
};

type HelpersType = {
  isSearching: boolean;
  isShowTable: boolean;
  isSearchResult: boolean;
};

const defaultQueries = {
  search: '',
  page: 0,
  tags: [],
  category: [],
  task: [],
  skills: [],
  experience: [],
  specializations: [],
};

const defaultData = {
  isLoading: true,
  count: 0,
  data: null,
};

const defaultHelpersData = {
  isSearching: false,
  isShowTable: true,
  isSearchResult: true,
};

export const useTableData = (getData: any) => {
  const [queries, setQueries] = useState<IQueries>(defaultQueries);
  const [data, setData] = useState<DataType>(defaultData);
  const [helpers, setHelpers] = useState<HelpersType>(defaultHelpersData);

  const fetchData = async (isSearch = false) => {
    if (isSearch) {
      setHelpers(prev => ({ ...prev, isSearching: true }));
    }
    const formattedQueries = {
      ...queries,
      tags: queries.tags.map(tag => tag.name),
      category: queries.category.map(category => category.name),
      task: queries.task.map(task => task.name),
      skills: queries.skills.map(skill => skill.name),
      experience: queries.experience.map(experience => experience.name),
      specializations: queries.specializations.map(specialization => specialization.name),
    };

    const { count, result } = await getData(formattedQueries);
    setData({
      isLoading: false,
      count,
      data: result,
    });
    setHelpers({
      isSearching: false,
      isShowTable: isSearch ? true : count > 0,
      isSearchResult: isSearch && count === 0,
    });
  };

  const handleDelete = (abandoned: any) => {
    setData((prev: any) => ({
      ...prev,
      data: prev.data.filter((data: any) => data !== abandoned),
    }));
  };
  const handleUpdateData = (callback: any) => {
    setData((prev: any) => ({ ...prev, data: prev.data.map(callback) }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 1) {
      setQueries(prev => ({ ...prev, search: value, page: 0 }));
    } else setQueries(prev => ({ ...prev, search: value }));
  };

  const handleReset = () => {
    setQueries(prev => ({ ...prev, search: '', page: 0 }));
  };

  const handleSwitchPage = (page: number) => {
    setQueries(prev => ({ ...prev, page }));
  };

  const handleApplyFilters = (filters: IFilters) => {
    setQueries(prev => ({ ...prev, ...filters, page: 0 }));
  };

  const isFiltersApplied =
    queries.tags.length > 0 ||
    queries.task.length > 0 ||
    queries.category.length > 0 ||
    queries.skills.length > 0 ||
    queries.experience.length > 0 ||
    queries.specializations.length > 0;

  useEffect(() => {
    if (queries.search.length > 1 || isFiltersApplied) {
      const timeoutId = setTimeout(async () => {
        fetchData(true);
      }, 500);
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      fetchData();
    }
  }, [queries]);

  return {
    data: data.data,
    count: data.count,
    isLoading: data.isLoading,
    value: queries.search,
    appliedFilters: { ...queries },
    isSearching: helpers.isSearching,
    isShowTable: helpers.isShowTable,
    isSearchResult: helpers.isSearchResult,
    isFiltersApplied,
    handleChange,
    handleReset,
    handleDelete,
    handleUpdateData,
    handleSwitchPage,
    handleApplyFilters,
  };
};
