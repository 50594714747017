export const NotificationIcon = () => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path
      d="M7 19.6302C7.79613 20.2332 8.84747 20.5999 10 20.5999C11.1525 20.5999 12.2039 20.2332 13 19.6302M1.57109 16.5271C1.09677 16.5271 0.831858 15.8206 1.11877 15.4281C1.78453 14.5173 2.42712 13.1814 2.42712 11.5727L2.45458 9.24167C2.45458 4.91078 5.83278 1.3999 10 1.3999C14.2286 1.3999 17.6566 4.9625 17.6566 9.35718L17.6291 11.5727C17.6291 13.1924 18.2495 14.5356 18.8882 15.4468C19.164 15.8403 18.8984 16.5271 18.43 16.5271H1.57109Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
