import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ALLIANCES_PATH } from '../../../../../router';
import { FLIcon } from '../../../../../assets/svg';

import styles from './FlLink.module.scss';

type PropTypes = {
  allianceId: number;
};

export const FlLink = ({ allianceId }: PropTypes) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.wrapper}>
        <FLIcon />
        <span className={styles.text}>{t('pages.modelsPage.modelHasPassFL')}</span>
      </div>
      <NavLink className={styles.link} to={`/${ALLIANCES_PATH}/${allianceId}`}>
        {t('pages.modelsPage.modelFlLink')}
      </NavLink>
    </>
  );
};
