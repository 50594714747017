import { BaIcon, EmailIcon, MembersIcon, DateIcon } from '../../../assets/svg';
import { OwnerData } from '../../../pages/AlliancePage/types';
import { formatDate } from '../../../utils';

import styles from './OwnerInfo.module.scss';

type PropTypes = {
  owner: OwnerData;
  members: number;
  date: string;
};

export const OwnerInfo = ({ owner, members, date }: PropTypes) => {
  return (
    <div className={styles.info}>
      <div>
        <BaIcon />
        <p>{owner.fullName}</p>
      </div>
      <div>
        <EmailIcon />
        <a className={styles.email} href={`mailto:${owner.email}`}>
          {owner.email}
        </a>
      </div>
      <div>
        <MembersIcon />
        <p>{members}</p>
      </div>
      <div>
        <DateIcon />
        <p>{formatDate(new Date(date))}</p>
      </div>
    </div>
  );
};
