import { useRef, useState } from 'react';
import cn from 'classnames';

import { CloseIcon } from './assets/CloseIcon';
import { SearchIcon } from '../../../assets/svg';
import { Spinner } from '../../../ui';
import { PropTypes } from './types';

import styles from './TableSearch.module.scss';

export const TableSearch = ({
  value,
  handleChange,
  handleReset,
  placeholder,
  isSearching,
}: PropTypes) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isActive, setIsActive] = useState<boolean>(false);

  const wrapperClassName = cn(styles.wrapper, {
    [styles.wrapperActive]: isActive,
  });

  const containerClassName = cn(styles.container, {
    [styles.containerActive]: isActive,
  });

  const onSearch = () => {
    setIsActive(true);
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 400);
  };

  const onClose = () => {
    handleReset();
    setIsActive(false);
  };

  return (
    <div className={wrapperClassName}>
      {isActive ? (
        <button className={styles.close} onClick={onClose}>
          <CloseIcon />
        </button>
      ) : (
        <button onClick={onSearch} className={styles.search}>
          <SearchIcon />
        </button>
      )}
      <div className={containerClassName}>
        <span className={styles.searchActive}>
          <SearchIcon />
        </span>
        <input
          placeholder={placeholder}
          className={styles.input}
          value={value ?? ''}
          onChange={handleChange}
          ref={inputRef}
        />
        {isSearching && <Spinner />}
      </div>
    </div>
  );
};
