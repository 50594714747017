export const QuestionMarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.40039 12.0004C2.40039 6.69846 6.69846 2.40039 12.0004 2.40039C17.3023 2.40039 21.6004 6.69846 21.6004 12.0004C21.6004 17.3023 17.3023 21.6004 12.0004 21.6004C6.69846 21.6004 2.40039 17.3023 2.40039 12.0004Z"
      stroke="#137CF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.684 13.66V13.24C11.684 12.856 11.756 12.496 11.9 12.16C12.052 11.816 12.308 11.436 12.668 11.02C12.948 10.7 13.144 10.428 13.256 10.204C13.368 9.972 13.424 9.728 13.424 9.472C13.424 9.168 13.316 8.928 13.1 8.752C12.884 8.576 12.58 8.488 12.188 8.488C11.364 8.488 10.62 8.796 9.956 9.412L9.524 8.464C9.844 8.152 10.248 7.904 10.736 7.72C11.232 7.528 11.74 7.432 12.26 7.432C12.98 7.432 13.56 7.612 14 7.972C14.44 8.324 14.66 8.788 14.66 9.364C14.66 9.74 14.576 10.092 14.408 10.42C14.248 10.74 13.964 11.1 13.556 11.5C13.18 11.876 12.916 12.208 12.764 12.496C12.612 12.776 12.52 13.056 12.488 13.336L12.44 13.66H11.684ZM11.348 16V14.572H12.776V16H11.348Z"
      fill="#137CF0"
    />
  </svg>
);
