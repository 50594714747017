import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { modelsApi } from '../../../../../api/ModelsApi';
import { adminModelApi } from '../../../../../api/adminModelApi';
import { GitHubIcon } from '../../../../../assets/svg';
import { InfoButton, RefreshButton, Status } from '../../../../../ui';
import { ModelErrorReport } from '../ModelErrorReport/ModelErrorReport';
import { useAppSelector } from '../../../../../hooks';
import { isAdminSelector } from '../../../../../store';

import styles from './Repo.module.scss';

type PropsType = {
  isControlled: boolean;
  state: string;
  link: string;
  isError: boolean;
  reports: string[];
  id?: number;
  isDisabled?: boolean;
};

export const Repo = ({
  isControlled,
  state,
  link,
  isError,
  reports = [],
  id,
  isDisabled = false,
}: PropsType) => {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(isAdminSelector);
  const [revalidationModel] = isAdmin
    ? adminModelApi.usePatchModelRevalidationAsAdminMutation()
    : modelsApi.usePatchModelRevalidationMutation();

  const DisplayStatus = () => {
    switch (state) {
      case 'pending':
        return (
          <div className={styles.statusWrap}>
            <Status state={'waiting'} text={t('statuses.waiting')} />
            <p className={styles.description}>{t('pages.singleModelPage.validationDescription')}</p>
          </div>
        );
      case 'ready':
      case 'validated':
        return <Status state={'confirmed'} text={t('statuses.confirm')} />;
      default:
        return <ModelErrorReport reports={reports} />;
    }
  };

  const handleRefresh = () => {
    revalidationModel({ id });
  };

  return (
    <div className={`${styles.colInner} repo`}>
      <h3 className={styles.subTitle}>{t('pages.singleModelPage.repoTitle')}</h3>
      {isControlled && DisplayStatus()}
      <div className={styles.git}>
        <span>
          <GitHubIcon />
        </span>
        <a
          className={cn(styles.link, isDisabled && styles.isDisabled)}
          target="_blank"
          rel="noreferrer"
          href={link}
          aria-label={t('pages.singleModelPage.repoTitle')}
        >
          {link}
        </a>
        {isError && (
          <div className={styles.refresh}>
            <RefreshButton onClick={handleRefresh} />
            <InfoButton info={t('pages.singleModelPage.hints.refresh')} />
          </div>
        )}
      </div>
    </div>
  );
};
