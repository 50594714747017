import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import {
  SANDBOX_PATH,
  DEVELOPER_PUBLIC_PROFILE_PATH,
  SANDBOX_TABS,
  DEV_TABS,
} from '../routeConstants';
import { Empty } from '../../ui';
import { notFoundRoutes } from './notFoundRoutes';

const SandboxPage = lazy(() => import('../../pages/SandboxPage/SandboxPage'));
const DeveloperPublicProfilePage = lazy(
  () => import('../../pages/SandboxPage/DeveloperPublicProfilePage/DeveloperPublicProfilePage'),
);
const DevelopersTab = lazy(() => import('../../pages/SandboxPage/DevelopersTab/DevelopersTab'));
const ResumeTab = lazy(() => import('../../pages/ProfilePage/ProfileData/ResumeTab/ResumeTab'));
const DeveloperProjectList = lazy(
  () => import('../../pages/SandboxPage/DeveloperPublicProfilePage/DeveloperProjectList'),
);

const { DEVELOPERS, MY } = SANDBOX_TABS;
const { CV, PORTFOLIO } = DEV_TABS;

export const sandboxRoutes = {
  path: SANDBOX_PATH,
  element: <ContentProtection />,
  children: [
    {
      element: suspense(<SandboxPage />),
      children: [
        {
          index: true,
          element: <Empty text="Данная страница находится на этапе разработки" />,
        },
        {
          path: MY,
          element: <Empty text="Данная страница находится на этапе разработки" />,
        },
        {
          path: DEVELOPERS,
          element: suspense(<DevelopersTab />),
        },
        ...notFoundRoutes,
      ],
    },
    {
      path: `${DEVELOPER_PUBLIC_PROFILE_PATH}/:id`,
      element: suspense(<DeveloperPublicProfilePage />),
      children: [
        {
          index: true,
          element: <ResumeTab editable={false} isDev />,
        },
        {
          path: CV,
          element: <ResumeTab editable={false} isDev />,
        },
        {
          path: PORTFOLIO,
          element: suspense(<DeveloperProjectList />),
        },
        ...notFoundRoutes,
      ],
    },
  ],
};
