import {
  MembersIcon,
  ModelsIcon,
  AlliancesIcon,
  SandboxIcon,
  DirectoryIcon,
} from '../../assets/svg';
import {
  EMPLOYEES_PATH,
  MODELS_PATH,
  ALLIANCES_PATH,
  SANDBOX_PATH,
  DIRECTORY_PATH,
} from '../routeConstants';

export const adminNavBar = [
  { name: 'Участники', path: EMPLOYEES_PATH, to: EMPLOYEES_PATH, image: <MembersIcon /> },
  {
    name: 'ML-модели',
    path: MODELS_PATH,
    to: MODELS_PATH,
    image: <ModelsIcon />,
    onboardingClass: 'navLinkToMLModels',
  },
  {
    name: 'Альянсы',
    path: ALLIANCES_PATH,
    to: ALLIANCES_PATH,
    image: <AlliancesIcon />,
    onboardingClass: 'navLinkToAlliances',
  },
  // { name: 'Песочницы', path: SANDBOX_PATH, to: SANDBOX_PATH, image: <SandboxIcon /> },
  // {
  //   name: 'Справочник',
  //   path: DIRECTORY_PATH,
  //   to: DIRECTORY_PATH,
  //   image: <DirectoryIcon />,
  //   isCounter: true,
  // },
];
