import { rootAPI } from '../store/rootApi';
import {
  AddExistentWeightToModelRequest,
  AddExistentWeightToModelResponse,
  AddWeightByOwnerRequest,
  AddedModelsResponse,
  AddedModelsResponseTransformed,
  AggregationForAdminResponseTransformedType,
  AggregationForAdminResponseType,
  AggregationResponseTransformedType,
  AggregationResponseType,
  AvailableModelsResponse,
  AvailableModelsResponseObj,
  ChangeEntityNameInRequest,
  ChangeEntityStatusInRequest,
  DeleteModelRequest,
  DeleteModelResponse,
  DeleteWeightRequest,
  DeleteWeightResponse,
  EditModelRequest,
  EditModelResponseObj,
  EditNameResponse,
  EditWeightRequest,
  EditWeightResponse,
  ImageInfoResponse,
  ImageInfoResponseTransformed,
  ModelApplicationInfoResponse,
  ModelByIdRequest,
  ModelByIdResponse,
  ModelWeightsResponse,
  ModelWeightsResponseTransformed,
  MyModelsResponse,
  MyModelsResponseTransformed,
  OpenModelsResponse,
  OpenModelsResponseTransformed,
  QueriesTypeWithIdRequest,
  RequestId,
  TransformedModelApplicationInfoResponse,
  WeightsHistoryResponse,
  WeightsHistoryResponseTransformed,
} from './models/types';
import { modelPageAdapter } from '../pages/ModelPage/modelPageAdapter';
import { IModel } from '../pages/ModelPage/types';
import { ValuesType } from '../pages/NewModelPage/NewModelForm/constants';
import {
  mapAddedModels,
  mapAddedWeights,
  mapAvailableModels,
  mapCreateModelData,
  mapWeightToFormData,
  mapImageInfo,
  mapMyModels,
  mapOpenModels,
  mapWeights,
} from '../mappers/models/models';
import { QueriesType } from './types';
import { formatQueriesToString } from '../utils';
import {
  mapModel,
  mapUser,
  mapWeight,
} from '../ui/components/Popups/ModelToAllianceApplicationPopUp/mapper';

export const adminModelApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getModelByIdAsAdmin: build.query<IModel, ModelByIdRequest>({
      query({ id }) {
        return {
          url: `admin/mlmodels/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelByIdResponse, errors, args) => {
        return modelPageAdapter(response, Number(args.id) || 0);
      },
      providesTags: () => ['Model'],
    }),
    getMyModelsByUserAsAdmin: build.query<MyModelsResponseTransformed, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/admin/mlmodels/user/${id}/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: MyModelsResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapMyModels(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['MyModels'],
    }),
    getAddedModelsByUserAsAdmin: build.query<
      AddedModelsResponseTransformed,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/admin/mlmodels/applied-to-alliance/user/${id}/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AddedModelsResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapAddedModels(response.results),
          count: response.count,
        };
      },
    }),
    getWeightsHistoryByUserAsAdmin: build.query<
      WeightsHistoryResponseTransformed,
      QueriesTypeWithIdRequest
    >({
      query({ id, ...rest }) {
        return {
          url: `/admin/mlmodels/weights/history/user/${id}/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightsHistoryResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapAddedWeights(response.results),
          count: response.count,
        };
      },
    }),
    getAggregationByUserAsAdmin: build.query<AggregationResponseTransformedType, number>({
      query(id) {
        return {
          url: `/admin/mlmodels/user/${id}/aggregate-info/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AggregationResponseType) => {
        return {
          myModels: response.added_models,
          addedModels: response.mlmodels_in_alliances,
          addedWeights: response.weights_in_alliances,
        };
      },
    }),
    getAggregationAsAdmin: build.query<AggregationForAdminResponseTransformedType, void>({
      query() {
        return {
          url: `/admin/mlmodels/aggregate-info/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AggregationForAdminResponseType) => {
        return {
          myModels: response.added_models,
          allModels: response.all_models,
          addedModels: response.mlmodels_in_alliances,
          weightsOfModels: response.weights_in_alliances,
        };
      },
    }),
    getModelApplicationInfoAsAdmin: build.mutation<
      TransformedModelApplicationInfoResponse,
      RequestId
    >({
      query(id) {
        return {
          url: `/admin/alliances/applications/model/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ModelApplicationInfoResponse) => {
        return {
          user: mapUser(response),
          weight: mapWeight(response),
          model: mapModel(response),
        };
      },
    }),
    getModelWeightsAsAdmin: build.query<ModelWeightsResponseTransformed, QueriesTypeWithIdRequest>({
      query({ id, ...rest }) {
        return {
          url: `/admin/mlmodels/${id}/weights/${formatQueriesToString(
            rest as unknown as QueriesType,
          )}`,

          credentials: 'include',
        };
      },
      transformResponse: (response: ModelWeightsResponse) => {
        return { count: response.count, result: mapWeights(response.results) };
      },
      providesTags: () => ['ModelWeights'],
    }),
    getImageInfoAsAdmin: build.query<ImageInfoResponseTransformed, number>({
      query(id) {
        return {
          url: `/admin/mlmodels/built-image-info/${id}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ImageInfoResponse) => {
        return mapImageInfo(response);
      },
      providesTags: () => ['Docker'],
    }),
    deleteWeightAsAdmin: build.mutation<DeleteWeightResponse, DeleteWeightRequest>({
      query({ id }) {
        return {
          url: `/admin/mlmodels/weights/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['WeightsOfModels', 'ModelWeights', 'ModelMetrics', 'Model'],
    }),
    postCreateModelAsAdmin: build.mutation<null, ValuesType>({
      query(values) {
        return {
          url: `/admin/mlmodels/`,
          method: 'POST',
          credentials: 'include',
          body: mapCreateModelData(values),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['MyModels', 'OpenModels'],
    }),
    patchChangeModelNameInRequestAsAdmin: build.mutation<
      EditNameResponse,
      ChangeEntityNameInRequest
    >({
      query({ id, name }) {
        return {
          url: `/admin/alliances/applications/model/${id}/edit/`,
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify({ name }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['AllianceModelRequests'],
    }),
    patchChangeModelStatusInRequestAsAdmin: build.mutation<string, ChangeEntityStatusInRequest>({
      query({ id, state }) {
        return {
          url: `/admin/alliances/model/applications/${id}/respond/?state=${state}`,
          method: 'PATCH',
          body: JSON.stringify({ state }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AllianceModel', 'AllianceModelsInfo'],
    }),
    patchEditModelAsAdmin: build.mutation<EditModelResponseObj, EditModelRequest>({
      query({ modelData, modelId }) {
        return {
          url: `/admin/mlmodels/${modelId}/`,
          method: 'PATCH',
          body: JSON.stringify(modelData),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model', 'AllianceModel', 'MyModels'],
    }),
    patchEditWeightAsAdmin: build.mutation<EditWeightResponse, EditWeightRequest>({
      query({ data, weightId }) {
        return {
          url: `/admin/mlmodels/weights/${weightId}/`,
          method: 'PATCH',
          body: mapWeightToFormData(data),
          credentials: 'include',
        };
      },
      invalidatesTags: (results, errors, args) =>
        args.isAggregatedWeight ? ['FLRound'] : ['ModelWeights', 'ModelMetrics'],
    }),
    deleteModelAsAdmin: build.mutation<DeleteModelResponse, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/admin/mlmodels/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => [
        'MyModels',
        'OpenModels',
        'AlliancesModels',
        'AllianceModel',
        'Model',
      ],
    }),
    postBuildImageAsAdmin: build.mutation<null, { modelId: number; weightId: number | null }>({
      query({ modelId, weightId }) {
        return {
          url: `/admin/mlmodels/${modelId}/build-image/`,
          method: 'POST',
          body: { weight_id: weightId },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model'],
    }),
    postRebuildImageAsAdmin: build.mutation<null, { id: number }>({
      query({ id }) {
        return {
          url: `/admin/mlmodels/rebuild-image/`,
          method: 'POST',
          body: { id },
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Docker'],
    }),
    getAdminAddedModels: build.query<AddedModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/admin/mlmodels/applied-to-alliance/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AddedModelsResponse) => {
        return {
          count: response.count,
          nextPageLink: response.links.next,
          result: mapAddedModels(response.results),
        };
      },
      providesTags: () => ['AlliancesModels'],
    }),
    getAdminWeightsHistory: build.query<WeightsHistoryResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/admin/application/weights/history/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: WeightsHistoryResponse) => {
        return {
          count: response.count,
          nextPageLink: response.links.next,
          result: mapAddedWeights(response.results),
        };
      },
      providesTags: () => ['WeightsOfModels'],
    }),
    getMyModelsAsAdmin: build.query<MyModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/admin/mlmodels/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: MyModelsResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapMyModels(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['MyModels'],
    }),
    getAllModelsAsAdmin: build.query<OpenModelsResponseTransformed, QueriesType>({
      query(queries) {
        return {
          url: `/admin/mlmodels/all/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: OpenModelsResponse) => {
        return {
          count: response.count,
          result: mapOpenModels(response.results),
        };
      },
      providesTags: () => ['OpenModels'],
    }),
    getAvailableModelsAsAdmin: build.mutation<AvailableModelsResponseObj, QueriesTypeWithIdRequest>(
      {
        query({ id, ...rest }) {
          return {
            url: `/admin/mlmodels/available-models/alliance/${id}/${formatQueriesToString(
              rest as unknown as QueriesType,
            )}`,
            credentials: 'include',
          };
        },
        transformResponse: (response: AvailableModelsResponse) => {
          return { count: response.count, result: mapAvailableModels(response.results) };
        },
      },
    ),
    postAddWeightAsAdmin: build.mutation<null, AddWeightByOwnerRequest>({
      query({ data, modelId }) {
        return {
          url: `/admin/mlmodels/${modelId}/weights/`,
          method: 'POST',
          body: mapWeightToFormData(data),
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['ModelWeights', 'ModelMetrics', 'AllianceModel'],
    }),
    postAddExistentWeightToModelAsAdmin: build.mutation<
      AddExistentWeightToModelResponse,
      Omit<AddExistentWeightToModelRequest, 'isOwner'>
    >({
      query({ data }) {
        return {
          url: `/admin/mlmodels/add-weight/`,
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['ModelWeights'],
    }),
    patchModelRevalidationAsAdmin: build.mutation<null, DeleteModelRequest>({
      query({ id }) {
        return {
          url: `/admin/mlmodels/${id}/revalidate/`,
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['Model'],
    }),
  }),
});
