import { formatQueriesToString } from '../utils';
import { request } from '../utils/fetchHandler';
import { QueriesType } from './types';
import { IUserData, UsersResponse, UsersVerifyResponse } from './verification/types';

export const getEmployeesAmount = async (
  id: number | null,
): Promise<{ status: string; payload: UsersResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/organization/${id}/users/?page=1`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const getList = async (
  id: number | null,
  queries: QueriesType,
): Promise<{ status: string; payload: UsersResponse }> => {
  const { status, payload } = await request(
    `${
      process.env.REACT_APP_BACK_END
    }/business-admin/organization/${id}/users/${formatQueriesToString(queries)}`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const changeStatus = async (
  data: IUserData,
  id: number | null,
): Promise<{ status: string; payload: UsersVerifyResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/organization/${id}/users/`,
    {
      credentials: 'include',
    },
    'PATCH',
    JSON.stringify(data),
  );

  return { status, payload };
};

export const findEmployee = async (
  value: string,
  id: number | null,
): Promise<{ status: string; payload: UsersResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/business-admin/organization/${id}users/?search=${value}`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};
