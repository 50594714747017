import cn from 'classnames';
import { MouseEventHandler } from 'react';

import { NavigateArrowIcon } from './assets/NavigateArrowIcon';

import styles from './NavigateBtn.module.scss';

type PropsType = {
  isPrev?: boolean;
  isDisabled?: boolean;
  props?: {
    'aria-label': string;
    'data-action': string;
    onClick: MouseEventHandler<HTMLElement>;
    role: string;
    title: string;
  };
  classNameProp?: string;
};

export const NavigateBtn = ({
  props,
  isPrev = false,
  isDisabled = false,
  classNameProp,
}: PropsType) => {
  const btnStyles = cn(styles.button, classNameProp && classNameProp, {
    [styles.buttonPrev]: isPrev,
  });
  return (
    <button className={btnStyles} disabled={isDisabled} {...props} title="">
      <NavigateArrowIcon />
    </button>
  );
};
