export const LocationIcon = () => (
  <svg width="17" height="21" viewBox="0 0 17 21" fill="none">
    <path
      d="M8.51304 20.0999C8.51304 20.0999 16.0261 13.4216 16.0261 8.41294C16.0261 4.26361 12.6624 0.899902 8.51304 0.899902C4.3637 0.899902 1 4.26361 1 8.41294C1 13.4216 8.51304 20.0999 8.51304 20.0999Z"
      stroke="#2D89EF"
    />
    <path
      d="M10.9134 8.10005C10.9134 9.42554 9.83883 10.5001 8.51335 10.5001C7.18787 10.5001 6.11335 9.42554 6.11335 8.10005C6.11335 6.77457 7.18787 5.70005 8.51335 5.70005C9.83883 5.70005 10.9134 6.77457 10.9134 8.10005Z"
      stroke="#2D89EF"
    />
  </svg>
);
