import { useState } from 'react';

import { CopyButton } from '../../../../common';
import { TickIcon } from '../../../ProgressBar/assets/TickIcon';

import styles from './ConsoleCommand.module.scss';

export const ConsoleCommand = ({ text }: { text: string }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <span>{text}</span>
      <div className={styles.iconWrapper}>
        {isCopied ? <TickIcon /> : <CopyButton target={text} onTrigger={setIsCopied} />}
      </div>
    </div>
  );
};
