import * as yup from 'yup';

const description = yup
  .string()
  .required('Причина обязательно к заполнению')
  .min(50, 'Причина не может быть короче 50-ти символов')
  .test(
    'Причина не может быть короче 50-ти символов',
    'Причина не может быть короче 50-ти символов',
    (value): boolean => Boolean(value && value?.trim().length >= 50),
  );

export const pauseAppealSchema = yup.object().shape({
  description,
});
