import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AddProjectCard } from './AddProjectCard/AddProjectCard';
import { Button, Empty, InfoBlock } from '../../../../ui';
import { ProjectCardList } from './ProjectCardList/ProjectCardList';

import { isAdminSelector, openPopup, summarySelector } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { PopupTypes } from '../../../../store/types';
import { developerApi } from '../../../../api/developerApi';
import { adminUsersApi } from '../../../../api/adminUsersApi';

import styles from './PortfolioTab.module.scss';

export const PortfolioTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: developerId } = useAppSelector(summarySelector);
  const { id: paramsId } = useParams();
  const isAdmin = useAppSelector(isAdminSelector);
  const [getProjects, { data: projects, isLoading }] = isAdmin
    ? adminUsersApi.useGetDeveloperProjectsAsAdminMutation()
    : developerApi.useGetProjectsMutation();

  const fetchData = () => {
    const id = paramsId || developerId;
    if (id) {
      getProjects(Number(id));
    }
  };

  useEffect(() => {
    fetchData();
  }, [developerId, paramsId]);

  const handleAddClick = () => {
    dispatch(
      openPopup({
        type: PopupTypes.AddProjectToPortfolio,
        onAction: fetchData,
      }),
    );
  };

  if (isLoading || !projects) return null;

  if (projects.length === 0) {
    return !isAdmin ? (
      <div className={styles.addProjectWrapper}>
        <AddProjectCard onClick={handleAddClick} />
      </div>
    ) : (
      <Empty text={t('pages.devPublicProfilePage.emptyPortfolioText')} />
    );
  }

  return (
    <>
      {projects.length < 10 && !isAdmin && (
        <div className={styles.btnWrapper}>
          <Button onClick={handleAddClick} iconPosition="left">
            {t('pages.portfolioPage.addProjectBtn')}
          </Button>
        </div>
      )}
      <ProjectCardList projects={projects} onAction={fetchData} editable={!isAdmin} />
      {projects.length >= 10 && !isAdmin && <InfoBlock text={t('pages.portfolioPage.warnInfo')} />}
    </>
  );
};

export default PortfolioTab;
