const MILLISECONDS_IN_HOUR = 60 * 60 * 1000;

export const getRoundEndTime = (timeStart: string, duration: string) => {
  let roundHours;

  const durationArr = duration.split(' ');

  if (durationArr.length > 1) {
    const durationHours = Number(durationArr[1].split(':')[0]);
    const durationDays = Number(durationArr[0]);
    roundHours = durationDays * 24 + durationHours;
  } else {
    roundHours = Number(duration.split(':')[0]);
  }

  const roundMilliseconds = roundHours * MILLISECONDS_IN_HOUR;
  const localeTimeStart = new Date(timeStart);

  return new Date(localeTimeStart.getTime() + roundMilliseconds);
};
