import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { emitOnboardingEvent, setOnboardingData } from '../../../../utils';
import { Button } from '../../..';
import { ConfirmIcon } from '../../../../assets/svg';

import styles from './StartOnboardingPopup.module.scss';

export const StartOnboardingPopup = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  setOnboardingData('default');

  const handleStart = () => {
    setOnboardingData('start');

    ref.current && emitOnboardingEvent(ref.current);

    onClose();
  };

  return (
    <div className={styles.container}>
      <ConfirmIcon />
      <div className={styles.text}>
        <h2 className={styles.title}>{t('onboarding.start.title')}</h2>
        <p className={styles.info}>{t('onboarding.start.info')}</p>
        <span className={styles.subtitle}>{t('onboarding.start.subtitle')}</span>
      </div>
      <div ref={ref} className={styles.buttons}>
        <Button onClick={onClose} size="small" style="secondary">
          {t('onboarding.start.cancel')}
        </Button>
        <Button onClick={handleStart} size="small">
          {t('buttons.continue')}
        </Button>
      </div>
    </div>
  );
};
