import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Select } from '../../../common';
import { modelsApi } from '../../../../api/ModelsApi';
import { adminModelApi } from '../../../../api/adminModelApi';
import { SelectOptionType } from '../StartFLPopup/types';
import { useAppSelector } from '../../../../hooks';
import { useDebounce } from '../../../../hooks/useDebounce';
import { isAdminSelector } from '../../../../store';

import styles from './WeightForBuildingImagePopup.module.scss';

type PropsType = {
  onClose: () => void;
  modelId: number;
};

export const WeightForBuildingImagePopup = ({ onClose, modelId }: PropsType) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
  });
  const [weightId, setWeightId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const { t } = useTranslation();
  const debouncedSearch = useDebounce(setSearchValue, 500);
  const isAdmin = useAppSelector(isAdminSelector);
  const [buildImage] = isAdmin
    ? adminModelApi.usePostBuildImageAsAdminMutation()
    : modelsApi.usePostBuildImageMutation();
  const { data } = modelsApi.useGetModelWeightsQuery({
    id: modelId,
    search: searchValue?.trim() || '',
    state: 'valid',
  });
  const weightsList: SelectOptionType[] = data
    ? data.result.map(({ name, id }) => ({
        name,
        id,
      }))
    : [];
  const [weight] = watch(['weight']);

  useEffect(() => {
    debouncedSearch(weight);
  }, [weight]);

  useEffect(() => {
    if (weight) {
      setValue('weight', weight.replace(/\s+/g, ' '));
    }
    if (!weight) return;
    const invalidValue =
      weight && !weightsList.map((w: SelectOptionType) => w.name).includes(weight);
    if (invalidValue) {
      setError('weightsList', {
        type: 'custom',
        message: t('popups.startRound.searchError'),
      });
      setWeightId(null);
    } else {
      clearErrors('weightsList');
      setWeightId(weightsList?.find((w: SelectOptionType) => w.name === weight)!.id);
    }
  }, [searchValue]);

  const handleSubmit = () => {
    buildImage({ modelId, weightId })
      .unwrap()
      .then(() => onClose());
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.weightForBuildingImage.title')}</h2>
      <Select
        id="weight"
        label={t('popups.startRound.selectLabel')}
        register={register}
        helperText={errors.weightsList?.message}
        setValue={setValue}
        value={weight}
        options={weightsList}
        setItemId={setWeightId}
        placeholder={''}
        isRequired={true}
        isReadOnly={false}
      />
      <div className={styles.btnWrapper}>
        <Button style="secondary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          isDisabled={!weightId || Boolean(errors.weightsList?.message)}
        >
          {t('buttons.build')}
        </Button>
      </div>
    </div>
  );
};
