import cn from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { TabStateType, TabType } from '../types';

import styles from './Tab.module.scss';

export const Tab = ({
  tabData,
  tabState,
  style = 'secondary',
  indexRoute,
}: {
  tabData: TabType;
  tabState: TabStateType;
  style?: 'primary' | 'secondary';
  indexRoute: string;
}) => {
  const location = useLocation();
  const locationState = location.state;
  const isIndexLocation = RegExp(`.*${indexRoute}/?$`).test(location.pathname);

  return (
    <li>
      <NavLink
        state={locationState}
        to={tabData.path}
        className={({ isActive }) => {
          const isTabActive = tabData.index && isIndexLocation ? true : isActive;

          return cn(
            style === 'secondary' ? styles.tab : styles.primary,
            {
              [styles.tabSelected]: style === 'secondary' && isTabActive,
              [styles.primarySelected]: style === 'primary' && isTabActive,
              [styles.tabDisabled]: tabState.isDisabled,
            },
            `${tabData.path}Tab`,
          );
        }}
      >
        <span className={styles.label}>{tabData.name}</span>
        {tabState.count !== undefined && (
          <span className={tabState.isBlue ? styles.blueCount : styles.count}>
            {tabState.count}
          </span>
        )}
        {tabState.isNew && <span className={styles.new} />}
      </NavLink>
    </li>
  );
};
