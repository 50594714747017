import { formatDate } from './../../utils/formatData';
import { AppealChatHistoryResponse, AppealsDataResponse } from '../../api/appeals/types';
import { resultsKey } from '../constants';
import { getTimeFromDate } from '../../utils/formatTime';

export const mapAppeals = (results: AppealsDataResponse[typeof resultsKey]) =>
  results.map(result => {
    const { id, user, text, created_at, state } = result;
    const { email, position, first_name, last_name, middle_name, company } = user;

    return {
      id,
      email,
      position,
      text,
      state,
      user: `${last_name} ${first_name} ${middle_name ?? ''}`,
      company: company
        ? {
            name: company.name,
            inn: company.inn,
            industry: company.industry,
          }
        : null,
      date: formatDate(new Date(created_at)),
      time: getTimeFromDate(new Date(created_at)),
    };
  });

export const mapAppealChatHistory = (history: AppealChatHistoryResponse[typeof resultsKey]) =>
  history.map(history => ({
    id: history.id,
    who: history.who,
    date: history.updated_at,
    text: history.text,
    type: history.message_type,
  }));
