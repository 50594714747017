export const ModelUploadIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none">
    <path
      d="M7.00003 15.7L13.2354 12.1V4.89999L7.00003 1.29999L0.764648 4.89999V12.1L7.00003 15.7ZM7.00003 15.7V8.94999M7.00003 8.94999L1.15003 5.34999M7.00003 8.94999L12.85 5.34999"
      stroke="#137CF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
