import { lazy } from 'react';

import { ContentProtection } from '../HOC/RoutesProtection';
import { suspense } from '../HOC/suspense';
import { FAVORITES_PATH, FAVORITES_TABS } from '../routeConstants';

const FavoritesPage = lazy(() => import('../../pages/FavoritesPage/FavoritesPage'));

const AlliancesTable = lazy(
  () => import('../../pages/FavoritesPage/AlliancesTable/AlliancesTable'),
);
const ModelsTable = lazy(() => import('../../pages/FavoritesPage/ModelsTable/ModelsTable'));
const DevelopersTable = lazy(
  () => import('../../pages/FavoritesPage/DevelopersTable/DevelopersTable'),
);

const { F_ALLIANCE, F_DEVELOPERS, F_MODELS } = FAVORITES_TABS;

export const favoritesRoutes = {
  path: FAVORITES_PATH,
  element: <ContentProtection />,
  children: [
    {
      element: suspense(<FavoritesPage />),
      children: [
        {
          index: true,
          element: suspense(<AlliancesTable />),
        },
        {
          path: F_ALLIANCE,
          element: suspense(<AlliancesTable />),
        },
        {
          path: F_MODELS,
          element: suspense(<ModelsTable />),
        },
        {
          path: F_DEVELOPERS,
          element: suspense(<DevelopersTable />),
        },
      ],
    },
  ],
};
