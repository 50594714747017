import cn from 'classnames';

import { searchHelpText } from './constants';
import { EmptyIcon } from '../../../assets/svg';
import { Button, Pagination } from '../../../ui';
import { PropTypes, TableTypes } from './types';

import styles from './Table.module.scss';

export const Table = ({
  columns,
  type,
  children,
  isShowTable = true,
  isSearchResult = true,
  helpText,
  button,
  pagesCount = 1,
  handleSwitchPage = () => null,
  isWithoutBorder = false,
  isColored = false,
}: PropTypes) => {
  const columnsClassName = cn(styles.columns, {
    [styles.alliancesColumns]: type === TableTypes.DefaultAlliance,
    [styles.alliancesColumnsRequest]: type === TableTypes.AllianceRequests,
    [styles.myAlliancesColumns]: type === TableTypes.MyAlliances,
    [styles.membersColumns]: type === TableTypes.DefaultMembers,
    [styles.membersColumnsInvites]: type === TableTypes.MembersInvites,
    [styles.modelsColumns]: type === TableTypes.DefaultModels,
    [styles.myModelsColumns]: type === TableTypes.MyModels,
    [styles.addedModelsColumns]: type === TableTypes.AddedModels,
    [styles.addedWeightsColumns]: type === TableTypes.AddedWeights,
    [styles.adminModelsColumns]: type === TableTypes.AdminModels,
    [styles.adminWeightColumns]: type === TableTypes.AdminWeight,
    [styles.allianceModelsColumns]: type === TableTypes.AllianceModels,
    [styles.allianceModelsRequestsColumns]: type === TableTypes.AllianceModelsRequests,
    [styles.allianceWeightsRequestsColumns]: type === TableTypes.AllianceWeightsRequests,
    [styles.controlledAlliancesColumns]: type === TableTypes.ControlledAlliances,
    [styles.alliancesInvitationsColumns]: type === TableTypes.AllianceInvitations,
    [styles.allianceMembersRequestsColumns]: type === TableTypes.AllianceMembersRequests,
    [styles.employeesColumns]: type === TableTypes.Employees,
    [styles.favoriteAlliancesColumns]: type === TableTypes.FavoriteAlliances,
    [styles.modelWeightsColumns]: type === TableTypes.ModelWeights,
    [styles.flEmployeesColumns]: type === TableTypes.FLEmployees,
    [styles.flEmployeesControlledColumns]: type === TableTypes.ControlledFLEmployees,
    [styles.adminAllAlliancesColumns]: type === TableTypes.AdminAllAlliances,
    [styles.adminMyAlliancesColumns]: type === TableTypes.AdminMyAlliances,
    [styles.adminUsersColumns]: type === TableTypes.AdminUsers,
  });
  const rowsClassName = cn(styles.rows, {
    [styles.alliancesRows]: type === TableTypes.DefaultAlliance,
    [styles.alliancesRowsRequest]: type === TableTypes.AllianceRequests,
    [styles.myAlliancesRows]: type === TableTypes.MyAlliances,
    [styles.membersRows]: type === TableTypes.DefaultMembers,
    [styles.membersRowsInvites]: type === TableTypes.MembersInvites,
    [styles.modelsRows]: type === TableTypes.DefaultModels,
    [styles.myModelsRows]: type === TableTypes.MyModels,
    [styles.addedModelsRows]: type === TableTypes.AddedModels,
    [styles.addedWeightsRows]: type === TableTypes.AddedWeights,
    [styles.adminModelsRows]: type === TableTypes.AdminModels,
    [styles.adminWeightRows]: type === TableTypes.AdminWeight,
    [styles.allianceModelsRows]: type === TableTypes.AllianceModels,
    [styles.allianceModelsRequestsRows]: type === TableTypes.AllianceModelsRequests,
    [styles.allianceWeightsRequestsRows]: type === TableTypes.AllianceWeightsRequests,
    [styles.controlledAlliancesRows]: type === TableTypes.ControlledAlliances,
    [styles.alliancesInvitationsRows]: type === TableTypes.AllianceInvitations,
    [styles.allianceMembersRequestsRows]: type === TableTypes.AllianceMembersRequests,
    [styles.employeesRows]: type === TableTypes.Employees,
    [styles.favoriteAlliancesRows]: type === TableTypes.FavoriteAlliances,
    [styles.modelWeightsRows]: type === TableTypes.ModelWeights,
    [styles.flEmployeesRows]: type === TableTypes.FLEmployees,
    [styles.flEmployeesControlledRows]: type === TableTypes.ControlledFLEmployees,
    [styles.adminAllAlliancesRows]: type === TableTypes.AdminAllAlliances,
    [styles.adminMyAlliancesRows]: type === TableTypes.AdminMyAlliances,
    [styles.adminUsersRows]: type === TableTypes.AdminUsers,
    [styles.rowsColored]: isColored,
  });
  // TODO : Избавиться от ф-ии cn в таблице, продумать как вынести это в объект или что-то еще

  const wrapper = cn(styles.wrapper, {
    [styles.withoutBorder]: isWithoutBorder,
  });

  const table = cn(styles.table, {
    [styles.withoutBorder]: isWithoutBorder,
    [styles.colored]: isColored,
  });
  if (isShowTable) {
    if (isSearchResult) {
      return (
        <div className={styles.search}>
          <EmptyIcon />
          <p className={styles.text}>{searchHelpText}</p>
        </div>
      );
    }
    return (
      <div className={wrapper}>
        <div className={table}>
          <ul className={columnsClassName}>
            {columns.map(column => (
              <li key={column}>{column}</li>
            ))}
          </ul>
          <ul className={rowsClassName}>{children}</ul>
          {pagesCount > 1 && (
            <Pagination pagesCount={pagesCount} handleSwitchPage={handleSwitchPage} />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.container}>
        <EmptyIcon />
        <p className={styles.text}>{helpText}</p>
        {button && (
          <Button onClick={button.onAction} isDisabled={button.isDisabled} iconPosition="left">
            {button.text}
          </Button>
        )}
      </div>
    );
  }
};
