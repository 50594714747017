import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { getParamSchema } from '../../../../schemas/paramSchema';
import { Input, TextButton } from '../../../common';
import { EditParamTypes } from '../types';

import styles from './EditParam.module.scss';

export const EditParam = ({
  param,
  params,
  category,
  onSave,
  onClose,
  isEdit,
  isDisabled = false,
}: EditParamTypes) => {
  const { t } = useTranslation();
  const inputName = `paramName${category}`;
  const inputValue = `paramValue${category}`;

  const {
    formState: { isValid, errors },
    register,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(getParamSchema(params, param.name, category)),
    defaultValues: { [inputName]: param.name, [inputValue]: param.value },
    values: { [inputName]: param.name, [inputValue]: param.value },
    mode: 'onChange',
  });

  const [paramName, paramValue] = watch([inputName, inputValue]);

  const handleSave = () => {
    onSave({ ...param, name: paramName.trim(), value: paramValue.trim() });
    onClose();
  };

  useEffect(() => {
    if (paramName) {
      setValue(inputName, paramName.replace(/\s+/g, ''));
    }
    if (!paramName?.trim()) {
      setValue(inputName, '');
    }
    if (!paramValue?.trim()) {
      setValue(inputValue, '');
    }
  }, [paramName, paramValue]);

  return (
    <div className={styles.form}>
      <div className={styles.inputsWrapper}>
        <Input
          id={`paramName${category}`}
          label={t('pages.modelFormPage.paramName')}
          register={register}
          helperText={errors[inputName]?.message}
          isDisabled={isDisabled}
          maxLength={50}
        />
        <Input
          id={`paramValue${category}`}
          label={t('pages.modelFormPage.paramValue')}
          register={register}
          helperText={errors[inputValue]?.message}
          isDisabled={false}
          maxLength={50}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <TextButton onClick={onClose}>{t('buttons.cancel')}</TextButton>
        <TextButton isDisabled={!isValid} onClick={!isValid ? () => null : handleSave}>
          {t(`buttons.${isEdit ? 'save' : 'add'}`)}
        </TextButton>
      </div>
    </div>
  );
};
