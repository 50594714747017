import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { filesSizeSum } from '../../../utils';
import { InfoBlock } from '../../common/InfoBlock/InfoBlock';
import { UploadArea } from './UploadArea/UploadArea';
import { UploadedItem } from './UploadedItem/UploadedItem';
import { APPLICATIONS_FORMATS, BYTES_IN_MB, MODEL_FORMATS, WEIGHT_FORMATS } from './constants';
import { PropTypes } from './types';

import styles from './FormUpload.module.scss';

export const FormUpload = ({
  files,
  setFiles,
  isMulti = false,
  maxQuantity = 1,
  maxSize = 100000,
  type = 'applications',
  isFreeze = false,
  onDelete,
  onTrigger,
}: PropTypes) => {
  const [quantityLimit, setQuantityLimit] = useState<number>(maxQuantity);
  const [sizeLimit, setSizeLimit] = useState<number>(maxSize);

  let formats = APPLICATIONS_FORMATS;

  const { t } = useTranslation();

  const handleDelete = (deleted: any) => {
    if (onDelete) {
      onDelete(deleted.id);
    }
    setFiles(files.filter((file: any) => file !== deleted));
    onTrigger?.();
  };

  const handleAdd = (added: any[]) => {
    setFiles([...files, ...added.splice(0, quantityLimit)]);
    onTrigger?.();
  };

  useEffect(() => {
    setQuantityLimit(maxQuantity - files.length);
    setSizeLimit(maxSize - filesSizeSum(files) / BYTES_IN_MB);
  }, [files]);

  switch (type) {
    case 'model':
      formats = MODEL_FORMATS;
      break;
    case 'weight':
      formats = WEIGHT_FORMATS;
      break;
    default:
      formats = APPLICATIONS_FORMATS;
  }

  return (
    <div className={styles.container}>
      <ul className={styles.files}>
        {files.map((file: File, index: number) => (
          <UploadedItem
            file={file}
            handleDelete={handleDelete}
            key={`${file.name}${index}`}
            type={type}
            isFreeze={isFreeze}
          />
        ))}
      </ul>
      {files.length < maxQuantity ? (
        <UploadArea
          setFiles={handleAdd}
          formats={formats}
          isMulti={isMulti}
          quantityLimit={quantityLimit}
          sizeLimit={sizeLimit}
        />
      ) : (
        <InfoBlock text={isMulti ? t('upload.applications.info') : t('upload.file.info')} />
      )}
    </div>
  );
};
