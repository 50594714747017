import cn from 'classnames';
import { SyntheticEvent, useEffect, useState } from 'react';

import styles from './MenuButton.module.scss';

export const MenuButton = ({
  buttons,
}: {
  buttons: {
    name: string;
    handler: () => void;
    type?: string;
    isDisable?: boolean;
    description?: string;
  }[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (e: SyntheticEvent, handler: () => void) => {
    e.stopPropagation();
    handler();
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', () => setIsOpen(false), true);
    return document.removeEventListener('click', () => setIsOpen(false), true);
  }, []);

  return (
    <div className={styles.container}>
      <button
        className={cn(styles.menu, isOpen && styles.menuActive)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.dot} />
        <span className={styles.dot} />
        <span className={styles.dot} />
      </button>
      <ul className={cn(styles.buttons, isOpen && styles.buttonsActive)}>
        {buttons.map((button, index) => (
          <button
            className={cn(styles.button, button.type === 'delete' && styles.buttonDelete)}
            onMouseDown={(e: SyntheticEvent) => handleClick(e, button.handler)}
            key={index}
            disabled={button.isDisable}
          >
            {button.name}
            {button.description && <span className={styles.description}>{button.description}</span>}
          </button>
        ))}
      </ul>
    </div>
  );
};
