import { TransformedAllianceModelsResponse } from '../../api/alliances/types';
import {
  RoundByIdResponse,
  RoundByIdResponseTransFormed,
  RoundHistoryResponse,
} from '../../api/flearning/types';
import { transformObjectToArray } from '../../utils/formatData';
import { resultsKey } from '../constants';

export const mapFlearningHistory = (payload: RoundHistoryResponse[typeof resultsKey]) =>
  payload.map(roundItem => ({ id: roundItem.id, roundNumber: roundItem.round_number }));

export const mapStartRoundData = (payload: TransformedAllianceModelsResponse | undefined) => {
  return {
    paramsData: {
      model: payload?.result[0].modelParams,
      dataset: payload?.result[0].datasetParams,
      train: payload?.result[0].trainParams,
      test: payload?.result[0].testParams,
    },
    modelId: payload?.result[0].id,
    weightsCount: payload?.result[0].weightsCount,
    modelName: payload?.result[0].name,
  };
};

export const mapFlearningRoundData = (
  response: RoundByIdResponse,
): RoundByIdResponseTransFormed => ({
  isClient: response.is_client,
  roundName: response.name,
  roundState: response.state,
  startDate: response.start_date,
  duration: response.duration,
  modelName: response.persistent_info.ml_model.name,
  modelTask: response.persistent_info.ml_model.task,
  weightName: response.initial_weight?.meta_data?.name || null,
  dockerImageName: response.docker_image_name,
  aggregatedWeight: response.aggregated_weight
    ? {
        id: response.aggregated_weight.id,
        name: response.aggregated_weight.meta_data.name,
        date: response.start_date,
        description: response.aggregated_weight.meta_data.description,
        params: {
          model: transformObjectToArray(response.aggregated_weight.meta_data.model_params),
          test: transformObjectToArray(response.aggregated_weight.meta_data.test_params),
          train: transformObjectToArray(response.aggregated_weight.meta_data.train_params),
        },
        size: response.aggregated_weight.meta_data.file_size,
      }
    : null,
  params: {
    model: transformObjectToArray(response.model_params),
    dataset: transformObjectToArray(response.dataset_params),
    test: transformObjectToArray(response.test_params),
    train: transformObjectToArray(response.train_params),
  },
  initialWeight: response.initial_weight
    ? {
        id: response.initial_weight.id,
        name: response.initial_weight.meta_data.name,
        date: '',
        description: response.initial_weight.meta_data.description,
        params: {
          model: transformObjectToArray(response.initial_weight.meta_data.model_params),
          test: transformObjectToArray(response.initial_weight.meta_data.test_params),
          train: transformObjectToArray(response.initial_weight.meta_data.train_params),
        },
        size: response.initial_weight.meta_data.file_size,
      }
    : null,
  isRoundFinished:
    response.state === 'round_has_finished' ||
    response.state === 'round_has_finished_without_aggregation',
  isRoundError:
    response.state === 'building_image_was_failed' ||
    response.state === 'generation_weights_was_failed' ||
    response.state === 'weights_aggregation_was_failed',
});
