import { MetricTag } from '../../../../common';
import { MetricTabsProps } from '../types';

import styles from './MetricsTabs.module.scss';

export const MetricsTabs = ({ activeId, metricsList, onClick }: MetricTabsProps) => {
  return (
    <ul className={styles.metricsTabs}>
      {metricsList.map(({ id, name }) => (
        <MetricTag
          metric={{ name, value: '' }}
          isReadOnly
          isNameOnly
          key={id}
          onSelect={() => onClick(id)}
          isSelected={id === activeId}
        />
      ))}
    </ul>
  );
};
