import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../hooks';
import { accountSelector, roleSelector } from '../../store';
import { Roles } from '../../store/types';
import { LOG_IN_PATH, HOME_PATH } from '../routeConstants';
import { EmployeesProvider } from '../../pages/EmployeesPage/EmployeesPageOnboarding/context';
import { TProtectedPage, TProtectedPages, TProtectedProps } from '../types';

export const AuthProtection: FC<TProtectedProps> = ({ protectedRoute }) => {
  const location = useLocation();
  const { isAuth } = useAppSelector(accountSelector);

  switch (isAuth) {
    case true:
      return protectedRoute;
    case null:
      return null;
    default:
      return <Navigate to={LOG_IN_PATH} replace state={{ from: location }} />;
  }
};

export const NewUserProtection: FC<TProtectedPages> = ({ protectedPage, publicPage }) => {
  const role = useAppSelector(roleSelector);

  switch (role) {
    case Roles.Unknown:
      return publicPage;
    case null:
      return null;
    default:
      return protectedPage;
  }
};

export const ContentProtection = () => {
  const location = useLocation();
  const role = useAppSelector(roleSelector);

  switch (role) {
    case Roles.Unknown:
      return <Navigate to={HOME_PATH} replace state={{ from: location }} />;
    case null:
      return null;
    default:
      return <Outlet />;
  }
};

export const BAProtection = () => {
  const location = useLocation();
  const role = useAppSelector(roleSelector);

  switch (role) {
    case Roles.BusinessAdministrator:
    case Roles.DeveloperBA:
      return (
        <EmployeesProvider>
          <Outlet />
        </EmployeesProvider>
      );
    case null:
      return null;
    default:
      return <Navigate to={HOME_PATH} replace state={{ from: location }} />;
  }
};

export const ProfileTabsProtection: FC<TProtectedPage> = ({ protectedPage, route }) => {
  const location = useLocation();
  const role = useAppSelector(roleSelector);
  switch (role) {
    case Roles.DeveloperBA:
      return protectedPage;
    case Roles.BusinessAdministrator:
      if (route === 'organization') return protectedPage;
      return <Navigate to={HOME_PATH} replace state={{ from: location }} />;
    case Roles.Developer:
      if (route === 'organization') {
        return <Navigate to={HOME_PATH} replace state={{ from: location }} />;
      }
      return protectedPage;
    default:
      return <Navigate to={HOME_PATH} replace state={{ from: location }} />;
  }
};

export const SystemAdminProtection = () => {
  const role = useAppSelector(roleSelector);

  switch (role) {
    case Roles.SystemAdministrator:
      return <Outlet />;
    case null:
      return null;
    default:
      return <Navigate to={HOME_PATH} />;
  }
};
