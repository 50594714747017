import cn from 'classnames';
import { SyntheticEvent } from 'react';

import { PlusIcon } from '../../../../assets/svg';

import styles from './Button.module.scss';

type PropsType = {
  children: string | JSX.Element;
  onClick?: () => void;
  id?: string;
  isDisabled?: boolean;
  isResizing?: boolean;
  description?: string;
  type?: 'button' | 'submit' | 'reset';
  style?: 'primary' | 'secondary' | 'canceled';
  size?: 'normal' | 'small';
  iconPosition?: 'left' | 'right';
  descrPosition?: 'center' | 'right';
};

export const Button = ({
  children,
  isDisabled,
  onClick,
  id,
  iconPosition,
  isResizing,
  description,
  descrPosition = 'center',
  type = 'button',
  style = 'primary',
  size = 'normal',
}: PropsType) => {
  const buttonClassName = cn(styles.button, {
    [styles.normal]: size === 'normal',
    [styles.small]: size === 'small',
    [styles.primary]: style === 'primary',
    [styles.secondary]: style === 'secondary',
    [styles.canceled]: style === 'canceled',
    [styles.resizing]: isResizing,
  });

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const IconDisplay = () => {
    switch (iconPosition) {
      case 'right':
        return (
          <>
            {children}
            {<PlusIcon />}
          </>
        );
      case 'left':
        return (
          <>
            {<PlusIcon />}
            {children}
          </>
        );
      default:
        return <>{children}</>;
    }
  };

  return (
    <button
      className={buttonClassName}
      disabled={isDisabled}
      onClick={handleClick}
      type={type}
      id={id}
      data-descr={description}
    >
      {IconDisplay()}
      {description && (
        <span
          className={cn(styles.description, descrPosition === 'right' && styles.descriptionRight)}
        >
          {description}
        </span>
      )}
    </button>
  );
};
