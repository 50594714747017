import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { getFormattedTickValue } from './getFormattedTickValue';

import { MetricChartItem } from '../types';

export const getMetricCharOptions = (metric: MetricChartItem) => {
  const { name, value } = metric;

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

  const options = {
    responsive: true,
    interaction: {
      includeInvisible: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        padding: {
          x: 12,
          y: 8,
        },
        cornerRadius: 4,
        caretSize: 6,
        caretPadding: 12,
        titleMarginBottom: 4,
        titleFont: {
          size: 14,
          weight: 'normal',
        },
        titleColor: 'rgba(105, 115, 129, 1)',
        footerColor: 'rgba(21, 23, 25, 1)',
        footerFont: {
          size: 16,
          weight: 'bold',
        },
        backgroundColor: 'rgba(255, 255, 255, 1)',
        callbacks: {
          title: (tooltipItems: any) => `${tooltipItems[0].dataset.label}`,
          footer: (tooltipItems: any) => {
            return new Intl.NumberFormat('ru', { maximumSignificantDigits: 5 }).format(
              tooltipItems[0].parsed.y,
            );
          },
          label: () => '',
        },
        shadowBlur: 3,
        shadowOffsetX: 3,
        shadowOffsetY: 10,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 1,
        suggestedMin: 0,
        margin: 0,
        padding: 0,
        color: '#DC3E22',
        border: { display: false },
        ticks: {
          callback: (value: unknown) => getFormattedTickValue(Number(value)),
          stepSize: 0.25,
          count: 5,
          padding: 32,
          font: {
            size: 12,
            lineHeight: 1.6,
          },
          color: '#697381',
        },
      },
      x: {
        display: false,
      },
    },
  };

  const data = {
    labels: value,
    datasets: [
      {
        fill: true,
        label: name,
        data: value,
        borderColor: '#0D70F2',
        backgroundColor: (context: any) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 375);
          gradient.addColorStop(0, 'rgba(45, 137, 239, 1)');
          gradient.addColorStop(0.6764, 'rgba(45, 137, 239, 0.25)');
          gradient.addColorStop(1, 'rgba(45, 137, 239, 0)');

          return gradient;
        },
        pointStyle: 'circle',
        pointRadius: 6,
        pointHoverRadius: 9,
        pointBackgroundColor: 'rgba(13, 112, 242, 1)',
        pointHoverBackgroundColor: 'rgba(8, 67, 145, 1)',
        pointBorderColor: 'rgba(255, 255, 255, 1)',
        pointHoverBorderColor: 'rgba(255, 255, 255, 1)',
        pointBorderWidth: 2,
        pointHoverBorderWidth: 6,
      },
    ],
  };

  return { data, options };
};
