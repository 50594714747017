import { AlliancesIcon } from '../../../assets/svg';
import { ModelTitleIcon } from '../../common';
import { PropTypes } from './types';

import styles from './TitleWithIcon.module.scss';

export const TitleWithIcon = ({
  title,
  subtitle,
  secondarySubtitle,
  to,
  isPaid = false,
  isJointMl = false,
}: PropTypes) => {
  const IconDisplay = () => {
    switch (to) {
      case 'alliance':
        return <AlliancesIcon />;
      case 'model':
        return <ModelTitleIcon isPaid={isPaid} isJointMl={isJointMl} />;
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.container}>
      {IconDisplay()}
      <div className={styles.title}>
        <h1>{title}</h1>
        {subtitle && (
          <div className={styles.info}>
            <p>{subtitle}</p>
            {secondarySubtitle && (
              <>
                <span className={styles.dot} />
                <p>{secondarySubtitle}</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
