export const DeactivateIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12.0004 18.8556C19.3235 18.9753 21.6004 12.119 21.6004 12.119C21.6004 12.119 19.4158 5.14282 12.0004 5.14282C4.58501 5.14282 2.40039 12.119 2.40039 12.119C2.40039 12.119 4.67731 18.7358 12.0004 18.8556Z"
      stroke="currentColor"
    />
    <path
      d="M14.3996 12.0292C14.3996 13.319 13.3251 14.3646 11.9996 14.3646C10.6741 14.3646 9.59961 13.319 9.59961 12.0292C9.59961 10.7394 10.6741 9.69385 11.9996 9.69385C13.3251 9.69385 14.3996 10.7394 14.3996 12.0292Z"
      stroke="currentColor"
    />
  </svg>
);
