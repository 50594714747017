import { useTranslation } from 'react-i18next';

import { Button } from '../../../../../ui';
import { ProjectIcon } from '../../../../../assets/svg';

import styles from './AddProjectCard.module.scss';

export const AddProjectCard = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <span className={styles.icon}>
        <ProjectIcon />
      </span>
      <h1 className={styles.title}>{t('pages.portfolioPage.addProjectCard.title')}</h1>
      <div className={styles.text}>{t('pages.portfolioPage.addProjectCard.text')}</div>
      <Button onClick={onClick}>{t('buttons.add')}</Button>
    </div>
  );
};
