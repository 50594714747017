import { Step } from 'react-joyride';

import { ParamType } from '../../ui/components/Params/types';

export interface IAlliance {
  id: number;
  owner: OwnerData;
  industry: string;
  members: number;
  attachments: AttachmentsType[];
  applicationId: number;
  date: string;
  name: string;
  description: string;
  isClosed: boolean;
  isActive: boolean;
  isHidden: boolean;
  isFavorite: boolean;
  isPaidModel?: boolean;
  isJointMl: boolean;
  notAllMembersSubscribed: boolean;
  role: string;
  currentRound: {
    state: string;
    id: number;
  } | null;
  modelId?: number;
}

export type OwnerData = {
  id: number;
  fullName: string;
  position: string;
  companyName: string;
  INN: string;
  industry: string;
  email: string;
};

export enum RoleInAlliance {
  Owner = 'is_owner',
  Member = 'is_member',
  Waiting = 'waiting_to_approve',
  Banned = 'banned',
  Outside = 'out_of_alliance',
  NotAllowed = 'not_allowed',
  Consider = 'consider_invitation',
  NotFound = 'not_found',
  DeactivatedByOwner = 'deactivated_by_owner',
}

export type AttachmentsType = {
  name: string;
  id: number;
  type: string;
  size: number;
  uuid: string;
};

export interface IAllianceModel {
  id: number;
  type: string;
  task: string;
  name: string;
  weightsCount: number;
  isPaid: boolean;
  isJointMl: boolean;
  hasFl: boolean;
}

export interface IAllianceModelFL extends IAllianceModel {
  modelParams: ParamType[];
  datasetParams: ParamType[];
  testParams: ParamType[];
  trainParams: ParamType[];
}

export interface IMember {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  position: string;
  company: string;
  inn: string;
  industry: string;
  id: number;
  isComment: boolean;
  isBanned: boolean;
  state: string;
  startDate: string;
  currentDate: string;
  isAdmin: boolean;
}

export interface IAllianceModelResponse {
  id: number;
  userName: string;
  modelName: string;
  modelId: number;
  date: string;
  isValid: boolean;
  state?: 'approved_by_owner' | 'rejected_by_owner' | undefined;
}
export interface IAllianceWeightResponse extends IAllianceModelResponse {
  weightName: string;
}

export type UserControlsProps = {
  id: number;
  applicationId: number;
  role: string;
  name: string;
  industry: string;
  isFavorite: boolean;
  currentRound: {
    state: string;
    id: number;
  } | null;
};

export type AdminControlsProps = {
  alliance: IAlliance;
};

export type AllianceModelWithRoleProps = {
  role: string;
  allianceName: string;
  allianceId: number;
  isAllianceHidden: boolean;
  date: string;
  members: number;
  currentRound: {
    state: string;
    id: number;
  } | null;
};

export type RoundType = {
  state: string;
  id: number;
} | null;

export interface GuestState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
}
