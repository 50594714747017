import { createSlice } from '@reduxjs/toolkit';
import { AccountType } from '../types';

const initialState: AccountType = {
  isAuth: null,
  email: null,
  isConfirmed: null,
  id: null,
  isCookieAccepted: false,
  isStaff: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount(state, action) {
      state.isAuth = action.payload.isAuth;
      state.email = action.payload.email;
      state.isConfirmed = action.payload.confirmed;
      state.id = action.payload.id;
      state.isStaff = action.payload.isStaff;
    },
    checkCookie(state, action) {
      state.isCookieAccepted = action.payload;
    },
  },
});

export const { setAccount, checkCookie } = accountSlice.actions;
export default accountSlice.reducer;
