import styles from './Checkbox.module.scss';

type PropsType = {
  isChecked: boolean;
  id: string;
};

export const Checkbox = ({ isChecked, id }: PropsType) => {
  return (
    <div className={styles.checkbox} id={id}>
      <span className={!isChecked ? styles.input : styles.inputChecked} id={id} />
      <span className={styles.square} id={id} />
    </div>
  );
};
