export const QuestionIcon = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path
      d="M10.6 15.4V15.4422M8.20104 7.64533C8.20104 6.29481 9.27556 5.2 10.601 5.2C11.9265 5.2 13.001 6.29481 13.001 7.64533C13.001 8.99584 11.9265 10.0907 10.601 10.0907C10.601 10.0907 10.6 10.8205 10.6 11.7209M20.2 4.6L20.2 16.6001C20.2 18.5883 18.5882 20.2001 16.6 20.2001H4.6C2.61177 20.2001 1 18.5883 1 16.6001V4.6C1 2.61178 2.61177 1 4.6 1H16.6C18.5882 1 20.2 2.61177 20.2 4.6Z"
      stroke="#F6F7FA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
