import { lazy } from 'react';
import {
  ALLIANCE_EMPLOYEE_TABS,
  ALLIANCE_FL_TABS,
  ALLIANCE_MODEL_TABS,
  ALLIANCE_OWNER_TABS,
  EDIT,
  MODEL_PATH,
} from '../routeConstants';
import { suspense } from '../HOC/suspense';
import { Outlet } from 'react-router-dom';
import { modelRoutes } from './modelRoutes';

// TOP LEVEL
const AlliancePage = lazy(() => import('../../pages/AlliancePage/AlliancePage'));
const EditAlliancePage = lazy(() => import('../../pages/EditAlliancePage/EditAlliancePage'));
// const NewAlliancePage = lazy(() => import('../../pages/NewAlliancePage/NewAlliancePage'));
// ALLIANCE
const AllianceModel = lazy(() => import('../../pages/AlliancePage/AllianceModel/AllianceModel'));
const AllianceInfo = lazy(() => import('../../pages/AlliancePage/AllianceInfo/AllianceInfo'));
const AllianceEmployee = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/AllianceEmployee'),
);
const AllianceFL = lazy(() => import('../../pages/AlliancePage/AllianceFL/AllianceFL'));
// Model
const ModelsRequestsTableAdministrator = lazy(
  () =>
    import(
      '../../pages/AlliancePage/AllianceModel/AllianceModelAdministrator/ModelRequestsTable/ModelsRequestsTable'
    ),
);
const ModelTableAdministrator = lazy(
  () =>
    import(
      '../../pages/AlliancePage/AllianceModel/AllianceModelAdministrator/ModelTable/ModelTable'
    ),
);
const WeightsRequestsTableAdministrator = lazy(
  () =>
    import(
      '../../pages/AlliancePage/AllianceModel/AllianceModelAdministrator/WeightsRequestsTable/WeightsRequestsTable'
    ),
);
// FL
const CurrentRound = lazy(
  () => import('../../pages/AlliancePage/AllianceFL/CurrentRound/CurrentRound'),
);
const RoundsHistory = lazy(
  () => import('../../pages/AlliancePage/AllianceFL/RoundsHistory/RoundsHistory'),
);
// Employee
const MembersTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/MembersTable/MembersTable'),
);
const MembersRequestsTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/RequestsTable/RequestsTable'),
);
const MembersInvitationsTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/InvitationsTable/InvitationsTable'),
);
const BannedTable = lazy(
  () => import('../../pages/AlliancePage/AllianceEmployees/BannedTable/BannedTable'),
);

const { ALLIANCE_MODEL, INFO, MEMBERS, FL } = ALLIANCE_OWNER_TABS;
const { SUB_MODEL, ML_REQUESTS, WEIGHT_REQUESTS } = ALLIANCE_MODEL_TABS;
const { ALL, JOIN_REQUESTS, JOIN_INVITATIONS, BLOCKED } = ALLIANCE_EMPLOYEE_TABS;
const { CURRENT, HISTORY } = ALLIANCE_FL_TABS;

export const adminAllianceRoutes = [
  {
    path: ':id',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: suspense(<AlliancePage />),
        children: [
          {
            path: ALLIANCE_MODEL,
            element: suspense(<AllianceModel />),
            children: [
              {
                index: true,
                element: suspense(<ModelTableAdministrator />),
              },
              {
                path: SUB_MODEL,
                element: suspense(<ModelTableAdministrator />),
              },
              {
                path: ML_REQUESTS,
                element: suspense(<ModelsRequestsTableAdministrator />),
              },
              {
                path: WEIGHT_REQUESTS,
                element: suspense(<WeightsRequestsTableAdministrator />),
              },
            ],
          },
          {
            path: INFO,
            element: suspense(<AllianceInfo />),
          },
          {
            path: MEMBERS,
            element: suspense(<AllianceEmployee />),
            children: [
              {
                index: true,
                element: suspense(<MembersTable />),
              },
              {
                path: ALL,
                element: suspense(<MembersTable />),
              },
              {
                path: JOIN_REQUESTS,
                element: suspense(<MembersRequestsTable />),
              },
              {
                path: JOIN_INVITATIONS,
                element: suspense(<MembersInvitationsTable />),
              },
              {
                path: BLOCKED,
                element: suspense(<BannedTable />),
              },
            ],
          },
          {
            path: FL,
            element: suspense(<AllianceFL />),
            children: [
              {
                index: true,
                element: suspense(<CurrentRound />),
              },
              {
                path: CURRENT,
                element: suspense(<CurrentRound />),
              },
              {
                path: HISTORY,
                element: suspense(<RoundsHistory />),
              },
            ],
          },
        ],
      },
      {
        path: EDIT,
        element: suspense(<EditAlliancePage />),
      },
      {
        path: MODEL_PATH,
        element: <Outlet />,
        children: [...modelRoutes],
      },
    ],
  },
];
