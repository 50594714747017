export const BaIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3.7 15.263H3.69868L3.70129 15.2743C4.08012 16.9093 5.40329 18.4776 7.02701 19.6929C8.6515 20.9088 10.5856 21.778 12.1987 22.0095L12.2063 22.0106L12.2139 22.0093C13.6207 21.7777 15.5764 20.9087 17.2626 19.6934C18.9476 18.4792 20.3759 16.9104 20.7105 15.273L20.7115 15.2733V15.263V4.71024C20.7115 4.59741 20.6737 4.4995 20.6039 4.42967C20.534 4.35984 20.4361 4.32201 20.3233 4.32201C17.3936 4.32201 14.5989 3.21089 12.4774 1.19067C12.3228 1.03644 12.0888 1.03644 11.9341 1.19067C9.81264 3.21089 7.01788 4.32201 4.08823 4.32201C3.9754 4.32201 3.87749 4.35984 3.80766 4.42967C3.73783 4.4995 3.7 4.59741 3.7 4.71024V15.263ZM19.9351 5.09665V15.1849C19.5655 16.0223 19.0624 16.8265 18.4925 17.5307L17.9866 18.104C16.3648 19.7681 14.4049 20.6306 12.2058 21.2317C10.0221 20.6351 8.01698 19.8158 6.42558 18.1386L5.91971 17.5315C5.91964 17.5315 5.91956 17.5314 5.91949 17.5313C5.34879 16.8263 4.84586 16.0554 4.47646 15.1852V5.09721C7.33122 5.01826 10.0501 3.9101 12.2058 2.0036C14.3619 3.91016 17.0809 4.98501 19.9351 5.09665Z"
      fill="#0D70F2"
      stroke="#0D70F2"
      strokeWidth="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3882 18.1718C7.16613 15.6012 9.53375 13.8424 12.2058 13.8424C14.8778 13.8424 17.2454 15.6012 18.0233 18.138L18.5307 17.563C17.7527 15.4998 16.0278 13.9777 13.9646 13.4027C15.1484 12.7601 15.9263 11.5425 15.9263 10.1219C15.9263 8.0587 14.269 6.40137 12.2058 6.40137C10.1426 6.40137 8.48523 8.0587 8.48523 10.1219C8.48523 11.5425 9.29699 12.7601 10.447 13.4027C8.34994 13.9777 6.62497 15.4998 5.88086 17.563L6.3882 18.1718ZM9.16169 10.1219C9.16169 11.8131 10.5146 13.166 12.2058 13.166C13.8969 13.166 15.2498 11.8131 15.2498 10.1219C15.2498 8.43075 13.8969 7.07783 12.2058 7.07783C10.5146 7.07783 9.16169 8.43075 9.16169 10.1219Z"
      fill="#0D70F2"
    />
  </svg>
);
