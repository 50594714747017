import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { EditWeightRequest } from '../../../../api/models/types';
import { modelsApi } from '../../../../api/ModelsApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { weightSchema } from '../../../../schemas/weightSchema';
import { isAdminSelector, setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { Button, FormUpload, InfoBlock, Input, TextArea } from '../../../../ui';
import { PropsType } from './types';
import { ParamsTypes } from '../../Params/types';
import { Params } from '../../Params/Params';
import { MetricsForm } from './MetricsForm/MetricsForm';
import { adminModelApi } from '../../../../api/adminModelApi';

import styles from './AddWeightEditWeightPopUp.module.scss';

export const AddWeightEditWeightPopUp = ({
  defaultData = { name: null, description: null },
  paramsData = { model: [], train: [], test: [] },
  metricsData = [],
  weightId = null,
  modelId = null,
  modelName = null,
  allianceId = null,
  fileData,
  onClose,
  onBack,
  isOwner,
  isPending = false,
}: PropsType) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    watch,
    setValue,
    setError,
    setFocus,
  } = useForm({
    defaultValues: defaultData,
    resolver: yupResolver(weightSchema),
    mode: 'onTouched',
  });
  const [file, setFile] = useState<File[]>(fileData ? [fileData] : []);
  const [isfileChanged, setIsFileChanged] = useState<boolean>(false);
  const [params, setParams] = useState<ParamsTypes>(paramsData);
  const [description, name] = watch(['description', 'name']);
  const [metrics, setMetrics] = useState<{ name: string; value: string }[]>(metricsData);
  const isSubmitDisabled = !isValid || !file.length;
  const isAdmin = useAppSelector(isAdminSelector);
  const [editWeight] = isAdmin
    ? adminModelApi.usePatchEditWeightAsAdminMutation()
    : modelsApi.usePatchEditWeightMutation();
  const [addWeightByOwner] = modelsApi.usePostAddWeightByOwnerMutation();
  const [addWeightByUser] = modelsApi.usePostAddWeightByUserMutation();
  const [addWeightByAdmin] = adminModelApi.usePostAddWeightAsAdminMutation();

  useEffect(() => {
    name && setValue('name', name.replace(/\s+/g, ' '));
    if (name === ' ') {
      setValue('name', '');
    }
    if (description === ' ') {
      setValue('description', '');
    }
  }, [name, description, metrics]);

  const onSubmit: SubmitHandler<FieldValues> = ({ name, description }) => {
    const addWeight = isAdmin ? addWeightByAdmin : addWeightByOwner;

    if (modelId && (isOwner || isAdmin)) {
      addWeight({ data: { name, description, params, file, metrics }, modelId })
        .unwrap()
        .then(() => {
          onClose();
          dispatch(
            setNotification({
              type: allianceId
                ? NotificationTypes.WeightAddedToModelInAlliance
                : NotificationTypes.WeightAddedToModel,
              data: {
                weightName: name,
                modelName,
              },
            }),
          );
        })
        .catch(({ status }) => {
          if (status === 400) {
            setError('name', {
              type: 'custom',
              message: t('popups.addWeightEditWeight.nameError'),
            });
            setFocus('name');
          }
        });
    }

    if (modelId && !isOwner && !isAdmin) {
      addWeightByUser({ data: { name, description, params, file, metrics }, allianceId, modelId })
        .unwrap()
        .then(() => {
          onClose();
          dispatch(
            setNotification({
              type: NotificationTypes.AddWeightToModelRequest,
              data: {
                weightName: name,
              },
            }),
          );
        });
    }

    if (!modelId) {
      const patchData: EditWeightRequest['data'] = { description, params, metrics };
      if (isfileChanged) {
        patchData.file = file;
      }
      if (name !== defaultData.name) {
        patchData.name = name;
      }
      editWeight({ data: patchData, weightId })
        .unwrap()
        .then(() => onClose())
        .catch(({ status }) => {
          if (status === 400) {
            setError('name', {
              type: 'custom',
              message: t('popups.addWeightEditWeight.nameError'),
            });
          }
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        {weightId
          ? t('popups.addWeightEditWeight.editTitle', { weightName: defaultData.name })
          : t('popups.addWeightEditWeight.addTitle', { modelName })}
      </h2>
      {!isOwner && !isAdmin && (
        <div className={styles.warnInfo}>
          <InfoBlock text={t('popups.addWeightEditWeight.warnInfo')} />
        </div>
      )}
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formWrapper}>
          <div className={styles.inputsWrapper}>
            <Input
              id="name"
              label={t('popups.addWeightEditWeight.name')}
              register={register}
              helperText={errors.name?.message}
              maxLength={20}
              isRequired
            />
            <div className={styles.textAreaWrapper}>
              <TextArea
                id="description"
                label={t('popups.addWeightEditWeight.descriptionLabel')}
                register={register}
                helperText={errors.description?.message}
                value={description}
                maxLength={50}
                isRequired
              />
            </div>
          </div>
          <FormUpload
            files={file}
            setFiles={setFile}
            onTrigger={() => setIsFileChanged(true)}
            type="weight"
            isFreeze={isPending}
          />
          <MetricsForm metricsData={metrics} setMetricsData={setMetrics} />
          <Params
            title={t('popups.addWeightEditWeight.paramsTitle')}
            info={t('popups.addWeightEditWeight.paramsInfo')}
            params={params}
            isReadOnly={false}
            setParams={setParams}
          />
        </div>
        <div className={styles.btnsWrapper}>
          <Button style="secondary" size="small" onClick={onBack || onClose}>
            {onBack ? t('buttons.back') : t('buttons.cancel')}
          </Button>
          <Button type="submit" isDisabled={isSubmitDisabled} size="small">
            {weightId ? t('buttons.save') : t('buttons.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};
