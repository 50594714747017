export const ProvidedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.30078 14.9998V4.4248H4.23578L8.16212 11.4568L11.3608 4.4248H13.2658V14.9998H11.1508V8.54981C11.1508 8.54981 10.4898 10.0308 10.0663 10.9798C9.64282 11.9288 8.98187 13.4098 8.98187 13.4098H8.07282H7.20929C7.20929 13.4098 5.59838 10.4752 4.40078 8.59481V14.9998H2.30078Z"
      fill="#0D70F2"
    />
    <path d="M14.0604 14.9998V4.4248H16.3854V13.0348H21.2754V14.9998H14.0604Z" fill="#0D70F2" />
    <path
      d="M2.29297 13.2998L4.40285 8.59473L4.57743 13.8448C4.74063 15.844 5.88303 16.8436 8.00463 16.8436H21.806V19.8463L8.00463 19.9954C6.14823 19.9954 4.71003 19.516 3.69003 18.5572C2.67003 17.5984 2.4347 16.9538 2.3123 15.077L2.29297 13.2998Z"
      fill="#031630"
    />
  </svg>
);
