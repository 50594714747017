import { Trans, useTranslation } from 'react-i18next';

import { useNavigation } from '../../../../hooks';
import { text, button } from './constants';
import { Button, InfoBlock } from '../../../../ui';
import { PopupTypes } from '../../../../store/types';
import { PropTypes } from './types';

import styles from './ConfirmPopup.module.scss';

export const ConfirmPopup = ({ type, data, onConfirm, onCancel, onClose }: PropTypes) => {
  const { t } = useTranslation();
  const { onNavigate } = useNavigation();
  let title = '';
  let btnType: 'primary' | 'secondary' | 'canceled' = 'primary';
  let btnText = '';
  let cancelText = button.cancel;
  let info = null;
  const isClosingAllowed = Boolean(!data.link);

  const handleConfirm = () => {
    onConfirm();
    isClosingAllowed && onClose();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  const handleNavigate = () => {
    onNavigate(data.link ?? '', true);
    handleCancel();
  };

  switch (type) {
    case PopupTypes.DeleteAllianceML:
      title = text.deleteAllianceML.title;
      info = (
        <p className={styles.text}>
          {text.deleteAllianceML.prev}
          <span>{data.model}</span>
          {text.deleteAllianceML.middle}
          <span>{data.alliance}</span>
          {text.deleteAllianceML.next}
        </p>
      );
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.DeleteML:
      title = text.deleteML.title;
      info = (
        <p className={styles.text}>
          {text.deleteML.prev}
          <span>{data.model}</span>
          {text.deleteML.next}
        </p>
      );
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.DeleteWeight:
      title = text.deleteWeight.title;
      info = (
        <p className={styles.text}>
          {text.deleteWeight.prev}
          <span>{data.weight}</span>
          {text.deleteWeight.middle}
          <span>{data.model}</span>
          {text.deleteWeight.next}
        </p>
      );
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.DeleteAttachment:
      title = text.deleteAttachment.title;
      info = (
        <p className={styles.text}>
          {text.deleteAttachment.prev}
          <span>{data.file}</span>
          {data.type === 'alliance' ? text.deleteAttachment.alliance : text.deleteAttachment.model}
          <span>{data.name}</span>
          {text.deleteAttachment.next}
        </p>
      );
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.Logout:
      title = text.logout.title;
      info = (
        <p className={styles.text}>
          {text.logout.prev}
          <span>{data.firstName} </span>
          <span>{data.email}</span>
          {text.logout.next}
        </p>
      );
      btnText = button.leave;
      btnType = 'canceled';
      break;
    case PopupTypes.LeaveClose:
      title = text.leaveClose.title;
      info = (
        <p className={styles.text}>
          {text.leaveClose.prev}
          <span>{data.alliance}</span>
          {text.leaveClose.next}
        </p>
      );
      btnText = button.leave;
      btnType = 'canceled';
      break;
    case PopupTypes.LeaveOpen:
      title = text.leaveOpen.title;
      info = (
        <p className={styles.text}>
          {text.leaveOpen.prev}
          <span>{data.alliance}</span>?
        </p>
      );
      btnText = button.leave;
      btnType = 'canceled';
      break;
    case PopupTypes.BaConfirm:
      title = text.baConfirm.title;
      info = (
        <>
          <div className={styles.info}>
            <InfoBlock
              text={`${text.baConfirm.info.prev} «${data.company}» ${text.baConfirm.info.next}`}
              style="secondary"
            />
          </div>
          <p className={styles.text}>
            {text.baConfirm.prev} <p className={styles.text}>{text.baConfirm.next}</p>
          </p>
        </>
      );
      btnText = button.yes;
      btnType = 'primary';
      cancelText = button.back;
      break;
    case PopupTypes.CancelOrganizationAdd:
      title = text.cancelOrganizationAdd.title;
      info = <p className={styles.text}>{text.cancelOrganizationAdd.subtitle}</p>;
      btnText = button.confirm;
      btnType = 'primary';
      cancelText = button.back;
      break;
    case PopupTypes.CancelDataEdit:
      title = text.cancelDataEdit.title;
      info = <p className={styles.text}>{text.cancelDataEdit.subtitle}</p>;
      btnText = button.continue;
      btnType = 'primary';
      cancelText = button.back;
      break;
    case PopupTypes.DeleteProject:
      title = `${text.deleteProject.title} «${data?.name ?? ''}»?`;
      info = <p className={styles.text}>{text.deleteProject.subtitle}</p>;
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.DeactivateAlliance:
      title = `${text.deactivateAlliance.title}`;
      info = (
        <p className={styles.text}>
          {text.deactivateAlliance.subtitle.prev}
          <span>{data.name}</span>
          {text.deactivateAlliance.subtitle.next}
        </p>
      );
      btnText = button.deactivate;
      btnType = 'canceled';
      break;
    case PopupTypes.DeleteAlliance:
      title = `${text.deleteAlliance.title}`;
      info = (
        <p className={styles.text}>
          {text.deleteAlliance.subtitle.prev}
          <span>{data.name}</span>
          {text.deleteAlliance.subtitle.next}
        </p>
      );
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.DeleteFLFile:
      title = `${text.deleteFLFile.title}`;
      info = <p className={styles.text}>{text.deleteFLFile.subtitle}</p>;
      btnText = button.delete;
      btnType = 'canceled';
      break;
    case PopupTypes.StartPartialAggregation:
      title = t('popups.startPartialAggregation.title');
      info = (
        <p className={styles.text}>
          {t('popups.startPartialAggregation.text', { roundName: data.roundName })}
        </p>
      );
      btnText = t('buttons.continue');
      btnType = 'primary';
      cancelText = t('buttons.back');
      break;
    case PopupTypes.PaidFLWarning:
      title = t('popups.flPaidWarning.title');
      info = (
        <div className={styles.text}>
          <p>{t('popups.flPaidWarning.text.0')}</p>
          <p>
            <Trans
              i18nKey="popups.flPaidWarning.text.1"
              components={{
                link1: <button className={styles.link} onClick={handleNavigate} />,
              }}
            />
          </p>
        </div>
      );
      btnText = t('buttons.continue');
      btnType = 'primary';
      cancelText = t('buttons.back');
      break;
    case PopupTypes.DockerImageSubscriptionExpiredSoon:
      title = t('popups.dockerImageSubscriptionExpiredSoon.title');
      info = (
        <p className={styles.text}>
          <Trans
            i18nKey="popups.dockerImageSubscriptionExpiredSoon.info"
            components={{
              button: <button className={styles.link} onClick={handleNavigate} />,
            }}
          />
        </p>
      );
      btnText = t('buttons.continue');
      btnType = 'primary';
      cancelText = t('buttons.cancel');
      break;
    case PopupTypes.ExcludePopup:
      title = t('popups.exclude.title');
      info = (
        <p className={styles.text}>
          {t('popups.exclude.subtitle', { allianceName: data.alliance, userName: data.name })}
        </p>
      );
      btnText = t('buttons.confirm');
      btnType = 'primary';
      cancelText = t('buttons.cancel');
      break;
    default:
      title = `${text.verification.title} ${data?.counter ?? ''}?`;
      info = <p className={styles.text}>{text.verification.subtitle}</p>;
      btnText = button.verify;
      btnType = 'primary';
      cancelText = button.back;
      break;
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      {info}
      <div className={styles.buttons}>
        <Button style="secondary" size="small" onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button style={btnType} size="small" onClick={handleConfirm}>
          {btnText}
        </Button>
      </div>
    </div>
  );
};
