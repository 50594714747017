import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDirectoryCounter } from '../api/directory';
import { roleSelector, updateCounter } from '../store';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { Roles } from '../store/types';

export const useMenuCounterUpdate = (path: string) => {
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const role = useAppSelector(roleSelector);

  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const fetchDirectoryCount = async () => {
    const { payload } = await getDirectoryCounter();
    dispatch(updateCounter(payload.count));
  };

  useEffect(() => {
    if (isUpdate && pathname !== path) {
      fetchDirectoryCount();
      setIsUpdate(false);
    }
  }, [isUpdate, pathname]);

  useEffect(() => {
    if (pathname === path) {
      setIsUpdate(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (role && role !== Roles.Unknown) {
      fetchDirectoryCount();
    }
  }, [role]);
};
