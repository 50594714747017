import { createSlice } from '@reduxjs/toolkit';
import { MenuCounter } from '../types';

const initialState: MenuCounter = {
  directory: 0,
};

const menuCounterSlice = createSlice({
  name: 'menuCounter',
  initialState,
  reducers: {
    updateCounter(state, action) {
      state.directory = action.payload;
    },
    clearCounter(state) {
      state.directory = 0;
    },
  },
});

export const { clearCounter, updateCounter } = menuCounterSlice.actions;
export default menuCounterSlice.reducer;
