import { SyntheticEvent, useEffect, useState } from 'react';

import { ILink } from '../types';
import { errors } from './constants';

export const useValidation = (defaultValues: ILink) => {
  const [values, setValues] = useState<ILink>(defaultValues);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const handleChange = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setValues({ ...values, [name]: value.replace(/\s+/g, ' ') });
  };

  useEffect(() => {
    if (values.link.length === 0) {
      setErrorText(null);
      setIsValid(false);
      return;
    } else if (
      // eslint-disable-next-line
      !/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
        values.link,
      )
    ) {
      setErrorText(errors.validation);
      setIsValid(false);
    } else if (values.link.length < 10) {
      setErrorText(errors.length);
      setIsValid(false);
    } else {
      setErrorText(null);
      setIsValid(true);
    }
  }, [values]);

  return { values, errorText, isValid, handleChange };
};
