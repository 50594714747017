import styles from './Loader.module.scss';

export const Loader = () => (
  <div className={styles.container}>
    <div className={styles.item}>
      <span className={styles.big} />
      <span className={styles.medium} />
      <span className={styles.small} />
    </div>
  </div>
);
