import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { LOG_IN_PATH } from '../../../../../router';
import { Button } from '../../../../../ui';

import styles from '../../Form.module.scss';

interface PropsType {
  userName: string;
}

export const SuccessNotice = ({ userName }: PropsType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/${LOG_IN_PATH}`);
  };

  return (
    <div className={styles.notice}>
      <h2 className={styles.title}>{t('pages.authPage.successNotification.title')}</h2>
      <div className={cn(styles.subtitle, styles.subtitleBright, styles.successContainer)}>
        {t('pages.authPage.successNotification.subtitle', { userName })}
      </div>
      <Button onClick={handleClick}>{t('buttons.enter')}</Button>
    </div>
  );
};
