import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { PlusIcon } from '../../../../../assets/svg';
import { MetricTag, TextButton } from '../../../../common';
import { metricsSchema } from '../../../../../schemas/weightSchema';
import { EditForm } from './EditForm/EditForm';
import { ParamType as MetricType } from '../../../Params/types';

import styles from './MetricsForm.module.scss';

type PropsType = {
  metricsData: MetricType[];
  setMetricsData: Dispatch<SetStateAction<MetricType[]>>;
};

type TargetMetricType = {
  metricName: string;
  metricValue: string;
  id: number;
};

export const MetricsForm = ({ metricsData, setMetricsData }: PropsType) => {
  const [isFormShown, setIsFormShown] = useState<boolean>(false);
  const [isEditFormShown, setIsEditFormShown] = useState<boolean>(false);
  const [editTarget, setEditTarget] = useState<TargetMetricType>({
    metricName: '',
    metricValue: '',
    id: -1,
  });
  const [nameValue, setNameValue] = useState<string | null>(null);
  const {
    register,
    reset,
    watch,
    getValues,
    getFieldState,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(metricsSchema),
    mode: 'onChange',
  });
  const { ref: nameRef, ...nameRest } = register('metricName');
  const { ref: valueRef, ...valueRest } = register('metricValue');
  const { t } = useTranslation();
  const defaultProps = {
    options: t('popups.weight.metricsForm.options', { returnObjects: true }) as string[],
  };
  const [metricValue] = watch(['metricValue']);

  const handleReset = () => {
    setIsFormShown(false);
    setIsEditFormShown(false);
    reset();
    setNameValue('');
  };

  const handleSubmit = async () => {
    if (metricsData.find((metric: MetricType) => metric.name === getValues('metricName'))) {
      const metricsDataCopy = [...metricsData];
      metricsDataCopy[
        metricsDataCopy.findIndex(metric => metric.name === getValues('metricName'))
      ].value = getValues('metricValue');
    } else {
      setMetricsData([
        ...metricsData,
        { name: getValues('metricName'), value: getValues('metricValue') },
      ]);
    }
    handleReset();
  };

  const handleDeleteTag = (id: number) => {
    const target = metricsData[id];
    setMetricsData(metricsData.filter((metric: MetricType) => metric.name !== target.name));
    setIsEditFormShown(false);
  };

  const handleEditTag = (metric: MetricType, id: number) => {
    setIsEditFormShown(true);
    setIsFormShown(false);
    setEditTarget({ metricName: metric.name, metricValue: metric.value, id });
  };

  const handleEditSubmit = (newMetric: MetricType, id: number) => {
    const metricsCopy = [...metricsData];
    metricsCopy[id] = { name: newMetric.name, value: newMetric.value };
    setMetricsData(metricsCopy);
    handleReset();
  };

  useEffect(() => {
    if (getFieldState('metricName').isTouched && !nameValue) {
      setError('metricName', {
        type: 'custom',
        message: 'pages.modelFormPage.modelNameError',
      });
    }
  }, [nameValue]);

  const isValid = Boolean(!Object.keys(errors).length && nameValue && metricValue);

  return (
    <div className={styles.metricsWrapper}>
      <h2 className={styles.metricsTitle}>{t('popups.weight.metricsForm.title')}</h2>
      {metricsData.length > 0 && (
        <div className={styles.metricTags}>
          {metricsData.map((metric: MetricType, id: number) => (
            <MetricTag
              metric={{ name: metric.name, value: metric.value }}
              isReadOnly={false}
              key={metric.name}
              onDelete={() => handleDeleteTag(id)}
              onEdit={() => handleEditTag(metric, id)}
              isDisabled={isEditFormShown && id !== editTarget.id}
            />
          ))}
        </div>
      )}
      {isEditFormShown && (
        <EditForm
          defaultValues={{ metricName: editTarget.metricName, metricValue: editTarget.metricValue }}
          onCancelClick={() => setIsEditFormShown(false)}
          id={editTarget.id}
          onSubmitClick={handleEditSubmit}
        />
      )}
      <div className={styles.metricsForm}>
        {isFormShown && (
          <div className={styles.form}>
            <div className={styles.inputsWrapper}>
              <Autocomplete
                {...defaultProps}
                id="metricName"
                freeSolo
                inputValue={nameValue || ''}
                onChange={(event: SyntheticEvent, newValue: string | null) => {
                  setNameValue(newValue?.slice(0, 50) || '');
                }}
                onInputChange={(event: SyntheticEvent, newValue: string | null) => {
                  setNameValue(newValue?.slice(0, 50) || '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={
                      <span className={styles.styledLabel}>
                        {t('popups.weight.metricsForm.nameLabel')} <span>*</span>
                      </span>
                    }
                    variant="standard"
                    inputRef={nameRef}
                    {...nameRest}
                    error={Boolean(errors.metricName)}
                    helperText={errors.metricName ? String(errors.metricName?.message) : ''}
                  />
                )}
              />
              <TextField
                id="metricValue"
                label={
                  <span className={styles.styledLabel}>
                    {t('popups.weight.metricsForm.valueLabel')} <span>*</span>
                  </span>
                }
                variant="standard"
                inputRef={valueRef}
                inputProps={{ maxLength: 50 }}
                {...valueRest}
                error={Boolean(errors.metricValue)}
                helperText={errors.metricValue ? String(errors.metricValue?.message) : ''}
              />
            </div>
            <div className={styles.buttonsWrapper}>
              <TextButton onClick={handleReset}>{t('buttons.cancel')}</TextButton>
              <TextButton isDisabled={!isValid} onClick={!isValid ? () => null : handleSubmit}>
                {t('buttons.add')}
              </TextButton>
            </div>
          </div>
        )}
        {!isFormShown && !isEditFormShown && (
          <TextButton
            iconProperty={{ icon: <PlusIcon />, position: 'left' }}
            onClick={() => setIsFormShown(true)}
          >
            {t('popups.weight.metricsForm.addButton')}
          </TextButton>
        )}
      </div>
    </div>
  );
};
