import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useClickOutside } from '../../../../hooks';
import { isArraysEqual } from '../../../../utils';
import { FilterButton, TextButton, Button } from '../../../common';
import { ListItem } from './ListItem/ListItem';
import { IList, PropTypes } from './types';

import styles from './ListsFilter.module.scss';

export const ListsFilter = ({
  name,
  id,
  list,
  appliedFilters,
  onActionFilters,
  resetTrigger,
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<IList[]>(appliedFilters);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const { t } = useTranslation();

  useClickOutside([name], () => setIsOpen(false));

  const handleSelect = (list: IList) => {
    if (selectedList.some(selected => selected.name === list.name)) {
      setSelectedList(selectedList.filter(selected => selected.name !== list.name));
    } else {
      setSelectedList([...selectedList, list]);
    }
  };

  const handleApply = () => {
    onActionFilters(id, selectedList);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedList([]);
    onActionFilters(id, []);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isArraysEqual(appliedFilters, selectedList)) {
      setIsShowButton(false);
    } else setIsShowButton(true);
  }, [appliedFilters, selectedList]);

  useEffect(() => {
    setSelectedList([]);
  }, [resetTrigger]);

  return (
    <div className={styles.container}>
      <FilterButton
        name={name}
        count={appliedFilters.length}
        isActive={isOpen}
        setIsActive={() => setIsOpen(!isOpen)}
      />
      <div className={cn(styles.wrapper, isOpen && styles.wrapperActive)} id={name}>
        <ul className={styles.lists} id={name}>
          {list.map((item, index) => (
            <ListItem
              item={item}
              id={name}
              isChecked={selectedList.some(selected => selected.name === item.name)}
              handleSelect={handleSelect}
              key={`${item.name}_${index}`}
            />
          ))}
        </ul>
        {isShowButton && (
          <div className={styles.buttons} id={name}>
            <TextButton size="small" id={name} onClick={handleReset}>
              {t('buttons.reset')}
            </TextButton>
            <Button size="small" id={name} onClick={handleApply}>
              {t('buttons.apply')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
