const SECONDS_IN_MINUTES = 60;

export const formatTime = (timeLeft: number) => {
  const minutes = Math.floor(timeLeft / SECONDS_IN_MINUTES);
  const seconds = (timeLeft - minutes * SECONDS_IN_MINUTES).toString().padStart(2, '0');

  return `${minutes.toString().padStart(2, '0')}:${seconds}`;
};

export const timeSince = (date: Date) => {
  const seconds = Math.floor((Number(new Date()) - Number(date)) / 1000);
  let interval = seconds / 31536000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `${Math.floor(interval)} год`;
    }
    if (interval > 1 && interval < 5) {
      return `${Math.floor(interval)} годa`;
    }
    return `${Math.floor(interval)} лет`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `${Math.floor(interval)} месяц`;
    }
    if (interval > 1 && interval < 5) {
      return `${Math.floor(interval)} месяца`;
    }
    return `${Math.floor(interval)} месяцев`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `${Math.floor(interval)} день`;
    }
    if (interval > 1 && interval < 5) {
      return `${Math.floor(interval)} дня`;
    }
    return `${Math.floor(interval)} дней`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `${Math.floor(interval)} час`;
    }
    if (interval > 1 && interval < 5) {
      return `${Math.floor(interval)} часа`;
    }
    return `${Math.floor(interval)} часов`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    if (Math.floor(interval) === 1) {
      return `${Math.floor(interval)} минуту`;
    }
    if (interval > 1 && interval < 5) {
      return `${Math.floor(interval)} минуты`;
    }
    return `${Math.floor(interval)} минут`;
  }
  return `${Math.floor(interval * 60) <= 0 ? '1' : Math.floor(interval * 60)} секунд`;
};

export const getTimeFromDate = (date: Date) => {
  let hh: number | string = date.getHours();
  let min: number | string = date.getMinutes();

  if (hh < 10) hh = `0${hh}`;
  if (min < 10) min = `0${min}`;

  const resultTime = `${hh}:${min}`;

  return resultTime;
};
