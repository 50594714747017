import { lazy } from 'react';

import {
  LogInForm,
  SignInForm,
  ResetPasswordForm,
  NewPasswordForm,
} from '../../pages/AuthPage/Forms';
import {
  SIGN_IN_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_CONFIRM_PATH,
  AUTH_PATH,
} from '../routeConstants';
import { notFoundRoutes } from './notFoundRoutes';

const AuthPage = lazy(() => import('../../pages/AuthPage/AuthPage'));

export const authRoutes = [
  {
    path: AUTH_PATH,
    element: <AuthPage />,
    children: [
      {
        index: true,
        element: <LogInForm />,
      },
      {
        path: SIGN_IN_PATH,
        element: <SignInForm />,
      },
      {
        path: RESET_PASSWORD_PATH,
        element: <ResetPasswordForm />,
      },
      {
        path: `${RESET_PASSWORD_CONFIRM_PATH}/*`,
        element: <NewPasswordForm />,
      },
      ...notFoundRoutes,
    ],
  },
];
