import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import {
  USERS_PATH,
  ADMIN_USERS_TABS,
  ADMIN_USER_TABS,
  PROFILE_TABS,
  MODELS_TABS,
  EMPLOYEES_TABS,
  ADMIN_USER_ALLIANCES_TABS,
} from '../routeConstants';
import PortfolioTab from '../../pages/ProfilePage/ProfileData/PortfolioTab/PortfolioTab';

const AdminUsersPage = lazy(() => import('../../pages/AdminUsersPage/AdminUsersPage'));
const AdminUserPage = lazy(() => import('../../pages/AdminUserPage/AdminUserPage'));

const DeveloperPage = lazy(() => import('../../pages/AdminUserPage/DeveloperPage/DeveloperPage'));
const DeveloperResumeTab = lazy(
  () => import('../../pages/AdminUserPage/DeveloperPage/DeveloperResumeTab/DeveloperResumeTab'),
);

const AllTable = lazy(() => import('../../pages/AdminUsersPage/AllTable/AllTable'));
const UsersTable = lazy(() => import('../../pages/AdminUsersPage/UsersTable/UsersTable'));
const BAsTable = lazy(() => import('../../pages/AdminUsersPage/BAsTable/BAsTable'));
const DevelopersTable = lazy(
  () => import('../../pages/AdminUsersPage/DevelopersTable/DevelopersTable'),
);

const ModelsPage = lazy(() => import('../../pages/AdminUserPage/ModelsPage/ModelsPage'));
const MyModelsTable = lazy(
  () => import('../../pages/AdminUserPage/ModelsPage/MyModelsTable/MyModelsTable'),
);
const AddedModelsTable = lazy(
  () => import('../../pages/AdminUserPage/ModelsPage/AddedModelsTable/AddedModelsTable'),
);
const AddedWeightsTable = lazy(
  () => import('../../pages/AdminUserPage/ModelsPage/AddedWeightsTable/AddedWeightsTable'),
);

const AlliancesPage = lazy(() => import('../../pages/AdminUserPage/AlliancesPage/AlliancesPage'));
const ControlledAlliancesTable = lazy(
  () =>
    import(
      '../../pages/AdminUserPage/AlliancesPage/ControlledAlliancesTable/ControlledAlliancesTable'
    ),
);
const MyAlliancesTable = lazy(
  () => import('../../pages/AdminUserPage/AlliancesPage/MyAlliancesTable/MyAlliancesTable'),
);
const InvitationsTable = lazy(
  () => import('../../pages/AdminUserPage/AlliancesPage/InvitationsTable/InvitationsTable'),
);
const RequestsTable = lazy(
  () => import('../../pages/AdminUserPage/AlliancesPage/RequestsTable/RequestsTable'),
);

const BAPage = lazy(() => import('../../pages/AdminUserPage/BAPage/BAPage'));
const AllEmployeesTable = lazy(() => import('../../pages/AdminUserPage/BAPage/AllTable/AllTable'));
const WaitingTable = lazy(
  () => import('../../pages/AdminUserPage/BAPage/WaitingTable/WaitingTable'),
);
const DeclinedTable = lazy(
  () => import('../../pages/AdminUserPage/BAPage/DeclinedTable/DeclinedTable'),
);
const VerifiedTable = lazy(
  () => import('../../pages/AdminUserPage/BAPage/VerifiedTable/VerifiedTable'),
);

const { ALL, COMMON, BA, DEV } = ADMIN_USERS_TABS;
const { ALLIANCES, MODELS, DEVELOPER, BADMINISTRATOR } = ADMIN_USER_TABS;
const { CV, PORTFOLIO } = PROFILE_TABS;
const { MY, ADDED, WEIGHTS } = MODELS_TABS;
const { CREATED, INVITATIONS, MY: USER_MY_ALLIANCES, REQUESTS } = ADMIN_USER_ALLIANCES_TABS;
const { ALL: ALL_EMPLOYEES, VERIFIED, DECLINED, WAITING } = EMPLOYEES_TABS;

export const adminUsersRoutes = [
  {
    path: USERS_PATH,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: suspense(<AdminUsersPage />),
        children: [
          {
            index: true,
            element: suspense(<AllTable />),
          },
          {
            path: ALL,
            element: suspense(<AllTable />),
          },
          {
            path: COMMON,
            element: suspense(<UsersTable />),
          },
          {
            path: BA,
            element: suspense(<BAsTable />),
          },
          {
            path: DEV,
            element: suspense(<DevelopersTable />),
          },
        ],
      },
      {
        path: ':id',
        element: suspense(<AdminUserPage />),
        children: [
          {
            path: '',
            element: suspense(<AlliancesPage />),
            children: [
              {
                index: true,
                element: suspense(<ControlledAlliancesTable />),
              },
              {
                path: USER_MY_ALLIANCES,
                element: suspense(<MyAlliancesTable />),
              },
              {
                path: CREATED,
                element: suspense(<ControlledAlliancesTable />),
              },
              {
                path: INVITATIONS,
                element: suspense(<InvitationsTable />),
              },
              {
                path: REQUESTS,
                element: suspense(<RequestsTable />),
              },
            ],
          },
          {
            path: ALLIANCES,
            element: suspense(<AlliancesPage />),
            children: [
              {
                index: true,
                element: suspense(<ControlledAlliancesTable />),
              },
              {
                path: USER_MY_ALLIANCES,
                element: suspense(<MyAlliancesTable />),
              },
              {
                path: CREATED,
                element: suspense(<ControlledAlliancesTable />),
              },
              {
                path: INVITATIONS,
                element: suspense(<InvitationsTable />),
              },
              {
                path: REQUESTS,
                element: suspense(<RequestsTable />),
              },
            ],
          },
          {
            path: MODELS,
            element: suspense(<ModelsPage />),
            children: [
              {
                index: true,
                element: suspense(<MyModelsTable />),
              },
              {
                path: MY,
                element: suspense(<MyModelsTable />),
              },
              {
                path: ADDED,
                element: suspense(<AddedModelsTable />),
              },
              {
                path: WEIGHTS,
                element: suspense(<AddedWeightsTable />),
              },
            ],
          },
          {
            path: BADMINISTRATOR,
            element: suspense(<BAPage />),
            children: [
              {
                index: true,
                element: suspense(<AllEmployeesTable />),
              },
              {
                path: ALL_EMPLOYEES,
                element: suspense(<AllEmployeesTable />),
              },
              {
                path: WAITING,
                element: suspense(<WaitingTable />),
              },
              {
                path: VERIFIED,
                element: suspense(<VerifiedTable />),
              },
              {
                path: DECLINED,
                element: suspense(<DeclinedTable />),
              },
            ],
          },
          {
            path: DEVELOPER,
            element: suspense(<DeveloperPage />),
            children: [
              {
                index: true,
                element: suspense(<DeveloperResumeTab />),
              },
              {
                path: CV,
                element: suspense(<DeveloperResumeTab />),
              },
              {
                path: PORTFOLIO,
                element: suspense(<PortfolioTab />),
              },
            ],
          },
        ],
      },
    ],
  },
];
