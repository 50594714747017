export const InfoIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9.80054 2.23999V4.39999C9.80054 4.79764 10.1229 5.11999 10.5205 5.11999H12.6805M5.84054 5.11999H7.28054M5.84054 7.27999H10.1605M5.84054 9.43999H10.1605M11.6005 3.31999C11.2801 3.03328 10.9476 2.69322 10.7376 2.47236C10.598 2.32539 10.405 2.23999 10.2022 2.23999L4.76037 2.23999C3.96508 2.23999 3.32037 2.8847 3.32037 3.67998L3.32031 12.3199C3.32031 13.1152 3.96502 13.76 4.76031 13.76L11.2403 13.76C12.0356 13.76 12.6803 13.1153 12.6803 12.32L12.6805 4.68669C12.6805 4.50259 12.6103 4.32561 12.4825 4.19307C12.2462 3.94798 11.8517 3.5447 11.6005 3.31999Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
