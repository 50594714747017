import { useEffect, useState } from 'react';

import { getLink, uploadDelete } from '../../../../api/upload';
import { useAppDispatch } from '../../../../hooks';
import { PopupTypes } from '../../../../store/types';
import { openPopup } from '../../../../store';
import { filesTypesHelper, formatSize, makeFileName } from '../../../../utils/';
import { DeleteIcon, FileIcon, ImageIcon } from '../../../../assets/svg';
import { MB } from '../constants';

import { PropTypes } from './types';

import styles from '../UploadItem/UploadItem.module.scss';

export const UploadedItem = ({ attachment, onDelete, data, isControlled }: PropTypes) => {
  const [link, setLink] = useState<string>('');
  const { isImage, format } = filesTypesHelper(attachment.name);
  const dispatch = useAppDispatch();

  const handleDeleteOpen = () => {
    dispatch(
      openPopup({
        type: PopupTypes.DeleteAttachment,
        data: { file: attachment.name, name: data.name, type: data.to },
        onAction: handleDelete,
      }),
    );
  };
  const handleDelete = () => {
    onDelete();
    uploadDelete(attachment.id, data.to);
  };

  const fetchLink = async () => {
    if (attachment.url) {
      setLink(attachment.url);
      return;
    }

    const { status, payload } = await getLink(attachment.id, data.to);

    if (status === 'success') {
      setLink(payload.file);
    }
  };

  useEffect(() => {
    fetchLink();
  }, []);

  return (
    <li className={styles.container}>
      <div className={styles.info}>
        <div className={styles.icon}>{isImage ? <ImageIcon /> : <FileIcon />}</div>
        <a className={styles.name} href={attachment.url || link} target="_blank" rel="noreferrer">
          {makeFileName(attachment.name)}
        </a>
        <p className={styles.format}>
          {format}, {formatSize(attachment.size)} {MB}
        </p>
      </div>
      {isControlled && (
        <div className={styles.buttons}>
          <button className={styles.delete} onClick={handleDeleteOpen}>
            <DeleteIcon />
          </button>
        </div>
      )}
    </li>
  );
};
