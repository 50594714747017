export const EyeOpen = () => (
  <svg width="24" height="24" viewBox="0 0 24 18" fill="none">
    <path
      d="M11.9999 18.8556C19.323 18.9753 21.5999 12.119 21.5999 12.119C21.5999 12.119 19.4153 5.14282 11.9999 5.14282C4.58452 5.14282 2.3999 12.119 2.3999 12.119C2.3999 12.119 4.67683 18.7358 11.9999 18.8556Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M14.4001 12.0292C14.4001 13.319 13.3256 14.3646 12.0001 14.3646C10.6746 14.3646 9.6001 13.319 9.6001 12.0292C9.6001 10.7394 10.6746 9.69385 12.0001 9.69385C13.3256 9.69385 14.4001 10.7394 14.4001 12.0292Z"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);
