import cn from 'classnames';
import { ChangeHandler } from 'react-hook-form';

import { CheckMarkIcon } from '../../../../../assets/svg';
import { CrossIcon } from '../../../Icons/CrossIcon/CrossIcon';

import styles from './Input.module.scss';

type PropsType = {
  id: string;
  register?: (
    name: any,
  ) => {
    onChange: ChangeHandler;
    onBlur: ChangeHandler;
    name: string;
    ref: React.Ref<HTMLInputElement>;
  };
  helperText?: any;
  onClick?: () => void;
  onClose: () => void;
  onSubmit: () => void;
  maxLength: number;
};

export const Input = ({
  id,
  register,
  helperText,
  onClick,
  onClose,
  onSubmit,
  maxLength,
}: PropsType) => {
  const inputClassName = cn(styles.input, {
    [styles.inputNotValid]: helperText,
  });

  return (
    <div className={styles.container}>
      <input
        className={inputClassName}
        type="text"
        id={id}
        required
        {...register?.(id)}
        maxLength={maxLength}
      />
      <div className={styles.icons} onClick={onClick}>
        <div
          className={cn(styles.tickIcon, helperText && styles.tickIconDisabled)}
          onClick={helperText ? undefined : onSubmit}
        >
          <CheckMarkIcon />
        </div>
        <div className={styles.crossIcon} onClick={onClose}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.error}>{helperText}</div>
    </div>
  );
};
