import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { TextButton } from '../../../../../common';
import { metricsSchema } from '../../../../../../schemas/weightSchema';

import styles from '../MetricsForm.module.scss';

export const EditForm = ({ defaultValues, onCancelClick, onSubmitClick, id }: any) => {
  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(metricsSchema),
    defaultValues,
    mode: 'onChange',
  });
  const { ref: nameRef, ...nameRest } = register('metricName');
  const { ref: valueRef, ...valueRest } = register('metricValue');
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmitClick({ name: getValues('metricName'), value: getValues('metricValue') }, id);
  };

  return (
    <div className={styles.metricsForm}>
      <div className={styles.form}>
        <div className={styles.inputsWrapper}>
          <TextField
            id="metricName"
            label={
              <span className={styles.styledLabel}>
                {t('popups.weight.metricsForm.nameLabel')} <span>*</span>
              </span>
            }
            variant="standard"
            inputRef={nameRef}
            {...nameRest}
            error={Boolean(errors.metricName)}
            helperText={errors.metricName ? String(errors.metricName?.message) : ''}
            disabled={true}
          />
          <TextField
            id="metricValue"
            label={
              <span className={styles.styledLabel}>
                {t('popups.weight.metricsForm.valueLabel')} <span>*</span>
              </span>
            }
            variant="standard"
            inputRef={valueRef}
            {...valueRest}
            error={Boolean(errors.metricValue)}
            helperText={errors.metricValue ? String(errors.metricValue?.message) : ''}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <TextButton onClick={onCancelClick}>{t('buttons.cancel')}</TextButton>
          <TextButton isDisabled={!isValid} onClick={handleSubmit}>
            {t('buttons.add')}
          </TextButton>
        </div>
      </div>
    </div>
  );
};
