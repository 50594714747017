import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import { postAddToFavorites, deleteFromFavorites } from '../../../../../api';
import { userSelector, setNotification, openErrorPopup } from '../../../../../store';
import { NotificationTypes } from '../../../../../store/types';
import { FavoritesIcon } from '../../../../../assets/svg';
import { PropsType } from './types';

import styles from './FavoritesBtn.module.scss';

export const FavoritesBtn = ({ isActive, entity, entityId, entityName }: PropsType) => {
  const { t } = useTranslation();
  const [isFavorite, setIsFavorite] = useState<boolean>(isActive);
  const dispatch = useAppDispatch();
  const { id: userId } = useAppSelector(userSelector);

  const handleAddToFavorite = async () => {
    const { status } = await postAddToFavorites(userId, entityId, entity);

    if (status === 'success') {
      setIsFavorite(true);
      dispatch(
        setNotification({
          type: NotificationTypes.AddToFavorites,
          data: {
            textContent: t(`notifications.favorites.${entity}Add`, { [entity]: entityName }),
          },
        }),
      );
    } else {
      dispatch(openErrorPopup());
    }
  };

  const handleDeleteFromFavorite = async () => {
    const { status } = await deleteFromFavorites(entityId, entity);
    if (status === 'success') {
      setIsFavorite(false);
      dispatch(
        setNotification({
          type: NotificationTypes.DeleteFromFavorites,
          data: {
            textContent: t(`notifications.favorites.${entity}Delete`, { [entity]: entityName }),
          },
        }),
      );
    } else {
      dispatch(openErrorPopup());
    }
  };

  const handleFavorites = isFavorite ? handleDeleteFromFavorite : handleAddToFavorite;
  const description = isFavorite ? t('buttons.deleteFromFavorites') : t('buttons.addToFavorites');

  const btnStyles = cn(styles.button, {
    [styles.activeFavoritesBtn]: isFavorite,
  });

  return (
    <button className={btnStyles} onClick={handleFavorites} type="button">
      <FavoritesIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
