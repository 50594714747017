import { useAppSelector } from '../../../hooks';
import { isAdminSelector, modelSelector } from '../../../store';
import { ModelStatusList } from './ModelStatusList/ModelStatusList';
import { ModelContent } from './ModelContent/ModelContent';
import { RoleInModel } from '../types';

import styles from './ModelInfo.module.scss';

export const ModelInfo = ({ isInPopup = false }: { isInPopup?: boolean }) => {
  const modelData = useAppSelector(modelSelector);
  const isAdmin = useAppSelector(isAdminSelector);
  const isControlled = (modelData.role === RoleInModel.Owner || isAdmin) && !isInPopup;

  return (
    <div className={styles.container}>
      <ModelStatusList isControlled={isControlled} {...modelData} isInPopup={isInPopup} />
      <ModelContent isControlled={isControlled} {...modelData} isInPopup={isInPopup} />
    </div>
  );
};

export default ModelInfo;
