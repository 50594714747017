import styles from './SwitchButton.module.scss';

export const SwitchButton = ({
  checked,
  disabled = false,
  name = '',
  onChange = () => null,
}: {
  checked: boolean;
  disabled?: boolean;
  name?: string;
  onChange?: () => void;
}) => (
  <>
    <input
      className={styles.input}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      hidden
      id={`switch${name}`}
    />
    <label className={styles.switch} htmlFor={`switch${name}`} />
  </>
);
