import { EmptyIcon } from '../../../assets/svg';

import styles from './Empty.module.scss';

export const Empty = ({ text }: { text: string }) => {
  return (
    <div className={styles.wrapper}>
      <EmptyIcon />
      <p className={styles.text}>{text}</p>
    </div>
  );
};
