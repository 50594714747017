import { SyntheticEvent } from 'react';
import cn from 'classnames';

import { RefreshIcon } from '../../../../../assets/svg';

import styles from './RefreshButton.module.scss';

interface PropsType {
  isDisabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}

export const RefreshButton = ({ onClick, isDisabled = false }: PropsType) => {
  return (
    <button
      className={cn(styles.button, styles.refresh, isDisabled && styles.disabled)}
      onClick={onClick}
      type="button"
      disabled={isDisabled}
    >
      <RefreshIcon />
    </button>
  );
};
