import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { developerApi } from '../../api/developerApi';
import { ProjectType } from '../../pages/ProfilePage/ProfileData/PortfolioTab/types';
import { ITag } from '../../api/tags/types';
import { ProjectsResponseTransformed } from '../../api/developer/types';
import { SummaryResponseTransformed } from '../../api/summary/types';

export type TDeveloperProfile = {
  projects: ProjectType[];
  summary: {
    id: number;
    specialization: string;
    experience: string;
    country: string;
    region: string;
    skills: ITag[];
    about: string;
    link: {
      link: string;
      name: string;
    };
    isSummaryHidden: boolean;
    isFavorite: boolean;
  };
  profileId: number;
};

const initialState: TDeveloperProfile = {
  projects: [],
  summary: {
    id: 0,
    specialization: '',
    experience: '',
    country: '',
    region: '',
    skills: [],
    about: '',
    link: {
      link: '',
      name: '',
    },
    isSummaryHidden: false,
    isFavorite: false,
  },
  profileId: 0,
};

const developerSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      developerApi.endpoints.getProjects.matchFulfilled,
      (state: TDeveloperProfile, { payload }: PayloadAction<ProjectsResponseTransformed>) => {
        state.projects = payload;
      },
    );
    builder.addMatcher(
      developerApi.endpoints.getSummary.matchFulfilled,
      (state: TDeveloperProfile, { payload }: PayloadAction<SummaryResponseTransformed>) => {
        state.profileId = payload.profileId;
        state.summary = payload.summary;
      },
    );
  },
});

export default developerSlice.reducer;
