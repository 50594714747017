import styles from './RadioButton.module.scss';

type PropsType = {
  id: string;
  name: string;
  isDisabled?: boolean;
};

export const RadioButton = ({ id, name, isDisabled = false }: PropsType) => {
  return (
    <div className={styles.radioWrapper}>
      <input
        data-component="hidden"
        type="radio"
        name={name}
        id={id}
        className={styles.hidden}
        disabled={isDisabled}
      />
      <div data-component="radio" className={styles.radio}></div>
    </div>
  );
};
