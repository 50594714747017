import { useLocation, useNavigate } from 'react-router-dom';

export const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as string[];

  const isStateIncludes = (path: string) => state[state.length - 1].includes(path);

  let isBack = state && state.length > 0;
  let currentPath = `/${window.location.href.replace(/(?:^https?:\/\/[^/]+\/)(.*)$/gm, '$1')}`;
  let lastPatch = state && state.length > 0 ? state[state.length - 1] : '';
  let isToAlliance = isBack && isStateIncludes('alliances');
  let isToGuide = isBack && isStateIncludes('help');
  let isToUsersMls = isBack && isStateIncludes('users') && isStateIncludes('u_mls');
  let isToUsersAls = isBack && isStateIncludes('users') && isStateIncludes('u_al');
  let isToMain = isBack && state[state.length - 1] === '/';

  if (isBack && currentPath === lastPatch) {
    state.pop();
  }

  const setCurrentState = (isReturned: boolean) => {
    if (!isReturned) {
      return undefined;
    }

    if (state && state.length > 0) {
      return [...state, currentPath];
    } else {
      return [currentPath];
    }
  };

  const onNavigate = (to: string, isReturned: boolean | undefined = false) => {
    navigate(to, { state: setCurrentState(isReturned) });
  };

  const onGuideNavigate = (to: string) => {
    navigate(to, { state });
  };

  const handleBack = (link?: string | undefined) => {
    if (isBack) {
      navigate(lastPatch, { state });
    } else if (link) {
      navigate(link, { state });
    }
  };

  return {
    onNavigate,
    onGuideNavigate,
    isToAlliance,
    isToGuide,
    isToMain,
    isToUsersAls,
    isToUsersMls,
    handleBack,
    isBack,
  };
};
