type AttachmentsType = { name: string; id: number; type: string; size: number; uuid: string };

export const filesFormatHelper = (formats: string[]) =>
  formats.map((format: string) => `.${format.toLowerCase()}`).join(', ');

export const typeFormat = (type: string) =>
  type
    .replace(/.*(?=\.)/, '')
    .toUpperCase()
    .replace('.', '');

export const isValidFormat = (formats: string[], type: string) => {
  const typeFormate = type.replace(/.*(?=\.)/, '');
  const formatsArray = formats.map((format: string) => `.${format.toLowerCase()}`);
  return formatsArray.includes(typeFormate);
};

export const filesTypesHelper = (type: string) => {
  let isImage = null;
  let isFormat = null;
  let format = null;
  const typeFormate = type.replace(/.*(?=\.)/, '');
  switch (typeFormate) {
    case '.txt':
    case 'txt':
      format = 'TXT';
      isImage = false;
      isFormat = true;
      break;
    case '.doc':
    case 'doc':
      format = 'DOC';
      isImage = false;
      isFormat = true;
      break;
    case '.docx':
    case 'docx':
      format = 'DOCX';
      isImage = false;
      isFormat = true;
      break;
    case '.pdf':
    case 'pdf':
      format = 'PDF';
      isImage = false;
      isFormat = true;
      break;
    case '.jpeg':
    case 'jpeg':
      format = 'JPEG';
      isImage = true;
      isFormat = true;
      break;
    case '.jpg':
    case 'jpg':
      format = 'JPG';
      isImage = true;
      isFormat = true;
      break;
    case '.png':
    case 'png':
      format = 'PNG';
      isImage = true;
      isFormat = true;
      break;
    case '.svg':
    case 'svg':
      format = 'SVG';
      isImage = true;
      isFormat = true;
      break;
    case '.gif':
    case 'gif':
      format = 'GIF';
      isImage = true;
      isFormat = true;
      break;
    default:
      format = '';
      isImage = false;
      isFormat = false;
  }
  return { isImage, format, isFormat };
};

export const attachmentTextHelper = (quantity: number) => {
  let text = '';
  switch (quantity) {
    case 6:
    case 5:
      text = ' приложений';
      break;
    case 4:
    case 3:
    case 2:
      text = ' приложения';
      break;
    default:
      text = ' приложение';
  }
  return text;
};

export const filesSizeSum = (files: (File | AttachmentsType)[]) =>
  files.reduce(
    (accumulate: number, current: File | AttachmentsType) => accumulate + current.size,
    0,
  );

export const makeFileName = (name: string) => name.replace(name.replace(/.*(?=\.)/, ''), '');
