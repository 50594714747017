import * as yup from 'yup';

import { isPasswordWeak } from '../utils/checkPassword';

export const emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i;
//eslint-disable-next-line
export const passwordRegExp = /^[~`"!@#$%^&*()_+=[\]\{}|\\;':",.\/<>?a-zA-Z0-9-]+$/;

const email = yup
  .string()
  .required('')
  .max(200, 'Электронная почта не должна превышать 200 символов')
  .matches(emailRegExp, 'Неверный формат электронной почты');

const password = yup
  .string()
  .required('')
  .min(8, 'Некорректный пароль')
  .max(20, 'Некорректный пароль')
  .matches(passwordRegExp, 'Некорректный пароль')
  .test('Слабый пароль', 'Слабый пароль', (value): any => value && isPasswordWeak(value));

const loginPassword = yup.string().required('').min(8, 'Пароль не должен быть короче 8 символов');

const passwordRepeat = yup.string().oneOf([yup.ref('password'), null], 'Пароли не совпадают');

const feedback = yup
  .string()
  .required('Отзыв обязателен к заполнению')
  .min(50, 'Отзыв не может быть короче 50-ти символов');

  const message = yup
  .string()
  .required('')
  .min(50, 'Сообщение не может быть короче 50-ти символов');

const estimatedFeedback = yup
  .string()
  .nullable()
  .notRequired()
  .when('estimatedFeedback', {
    is: (value: string) => value?.length,
    then: rule => rule.min(50, 'Отзыв не может быть короче 50-ти символов'),
  });

const checkBox = yup.bool().oneOf([true], '');

export const signInSchema = yup.object().shape({
  email,
  password,
  passwordRepeat,
  checkBox,
});

export const loginSchema = yup.object().shape({
  email,
  loginPassword,
});

export const emailSchema = yup.object().shape({
  email,
});

export const resetPasswordSchema = yup.object().shape({
  password,
  passwordRepeat,
});

export const feedbackSchema = yup.object().shape({
  feedback,
});

export const messageSchema = yup.object().shape({
  message,
});

export const estimatedFeedbackSchema = yup.object().shape(
  {
    estimatedFeedback,
  },
  [['estimatedFeedback', 'estimatedFeedback']],
);
