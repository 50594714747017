export const OrganizationCardIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none">
    <g clipPath="url(#clip0_10935_1899)">
      <path
        d="M48.7476 48.5109H29.6242C19.0626 48.5109 10.5008 40.0089 10.5008 29.5212C10.5008 19.0335 19.0626 10.5315 29.6242 10.5315C40.1858 10.5315 48.7476 19.0335 48.7476 29.5212V48.5109Z"
        fill="#2D89EF"
      />
      <path
        d="M29.6258 31.1483C29.6258 30.2494 30.3596 29.5206 31.2649 29.5206H48.7492C59.3107 29.5206 67.8726 38.0226 67.8726 48.5104C67.8726 58.9981 59.3107 67.5001 48.7492 67.5001C38.1876 67.5001 29.6258 58.9981 29.6258 48.5104V31.1483Z"
        fill="white"
      />
      <mask id="mask0_10935_1899" maskUnits="userSpaceOnUse" x="10" y="10" width="39" height="39">
        <path
          d="M48.7476 48.5109H29.6242C19.0626 48.5109 10.5008 40.0089 10.5008 29.5212C10.5008 19.0335 19.0626 10.5315 29.6242 10.5315C40.1858 10.5315 48.7476 19.0335 48.7476 29.5212V48.5109Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_10935_1899)">
        <path
          d="M29.6261 48.5102H48.7495V29.5205L31.2649 29.5199C30.3596 29.5199 29.6258 30.2487 29.6258 31.1476L29.6261 48.5102Z"
          fill="url(#paint0_linear_10935_1899)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10935_1899"
        x1="28.7589"
        y1="29.5199"
        x2="53.4833"
        y2="52.1802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D89EF" />
        <stop offset="1" stopColor="#2D89EF" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_10935_1899">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
