export const WaitingIcon = () => (
  <svg width="14" height="17" viewBox="0 0 14 17" fill="none">
    <path
      d="M1.39844 1.2002H12.5359M1.39844 16.0502H12.5359M11.1438 1.2002V3.2273C11.1438 3.89924 10.9182 4.55173 10.5032 5.08019L8.32046 7.85957C7.9156 8.37511 7.92522 9.10324 8.34356 9.6079L10.4534 12.1531C10.8996 12.6913 11.1438 13.3685 11.1438 14.0677V16.0502M2.79063 1.2002V3.2273C2.79063 3.89924 3.01621 4.55173 3.43122 5.08019L5.61391 7.85957C6.01878 8.37511 6.00916 9.10324 5.59082 9.6079L3.48099 12.1531C3.03481 12.6913 2.79063 13.3685 2.79063 14.0677V16.0502"
      stroke="#6D6C83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
