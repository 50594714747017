import cn from 'classnames';

import { tabs } from './constants';
import { PropTypes } from './types';

import styles from './Tabs.module.scss';

export const Tabs = ({
  currentTab,
  myAlliancesCounter,
  adminAlliancesCounter,
  onTab,
}: PropTypes) => {
  return (
    <ul className={styles.tabs}>
      {tabs.map((tab: string, index: number) => (
        <li
          className={cn(styles.tab, currentTab === index && styles.tabSelected)}
          onClick={() => onTab(index)}
          key={index}
        >
          <span className={styles.label}>{tab}</span>
          <span className={styles.counter}>
            {(index === 0 ? myAlliancesCounter : adminAlliancesCounter) ?? 0}
          </span>
        </li>
      ))}
    </ul>
  );
};
