import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { TickIcon } from './assets/TickIcon';
import { CrossIcon } from '../..';

import styles from './ProgressBar.module.scss';

type PropsType = {
  currentStep: number;
  steps: string;
  isError?: boolean;
  isSkipped?: boolean;
};

export const ProgressBar = ({
  currentStep,
  steps,
  isError = false,
  isSkipped = false,
}: PropsType) => {
  const { t } = useTranslation();
  const progressBarData: string[] = t(steps, { returnObjects: true });

  const stepClassName = (countStep: number) =>
    cn(styles.step, {
      [styles.stepActive]: currentStep === countStep,
      [styles.stepPast]: currentStep > countStep,
      [styles.stepError]: currentStep === countStep && isError,
      [styles.stepSmaller]: progressBarData.length === 4,
      [styles.stepSkipped]: countStep === 3 && isSkipped,
      [styles.roundHasFinished]: currentStep === 5,
    });

  const RenderStep = (curStep: number, cntStep: number) => {
    if (curStep > cntStep) {
      if (cntStep === 3 && isSkipped) return <span></span>;
      return <TickIcon />;
    }
    return <span>{cntStep}</span>;
  };

  return (
    <div className={styles.progressBar}>
      {progressBarData.map((text, i) => {
        const countStep = i + 1;
        return (
          <div className={stepClassName(countStep)} key={text}>
            <div className={styles.count}>{RenderStep(currentStep, countStep)}</div>
            <div className={styles.text}>
              {isError && currentStep === countStep ? (
                <>
                  <span>
                    <CrossIcon />
                  </span>
                  <span>{text}</span>
                </>
              ) : (
                text
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
