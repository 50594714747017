import { useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { feedbacksApi } from '../../../../api/FeedbacksApi';
import { feedbackSchema } from '../../../../schemas/validationSchemas';
import { useAppDispatch } from '../../../../hooks';
import { openPopup } from '../../../../store';
import { PopupTypes } from '../../../../store/types';
import { Button, FormUpload, TextArea } from '../../../../ui';

import styles from './FeedbackPopUp.module.scss';

export const FeedbackPopUp = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    watch,
  } = useForm({
    resolver: yupResolver(feedbackSchema),
    mode: 'onTouched',
  });
  const [feedback] = watch(['feedback']);
  const [sendFeedback] = feedbacksApi.usePostFeedbackMutation();
  const [files, setFiles] = useState<File[]>([]);

  const onSubmit: SubmitHandler<FieldValues> = ({ feedback }) => {
    sendFeedback({ files, text: feedback })
      .unwrap()
      .then(() => {
        dispatch(
          openPopup({
            type: PopupTypes.Success,
            data: {
              title: t('popups.feedback.successTitle'),
              info: t('popups.feedback.successText'),
            },
          }),
        );
      });
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={styles.wrapper}
    >
      <h2 className={styles.title}>{t('popups.feedback.title')}</h2>
      <div className={styles.subtitle}>{t('popups.feedback.subtitle')}</div>
      <div className={styles.description}>
        <span>{t('pages.editPage.symbol')}</span>
        {t('pages.editPage.info')}
      </div>
      <div className={styles.scrollContent}>
        <div className={styles.textAreaWrapper}>
          <TextArea
            id="feedback"
            label={t('popups.feedback.feedbackLabel')}
            register={register}
            helperText={errors['feedback']?.message}
            value={feedback}
            maxLength={400}
            isRequired
          />
        </div>
        <FormUpload files={files} setFiles={setFiles} maxQuantity={6} maxSize={50} isMulti />
      </div>
      <div className={styles.btnWrapper}>
        <Button style="secondary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button isDisabled={!isValid} type="submit">
          {t('buttons.send')}
        </Button>
      </div>
    </form>
  );
};
