import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { filesSizeSum } from '../../../utils';
import { InfoBlock } from '../../common';
import { UploadedItem } from './UploadedItem/UploadedItem';
import { UploadArea } from './UploadArea/UploadArea';
import { UploadItem } from './UploadItem/UploadItem';
import { BYTES_IN_MB, MAX_QUANTITY, MAX_SIZE_IN_MB } from './constants';
import { AttachmentsType, PropTypes } from './types';

import styles from './Upload.module.scss';

export const Upload = ({ attachments, data, isControlled }: PropTypes) => {
  const [uploads, setUploads] = useState<File[]>([]);
  const [files, setFiles] = useState<AttachmentsType[]>(attachments);
  const [quantityLimit, setQuantityLimit] = useState<number>(MAX_QUANTITY);
  const [sizeLimit, setSizeLimit] = useState<number>(MAX_SIZE_IN_MB);
  const { t } = useTranslation();

  const container = cn(styles.container, {
    [styles.hasItems]: uploads.length || files.length,
  });

  const filesClassName = cn(styles.filesColMd, {
    [styles.filesColSm]: data.to === 'alliance',
  });

  useEffect(() => {
    setQuantityLimit(MAX_QUANTITY - uploads.length - files.length);
    setSizeLimit(
      MAX_SIZE_IN_MB - filesSizeSum(uploads) / BYTES_IN_MB - filesSizeSum(files) / BYTES_IN_MB,
    );
  }, [files, uploads]);

  const handleUpload = (item: File[]) => {
    setUploads([...uploads, ...item.splice(0, quantityLimit)]);
  };

  const handleCancel = (item: File) => {
    setUploads(prev => prev.filter((uploads: File) => uploads !== item));
  };

  const handleAddFile = (item: AttachmentsType) => {
    setFiles(prev => [...prev, item]);
  };

  const handleDeleteFile = (item: number) => {
    setFiles((prev: AttachmentsType[]) =>
      prev.filter((uploads: AttachmentsType) => uploads.id !== item),
    );
  };

  const uploadArea =
    sizeLimit > 0 && quantityLimit > 0 ? (
      <UploadArea onUpload={handleUpload} quantityLimit={quantityLimit} sizeLimit={sizeLimit} />
    ) : (
      <InfoBlock text={t('upload.applications.info')} />
    );

  return (
    <div className={container}>
      <ul className={filesClassName}>
        {files.map((attachment: AttachmentsType) => (
          <UploadedItem
            attachment={attachment}
            key={attachment.id}
            data={data}
            isControlled={isControlled}
            onDelete={() => handleDeleteFile(attachment.id)}
          />
        ))}
        {uploads.map((upload: File, index: number) => (
          <UploadItem
            upload={upload}
            key={index}
            onCancel={handleCancel}
            addUpload={handleAddFile}
            data={data}
          />
        ))}
      </ul>
      {isControlled && uploadArea}
    </div>
  );
};
