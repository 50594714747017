import { request } from '../utils/fetchHandler';
import { ModelByIdResponse, ModelCategoriesResponse, ModelTasksResponse } from './models/types';

export const getTasksList = async (): Promise<{ status: string; payload: ModelTasksResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/mlmodels/task-type/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const getCategories = async (): Promise<{
  status: string;
  payload: ModelCategoriesResponse;
}> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/mls-book/categories/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload };
};

export const getModelById = async (
  id: string | undefined,
): Promise<{ status: string; payload: ModelByIdResponse; code: number | undefined }> => {
  const { status, payload, code } = await request(
    `${process.env.REACT_APP_BACK_END}/mlmodels/${id}/`,
    {
      credentials: 'include',
    },
  );

  return { status, payload, code };
};
