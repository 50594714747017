export const FileIcon = () => (
  <svg width="12" height="15" viewBox="0 0 12 15" fill="none">
    <path
      d="M8.00005 1.1001V3.5001C8.00005 3.94193 8.35823 4.3001 8.80005 4.3001H11.2001M3.60005 4.3001H5.20005M3.60005 6.7001H8.40006M3.60005 9.1001H8.40006M10.0001 2.3001C9.644 1.98153 9.27453 1.60368 9.04129 1.35828C8.88608 1.19499 8.67163 1.1001 8.44634 1.1001H2.39987C1.51622 1.1001 0.799872 1.81644 0.799867 2.70009L0.799805 12.3001C0.799799 13.1837 1.51614 13.9001 2.3998 13.9001L9.59983 13.9001C10.4835 13.9001 11.1998 13.1838 11.1998 12.3001L11.2 3.81865C11.2001 3.61409 11.122 3.41745 10.98 3.27019C10.7175 2.99786 10.2791 2.54978 10.0001 2.3001Z"
      stroke="#137CF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
