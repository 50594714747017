import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ABOUT_PATH, HOW_TO_START_FL_PATH } from '../../../router';
import { FLSchemeIcon } from './assets/svg';

import styles from './FLHelpBlock.module.scss';

export const FLHelpBlock = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div>
        <h2 className={styles.title}>{t('pages.alliancePage.FLHelp.title')}</h2>
        <p className={styles.text}>{t('pages.alliancePage.FLHelp.text')}</p>
        <div className={styles.btnWrapper}>
          <Link to={`/${ABOUT_PATH}/${HOW_TO_START_FL_PATH}`}>
            {t('pages.alliancePage.FLHelp.link')}
          </Link>
        </div>
      </div>
      <div>
        <FLSchemeIcon />
      </div>
    </div>
  );
};
