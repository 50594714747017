export const RefreshIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g id="Component 26">
      <path
        id="Icon"
        d="M20.3068 7.20039C18.6388 4.33094 15.5213 2.40039 11.9507 2.40039C7.90235 2.40039 4.43643 4.88219 3.00539 8.40039M17.3769 8.40039H22.2002V3.60039M3.69364 16.8004C5.36157 19.6698 8.47907 21.6004 12.0497 21.6004C16.098 21.6004 19.564 19.1186 20.995 15.6004M6.62347 15.6004H1.80019V20.4004"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
