import * as yup from 'yup';

const name = yup
  .string()
  .required('Это поле обязательно к заполнению')
  .min(2, 'Название не может быть короче 50-ти символов');

const description = yup
  .string()
  .required('Это поле обязательно к заполнению')
  .min(50, 'Описание  не может быть короче 50-ти символов');

export const projectSchema = yup.object().shape({
  name,
  description,
});
