export const EyeClosed = () => (
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none">
    <path
      d="M19.3999 16.5L4.3999 1.5M9.1999 7.44157C8.82648 7.85326 8.5999 8.39403 8.5999 8.98631C8.5999 10.2761 9.67442 11.3217 10.9999 11.3217C11.611 11.3217 12.1688 11.0994 12.5926 10.7334M19.4387 11.3217C20.2649 10.0848 20.5999 9.07613 20.5999 9.07613C20.5999 9.07613 18.4153 2.1 10.9999 2.1C10.5836 2.1 10.1838 2.12199 9.7999 2.16349M16.3999 14.3494C15.0225 15.2281 13.2492 15.8495 10.9999 15.8127C3.67683 15.693 1.3999 9.07613 1.3999 9.07613C1.3999 9.07613 2.45776 5.69808 5.5999 3.64332"
      stroke="#137CF0"
      strokeOpacity="0.7"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </svg>
);
