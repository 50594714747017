import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingsType } from '../types';
import {
  AccountNotificationsSettingsKeys,
  NotificationsSettingsKeys,
} from '../../pages/SettingsPage/Notifications/types';

const initialState: SettingsType = {
  notificationsSettings: {
    quickNotifies: true,
    emailNotifies: true,
    accountNotifies: true,
  },
  accountNotificationsSettings: {
    alliancesNotifies: true,
    flNotifies: true,
    mlModelsNotifies: true,
    subscriptionNotifies: true,
    supportNotifies: true,
  },
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setNotificationsSettings(state, action) {
      state.notificationsSettings = action.payload;
    },
    setAccountNotificationsSettings(state, action) {
      state.accountNotificationsSettings = action.payload;
    },
    changeNotificationsSettings(state, action: PayloadAction<NotificationsSettingsKeys>) {
      const key = action.payload;

      if (key === 'accountNotifies') {
        const newAccountSettingsEntries = Object.entries(state.accountNotificationsSettings).map(
          ([entryKey]) => [entryKey, !state.notificationsSettings[key]],
        );
        state.accountNotificationsSettings = Object.fromEntries(newAccountSettingsEntries);
      }
      state.notificationsSettings = {
        ...state.notificationsSettings,
        [key]: !state.notificationsSettings[key],
      };
    },
    changeAccountNotificationsSettings(
      state,
      action: PayloadAction<AccountNotificationsSettingsKeys>,
    ) {
      const key = action.payload;
      const activeOptionsCount = Object.values(state.accountNotificationsSettings).filter(
        elem => elem,
      ).length;
      const autoChangeNotificationsSettings = () =>
        (state.notificationsSettings = {
          ...state.notificationsSettings,
          accountNotifies: !state.notificationsSettings.accountNotifies,
        });

      if (activeOptionsCount === 1 && state.accountNotificationsSettings[key] === true) {
        autoChangeNotificationsSettings();
      }
      if (activeOptionsCount === 0) {
        autoChangeNotificationsSettings();
      }
      state.accountNotificationsSettings = {
        ...state.accountNotificationsSettings,
        [key]: !state.accountNotificationsSettings[key],
      };
    },
  },
});

export const {
  setNotificationsSettings,
  setAccountNotificationsSettings,
  changeNotificationsSettings,
  changeAccountNotificationsSettings,
} = settingsSlice.actions;
export default settingsSlice.reducer;
