import { lazy } from 'react';

import { BAProtection } from '../HOC/RoutesProtection';
import { suspense } from '../HOC/suspense';
import { EDIT_ORGANIZATION_PATH, EMPLOYEES_PATH, EMPLOYEES_TABS } from '../routeConstants';

const EmployeesPage = lazy(() => import('../../pages/EmployeesPage/EmployeesPage'));
const EditOrganizationPage = lazy(
  () => import('../../pages/EditOrganizationPage/EditOrganizationPage'),
);
const EmployeesTable = lazy(() => import('../../pages/EmployeesPage/Table/EmployeesTable'));

const { ALL, DECLINED, VERIFIED, WAITING } = EMPLOYEES_TABS;

export const baRoutes = [
  {
    path: EMPLOYEES_PATH,
    element: <BAProtection />,
    children: [
      {
        path: '',
        element: suspense(<EmployeesPage />),
        children: [
          {
            index: true,
            element: suspense(<EmployeesTable tab={ALL} />),
          },
          {
            path: ALL,
            element: suspense(<EmployeesTable tab={ALL} />),
          },
          {
            path: DECLINED,
            element: suspense(<EmployeesTable tab={DECLINED} />),
          },
          {
            path: VERIFIED,
            element: suspense(<EmployeesTable tab={VERIFIED} />),
          },
          {
            path: WAITING,
            element: suspense(<EmployeesTable tab={WAITING} />),
          },
        ],
      },
    ],
  },
  {
    path: EDIT_ORGANIZATION_PATH,
    element: <BAProtection />,
    children: [
      {
        path: '',
        element: suspense(<EditOrganizationPage />),
      },
    ],
  },
];
