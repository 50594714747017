import { FieldErrorsImpl } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NotificationType, IncomesType } from '../pages/MainPage/Header/types';
import { ParamType } from '../ui/components/Params/types';
import { ParamDictionaryType, PeriodType } from '../types/types';

export const formatDate = (date: Date, isTimeNeeded = false) => {
  if (!date || isNaN(Number(date))) return '';

  const yyyy: number = date.getFullYear();
  let mm: number | string = date.getMonth() + 1;
  let dd: number | string = date.getDate();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;

  let hh: number | string = date.getHours();
  let min: number | string = date.getMinutes();

  if (hh < 10) hh = `0${hh}`;
  if (min < 10) min = `0${min}`;

  const resultDate = `${dd}.${mm}.${yyyy}`;
  const resultTime = `${hh}:${min}`;

  return isTimeNeeded ? `${resultDate} ${resultTime}` : `${resultDate}`;
};

const MONTH_ARRAY = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const formatDateToMonth = (date: string, isTimeNeeded = false, isYearNeeded = true) => {
  if (!date) {
    return;
  }

  const yy: string = date.substring(0, 4);
  const mm: string = date.substring(5, 7);
  const dd = String(Number(date.substring(8, 10)));

  let hh: number | string = new Date(date).getHours();
  let min: number | string = new Date(date).getMinutes();

  if (hh < 10) hh = `0${hh}`;
  if (min < 10) min = `0${min}`;
  const resultTime = `${hh}:${min}`;

  let mmts = MONTH_ARRAY[Number(mm) - 1];

  return `${dd} ${mmts}${isYearNeeded ? ` ${yy} года` : ''}${
    isTimeNeeded ? `, ${resultTime}` : ''
  }`;
};

export const formatMonth = (type: PeriodType) => {
  if (type === 'YEAR') return 'год';
  if (type === 'MONTH') return '1 месяц';
  if (type === 'HALF_A_YEAR') return '6 месяцев';
};

export const formatPhone = (phone: string) => {
  return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 ($1) $2-$3-$4');
};

type FullNameType = {
  last_name: string;
  first_name: string;
  middle_name: string | null;
};

export const makeFullName = (data: FullNameType) => {
  return `${data.last_name} ${data.first_name} ${data.middle_name || ''}`;
};

export const isError = (
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: string;
    }>
  >,
) => {
  return Boolean(Object.keys(errors).length);
};

export const removeDuplicates = (array: NotificationType[] | IncomesType[]) => {
  return [...new Set(array.map(el => JSON.stringify(el)))].map(el => JSON.parse(el));
};

export const makeFlat = (params: Record<string, string | number>[]) => {
  const result: ParamDictionaryType = {};
  const booleanValues = { False: false, false: false, True: true, true: true };
  for (let param of params) {
    const { name, value } = param;
    if (value in booleanValues) {
      result[name] = booleanValues[value as keyof typeof booleanValues];
    } else if (Number.isNaN(Number(value))) {
      result[name] = value;
    } else {
      result[name] = Number(value);
    }
  }
  return result;
};

export const transformObjectToArray = (obj: { [n: string]: string | number | boolean } | null) => {
  const result: ParamType[] = [];
  if (!obj) return result;

  for (let param of Object.entries(obj)) {
    const [name, value] = param;
    result.push({
      name,
      value: String(value),
    });
  }
  return result;
};

export const getText = (key: string): string[] => {
  const { t } = useTranslation();
  return t(key, {
    returnObjects: true,
  });
};

const MONTHS_ARRAY = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const formatMonthsAndYears = (date: string) => {
  const yy: string = date.substring(0, 4);
  const mm: string = date.substring(5, 7);

  let mmts = MONTHS_ARRAY[Number(mm) - 1];

  return `${mmts} ${yy}`;
};

export const isDatesEqual = (prevDate: string, nextDate: string) => {
  return new Date(prevDate).setHours(0, 0, 0, 0) === new Date(nextDate).setHours(0, 0, 0, 0);
};

export const formatPrice = (value: string | number, separator = ' '): string => {
  const splitStrToArray = value.toString().split('.');
  const integerPart = parseInt(splitStrToArray[0]).toString();
  let floatPart = splitStrToArray[1];

  if (floatPart?.length > 2) {
    floatPart = floatPart.slice(0, 2);
  }

  let result = '';
  const maxIndex = integerPart.length - 1;

  for (let i = maxIndex; i >= 0; i -= 1) {
    if (i !== maxIndex && (maxIndex - i) % 3 === 0) {
      result = integerPart[i] + separator + result;
    } else {
      result = integerPart[i] + result;
    }
  }

  return floatPart ? `${result}.${floatPart}` : result;
};
