import { SyntheticEvent } from 'react';

import cn from 'classnames';
import { ChangeIcon } from '../../../../../assets/svg';
import styles from './EditBtn.module.scss';

interface PropsType {
  description?: string;
  isSmall?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  isDisabled?: boolean;
}

export const EditBtn = ({
  description,
  onClick,
  isSmall = false,
  isDisabled = false,
}: PropsType) => {
  const buttonStyles = cn(styles.button, { [styles.smallButton]: isSmall });
  return (
    <button className={buttonStyles} onClick={onClick} type="button" disabled={isDisabled}>
      <ChangeIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
