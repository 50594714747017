import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { useScrollShadow } from '../../../../hooks';
import { LabeledWeightItem } from './LabeledWeightItem/LabeledWeightItem';
import { WeightTypeListItem, WeightsTableProps } from '../types';

import styles from './WeightsTable.module.scss';

export const WeightsTable = ({ modelName, setCheckedWeight, weights }: WeightsTableProps) => {
  const { t } = useTranslation();
  const { borderPosition, onScroll } = useScrollShadow();

  const listContainerClassName = cn(styles.listContainer, {
    [styles.listContainerTopBorder]: borderPosition === 'top' || borderPosition === 'both',
    [styles.listContainerBottomBorder]: borderPosition === 'bottom' || borderPosition === 'both',
  });
  const columns: string[] = t('pages.alliancePage.columns', { returnObjects: true });

  return (
    <div className={styles.container}>
      {weights.length === 0 ? (
        <span className={styles.description}>
          {t('popups.addAnyModelToSpecificAlliance.modelWithoutWeights')}
        </span>
      ) : (
        <>
          <ul className={styles.columns}>
            {columns.map(column => (
              <li key={column}>{column}</li>
            ))}
          </ul>
          <div className={listContainerClassName}>
            <ul className={styles.rows} onScroll={onScroll}>
              {weights.map((weight: WeightTypeListItem) => (
                <LabeledWeightItem
                  key={weight.id}
                  setCheckedWeight={setCheckedWeight}
                  weight={weight}
                  modelName={modelName}
                />
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
