export const text = {
  infoPopup: {
    length: 'От 8 до 20 символов',
    capitalLetters: 'Заглавные латинские буквы: от A до Z',
    smallLetters: 'Строчные латинские буквы: от a до z',
    //eslint-disable-next-line
    specialCharacters: `! “ # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _\` { | } ~(кроме пробела)`,
    numbers: 'Цифры от 0 до 9',
  },
  weak: 'Слабый пароль',
  strong: 'Надёжный пароль',
  errorPopup: {
    lengthShort: 'Используйте не менее 8 символов',
    lengthLong: 'Используйте менее 20 символов',
    //eslint-disable-next-line
    specialCharacters: `Используйте только буквы (a–z, A–Z), цифры и символы ! “ # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _\` { | } ~`,
  },
};
