import { useState, UIEvent } from 'react';

type BorderPositionType = 'both' | 'top' | 'bottom' | null;

export const useScrollShadow = () => {
  const [borderPosition, setBorderPosition] = useState<BorderPositionType>(null);

  const onScroll = (event: UIEvent<HTMLElement>) => {
    let scrollBottom =
      event.currentTarget.scrollHeight -
      event.currentTarget.scrollTop -
      event.currentTarget.clientHeight -
      1;
    let scrollTop = event.currentTarget.scrollTop;

    if (scrollTop > 0 && scrollBottom > 0) {
      setBorderPosition('both');
      return;
    }
    if (scrollTop > 0) {
      setBorderPosition('top');
      return;
    }
    if (scrollBottom > 0) {
      setBorderPosition('bottom');
      return;
    }

    setBorderPosition(null);
  };

  return { borderPosition, onScroll };
};
