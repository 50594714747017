export const CheckMarkIcon = () => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none">
    <path
      d="M14.2719 1L4.06296 11.2667L0.583008 7.76704"
      stroke="#0d70f2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
