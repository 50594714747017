import { useEffect, useState } from 'react';
import cn from 'classnames';

import { NextArrowIcon, PrevArrowIcon } from '../../../assets/svg';

import styles from './Pagination.module.scss';

const FIRST_PAGE = 1;

export const Pagination = ({
  pagesCount,
  handleSwitchPage,
}: {
  pagesCount: number;
  handleSwitchPage: (page: number) => void;
}) => {
  const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE);
  const pages: number[] = new Array(pagesCount).fill('');

  const prevBtnStyle = cn(styles.arrow, {
    [styles.arrowActive]: currentPage !== FIRST_PAGE,
  });

  const nextBtnStyle = cn(styles.arrow, {
    [styles.arrowActive]: currentPage !== pages.length,
  });

  const isPageHide = (page: number) => {
    if (page === FIRST_PAGE || page === pages.length) {
      return false;
    }
    if (currentPage >= pages.length - 3) {
      if (page >= pages.length - 5) {
        return false;
      }
    }

    if (currentPage < 5) {
      if (page <= 6) {
        return false;
      }
    }

    if (currentPage === 4) {
      if (page > currentPage + 1) {
        return true;
      }
    }

    if (currentPage === pages.length - 3) {
      if (page < pages.length - 4) {
        return true;
      }
    }
    if (page === currentPage) {
      return false;
    }

    if (Math.abs(page - currentPage) <= 3) {
      return false;
    }

    return true;
  };

  const isPageDotted = (page: number) => {
    if (page === FIRST_PAGE || page === pages.length) {
      return false;
    }
    if (currentPage >= pages.length - 3) {
      if (page >= pages.length - 4) {
        return false;
      }
    }

    if (currentPage < 5) {
      if (page <= 5) {
        return false;
      }
    }

    if (currentPage === 4) {
      if (page > currentPage + 1) {
        return true;
      }
    }

    if (currentPage === pages.length - 3) {
      if (page < pages.length - 4) {
        return true;
      }
    }
    if (page === currentPage) {
      return false;
    }

    if (Math.abs(page - currentPage) <= 2) {
      return false;
    }

    return true;
  };

  const pageClassName = (page: number) =>
    cn(styles.pagesItem, {
      [styles.pagesItemActive]: currentPage === page,
      [styles.pagesItemHide]: isPageHide(page),
      [styles.pagesItemDotted]: isPageDotted(page),
    });

  const handleArrowClick = (to: 'prev' | 'next') => {
    if (to === 'prev') {
      if (currentPage === FIRST_PAGE) {
        return;
      }
      handleSwitchPage(currentPage - 1);
      setCurrentPage(prev => prev - 1);
      return;
    }
    if (to === 'next') {
      if (currentPage === pages.length) {
        return;
      }
      handleSwitchPage(currentPage + 1);
      setCurrentPage(prev => prev + 1);
      return;
    }
  };

  const handlePageClick = (page: number) => {
    if (page === currentPage) {
      return;
    }
    handleSwitchPage(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    setCurrentPage(FIRST_PAGE);
  }, [pagesCount]);

  return (
    <div className={styles.wrapper}>
      <button className={prevBtnStyle} onClick={() => handleArrowClick('prev')} type="button">
        <PrevArrowIcon />
      </button>
      <ul className={styles.pagesList}>
        {pages.map((page: number, i: number) => (
          <li className={pageClassName(i + 1)} key={i + 1} onClick={() => handlePageClick(i + 1)}>
            {i + 1}
          </li>
        ))}
      </ul>
      <button className={nextBtnStyle} onClick={() => handleArrowClick('next')} type="button">
        <NextArrowIcon />
      </button>
    </div>
  );
};
