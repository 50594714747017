export const PaidIcon = ({
  isDisabled = false,
  isSmall = false,
}: {
  isDisabled?: boolean;
  isSmall?: boolean;
}) => {
  const fillColor = isDisabled ? '#EEEEF2' : '#FEF1D8';
  const strokeColor = isDisabled ? '#9CA2B0' : '#FF9A00';

  return isSmall ? (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none">
      <g clipPath="url(#clip0_15743_10933)">
        <circle cx="6.5" cy="6.5" r="6" fill="#FEF1D8" stroke="#FF9A00" />
        <path d="M4.55005 8.3999H6.71672" stroke="#FF9A00" strokeLinecap="round" />
        <path
          d="M5.1691 6.7168V4.2468C5.1691 4.12603 5.1691 4.06564 5.17672 4.01511C5.21867 3.73698 5.42642 3.51885 5.6913 3.4748C5.73942 3.4668 5.79693 3.4668 5.91195 3.4668H7.33576C8.19049 3.4668 8.88338 4.19433 8.88338 5.0918C8.88338 5.98926 8.19049 6.7168 7.33576 6.7168H5.1691ZM5.1691 6.7168V9.9668M5.1691 6.7168H4.55005"
          stroke="#FF9A00"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15743_10933">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <circle
        cx="12"
        cy="12"
        r="9.58333"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth="0.833333"
      />
      <path
        d="M9 14.9238H12.3333"
        stroke={strokeColor}
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
      <path
        d="M9.95238 12.334V8.53398C9.95238 8.34818 9.95238 8.25528 9.96411 8.17755C10.0287 7.74966 10.3483 7.41407 10.7558 7.3463C10.8298 7.33398 10.9183 7.33398 11.0952 7.33398H13.2857C14.6007 7.33398 15.6667 8.45327 15.6667 9.83398C15.6667 11.2147 14.6007 12.334 13.2857 12.334H9.95238ZM9.95238 12.334V17.334M9.95238 12.334H9"
        stroke={strokeColor}
        strokeWidth="0.833333"
        strokeLinecap="round"
      />
    </svg>
  );
};
