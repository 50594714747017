import { ModelErrorsTypes } from '../../../../pages/ModelPage/types';
import { ParamType } from '../../Params/types';
import { AttachmentsType } from '../../Upload/types';

export type PropTypes = {
  id: number | undefined;
  isReadOnly: boolean;
  onClose: () => void;
  onStateChange?: (state: string) => void;
};

export type ModelType = {
  id: number;
  state: string;
  role: string;
  alliance: { name: string; id: number; isHidden: boolean } | null;
  data: {
    name: string;
    type: string;
    category: string;
    task: string;
    description: string;
    tags: string[];
    attachments: AttachmentsType[];
    modelParams: Record<string, string>;
    datasetParams: Record<string, string>;
    testParams: Record<string, string>;
    trainParams: Record<string, string>;
    reports: ModelErrorsTypes[];
    isFavorite: boolean;
    isPrivate: boolean;
    isPaid: boolean;
    isJointMl: boolean;
    hasFl: boolean;
    link: { name: string; link: string };
    update: string;
    create: string;
    hasWeights?: boolean;
  };
  isNameTaken: boolean;
  createdDate: string;
  applicationId: number;
};

export const modelInitialState = {
  id: 0,
  state: '',
  role: '',
  alliance: null,
  data: {
    name: '',
    type: '',
    category: '',
    task: '',
    description: '',
    tags: [],
    attachments: [],
    modelParams: { mockParam: '' },
    datasetParams: { mockParam: '' },
    testParams: { mockParam: '' },
    trainParams: { mockParam: '' },
    reports: [],
    isFavorite: false,
    isPrivate: false,
    isPaid: false,
    isJointMl: false,
    hasFl: false,
    link: { name: '', link: '' },
    update: '',
    create: '',
  },
  isNameTaken: false,
  createdDate: '',
  applicationId: 0,
};

export type WeightType = {
  name: string;
  description: string;
  id: number;
  params: {
    model: ParamType[];
    test: ParamType[];
    train: ParamType[];
  };
  size: number;
  date: string;
};

export type UserType = {
  fullName: string;
  position: string;
  companyName: string;
  INN: string;
  industry: string;
  email: string;
};

export const userInitialState = {
  fullName: '',
  position: '',
  companyName: '',
  INN: '',
  industry: '',
  email: '',
};
