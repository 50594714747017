import { useNavigation } from '../../../hooks';
import { BackIcon } from '../../../assets/svg';
import { PropTypes } from './types';

import styles from './BackButton.module.scss';

export const BackButton = ({ title, link }: PropTypes) => {
  const { handleBack, isBack } = useNavigation();

  return (
    <div>
      {(isBack || link) && (
        <button className={styles.button} onClick={() => handleBack(link)}>
          <BackIcon />
          <p className={styles.title}>{title}</p>
        </button>
      )}
    </div>
  );
};
