import {
  mapControlledAlliances,
  mapInvitations,
  mapMyAlliances,
  mapOpenAlliances,
  mapRequests,
} from '../mappers';
import { mapAvailableAlliances } from '../mappers/alliances/alliances';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString } from '../utils';
import {
  AllianceMemberAction,
  AllianceMemberJoin,
  AvailableAlliancesCounterRequest,
  AvailableAlliancesRequest,
  AvailableAlliancesResponse,
  ControlledAlliancesResponse,
  INewAllianceData,
  InvitationsToAlliancesResponse,
  MyAlliancesResponse,
  NewAllianceResponse,
  OpenAlliancesResponse,
  RequestToAllianceResponse,
  RequestsToAlliancesResponse,
  TransformedAvailableAlliancesRequest,
  TransformedControlledUserAlliancesResponse,
  TransformedInvitationsToAlliancesResponse,
  TransformedMyAlliancesResponse,
  TransformedOpenAlliancesResponse,
  TransformedRequestsToAlliancesResponse,
} from './alliances/types';
import { QueriesType } from './types';

export const alliancesApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getNewAllianceData: build.query<NewAllianceResponse, INewAllianceData>({
      query(newAllianceData) {
        return {
          url: `/alliances/`,
          credentials: 'include',
          method: 'POST',
          body: JSON.stringify(newAllianceData),
        };
      },
    }),
    getOpenAlliances: build.query<TransformedOpenAlliancesResponse, QueriesType>({
      query(queries) {
        return {
          url: `/alliances/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: OpenAlliancesResponse) => {
        return {
          nextPageLink: response.links.next,
          result: mapOpenAlliances(response.results),
          count: response.count,
        };
      },
      providesTags: () => ['OpenAlliances'],
    }),
    getControlledAlliances: build.query<TransformedControlledUserAlliancesResponse, QueriesType>({
      query(queries) {
        return {
          url: `/alliances/administrated/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ControlledAlliancesResponse) => {
        return {
          count: response.count,
          result: mapControlledAlliances(response.results),
        };
      },
      providesTags: () => ['ControlledAlliances'],
    }),
    getInvitations: build.query<TransformedInvitationsToAlliancesResponse, QueriesType>({
      query(queries) {
        return {
          url: `/application/alliance/invited/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: InvitationsToAlliancesResponse) => ({
        count: response.count,
        result: mapInvitations(response.results),
      }),
      providesTags: () => ['InvitationsToAlliances'],
    }),
    getRequests: build.query<TransformedRequestsToAlliancesResponse, QueriesType>({
      query(queries) {
        return {
          url: `/application/alliance/applied/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: RequestsToAlliancesResponse) => ({
        count: response.count,
        result: mapRequests(response.results),
      }),
      providesTags: () => ['RequestsAlliance'],
    }),
    getMyAlliances: build.query<TransformedMyAlliancesResponse, QueriesType>({
      query(queries) {
        return {
          url: `/alliances/my-alliances/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: MyAlliancesResponse) => ({
        count: response.count,
        result: mapMyAlliances(response.results),
      }),
      providesTags: () => ['MyAlliances'],
    }),
    getAvailableListCounter: build.query<number, AvailableAlliancesCounterRequest>({
      query({ state }) {
        return {
          url: `/alliances/${state}/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AvailableAlliancesResponse) => response.count,
    }),
    getAvailableList: build.mutation<
      TransformedAvailableAlliancesRequest,
      AvailableAlliancesRequest
    >({
      query({ queries, state }) {
        return {
          url: `/alliances/${state}/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AvailableAlliancesResponse) =>
        mapAvailableAlliances(response.results),
    }),
    // INVALIDATES
    patchAllianceAction: build.mutation<null, AllianceMemberAction>({
      query({ action, id, userRole }) {
        return {
          url: `/application/${id}/alliance/change-state-by-${userRole}/?state=${action}`,
          method: 'PATCH',
          credentials: 'include',
        };
      },
      invalidatesTags: () => [
        'MyAlliances',
        'OpenAlliances',
        'AllAlliances',
        'AllianceMembers',
        'AllianceMembersCount',
        'AllianceJoinRequestsCount',
        'InvitationsToAlliances',
        'ControlledAlliances',
      ],
    }),
    postJoinAlliance: build.mutation<RequestToAllianceResponse, AllianceMemberJoin>({
      query({ comment, id }) {
        return {
          url: `/alliances/${id}/apply/`,
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({ comment }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['MyAlliances', 'OpenAlliances', 'RequestsAlliance'],
    }),
    deleteAllianceById: build.mutation<null, number>({
      query(id) {
        return {
          url: `/alliances/${id}/`,
          method: 'DELETE',
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
    patchAllianceActiveStatus: build.mutation<null, { id: number; isHidden: boolean }>({
      query({ id, isHidden }) {
        return {
          url: `/alliances/${id}/hide/`,
          method: 'PATCH',
          credentials: 'include',
          body: JSON.stringify({ is_hidden: isHidden }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
    postCreateAlliance: build.mutation<NewAllianceResponse, INewAllianceData>({
      query(newAlliance) {
        return {
          url: `/alliances/`,
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify(newAlliance),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        };
      },
      invalidatesTags: () => ['MyAlliances', 'OpenAlliances', 'ControlledAlliances'],
    }),
  }),
});
