import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Status } from '../../../../common';
import { HOW_TO_ADD_MODEL_PATH } from '../../../../../router';
import { useAppDispatch } from '../../../../../hooks';
import { closePopup } from '../../../../../store';

import styles from './InvalidStateInfo.module.scss';

export const InvalidStateInfo = ({ modelName }: { modelName: string | undefined }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <div className={styles.invalidStateWrapper}>
      <Status state={'canceled'} text={t('statuses.cancel')} />
      <ul className={styles.invalidStateContent}>
        <li className={styles.invalidStateText}>
          {t('popups.weight.invalidStateText.0', { model: modelName })}
        </li>
        <li className={styles.invalidStateText}>
          <Trans
            i18nKey="popups.weight.invalidStateText.1"
            components={{
              a: (
                <Link
                  className={styles.link}
                  to={`/${HOW_TO_ADD_MODEL_PATH}`}
                  onClick={() => dispatch(closePopup())}
                />
              ),
            }}
          />
        </li>
        <li className={styles.invalidStateText}>{t('popups.weight.invalidStateText.2')}</li>
      </ul>
    </div>
  );
};
