export const AccordionIcon = () => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none">
    <path
      d="M0.571655 0.568359L6.90166 6.89836L13.2317 0.568359"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
