import { mapProjects } from '../mappers/developer/developer';
import { mapSummary } from '../mappers/summary/summary';
import { rootAPI } from '../store/rootApi';
import { ProjectsResponse, ProjectsResponseTransformed } from './developer/types';
import { SummaryResponse, SummaryResponseTransformed } from './summary/types';

export const developerApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getProjects: build.mutation<ProjectsResponseTransformed, number>({
      query(id) {
        return {
          url: `/developer/${id}/projects/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: ProjectsResponse) => {
        return mapProjects(response);
      },
    }),
    getSummary: build.mutation<SummaryResponseTransformed, number>({
      query(id) {
        return {
          url: `/developer/${id}/resume/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: SummaryResponse) => {
        return {
          summary: mapSummary(response),
          profileId: response.profile_id,
        };
      },
    }),
  }),
});
