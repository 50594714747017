export const ProjectIcon = () => (
  <svg width="108" height="99" viewBox="0 0 108 99" fill="none">
    <path
      d="M2.06292 61.904C-0.68764 59.1535 -0.687642 54.694 2.06292 51.9433L51.9434 2.06292C54.6938 -0.687639 59.1534 -0.687643 61.904 2.06292L96.3654 36.5243C99.116 39.2749 99.116 43.7344 96.3654 46.485L46.4849 96.3653C43.7345 99.116 39.2749 99.116 36.5243 96.3653L2.06292 61.904Z"
      fill="#2D89EF"
    />
    <path
      d="M34.3477 42.6829C34.3477 38.793 37.501 35.6396 41.3909 35.6396H111.932C115.822 35.6396 118.976 38.793 118.976 42.6829V91.4186C118.976 95.3084 115.822 98.4618 111.932 98.4618H41.3909C37.501 98.4618 34.3477 95.3084 34.3477 91.4186V42.6829Z"
      fill="white"
    />
    <mask id="mask0_11079_524" maskUnits="userSpaceOnUse" x="0" y="0" width="99" height="99">
      <path
        d="M2.06292 61.904C-0.68764 59.1535 -0.687642 54.694 2.06292 51.9433L51.9434 2.06292C54.6938 -0.687639 59.1534 -0.687643 61.904 2.06292L96.3654 36.5243C99.116 39.2749 99.116 43.7344 96.3654 46.485L46.4849 96.3653C43.7345 99.116 39.2749 99.116 36.5243 96.3653L2.06292 61.904Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_11079_524)">
      <path
        d="M46.4837 96.3645L96.364 46.4842C99.1146 43.7336 99.1146 39.2741 96.364 36.5235L95.479 35.6385H41.391C37.501 35.6385 34.3477 38.7919 34.3477 42.6818V91.4175C34.3477 92.9281 34.8232 94.3276 35.6327 95.4745L36.5229 96.3645C39.2735 99.1152 43.733 99.1152 46.4837 96.3645Z"
        fill="url(#paint0_linear_11079_524)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_11079_524"
        x1="31.443"
        y1="35.6386"
        x2="113.319"
        y2="111.646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D89EF" />
        <stop offset="1" stopColor="#2D89EF" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
