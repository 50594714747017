import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { Params } from '../../Params/Params';
import { ParamsTypes } from '../../Params/types';
import { startFLSchema } from '../../../../schemas/modelSchema';
import { Button, InfoBlock, Input, Select } from '../../../common';
import { useAppDispatch } from '../../../../hooks';
import { setNotification } from '../../../../store';
import { adminFLearningApi } from '../../../../api/AdminFLearningApi';
import { flearningApi } from '../../../../api/FLearningApi';
import { makeFlat } from '../../../../utils/formatData';
import { modelsApi } from '../../../../api/ModelsApi';
import { StartRoundRequest } from '../../../../api/flearning/types';
import { PropsType, SelectOptionType, defaultParams } from './types';
import { useDebounce } from '../../../../hooks/useDebounce';
import { NotificationTypes } from '../../../../store/types';

import styles from './StartFLPopup.module.scss';

export const StartFLPopup = ({
  paramsData,
  modelId,
  modelName,
  weightsCount,
  isAdmin = false,
  onClose,
}: PropsType) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(startFLSchema),
    mode: 'onChange',
  });

  const [params, setParams] = useState<ParamsTypes>(paramsData || defaultParams);
  const [weightId, setWeightId] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [weight, roundDuration] = watch(['weight', 'roundDuration']);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const debouncedSearch = useDebounce(setSearchValue, 500);
  const flApi = isAdmin
    ? adminFLearningApi.usePostStartRoundAsAdminMutation
    : flearningApi.usePostStartRoundMutation;

  const [startRound] = flApi();
  const { data } = modelsApi.useGetModelWeightsQuery({
    id: modelId,
    search: searchValue?.trim() || '',
  });

  const isWarnShown = Number(weightsCount) > 0 && !weightId;
  const shouldShowParams = Boolean(Object.values(params).flat().length);

  const weightsList: SelectOptionType[] = data
    ? data.result.map(({ name, id }) => ({
        name,
        id,
      }))
    : [];

  useEffect(() => {
    debouncedSearch(weight);
  }, [weight]);

  useEffect(() => {
    weight && setValue('weight', weight.replace(/\s+/g, ' '));
    if (!weight) return;
    const invalidValue =
      weight && !weightsList.map((w: SelectOptionType) => w.name).includes(weight);
    if (invalidValue) {
      setError('weightsList', {
        type: 'custom',
        message: t('popups.startRound.searchError'),
      });
      setWeightId(null);
    } else {
      clearErrors('weightsList');
      setWeightId(weightsList?.find((w: SelectOptionType) => w.name === weight)!.id);
    }
  }, [searchValue]);

  const handleSubmit = () => {
    const data: StartRoundRequest = { ml_model: modelId, duration: `${roundDuration}:00:00` };
    if (weightId) {
      data.initial_weight = weightId;
    }
    if (params.model.length) {
      data.model_params = makeFlat(params.model);
    }
    if (params.dataset.length) {
      data.dataset_params = makeFlat(params.dataset);
    }
    if (params.test.length) {
      data.test_params = makeFlat(params.test);
    }
    if (params.train.length) {
      data.train_params = makeFlat(params.train);
    }

    startRound(data)
      .unwrap()
      .then(({ roundCount }) => {
        onClose();
        dispatch(
          setNotification({
            type: NotificationTypes.RoundHasStarted,
            data: {
              roundName: `Раунд ${roundCount}`,
              modelName,
            },
          }),
        );
      });
  };

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.startRound.title')}</h2>
      <form className={styles.form} noValidate autoComplete="off">
        {weightsCount ? (
          <div className={styles.inputsWrapper}>
            <Select
              id="weight"
              label={t('popups.startRound.selectLabel')}
              register={register}
              helperText={errors.weightsList?.message}
              setValue={setValue}
              value={weight}
              options={weightsList}
              setItemId={setWeightId}
              placeholder={''}
              isRequired={false}
              isReadOnly={false}
            />
            <Input
              id="roundDuration"
              label={t('popups.startRound.inputLabel')}
              register={register}
              helperText={errors.roundDuration?.message}
              maxLength={50}
              isRequired
            />
          </div>
        ) : (
          <Input
            id="roundDuration"
            label={t('popups.startRound.inputLabel')}
            register={register}
            helperText={errors.roundDuration?.message}
            maxLength={50}
            isRequired
          />
        )}
        {isWarnShown && (
          <div className={styles.infoBlockWrapper}>
            <InfoBlock text={t('popups.startRound.warnText')} />
          </div>
        )}
        {shouldShowParams && (
          <div className={styles.paramsWrapper}>
            <Params
              params={params}
              isReadOnly={true}
              isEditOnly={true}
              setParams={setParams}
              title={t('popups.startRound.paramsTitle')}
              info={t('popups.addWeightEditWeight.paramsInfo')}
            />
          </div>
        )}
      </form>
      <div className={styles.buttonsWrapper}>
        <Button style="secondary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button isDisabled={!roundDuration || Boolean(errors.roundDuration)} onClick={handleSubmit}>
          {t('popups.startRound.submitText')}
        </Button>
      </div>
    </div>
  );
};
