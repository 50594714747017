import { SyntheticEvent } from 'react';
import cn from 'classnames';
import { TickBtnIcon } from '../../../../../assets/svg';

import styles from './TickBtn.module.scss';

interface PropsType {
  description: string;
  isDisabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}

export const TickBtn = ({ description, isDisabled, onClick }: PropsType) => {
  return (
    <button
      className={cn(styles.button, styles.approve)}
      disabled={isDisabled}
      onClick={onClick}
      type="button"
    >
      <TickBtnIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
