import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { feedbacksApi } from '../../../api/FeedbacksApi';
import { useAppSelector } from '../../../hooks';
import { roleSelector } from '../../../store';
import { Roles } from '../../../store/types';
import { TickIcon } from '../../../assets/svg';
import { Estimation } from '..';
import { Spinner } from '../..';

import styles from './EstimatedFeedback.module.scss';

export const EstimatedFeedback = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isLoading } = feedbacksApi.useGetFeedbackRateQuery(Number(id));
  const role = useAppSelector(roleSelector) === Roles.SystemAdministrator ? 'admin' : 'user';

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <TickIcon />
        <h3 className={styles.title}>{t(`estimatedFeedback.${role}.title`)}</h3>
      </div>
      <div className={styles.subtitle}>{t(`estimatedFeedback.${role}.subtitle`)}</div>
      <Estimation count={Number(data?.rate)} />
      {data?.text && (
        <div className={styles.text}>{t(`estimatedFeedback.text`, { text: data?.text })}</div>
      )}
    </div>
  );
};
