import { formatQueriesToString, request } from '../utils';
import { QueriesType } from './types';

import {
  EntityIdType,
  EntityType,
  FavoriteAlliancesResponse,
  FavoriteDevelopersResponse,
  FavoriteModelsResponse,
} from './favorites/types';

export const getFavoriteAlliances = async (
  queries: QueriesType,
): Promise<{ status: string; payload: FavoriteAlliancesResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/favorites/alliances/${formatQueriesToString(queries)}`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const getFavoriteModels = async (
  queries: QueriesType,
): Promise<{ status: string; payload: FavoriteModelsResponse }> => {
  const { status, payload } = await request(
    `${process.env.REACT_APP_BACK_END}/favorites/ml-models/${formatQueriesToString(queries)}`,
    {
      credentials: 'include',
    },
  );
  return { status, payload };
};

export const postAddToFavorites = async (
  userId: number | null,
  entityId: EntityIdType,
  entity: EntityType,
): Promise<{ status: string }> => {
  const formattedEntity = entity === 'ml-model' ? 'ml_model' : entity;
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/favorites/${entity}/`,
    {
      credentials: 'include',
    },
    'POST',
    JSON.stringify({ user: userId, [formattedEntity]: entityId }),
  );

  return { status };
};

export const deleteFromFavorites = async (
  entityId: EntityIdType,
  entity: EntityType,
): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/favorites/${entityId}/${entity}/`,
    {
      credentials: 'include',
    },
    'DELETE',
  );

  return { status };
};

export const getFavoriteDevelopers = async (
  queries: QueriesType,
): Promise<{ status: string; payload: FavoriteDevelopersResponse }> => {
  const { payload, status } = await request(
    `${process.env.REACT_APP_BACK_END}/favorites/developers/${formatQueriesToString(queries)}`,
    {
      credentials: 'include',
    },
  );

  return { payload, status };
};
