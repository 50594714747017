import { SyntheticEvent } from 'react';
import cn from 'classnames';

import { DeleteIcon } from '../../../../../assets/svg';

import styles from './DeleteBtn.module.scss';

interface PropsType {
  description?: string;
  onClick?: (e: SyntheticEvent) => void;
  isDisabled?: boolean;
}

export const DeleteBtn = ({ description, onClick, isDisabled }: PropsType) => {
  return (
    <button
      className={cn(styles.button, styles.deleteButton)}
      onClick={onClick}
      disabled={isDisabled}
      type="button"
    >
      <DeleteIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
