export const FLIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_17869_242613)">
      <rect x="2" y="2" width="20" height="20" rx="10" fill="#0D70F2" />
      <path
        d="M19.7441 2H4.25563C3.01188 2 2 3.01219 2 4.25594V19.7441C2 20.9881 3.01188 22 4.25563 22H19.7441C20.9881 22 22 20.9881 22 19.7441V4.25563C22 3.01188 20.9881 2 19.7441 2ZM18.3997 14.5503C18.3997 15.3772 17.765 16.0506 16.9591 16.1291L13.5656 18.1128C13.43 18.8556 12.7812 19.4216 12 19.4216C11.1681 19.4216 10.4906 18.7788 10.4194 17.9653L7.04812 16.1306C6.23812 16.055 5.6 15.38 5.6 14.5509C5.6 13.8353 6.07625 13.2362 6.72594 13.0341V10.9653C6.07625 10.7637 5.6 10.1644 5.6 9.44937C5.6 8.6225 6.23469 7.94875 7.04125 7.87031L10.4344 5.88719C10.5697 5.14437 11.2188 4.57844 12 4.57844C12.7812 4.57844 13.43 5.14469 13.5656 5.88719L16.9591 7.87062C17.7653 7.94906 18.4 8.62281 18.4 9.44969C18.4 10.1647 17.9237 10.7641 17.2738 10.9656V13.0347C17.9237 13.2359 18.3997 13.8353 18.3997 14.5503Z"
        fill="#ECF3FE"
      />
      <path
        d="M18.3997 14.5503C18.3997 15.3772 17.765 16.0506 16.9591 16.1291L13.5656 18.1128C13.43 18.8556 12.7812 19.4216 12 19.4216C11.1681 19.4216 10.4906 18.7788 10.4194 17.9653L7.04812 16.1306C6.23812 16.055 5.6 15.38 5.6 14.5509C5.6 13.8353 6.07625 13.2362 6.72594 13.0341V10.9653C6.07625 10.7637 5.6 10.1644 5.6 9.44937C5.6 8.6225 6.23469 7.94875 7.04125 7.87031L10.4344 5.88719C10.5697 5.14437 11.2188 4.57844 12 4.57844C12.7812 4.57844 13.43 5.14469 13.5656 5.88719L16.9591 7.87062C17.7653 7.94906 18.4 8.62281 18.4 9.44969C18.4 10.1647 17.9237 10.7641 17.2738 10.9656V13.0347C17.9237 13.2359 18.3997 13.8353 18.3997 14.5503Z"
        fill="#ECF3FE"
      />
      <path
        d="M7.19625 13.4784C6.60442 13.4784 6.12406 13.9591 6.12406 14.5509C6.12406 15.1421 6.60499 15.6228 7.19625 15.6228C7.78764 15.6228 8.26812 15.1423 8.26812 14.5509C8.26812 13.9589 7.78821 13.4784 7.19625 13.4784Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M7.19625 10.5215C7.7884 10.5215 8.26844 10.0415 8.26844 9.44936C8.26844 8.85721 7.7884 8.37718 7.19625 8.37718C6.6041 8.37718 6.12406 8.85721 6.12406 9.44936C6.12406 10.0415 6.6041 10.5215 7.19625 10.5215Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M12.0009 7.2453C12.5931 7.2453 13.0731 6.76527 13.0731 6.17312C13.0731 5.58096 12.5931 5.10093 12.0009 5.10093C11.4088 5.10093 10.9288 5.58096 10.9288 6.17312C10.9288 6.76527 11.4088 7.2453 12.0009 7.2453Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M12.0009 18.8987C12.5931 18.8987 13.0731 18.4187 13.0731 17.8266C13.0731 17.2344 12.5931 16.7544 12.0009 16.7544C11.4088 16.7544 10.9288 17.2344 10.9288 17.8266C10.9288 18.4187 11.4088 18.8987 12.0009 18.8987Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M16.8056 10.5212C17.3978 10.5212 17.8778 10.0412 17.8778 9.44906C17.8778 8.85691 17.3978 8.37687 16.8056 8.37687C16.2135 8.37687 15.7334 8.85691 15.7334 9.44906C15.7334 10.0412 16.2135 10.5212 16.8056 10.5212Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M16.4605 13.43L16.7522 13.3393V13.0338V10.965V10.6592L16.4601 10.5686C15.975 10.4182 15.6259 9.97317 15.6259 9.44907C15.6259 9.10053 15.7824 8.7873 16.0337 8.56723L16.4673 8.18753L15.9697 7.8967L13.6281 6.52827L13.2477 6.30592L13.0485 6.69902C12.8512 7.08862 12.4555 7.35219 12.0006 7.35219C11.5456 7.35219 11.1498 7.08852 10.9528 6.69919L10.7537 6.30582L10.3731 6.52827L8.03154 7.8967L7.53439 8.18725L7.96727 8.56698C8.21822 8.78712 8.37469 9.10048 8.37469 9.44907C8.37469 9.97334 8.02577 10.4183 7.54109 10.5686L7.24906 10.6592V10.965V13.0338V13.3394L7.54092 13.4301C8.02568 13.5807 8.37469 14.0257 8.37469 14.5506C8.37469 14.8915 8.22576 15.1985 7.98517 15.4182L7.55516 15.8109L8.06662 16.0892L10.4376 17.3795L10.7879 17.5702L10.9923 17.2277C11.2009 16.8782 11.5747 16.6472 12.0006 16.6472C12.4555 16.6472 12.8512 16.9108 13.0485 17.3004L13.2477 17.6935L13.6282 17.4711L15.9691 16.1027L16.4662 15.8121L16.0333 15.4324C15.7827 15.2125 15.6259 14.8988 15.6259 14.5506C15.6259 14.026 15.975 13.581 16.4605 13.43Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
      <path
        d="M16.8056 15.6225C17.3978 15.6225 17.8778 15.1425 17.8778 14.5503C17.8778 13.9582 17.3978 13.4781 16.8056 13.4781C16.2135 13.4781 15.7334 13.9582 15.7334 14.5503C15.7334 15.1425 16.2135 15.6225 16.8056 15.6225Z"
        fill="#ECF3FE"
        stroke="#0D70F2"
        strokeWidth="0.83"
      />
    </g>
    <rect
      x="2.415"
      y="2.415"
      width="19.17"
      height="19.17"
      rx="9.585"
      stroke="#0D70F2"
      strokeWidth="0.83"
    />
    <defs>
      <clipPath id="clip0_17869_242613">
        <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
