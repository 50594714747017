import { CrossMarkIcon } from '../../../../assets/svg';

export const CrossIcon = ({ color = null }: { color?: string | null }) => {
  let hexColor = null;

  switch (color) {
    case 'RED':
      hexColor = '#e90b11';
      break;
    case 'GRAY':
      hexColor = '#697381';
      break;
    case 'WHITE':
      hexColor = '#FFFFFF';
      break;
    default:
      hexColor = '#0d70f2';
  }

  return <CrossMarkIcon color={hexColor} />;
};
