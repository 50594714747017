import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import { MODELS_PATH, MODELS_TABS, MODEL_TABS, NEW_MODEL_PATH } from '../routeConstants';

const AdminModelsPage = lazy(() => import('../../pages/AdminModelsPage/ModelsPage'));
const AllModelsTable = lazy(() =>
  import('../../pages/AdminModelsPage/AllModelsTable/AllModelsTable'),
);
const MyModelsTable = lazy(() => import('../../pages/AdminModelsPage/MyModelsTable/MyModelsTable'));
const AddedModelsTable = lazy(() =>
  import('../../pages/AdminModelsPage/AddedModelsTable/AddedModelsTable'),
);
const AddedWeightsTable = lazy(() =>
  import('../../pages/AdminModelsPage/AddedWeightsTable/AddedWeightsTable'),
);
const ModelPage = lazy(() => import('../../pages/ModelPage/ModelPage'));
const ModelInfo = lazy(() => import('../../pages/ModelPage/ModelInfo/ModelInfo'));
const ModelWeights = lazy(() => import('../../pages/ModelPage/ModelWeights/ModelWeights'));
const ModelCode = lazy(() => import('../../pages/ModelPage/ModelCode/ModelCode'));
const NewModelPage = lazy(() => import('../../pages/NewModelPage/NewModelPage'));

const { MY, OPEN, ADDED, WEIGHTS } = MODELS_TABS;
const { INFO, MODEL_WEIGHTS, SOURCES } = MODEL_TABS;

export const adminModelsRoutes = [
  {
    path: MODELS_PATH,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: suspense(<AdminModelsPage />),
        children: [
          {
            index: true,
            element: suspense(<MyModelsTable />),
          },
          {
            path: MY,
            element: suspense(<MyModelsTable />),
          },
          {
            path: OPEN,
            element: suspense(<AllModelsTable />),
          },
          {
            path: ADDED,
            element: suspense(<AddedModelsTable />),
          },
          {
            path: WEIGHTS,
            element: suspense(<AddedWeightsTable />),
          },
        ],
      },
      {
        path: NEW_MODEL_PATH,
        element: suspense(<NewModelPage />),
      },
      {
        path: ':id',
        element: suspense(<ModelPage />),
        children: [
          {
            index: true,
            element: suspense(<ModelInfo />),
          },
          {
            path: INFO,
            element: suspense(<ModelInfo />),
          },
          {
            path: MODEL_WEIGHTS,
            element: suspense(<ModelWeights />),
          },
          {
            path: SOURCES,
            element: suspense(<ModelCode />),
          },
        ],
      },
    ],
  },
];
