import cn from 'classnames';
import { useEffect } from 'react';

import { useAppSelector } from '../../../hooks';
import { popupSelector } from '../../../store/selectors/popupSelector';
import { CloseIcon } from './assets/CloseIcon';

import styles from './Overlay.module.scss';

interface PropTypes {
  children: React.ReactNode;
  onClose: () => void;
  onCancel?: () => void;
}

export const Overlay = ({ children, onClose, onCancel }: PropTypes) => {
  const { isOpen } = useAppSelector(popupSelector);
  const popupClassName = cn(styles.popup, isOpen ? styles.popupOpen : styles.popupClose);

  const handleClose = () => {
    onCancel && onCancel();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight = '1%';
    } else {
      document.body.style.overflowY = 'visible';
      document.body.style.paddingRight = '';
    }
  }, [isOpen]);

  return (
    <div className={popupClassName}>
      {isOpen && (
        <div className={styles.content}>
          <button className={styles.closeBtn} onClick={handleClose}>
            <CloseIcon />
          </button>
          {children}
        </div>
      )}
      <div className={styles.overlay} onClick={handleClose} />
    </div>
  );
};
