import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';

import { appealsApi } from '../../../../api/appealsApi';
import { pauseAppealSchema } from '../../../../schemas/pauseAppealSchema';
import { Button, TextArea } from '../../../common';
import { PropsType } from './types';

import styles from './PauseAppealPopup.module.scss';

export const PauseAppealPopup = ({ id, onClose, onAction }: PropsType) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
    watch,
  } = useForm({
    resolver: yupResolver(pauseAppealSchema),
    mode: 'onTouched',
  });
  const [description] = watch(['description']);
  const [pauseAppeal] = appealsApi.useChangeAppealStateMutation();

  const onSubmit: SubmitHandler<FieldValues> = ({ description }) => {
    pauseAppeal({ id, text: description, state: 'suspended' })
      .unwrap()
      .then(() => {
        onAction();
        onClose();
      });
  };

  return (
    <form
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      className={styles.wrapper}
    >
      <h2 className={styles.title}>{t('popups.pauseAppeal.title')}</h2>
      <p className={styles.subtitle}>
        <Trans
          i18nKey="popups.pauseAppeal.subtitle"
          components={{
            span: <span />,
          }}
        />
      </p>
      <div className={styles.scrollWrapper}>
        <div className={styles.textAreaWrapper}>
          <TextArea
            id="description"
            label={t('popups.pauseAppeal.label')}
            register={register}
            helperText={errors.description?.message}
            value={description}
            maxLength={400}
            isRequired
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button style="secondary" onClick={onClose}>
          {t('buttons.cancel')}
        </Button>
        <Button isDisabled={!isValid} type="submit">
          {t('buttons.pause')}
        </Button>
      </div>
    </form>
  );
};
