export const VerifiedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 1L14.3454 4.78148L18.4656 3.10081L18.1404 7.53871L22.4616 8.60081L19.59 12L22.4616 15.3992L18.1404 16.4613L18.4656 20.8992L14.3454 19.2185L12 23L9.65456 19.2185L5.53436 20.8992L5.85956 16.4613L1.53838 15.3992L4.41 12L1.53838 8.60081L5.85956 7.53871L5.53436 3.10081L9.65456 4.78148L12 1Z"
      fill="#2D89EF"
    />
    <path
      d="M15.6667 10L10.6948 15L9 13.2956"
      stroke="white"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
