import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { EmptyIcon } from '../../../assets/svg';
import { Pagination } from '../Pagination/Pagination';
import { PropTypes } from './types';

import styles from './CardsContainer.module.scss';

export const CardsContainer = ({
  display,
  pageSize,
  children,
  pagesCount,
  handleSwitchPage,
  isEmpty,
  isSearchEmpty,
  emptyText,
  searchEmptyText,
  isInner = false,
}: PropTypes) => {
  const { t } = useTranslation();

  const cardsClassName = cn(styles.cards, {
    [styles.grid]: display === 'grid',
    [styles.gridWeights]: display === 'grid-weights',
    [styles.flex]: display === 'flex',
    [styles.inner]: isInner,
  });

  if (isEmpty) {
    return (
      <div className={styles.helper}>
        <EmptyIcon />
        <p className={styles.text}>{emptyText}</p>
      </div>
    );
  }

  if (isSearchEmpty) {
    return (
      <div className={styles.helper}>
        <EmptyIcon />
        <p className={styles.text}>
          {searchEmptyText ?? t('pages.modelsPage.myTable.searchPlaceholder')}
        </p>
      </div>
    );
  }

  return (
    <div className={isInner ? styles.inner : styles.container}>
      <ul className={cardsClassName}>{children}</ul>
      {Math.ceil(pagesCount / pageSize) > 1 && (
        <Pagination
          pagesCount={Math.ceil(pagesCount / pageSize)}
          handleSwitchPage={handleSwitchPage}
        />
      )}
    </div>
  );
};
