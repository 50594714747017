import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserType } from '../types';
import { walletApi } from '../../api/walletApi';
import { WalletInfo } from '../../api/wallet/types';

const initialState: UserType = {
  firstName: '',
  lastName: '',
  middleName: '',
  phone: '',
  date: '',
  id: null,
  isPhoneHidden: false,
  isSubscriptionActive: false,
  subscription: {
    type: 'none',
    days: 0,
    date: '',
    expirationDate: '',
  },
  wallet: {
    currency: 'RUB',
    currentAmount: '0.00',
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUserId(state, action) {
      state.id = action.payload;
    },
    addUser(state, action) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.middleName = action.payload.middleName;
      state.phone = action.payload.phone;
      state.isPhoneHidden = action.payload.isPhoneHidden;
      state.date = action.payload.date;
      state.id = action.payload.id;
      state.wallet.currency = action.payload.currency;
      state.wallet.currentAmount = action.payload.currentAmount;
    },
    addSubscriptionInfo(state, action) {
      state.isSubscriptionActive = true;
      state.subscription.type = action.payload.type;
      state.subscription.date = action.payload.date;
      state.subscription.days = action.payload.days;
      state.subscription.expirationDate = action.payload.expirationDate;
    },
    editUser(state, action) {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.middleName = action.payload.middleName;
      state.phone = action.payload.phone;
    },
    editPhoneHidden(state, action) {
      state.isPhoneHidden = action.payload;
    },
    clearState(state) {
      state.firstName = '';
      state.lastName = '';
      state.middleName = '';
      state.phone = '';
      state.isPhoneHidden = false;
      state.date = '';
      state.id = null;
      state.isSubscriptionActive = false;
      state.subscription.type = 'none';
      state.subscription.days = 0;
      state.subscription.date = '';
      state.subscription.expirationDate = '';
    },
    increaseWallet(state, action) {
      const increasedWallet = Number(state.wallet.currentAmount) + Number(action.payload);
      state.wallet.currentAmount = increasedWallet.toFixed(2);
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      walletApi.endpoints.getWalletInfo.matchFulfilled,
      (state: UserType, { payload }: PayloadAction<WalletInfo>) => {
        state.wallet.currentAmount = payload.currentAmount;
      },
    );
  },
});

export const {
  addUser,
  editPhoneHidden,
  editUser,
  clearState,
  addUserId,
  addSubscriptionInfo,
  increaseWallet,
} = userSlice.actions;
export default userSlice.reducer;
