import { formatQueriesToString, request } from '../utils';
import { DevelopersResponse, FiltersListResponse } from './developer/types';
import { QueriesType } from './types';

export const addNewProject = async (data: FormData): Promise<Response> => {
  const response = await fetch(`${process.env.REACT_APP_BACK_END}/developer/project/`, {
    method: 'POST',
    body: data,
    credentials: 'include',
  });

  return response;
};

export const editProject = async ({
  formData,
  id,
}: {
  formData: FormData;
  id: number;
}): Promise<Response> => {
  const response = await fetch(`${process.env.REACT_APP_BACK_END}/developer/project/${id}/`, {
    method: 'PATCH',
    body: formData,
    credentials: 'include',
  });
  return response;
};

export const deleteProject = async (id: number | null | undefined): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/project/${id}/`,
    {
      credentials: 'include',
    },
    'DELETE',
  );

  return { status };
};

export const deleteFileInProject = async (
  id: number | null | undefined,
  files: number[],
): Promise<{ status: string }> => {
  const { status } = await request(
    `${process.env.REACT_APP_BACK_END}/upload/project/${id}/attachments/`,
    {
      credentials: 'include',
    },
    'DELETE',
    JSON.stringify({ attachment_ids: files }),
  );

  return { status };
};

export const getDevelopers = async (
  queries: QueriesType,
): Promise<{ status: string; payload: DevelopersResponse }> => {
  const { payload, status } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/resume/${formatQueriesToString(queries)}`,
    {
      credentials: 'include',
    },
  );

  return { payload, status };
};

export const getFiltersList = async (): Promise<{
  status: string;
  payload: FiltersListResponse;
}> => {
  const { payload, status } = await request(
    `${process.env.REACT_APP_BACK_END}/developer/resume-common-info/`,
    { credentials: 'include' },
  );

  return { payload, status };
};
