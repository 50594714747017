import cn from 'classnames';

import { PropTypes } from './types';

import styles from './TitledInfo.module.scss';

export const TitledInfo = ({ title, text, isRow, isSmallGaps, isMonochrome }: PropTypes) => {
  const style = cn(styles.wrapper, {
    [styles.column]: !isRow,
    [styles.row]: isRow,
    [styles.smallGaps]: isSmallGaps,
  });
  const paragraphStyles = cn({
    [styles.text]: isMonochrome,
  });

  return (
    <div className={style}>
      <h3>{title}</h3>
      <p className={paragraphStyles}>{text}</p>
    </div>
  );
};
