import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import { MODELS_PATH, MODELS_TABS, NEW_MODEL_PATH } from '../routeConstants';
import { modelRoutes } from './modelRoutes';

const ModelsPage = lazy(() => import('../../pages/ModelsPage/ModelsPage'));

const NewModelPage = lazy(() => import('../../pages/NewModelPage/NewModelPage'));
const MyModelsTable = lazy(() => import('../../pages/ModelsPage/MyModelsTable/MyModelsTable'));
const OpenModelsTable = lazy(
  () => import('../../pages/ModelsPage/OpenModelsTable/OpenModelsTable'),
);
const AddedModelsTable = lazy(
  () => import('../../pages/ModelsPage/AddedModelsTable/AddedModelsTable'),
);
const AddedWeightsTable = lazy(
  () => import('../../pages/ModelsPage/AddedWeightsTable/AddedWeightsTable'),
);

const { MY, OPEN, ADDED, WEIGHTS } = MODELS_TABS;

export const modelsRoutes = {
  path: MODELS_PATH,
  element: <ContentProtection />,
  children: [
    {
      path: '',
      element: suspense(<ModelsPage />),
      children: [
        {
          index: true,
          element: suspense(<MyModelsTable />),
        },
        {
          path: MY,
          element: suspense(<MyModelsTable />),
        },
        {
          path: OPEN,
          element: suspense(<OpenModelsTable />),
        },
        {
          path: ADDED,
          element: suspense(<AddedModelsTable />),
        },
        {
          path: WEIGHTS,
          element: suspense(<AddedWeightsTable />),
        },
      ],
    },
    ...modelRoutes,
    {
      path: NEW_MODEL_PATH,
      element: suspense(<NewModelPage />),
    },
  ],
};
