import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import { APPEALS_PATH, APPEALS_TABS } from '../routeConstants';
import AppealsTable from '../../pages/AppealsPage/AppealsTable/AppealsTable';

const { ALL, OPEN, IN_PROCESS, SUSPENDED, RESOLVED } = APPEALS_TABS;

const AppealsPage = lazy(() => import('../../pages/AppealsPage/AdminAppealsPage/AdminAppealsPage'));
const AdminAppealPage = lazy(
  () => import('../../pages/AppealPage/AdminAppealPage/AdminAppealPage'),
);

export const appealsRoutes = [
  {
    path: APPEALS_PATH,
    element: <Outlet />,
    children: [
      {
        path: '',
        element: suspense(<AppealsPage />),
        children: [
          {
            index: true,
            element: suspense(<AppealsTable tab={ALL} />),
          },
          {
            path: ALL,
            element: suspense(<AppealsTable tab={ALL} />),
          },
          {
            path: OPEN,
            element: suspense(<AppealsTable tab={OPEN} />),
          },
          {
            path: IN_PROCESS,
            element: suspense(<AppealsTable tab={IN_PROCESS} />),
          },
          {
            path: SUSPENDED,
            element: suspense(<AppealsTable tab={SUSPENDED} />),
          },
          {
            path: RESOLVED,
            element: suspense(<AppealsTable tab={RESOLVED} />),
          },
        ],
      },
      {
        path: ':id',
        element: suspense(<AdminAppealPage />),
      },
    ],
  },
];
