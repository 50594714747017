export const WaitingIcon = () => (
  <svg width="35" height="45" viewBox="0 0 35 45" fill="none">
    <path
      d="M17.5001 31.5881C17.1507 32.5433 15.892 33.3915 13.9862 33.9232C9.14641 35.2735 6.15213 37.1997 5.57227 39.2929H29.4279C28.8478 37.1997 25.8537 35.2735 21.0139 33.9232C19.1082 33.3915 17.8494 32.5433 17.5001 31.5881Z"
      fill="#9CA2B0"
    />
    <path
      d="M7.19824 10.0171C8.64092 12.4807 10.9505 14.6862 13.9862 16.4265C14.9175 16.9574 15.7123 17.6595 16.318 18.4866C16.9238 19.3136 17.3267 20.2465 17.5 21.2236C17.6733 20.2465 18.0762 19.3136 18.682 18.4866C19.2877 17.6595 20.0825 16.9574 21.0138 16.4265C24.0495 14.6862 26.3591 12.4807 27.8018 10.0171H7.19824Z"
      fill="#9CA2B0"
    />
    <path
      d="M22.8656 19.0218C29.0163 15.4962 32.9688 10.0122 32.9688 3.85237C33.4246 3.85237 33.8618 3.69002 34.1841 3.40104C34.5064 3.11205 34.6875 2.72011 34.6875 2.31142V0H0.3125V2.21511C0.312703 2.63241 0.490782 3.03386 0.810292 3.33731C1.1298 3.64076 1.56658 3.82326 2.03125 3.84746V3.85237C2.03125 10.0122 5.98373 15.4962 12.1344 19.0218C14.837 20.571 14.837 24.1165 12.1344 25.6657C5.98373 29.1913 2.03125 34.6753 2.03125 40.8351V40.84C1.56658 40.8642 1.1298 41.0467 0.810292 41.3502C0.490782 41.6536 0.312703 42.0551 0.3125 42.4724V44.6875H34.6875V42.3761C34.6875 41.9674 34.5064 41.5754 34.1841 41.2865C33.8618 40.9975 33.4246 40.8351 32.9688 40.8351C32.9688 34.6753 29.0163 29.1913 22.8656 25.6657C20.163 24.1165 20.163 20.571 22.8656 19.0218ZM19.1199 22.3438C19.1171 23.2646 19.3742 24.1711 19.8672 24.9789C20.3603 25.7867 21.0732 26.4696 21.9397 26.964C27.8565 30.3555 31.25 35.4115 31.25 40.8351H3.75C3.75 35.4115 7.14346 30.3555 13.0603 26.964C13.9248 26.4678 14.6363 25.7845 15.1291 24.9771C15.6218 24.1697 15.8801 23.2641 15.8801 22.3438C15.8801 21.4234 15.6218 20.5178 15.1291 19.7104C14.6363 18.903 13.9248 18.2197 13.0603 17.7235C7.14346 14.332 3.75 9.27603 3.75 3.85237H31.25C31.25 9.27603 27.8565 14.332 21.9397 17.7235C21.0732 18.2179 20.3603 18.9008 19.8672 19.7086C19.3742 20.5164 19.1171 21.4229 19.1199 22.3438Z"
      fill="#9CA2B0"
    />
    <path
      d="M17.5 25.3755C17.7137 25.3755 17.9186 25.2994 18.0697 25.1639C18.2208 25.0285 18.3057 24.8447 18.3057 24.6532V23.8839C18.3057 23.6924 18.2208 23.5086 18.0697 23.3732C17.9186 23.2377 17.7137 23.1616 17.5 23.1616C17.2863 23.1616 17.0814 23.2377 16.9303 23.3732C16.7792 23.5086 16.6943 23.6924 16.6943 23.8839V24.6532C16.6943 24.8447 16.7792 25.0285 16.9303 25.1639C17.0814 25.2994 17.2863 25.3755 17.5 25.3755Z"
      fill="#9CA2B0"
    />
    <path
      d="M16.6943 28.5064C16.6943 28.698 16.7792 28.8817 16.9303 29.0172C17.0814 29.1527 17.2863 29.2288 17.5 29.2288C17.7137 29.2288 17.9186 29.1527 18.0697 29.0172C18.2208 28.8817 18.3057 28.698 18.3057 28.5064V27.7372C18.3057 27.5456 18.2208 27.3619 18.0697 27.2265C17.9186 27.091 17.7137 27.0149 17.5 27.0149C17.2863 27.0149 17.0814 27.091 16.9303 27.2265C16.7792 27.3619 16.6943 27.5456 16.6943 27.7372V28.5064Z"
      fill="#9CA2B0"
    />
  </svg>
);

export const InProgressIcon = () => (
  <svg width="54" height="55" viewBox="0 0 54 55" fill="none">
    <path d="M24.8135 20.1626L3.99531 42.5419" stroke="#94C4FF" />
    <path d="M24.9912 35.4619L1.59877 43.2008" stroke="#94C4FF" />
    <path d="M25.152 5.49954L6.52245 11.5431" stroke="#94C4FF" />
    <path d="M24.6045 20.3145L4.2718 28.2714" stroke="#94C4FF" />
    <path d="M25.1097 50.4411L1.59757 42.8495" stroke="#94C4FF" />
    <path d="M25.1098 35.7618L1.41863 28.7789" stroke="#94C4FF" />
    <path d="M3.72077 13.4543L25.165 35.461M1.24496 28.4257L25.165 49.7075" stroke="#94C4FF" />
    <path d="M3.72075 13.4543L25.165 20.5108" stroke="#94C4FF" />
    <path d="M24.8135 5.38672L1.42103 28.4274" stroke="#94C4FF" />
    <path d="M24.6377 5.73926L3.72107 43.364" stroke="#94C4FF" />
    <path d="M24.9912 50.2388L1.42289 13.4792" stroke="#94C4FF" />
    <ellipse
      cx="4.06722"
      cy="13.5777"
      rx="3.35563"
      ry="3.35563"
      transform="rotate(180 4.06722 13.5777)"
      fill="#51A0F7"
    />
    <ellipse
      cx="4.06722"
      cy="28.3514"
      rx="3.35563"
      ry="3.35563"
      transform="rotate(180 4.06722 28.3514)"
      fill="#51A0F7"
    />
    <ellipse
      cx="4.06722"
      cy="43.1258"
      rx="3.35563"
      ry="3.35563"
      transform="rotate(180 4.06722 43.1258)"
      fill="#51A0F7"
    />
    <path d="M25.1514 19.8745L48.3679 42.7393" stroke="#94C4FF" />
    <path d="M24.9766 35.1782L48.369 42.9171" stroke="#94C4FF" />
    <path d="M30.0778 7.03599L43.4449 11.2571" stroke="#94C4FF" />
    <path d="M30.0778 21.608L43.4449 25.8291" stroke="#94C4FF" />
    <path d="M24.8571 50.156L48.3692 42.5644" stroke="#94C4FF" />
    <path d="M24.857 35.4767L48.5482 28.4937" stroke="#94C4FF" />
    <path d="M48.7209 13.1912L24.8008 35.1765M48.7209 28.1412L24.8008 49.4231" stroke="#94C4FF" />
    <path d="M48.3691 12.8391L24.8008 20.2262" stroke="#94C4FF" />
    <path d="M25.1514 5.10229L48.5438 28.143" stroke="#94C4FF" />
    <path d="M25.3291 5.45435L48.3698 42.9174" stroke="#94C4FF" />
    <path d="M24.9766 49.9507L48.5449 13.1912" stroke="#94C4FF" />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 19.876 10.553)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 43.0928 18.2915)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 19.876 25.3284)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 43.0928 33.0681)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 19.876 40.0996)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 43.0928 47.8408)"
      fill="#94C4FF"
    />
    <ellipse
      cx="5.27649"
      cy="5.27649"
      rx="5.27649"
      ry="5.27649"
      transform="matrix(1 0 0 -1 19.876 54.8765)"
      fill="#94C4FF"
    />
  </svg>
);

export const ErrorIcon = () => (
  <svg width="47" height="43" viewBox="0 0 47 43" fill="none">
    <path
      d="M23.5 23.7248V12.6369M23.5 31.9432V32.0407M37.3598 41.2752H9.64017C5.85381 41.2752 2.65848 38.7447 1.65315 35.2826C1.224 33.8047 1.75131 32.2654 2.55785 30.9547L16.4177 5.68237C19.6649 0.405595 27.3351 0.405603 30.5823 5.68238L44.4421 30.9547C45.2487 32.2654 45.776 33.8047 45.3468 35.2826C44.3415 38.7447 41.1462 41.2752 37.3598 41.2752Z"
      stroke="#FF3A40"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
