import { t } from 'i18next';
import { IModel } from './types';
import { Tab } from '../../ui/components/Tabs/Tab/Tab';
import { MODEL_PATH, MODELS_PATH } from '../../router';

export const initialModelData: IModel = {
  id: 0,
  data: {
    name: '',
    type: '',
    category: '',
    task: '',
    description: '',
    tags: [],
    attachments: [],
    modelParams: {},
    datasetParams: {},
    testParams: {},
    trainParams: {},
    link: { name: null, link: '' },
    create: '',
    update: '',
    isFavorite: false,
    isPrivate: false,
    isPaid: false,
    isJointMl: false,
    hasFl: false,
    reports: [],
    hasWeights: false,
  },
  alliance: null,
  state: 'ready',
  role: '',
  owner: null,
};

export const initialTabs = [
  { name: 'Карточка модели', path: 'info', index: true },
  { name: 'Версии весов', path: 'weights' },
  { name: 'Исходный код', path: 'sources' },
];

export const accordionData = {
  title: 'Какие документы следует добавить?',
  content: [
    'Пример ETL-скрипта',
    'Инструкцию о развертывании и использовании ML-модели на устройстве пользователя',
    'Паспорт ML-модели, с ее входными и выходными параметрами',
  ],
};

export const getBackTitle = (isToAlliance: boolean, isToUsersMls: boolean, isToMain: boolean) => {
  if (isToAlliance) return t('navigation.toAlliance');
  if (isToUsersMls) return t('navigation.toUserModelsList');
  if (isToMain) return t('navigation.toMainPage');
  return t('navigation.toModelsList');
};

export const tabsConstructor = (
  id: string | undefined,
  isAllianceModel: boolean,
  isWeightNotReady: boolean,
  isPaidContent: boolean,
) => {
  const indexRoute = `${isAllianceModel ? MODEL_PATH : MODELS_PATH}/${id}`;
  return initialTabs.map((el, index) => {
    const isTabDisabled = (index !== 0 && isWeightNotReady) || (isPaidContent && index === 2);
    return (
      <Tab
        indexRoute={indexRoute}
        key={index}
        tabData={el}
        tabState={{ isDisabled: isTabDisabled }}
        style="primary"
      />
    );
  });
};
