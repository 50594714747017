import { ParamsBoard } from './ParamsBoard/ParamsBoard';
import { InfoBlock } from '../../common';
import { ParamType, ParamsCategory, PropTypes } from './types';

import styles from './Params.module.scss';

export const Params = ({
  params,
  isReadOnly,
  isEditOnly,
  setParams,
  title = '',
  info = '',
}: PropTypes) => {
  const handleDelete = (category: ParamsCategory, paramName: string) => {
    const changedParamsCategory = params[category]
      .slice()
      .filter(param => param.name !== paramName);
    setParams({ ...params, [category]: changedParamsCategory });
  };

  const handleEdit = (category: ParamsCategory, paramName: string, editedParam: ParamType) => {
    const changedParamsCategory = params[category]
      .slice()
      .map(param => (param.name === paramName ? editedParam : param));
    setParams({ ...params, [category]: changedParamsCategory });
  };

  const handleAdd = (category: ParamsCategory, newParam: ParamType) => {
    const changedParamsCategory = params[category].slice();
    changedParamsCategory.push(newParam);
    setParams({ ...params, [category]: changedParamsCategory });
  };

  const isHidden = (param: ParamType[]) => isReadOnly && !param.length;

  const paramsArr = Object.entries(params);

  const ParamsNodes = paramsArr.map(([category, paramArr]) => {
    if (isHidden(paramArr)) return null;
    return (
      <ParamsBoard
        key={category}
        params={paramArr}
        category={category as ParamsCategory}
        isReadOnly={isReadOnly}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onEdit={handleEdit}
        isEditOnly={isEditOnly}
      />
    );
  });

  return (
    <div>
      {title && <p className={styles.title}>{title}</p>}
      {info && (
        <div className={styles.infoWrapper}>
          <InfoBlock text={info} />
        </div>
      )}
      <div className={styles.paramsWrapper}>{ParamsNodes}</div>
    </div>
  );
};
