import { RouteObject, useRoutes } from 'react-router-dom';
import { authRoutes, mainRoutes } from './routes';
import App from '../App';

export const AppRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [...authRoutes, ...mainRoutes],
  },
];

export const AppRouter = () => useRoutes(AppRoutes);

export * from './routeConstants';
export * from './HOC/RoutesProtection';
export { adminNavBar } from './navBars/adminNavBar';
export { navBar } from './navBars/navBar';
export { systemAdminNavBar } from './navBars/systemAdminNavBar';
export { suspense } from './HOC/suspense';
