import { useEffect, useState } from 'react';

import { modelsApi } from '../../../../api/ModelsApi';
import { ModelPopupType } from '../../../../types';
import { useAppSelector } from '../../../../hooks';
import { isAdminSelector } from '../../../../store';
import { adminModelApi } from '../../../../api/adminModelApi';

export const useScrollData = (borderPosition: string | null, id: number) => {
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<ModelPopupType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const isAdmin = useAppSelector(isAdminSelector);

  const [fetchAvailableModels] = isAdmin
    ? adminModelApi.useGetAvailableModelsAsAdminMutation()
    : modelsApi.useGetAvailableModelsMutation();

  const getAvailableModels = (page: number) => {
    fetchAvailableModels({
      id,
      page,
    })
      .unwrap()
      .then(data => {
        setData(prev => [...prev, ...(data.result ?? [])]);
        setCount(data.count);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAvailableModels(page);
  }, [page]);

  useEffect(() => {
    let maxPages = Math.ceil(count / 10);
    if (borderPosition === 'top' && page < maxPages) {
      setPage(prev => prev + 1);
    }
  }, [borderPosition]);

  return { data, isLoading };
};
