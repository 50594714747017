import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getProjectFormData } from '../../../../utils/projectFormData';
import { projectSchema } from '../../../../schemas/projectSchema';
import { addNewProject } from '../../../../api/';
import { useAppDispatch } from '../../../../hooks';
import { openErrorPopup, setNotification } from '../../../../store';
import { NotificationTypes } from '../../../../store/types';
import { Button, Input, TextArea } from '../../../common';
import { FormUpload } from '../../FormUpload/FormUpload';
import { LinkController } from '../../LinkController/LinkController';
import { PropTypes } from './types';

import styles from './AddProjectToPortfolio.module.scss';

export const AddProjectToPortfolio = ({ onClose, onAction }: PropTypes) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(projectSchema),
    mode: 'onTouched',
  });

  const [files, setFiles] = useState<File[]>([]);
  const [link, setLink] = useState<{ name: string | null; link: string }>({
    name: '',
    link: '',
  });

  const [description, name] = watch(['description', 'name']);

  const onSubmit: SubmitHandler<FieldValues> = async data => {
    setIsDisabled(true);
    const formData = getProjectFormData({ inputValues: data, link, files });
    const response = await addNewProject(formData);
    if (!response.ok) {
      dispatch(openErrorPopup());
    } else {
      onAction();
      onClose();
      dispatch(
        setNotification({
          type: NotificationTypes.AddProjectToPortfolio,
          data: { projectName: data.name },
        }),
      );
    }
  };

  useEffect(() => {
    name && setValue('name', name.replace(/\s+/g, ' '));
    if (name === ' ') {
      setValue('name', '');
    }
    if (description === ' ') {
      setValue('description', '');
    }
  }, [name, description]);

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h2 className={styles.title}>{t('popups.addProjectToPortfolio.title')}</h2>
        <div className={styles.description}>
          <span>{t('headings.requiredSymbol')}</span>
          {t('popups.addProjectToPortfolio.info')}
        </div>
      </div>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.input}>
          <Input
            id="name"
            label={t('popups.addProjectToPortfolio.name')}
            register={register}
            helperText={errors.name?.message}
            maxLength={20}
            isRequired
          />
          <div className={styles.link}>
            <p>{t('popups.addProjectToPortfolio.link')}</p>
            <LinkController link={link} setLink={setLink} />
          </div>
        </div>
        <div className={styles.textArea}>
          <TextArea
            id="description"
            label={t('popups.addProjectToPortfolio.description')}
            register={register}
            helperText={errors.description?.message}
            value={description}
            maxLength={400}
            isRequired
          />
        </div>
        <FormUpload files={files} setFiles={setFiles} maxQuantity={6} maxSize={50} isMulti />
        <div className={styles.buttons}>
          <Button style="secondary" onClick={onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button type="submit" isDisabled={!isValid || isDisabled}>
            {t('buttons.add')}
          </Button>
        </div>
      </form>
    </div>
  );
};
