import { SyntheticEvent } from 'react';
import cn from 'classnames';

import { MessageIcon } from '../../../../../assets/svg';

import styles from './MessageBtn.module.scss';

interface PropsType {
  description: string;
  onClick: (e: SyntheticEvent) => void;
}

export const MessageBtn = ({ description, onClick }: PropsType) => {
  return (
    <button className={cn(styles.button, styles.message)} onClick={onClick}>
      <MessageIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
