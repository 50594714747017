export const TireIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      d="M9.22246 1.81345C8.79312 1.86712 8.37201 1.9538 7.96331 2.07354C5.1395 0.657507 2.70374 0.426317 1.5643 1.56573C0.424863 2.70517 0.656053 5.14506 2.06798 7.96473C1.94824 8.37347 1.86156 8.79454 1.80789 9.22802C-0.248065 5.64048 -0.586597 2.55242 0.978079 0.979509C2.54686 -0.589275 5.63489 -0.246611 9.22246 1.81345Z"
      fill="#137CF0"
    />
    <path
      d="M16.8367 9.22803C16.783 8.79455 16.6963 8.37347 16.5766 7.96887C17.9885 5.14507 18.2197 2.70518 17.0803 1.56574C15.9408 0.422189 13.501 0.657511 10.6771 2.06941C10.2684 1.9538 9.84734 1.86712 9.41797 1.81345C13.0055 -0.246611 16.0936 -0.589275 17.6665 0.979509C19.2312 2.55243 18.8926 5.64048 16.8367 9.22803Z"
      fill="#137CF0"
    />
    <path
      d="M9.22246 16.8384C6.74288 18.2631 2.96478 19.6591 0.978079 17.6724C-0.586597 16.0995 -0.248065 13.0114 1.80789 9.42383C1.86156 9.85733 1.94824 10.2784 2.06798 10.6871C0.656053 13.5068 0.424863 15.9467 1.5643 17.0861C2.70374 18.2256 5.1395 17.9944 7.96331 16.5783C8.37201 16.698 8.79312 16.7848 9.22246 16.8384Z"
      fill="#137CF0"
    />
    <path
      d="M9.32259 11.1311C11.5754 11.1311 13.1487 10.1947 13.1724 9.34622V8.10999C12.1773 8.91475 10.5953 9.22333 9.32057 9.22333C8.02862 9.22333 6.45933 8.90991 5.46875 8.10254V9.31875C5.4688 9.32158 5.47036 9.32392 5.47036 9.32674C5.47036 10.1802 7.05237 11.1311 9.32259 11.1311Z"
      fill="#137CF0"
    />
    <path
      d="M9.32057 8.39811C10.6084 8.39811 13.1764 7.86228 13.1764 6.58972C13.1764 5.73643 11.5928 4.78577 9.32057 4.78577C7.05056 4.78577 5.46875 5.73642 5.46875 6.58972C5.46875 7.44503 7.05056 8.39811 9.32057 8.39811Z"
      fill="#137CF0"
    />
    <path
      d="M9.32258 11.9569C7.70497 11.9569 6.30549 11.5168 5.46875 10.8341V12.0638C5.46875 13.3306 8.00704 13.8679 9.3246 13.8679C11.5922 13.8679 13.1724 12.9171 13.1724 12.0638V10.837C12.3352 11.5179 10.9377 11.9569 9.32258 11.9569Z"
      fill="#137CF0"
    />
    <path
      d="M9.32019 1.56067C5.03905 1.56067 1.55469 5.04503 1.55469 9.32617C1.55469 13.6073 5.03905 17.0917 9.32019 17.0917C13.6055 17.0917 17.0898 13.6073 17.0898 9.32617C17.0898 5.04503 13.6055 1.56067 9.32019 1.56067ZM13.9977 12.0633C13.9977 13.5381 11.9448 14.6932 9.32425 14.6932C7.46212 14.6932 4.64272 13.9146 4.64272 12.0633V6.58899C4.64272 5.11442 6.69724 3.95936 9.32022 3.95936C11.2864 3.95936 14.1102 4.79751 13.9973 6.70273C13.9982 12.9086 13.9974 5.85802 13.9977 12.0633Z"
      fill="#137CF0"
    />
    <path
      d="M17.6665 17.672C15.6799 19.6586 11.903 18.263 9.41797 16.8381C9.84734 16.7844 10.2684 16.6977 10.6771 16.5821C13.501 17.994 15.9408 18.2293 17.0803 17.0858C18.2197 15.9463 17.9885 13.5064 16.5766 10.6826C16.6963 10.278 16.783 9.85696 16.8367 9.42346C18.8926 13.011 19.2312 16.0991 17.6665 17.672Z"
      fill="#137CF0"
    />
  </svg>
);
