export const text = {
  name: 'Название ссылки',
  link: 'URL',
  save: 'Сохранить',
};

export const errors = {
  length: 'Ссылка  не может быть короче 10-ти символов.',
  validation: 'Введите URL в формате "https://..."',
};
