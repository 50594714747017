export const CloseIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.49372 0.256282C1.15201 -0.0854272 0.59799 -0.0854272 0.256282 0.256282C-0.0854272 0.59799 -0.0854272 1.15201 0.256282 1.49372L5.76256 7L0.256282 12.5063C-0.0854272 12.848 -0.0854272 13.402 0.256282 13.7437C0.59799 14.0854 1.15201 14.0854 1.49372 13.7437L7 8.23744L12.5063 13.7437C12.848 14.0854 13.402 14.0854 13.7437 13.7437C14.0854 13.402 14.0854 12.848 13.7437 12.5063L8.23744 7L13.7437 1.49372C14.0854 1.15201 14.0854 0.59799 13.7437 0.256282C13.402 -0.0854272 12.848 -0.0854272 12.5063 0.256282L7 5.76256L1.49372 0.256282Z"
      fill="currentColor"
    />
  </svg>
);
