import { CopyIcon } from '../../../../assets/svg';

import styles from './CopyButton.module.scss';

type PropTypes = {
  target: string;
  onTrigger: (trigger: boolean) => void;
  description?: string;
};

export const CopyButton = ({ target, onTrigger, description }: PropTypes) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(target);
    onTrigger(true);
    setTimeout(() => {
      onTrigger(false);
    }, 3000);
  };

  return (
    <button className={styles.copy} onClick={handleCopy}>
      {<CopyIcon />}
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
