import { mapAppealChatHistory, mapAppeals } from '../mappers/appeals/appeals';
import { mapAppealsAttachments } from '../mappers/attachments/attachments';
import { rootAPI } from '../store/rootApi';
import { formatQueriesToString, getPageFromLink } from '../utils';
import {
  AppealChatHistoryRequest,
  AppealChatHistoryResponse,
  AppealChatHistoryTransformedResponse,
  AppealDataResponse,
  AppealDataTransformedResponse,
  AppealsCountsResponse,
  AppealsDataResponse,
  AppealsDataTransformedResponse,
  ChangeAppealStateResponse,
} from './appeals/types';
import { MessageRequest } from './feedbacks/types';
import { QueriesType } from './types';

export const appealsApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getAppealsCounts: build.query<number[], void>({
      query() {
        return {
          url: `/admin/feedbacks/feedbacks-aggregation/`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AppealsCountsResponse) => Object.values(response),
    }),
    getAppealsList: build.query<AppealsDataTransformedResponse, QueriesType>({
      query(queries) {
        return {
          url: `/admin/feedbacks/${formatQueriesToString(queries)}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AppealsDataResponse) => ({
        count: response.count,
        results: mapAppeals(response.results),
      }),
      providesTags: () => ['AppealsList'],
    }),
    getAppealData: build.query<AppealDataTransformedResponse, number>({
      query(id) {
        return {
          url: `/admin/feedbacks/${id}/`,
          credentials: 'include',
        };
      },
      providesTags: () => ['AppealData'],
      transformResponse: (response: AppealDataResponse) => {
        const {
          id,
          user,
          text,
          state,
          created_at,
          rate,
          has_messages_in_week: isActive,
          attachments: attachmentsRes,
          full_name: fullName,
        } = response;
        const { company, email, position } = user;
        const date = created_at;
        const attachments = mapAppealsAttachments(attachmentsRes);

        return {
          id,
          fullName,
          email,
          position,
          text,
          state,
          date,
          company,
          attachments,
          isActive,
          isRated: Boolean(rate),
        };
      },
    }),
    getAppealChatHistory: build.mutation<
      AppealChatHistoryTransformedResponse,
      AppealChatHistoryRequest
    >({
      query({ role, id, page }) {
        return {
          url: `${role === 'admin' ? '/admin' : ''}/feedbacks/${id}/messages/?page=${page}`,
          credentials: 'include',
        };
      },
      transformResponse: (response: AppealChatHistoryResponse) => ({
        nextPage: Number(getPageFromLink(response.links.next, 'page')),
        history: mapAppealChatHistory(response.results),
      }),
    }),
    postMessage: build.mutation<null, MessageRequest>({
      query(data) {
        return {
          url: '/feedbacks/message/',
          method: 'POST',
          body: data,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: arg => [{ type: 'AppealMessages', page: 1 }],
    }),
    changeAppealState: build.mutation<null, ChangeAppealStateResponse>({
      query({ id, text, state }) {
        return {
          url: `/admin/feedbacks/${id}/state/`,
          method: 'PATCH',
          body: JSON.stringify({ state, text }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          credentials: 'include',
        };
      },
      invalidatesTags: () => ['AppealData', 'AppealsList', 'AppealMessages'],
    }),
  }),
});
