export const getRoundEndTimeText = (endRoundDate: Date) => {
  const day = endRoundDate.getDate();
  const month = endRoundDate.getMonth() + 1;
  const year = endRoundDate.getFullYear();
  const hours = endRoundDate.getHours();
  const minutes = endRoundDate.getMinutes();

  const date = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
  const time = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return `${date} в ${time}`;
};
