import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { suspense } from '../HOC/suspense';
import { ContentProtection } from '../HOC/RoutesProtection';
import { DIRECTORY_PATH } from '../routeConstants';
import { modelRoutes } from './modelRoutes';

const DirectoryPage = lazy(() => import('../../pages/DirectoryPage/DirectoryPage'));

export const directoryRoutes = {
  path: DIRECTORY_PATH,
  element: <ContentProtection />,
  children: [
    {
      index: true,
      element: suspense(<DirectoryPage />),
    },
    {
      path: ':id',
      element: <Outlet />,
      children: [...modelRoutes],
    },
  ],
};
