import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ADMIN_PATH, MODELS_PATH } from '../router';
import { useAppSelector } from './useAppSelector';
import { isAdminSelector } from '../store';

type PropsType = {
  isWeightNotReady: boolean;
  isPaidContent: boolean;
  id: string | undefined;
  path: string;
};

export const useModelRedirect = ({ id, isWeightNotReady, isPaidContent, path }: PropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const isAdmin = useAppSelector(isAdminSelector);

  const adminPrefix = isAdmin ? `/${ADMIN_PATH}` : '';
  const isIndexOrInfoRoute = RegExp(`^/${MODELS_PATH}/${id}(/)?(info/?)?$`).test(path);
  const isNotPaidRoute = RegExp(`^/${MODELS_PATH}/${id}(/(info|weights)/?)?$`).test(path);
  const shouldNavigate =
    (isWeightNotReady && !isIndexOrInfoRoute) || (isPaidContent && !isNotPaidRoute);
  const navSettings = {
    state: locationState,
    replace: true,
  };
  useEffect(() => {
    if (location.pathname.startsWith(`/${MODELS_PATH}`) && isAdmin) {
      navigate(`${adminPrefix}${location.pathname}`, navSettings);
    }
    if (shouldNavigate) {
      navigate(`${adminPrefix}/${MODELS_PATH}/${id}/info`, navSettings);
    }
  }, [isWeightNotReady, isPaidContent, path, id]);
};
