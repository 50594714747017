import cn from 'classnames';
import { SyntheticEvent } from 'react';

import { CrossIcon } from '../../../../../assets/svg';

import styles from './CrossBtn.module.scss';

interface PropsType {
  description: string;
  isDisabled?: boolean;
  isSmall?: boolean;
  onClick?: (e: SyntheticEvent) => void;
}

export const CrossBtn = ({ description, isSmall = false, isDisabled, onClick }: PropsType) => {
  return (
    <button
      className={cn(styles.button, styles.decline, { [styles.smallButton]: isSmall })}
      data-descr={description}
      disabled={isDisabled}
      onClick={onClick}
      type="button"
    >
      <CrossIcon />
      {description && <span className={styles.description}>{description}</span>}
    </button>
  );
};
