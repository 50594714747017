import { useTranslation } from 'react-i18next';

import { HOME_PATH } from '../../../router';
import { RedirectBlock } from '../RedirectBlock/RedirectBlock';

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <RedirectBlock
      title={t('pages.notFoundPage.title')}
      text={t('pages.notFoundPage.text')}
      reservePath={HOME_PATH}
      notFound={true}
    />
  );
};
