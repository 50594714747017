import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { adminUsersApi } from '../../api/adminUsersApi';
import { UserForAdmin } from '../../api/user/types';

const initialState: UserForAdmin = {
  fullName: '',
  email: '',
  phone: '',
  date: '',
  companyData: null,
  isDev: false,
  isBA: false,
  isVerified: false,
  modelsCount: 0,
  alliancesCount: 0,
};

const adminUserInfoSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      adminUsersApi.endpoints.getUserAsAdmin.matchFulfilled,
      (state: UserForAdmin, { payload }: PayloadAction<UserForAdmin>) => (state = payload),
    );
  },
});

export default adminUserInfoSlice.reducer;
