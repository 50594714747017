import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const rootAPI = createApi({
  reducerPath: 'rootAPI',
  tagTypes: [
    'OpenModels',
    'MyModels',
    'AlliancesModels',
    'WeightsOfModels',
    'OpenAlliances',
    'AllAlliances',
    'ControlledAlliances',
    'InvitationsToAlliances',
    'RequestsAlliance',
    'MyAlliances',
    'ModelWeights',
    'Model',
    'ModelMetrics',
    'Alliance',
    'AllianceModel',
    'AllianceModelRequests',
    'AllianceWeightsRequests',
    'AllianceModelRequestsCount',
    'AllianceWeightsRequestsCount',
    'AllianceMembers',
    'AllianceJoinRequests',
    'AllianceBanned',
    'AllianceInvitations',
    'AllianceMembersCount',
    'AllianceJoinRequestsCount',
    'AllianceBannedCount',
    'AllianceInvitationsCount',
    'FLUserFiles',
    'FlearningHistory',
    'FLRound',
    'Subscription',
    'Docker',
    'AppealMessages',
    'AppealData',
    'AppealsList',
    'FLClients',
    'Employees',
    'AllianceModelsInfo',
    'UsersModels',
    'Feedback',
    'FeedbacksList',
    'WalletInfo',
  ],
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BACK_END }),
  endpoints: () => ({}),
});
