import { createSlice } from '@reduxjs/toolkit';

import { NotificationType } from '../types';

const initialState: NotificationType[] = [];

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification(state, action) {
      state.push({ type: action.payload.type, data: action.payload.data, id: Date.now() });
    },
    deleteNotification(state, action) {
      state.splice(
        state.findIndex(notification => notification.id === action.payload),
        1,
      );
    },
    clearNotification() {
      return initialState;
    },
    removeNotification(state) {
      state.shift();
    },
  },
});

export const { setNotification, removeNotification, deleteNotification, clearNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
