import { lazy } from 'react';

import { suspense } from '../HOC/suspense';
import { ContentProtection, ProfileTabsProtection } from '../HOC/RoutesProtection';
import { PROFILE_PATH, PROFILE_TABS } from '../routeConstants';

const ProfileData = lazy(() => import('../../pages/ProfilePage/ProfileData/ProfileData'));

const OrganizationTab = lazy(
  () => import('../../pages/ProfilePage/ProfileData/OrganizationTab/OrganizationTab'),
);
const ResumeTab = lazy(() => import('../../pages/ProfilePage/ProfileData/ResumeTab/ResumeTab'));
const PortfolioTab = lazy(
  () => import('../../pages/ProfilePage/ProfileData/PortfolioTab/PortfolioTab'),
);
const UserTab = lazy(() => import('../../pages/ProfilePage/ProfileData/UserTab/UserTab'));

const { PORTFOLIO, CV, ORGANIZATION, INFO } = PROFILE_TABS;

export const profileRoutes = [
  {
    path: PROFILE_PATH,
    element: <ContentProtection />,
    children: [
      {
        element: suspense(<ProfileData />),
        children: [
          {
            index: true,
            element: suspense(<UserTab />),
          },
          {
            path: INFO,
            element: suspense(<UserTab />),
          },
          // {
          //   path: CV,
          //   element: <ProfileTabsProtection protectedPage={suspense(<ResumeTab />)} route={CV} />,
          // },
          {
            path: ORGANIZATION,
            element: (
              <ProfileTabsProtection
                protectedPage={suspense(<OrganizationTab />)}
                route={ORGANIZATION}
              />
            ),
          },
          // {
          //   path: PORTFOLIO,
          //   element: (
          //     <ProfileTabsProtection protectedPage={suspense(<PortfolioTab />)} route={PORTFOLIO} />
          //   ),
          // },
        ],
      },
    ],
  },
];
