import { DeveloperData, DevelopersResponse, ProjectsResponse } from '../../api/developer/types';
import { FavoriteDevelopersResponse } from '../../api/favorites/types';
import { SummaryResponse } from '../../api/summary/types';
import { makeFullName } from '../../utils/formatData';
import { resultsKey } from '../constants';

export const mapProjects = (payload: ProjectsResponse) =>
  payload.map(project => ({
    id: project.id,
    name: project.name,
    description: project.description,
    link: { name: project.link_name, link: project.link },
    files: project.files.map(file => ({
      name: file.file_data.file_name,
      id: file.attachment_id,
      type: file.file_data.data_type,
      size: file.file_data.size,
      uuid: file.file_data.uuid,
      url: file.file_data.url,
    })),
  }));

export const mapDevelopers = (payload: DevelopersResponse[typeof resultsKey]): DeveloperData[] => {
  return payload.map(developer => ({
    id: developer.id,
    fullName: makeFullName(developer.profile),
    position: developer.specialization,
    experience: developer.experience,
    tags: developer.skills.map(skill => skill.name),
    description: developer.about,
  }));
};

export const mapFavoriteDevelopers = (payload: FavoriteDevelopersResponse[typeof resultsKey]) => {
  return payload.map(developer => ({
    favoriteId: developer.id,
    developerId: developer.developer.id,
    fullName: `${developer.developer.first_name} ${developer.developer?.middle_name || ''} ${
      developer.developer.last_name
    } `,
    position: developer.developer.specialization,
    experience: developer.developer.experience,
    tags: developer.developer.skills.map(skill => skill.name),
    description: developer.developer.about,
  }));
};

export const mapDeveloperResume = (payload: SummaryResponse) => {
  const {
    specialization,
    experience,
    about,
    country,
    region,
    skills,
    id,
    socials,
    is_hidden: isHidden,
  } = payload;
  const { link_name: name, link } = socials[0];

  return {
    specialization,
    experience,
    about,
    country,
    region,
    skills,
    id,
    isHidden,
    link: { name: String(name), link: String(link) },
  };
};
