export const NavigateArrowIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18">
    <path
      d="M6.90234 13.8983L11.8023 8.99825L6.90234 4.09826"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
