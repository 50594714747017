import { rootAPI } from '../store/rootApi';

import {
  AddCompanyData,
  EditCompanyData,
  GetBACompanyResponse,
  SetBACompanyResponse,
} from './businessAdmin/types';

export const businessAdminApi = rootAPI.injectEndpoints({
  endpoints: build => ({
    getBACompanyData: build.mutation<GetBACompanyResponse, void>({
      query() {
        return {
          url: `/business-admin/company-data/`,
          credentials: 'include',
        };
      },
    }),
    setBaCompanyData: build.mutation<SetBACompanyResponse, AddCompanyData>({
      query(data) {
        return {
          url: `/business-admin/organization/`,
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify(data),
        };
      },
    }),
    editBaCompanyData: build.mutation<SetBACompanyResponse, EditCompanyData>({
      query({ id, data }) {
        return {
          url: `/business-admin/organization/${id}/`,
          credentials: 'include',
          method: 'PATCH',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
          body: JSON.stringify({ ...data, position_input: data.position }),
        };
      },
    }),
  }),
});
