import { FieldValues } from 'react-hook-form';

type ArgumentsType = {
  inputValues: FieldValues;
  files: File[];
  link: { link: string; name: string | null };
};

export const getProjectFormData = ({ inputValues, files, link }: ArgumentsType) => {
  files = files.filter((file: File) => file instanceof Blob);
  const filesObj: Record<string, File> = {};
  for (let i = 0; i < files.length; i++) {
    filesObj[`file_${i + 1}`] = files[i];
  }
  const projectData: Record<string, any> = {
    name: inputValues.name,
    description: inputValues.description,
    link: link.link,
    link_name: link.name,
    ...filesObj,
  };
  const formData = new FormData();
  Object.keys(projectData).forEach((key: string) => {
    formData.append(key, projectData[key]);
  });
  return formData;
};
