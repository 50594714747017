export const getTimeRemaining = (endTime: Date) => {
  const t = endTime.getTime() - new Date().getTime();
  const seconds = Math.floor((t / 1000) % 60);
  const minutes = Math.floor((t / 1000 / 60) % 60);
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  const days = Math.floor(t / (1000 * 60 * 60 * 24));

  const daysRemaining = days ? `${days} д. ` : '';
  const timeRemaining = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return {
    total: t,
    result: daysRemaining + timeRemaining,
  };
};
