const defaultOnboardingData = {
  profile: false,
  models: false,
  model: false,
  alliances: false,
  alliance: false,
  fl: false,
  employees: false,
};

const startOnboardingData = {
  profile: true,
  models: true,
  model: true,
  alliances: true,
  alliance: true,
  fl: true,
  employees: true,
};

const onboardingKey = 'onboarding';

type OnboardingDataTypes = {
  [key: string]: boolean;
};

export type FlagTypes =
  | 'profile'
  | 'models'
  | 'model'
  | 'alliances'
  | 'alliance'
  | 'fl'
  | 'employees';

type SetDataType = 'start' | 'default';

export const setOnboardingDataByKey = (key: FlagTypes, value: boolean) => {
  const onboardingData = localStorage.getItem(onboardingKey);

  if (onboardingData) {
    localStorage.setItem(
      onboardingKey,
      JSON.stringify({ ...JSON.parse(onboardingData), [key]: value }),
    );
    return;
  }

  localStorage.setItem(onboardingKey, JSON.stringify({ ...defaultOnboardingData, [key]: value }));
};

export const setOnboardingData = (type: SetDataType = 'start') => {
  switch (type) {
    case 'start':
      localStorage.setItem(onboardingKey, JSON.stringify(startOnboardingData));
      break;
    case 'default':
      localStorage.setItem(onboardingKey, JSON.stringify(defaultOnboardingData));
      break;
    default:
      break;
  }
};

export const getOnboardingData = (key: FlagTypes) => {
  const onboardingData = localStorage.getItem(onboardingKey);

  if (!onboardingData) {
    return false;
  }

  const parseData: OnboardingDataTypes = JSON.parse(onboardingData);

  return parseData[key];
};
