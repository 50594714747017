export const BYTES_IN_MB = 1048576;

export const APPLICATIONS_FORMATS = [
  'TXT',
  'DOC',
  'DOCX',
  'PDF',
  'JPEG',
  'JPG',
  'PNG',
  'SVG',
  'GIF',
];
export const MODEL_FORMATS = ['PY', 'PYC', 'PB', 'ONNX', 'H5', 'PT', 'CAFFEMODEL', 'MODEL'];

export const WEIGHT_FORMATS = ['PTH', 'PT', 'H5', 'CKPT', 'CAFFEMODEL', 'MODEL'];
