import { WeightRequestResponse } from '../../api/models/types';
import { UserOrganizationResponse } from '../../api/organization/types';
import {
  UserDataResponse,
  UserForAdmin,
  UserForAdminResponse,
  UsersListResponse,
} from '../../api/user/types';
import { EmployeeResponse } from '../../api/verification/types';
import { makeFullName } from '../../utils/formatData';
import { resultsKey } from '../constants';

export const mapUser = (payload: UserDataResponse & UserOrganizationResponse) => {
  return {
    fullName: makeFullName(payload),
    phone: payload.phone,
    isPhoneHidden: payload.hide_phone,
    email: payload.account.email,
    date: payload.account.created_at,
    companyName: payload.company_name,
    position: payload.position,
  };
};

export const mapApplicationUser = (payload: WeightRequestResponse) => {
  return {
    fullName: makeFullName({
      first_name: payload.user.first_name,
      last_name: payload.user.last_name,
      middle_name: payload.user.middle_name,
    }),
    position: payload.user?.position || '',
    companyName: payload.user.company?.name ?? '',
    INN: payload.user.company?.inn ?? '',
    industry: payload.user.company?.industry ?? '',
    email: payload.user.email,
  };
};

export const mapUsersList = (payload: UsersListResponse[typeof resultsKey]) => {
  return payload.map(user => ({
    fullName: makeFullName({
      first_name: user.first_name,
      last_name: user.last_name,
      middle_name: user.middle_name,
    }),
    id: user.id,
    position: user.juridical && user.juridical.state === 'verified' ? user.juridical.position : '',
    companyName:
      user.juridical && user.juridical.state === 'verified' ? user.juridical.company.name : '',
    companyINN:
      user.juridical && user.juridical.state === 'verified' ? user.juridical.company.inn : '',
    email: user.email,
    phone: user.phone || '',
    modelsCount: user.models_count,
    alliancesCount: user.alliances_count,
    roles:
      user.user_roles.length > 1
        ? user.user_roles.filter(role => role !== 'default')
        : user.user_roles,
  }));
};

export const mapUserForAdmin = (payload: UserForAdminResponse): UserForAdmin => {
  return {
    alliancesCount: payload.alliances_count,
    companyData: payload.juridical
      ? {
          companyName: payload.juridical.company?.name ?? '',
          country: payload.juridical.company?.country ?? '',
          position: payload.juridical.position,
          region: payload.juridical.company?.region ?? '',
        }
      : null,
    date: payload.account.created_at,
    email: payload.account.email,
    fullName: makeFullName({
      first_name: payload.first_name,
      last_name: payload.last_name,
      middle_name: payload.middle_name,
    }),
    modelsCount: payload.models_count,
    phone: payload.phone,
    isBA: payload.user_roles.includes('ba'),
    isDev: payload.user_roles.includes('developer'),
    isVerified: payload.juridical?.state === 'verified',
  };
};

export const mapEmployee = (payload: EmployeeResponse[typeof resultsKey]) => {
  return payload.map(user => ({
    id: user.user.id,
    position: user.position,
    state: user.state,
    company_data: {
      id: user.company.id,
      name: user.company.name,
    },
    profile_data: {
      email: user.user.email,
      updated_at: user.user.updated_at,
      created_at: user.user.created_at,
      first_name: user.user.first_name,
      last_name: user.user.last_name,
      middle_name: user.user.middle_name,
      phone: user.user.phone,
    },
  }));
};
