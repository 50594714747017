import { useTranslation } from 'react-i18next';

import { PopupDataType } from '../../../../store/types';
import { formatDate } from '../../../../utils/formatData';
import { allianceApi } from '../../../../api/allianceApi';

import styles from './InvitationCommentPopUp.module.scss';

export const InvitationCommentPopUp = ({ data }: { data: PopupDataType }) => {
  const { t } = useTranslation();
  const { data: comment } = allianceApi.useGetAllianceInvitationCommentQuery(Number(data.id));

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('popups.invitationComment.title')}</h2>
      <div className={styles.comment}>{comment?.text}</div>
      <div className={styles.grid}>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('popups.invitationComment.name')}</div>
          <div className={styles.textPrimary}>{data.name}</div>
        </div>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('popups.invitationComment.createdAt')}</div>
          <div className={styles.textPrimary}>
            {formatDate(new Date(String(comment?.createdAt))).substring(-1, 10)}
          </div>
        </div>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('headings.industry')}</div>
          <div className={styles.textPrimary}>{data.industry}</div>
        </div>
        <div className={styles.personalInfo}>
          <div className={styles.gridTitle}>{t('popups.invitationComment.members')}</div>
          <div className={styles.textPrimary}>{data.members ?? 0}</div>
        </div>
      </div>
    </div>
  );
};
