import { ModelApplicationInfoResponse } from '../../../../api/models/types';
import { RoleInModel } from '../../../../pages/ModelPage/types';
import { makeFullName, transformObjectToArray } from '../../../../utils/formatData';

export const mapModel = (payload: ModelApplicationInfoResponse) => {
  const {
    id,
    tags,
    name,
    task,
    model_type,
    description,
    ml_model_attachments,
    model_params,
    dataset_params,
    test_params,
    train_params,
    link,
    link_name,
    created_at,
  } = payload.ml_model;
  return {
    id,
    state: 'ready',
    role: RoleInModel.Waiting,
    alliance: null,
    data: {
      name,
      type: model_type,
      category: '',
      task,
      description,
      tags: tags.map(tag => tag.name),
      attachments: ml_model_attachments.map(file => ({
        name: file.file_data.file_name,
        uuid: file.file_data.uuid_name,
        size: file.file_data.size,
        id: file.file_data.id,
        type: file.file_data.data_type,
        url: file.file_data.url,
      })),
      modelParams: model_params,
      datasetParams: dataset_params,
      testParams: test_params,
      trainParams: train_params,
      reports: [],
      isFavorite: false,
      isPrivate: false,
      isPaid: false,
      isJointMl: false,
      hasFl: false,
      link: { name: link_name, link },
      update: '',
      create: created_at,
    },
    isNameTaken: !payload.is_free,
    createdDate: payload.created_at,
    applicationId: payload.id,
  };
};

export const mapUser = (payload: ModelApplicationInfoResponse) => {
  const { first_name, last_name, middle_name, position, company, email } = payload.user;
  return {
    fullName: makeFullName({
      first_name,
      last_name,
      middle_name,
    }),
    position: position || '',
    companyName: company?.name || '',
    INN: company?.inn || '',
    industry: company?.industry || '',
    email,
  };
};

export const mapWeight = (payload: ModelApplicationInfoResponse) => {
  if (!payload.ml_model.weights[0]) return null;
  const { id, meta_data } = payload.ml_model.weights[0];
  const { name, description, model_params, test_params, train_params, file_size, created_at } =
    meta_data;
  return {
    name,
    description,
    id,
    params: {
      model: transformObjectToArray(model_params),
      test: transformObjectToArray(test_params),
      train: transformObjectToArray(train_params),
    },
    size: file_size,
    date: created_at,
  };
};
