export const WarningIcon = () => (
  <svg width="22" height="19" viewBox="0 0 22 19" fill="none">
    <path
      d="M10.9999 10.4708V5.63251M10.9999 14.0571V14.0996M17.0478 18.1292H4.95198C3.29975 18.1292 1.90542 17.025 1.46673 15.5143C1.27947 14.8694 1.50956 14.1977 1.86151 13.6257L7.90944 2.59779C9.32641 0.2952 12.6734 0.295203 14.0904 2.5978L20.1383 13.6257C20.4902 14.1977 20.7203 14.8694 20.5331 15.5143C20.0944 17.025 18.7001 18.1292 17.0478 18.1292Z"
      stroke="#FF3A40"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
