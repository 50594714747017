export const BackIcon = () => (
  <svg width="16" height="31" viewBox="0 0 16 31" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.75648 15.5L8.87824 8.32429L16 1.14858L15.3563 0.5L0.469068 15.5L15.3563 30.5L16 29.8514L1.75648 15.5Z"
      fill="currentColor"
    />
  </svg>
);
