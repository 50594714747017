export const MessageIcon = () => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path
      d="M2.5999 2.1043L10.9999 8.7043L19.9999 2.1043M9 7.5L2.5999 14.1043M19.3999 13.6043L13 7.5M3.7999 15.0956C2.47442 15.0956 1.3999 14.0211 1.3999 12.6956V3.3043C1.3999 1.97882 2.47442 0.904297 3.7999 0.904297H18.1999C19.5254 0.904297 20.5999 1.97881 20.5999 3.3043V12.6956C20.5999 14.0211 19.5254 15.0956 18.1999 15.0956H3.7999Z"
      stroke="#137CF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
