export const HOME_PATH = '/';
export const PROFILE_PATH = 'profile';
export const ORGANIZATION_PATH = 'organization';
export const SUMMARY_PATH = 'cv';
export const CATCH_ALL_PATH = '/*';
export const AUTH_PATH = 'auth/*';
export const LOG_IN_PATH = 'auth';
export const SIGN_IN_PATH = 'sign-in';
export const RESET_PASSWORD_PATH = 'password-recovery';
export const RESET_PASSWORD_CONFIRM_PATH = 'reset-password-confirm';
export const ADMIN_PATH = 'admin';
export const ALLIANCES_PATH = 'alliances';
export const NEW_ALLIANCE_PATH = 'new-alliance';
export const SANDBOX_PATH = 'sandbox';
export const DEVELOPER_PUBLIC_PROFILE_PATH = 'developer';
export const MODELS_PATH = 'models';
export const MODEL_PATH = 'model';
export const FL_PATH = 'flearning';
export const NEW_MODEL_PATH = 'new-model';
export const EMPLOYEES_PATH = 'employees';
export const USERS_PATH = 'users';
export const DIRECTORY_PATH = 'directory';
export const ADD_ORGANIZATION_PATH = 'add-organization';
export const EDIT_PROFILE_PATH = 'profile-edit';
export const EDIT_ORGANIZATION_PATH = 'organization-edit';
export const ADD_SUMMARY_PATH = 'add-summary';
export const EDIT_SUMMARY_PATH = 'edit-summary';
export const FAVORITES_PATH = 'favorites';
export const EDIT = 'edit';
export const EDIT_PARAMS = 'edit-params';
export const ABOUT_PATH = 'about';
export const WHERE_TO_START_PATH = 'where-to-start';
export const HOW_TO_ADD_MODEL_PATH = 'how-to-add-model';
export const HOW_TO_START_FL_PATH = 'how-to-start-federal-training';
export const START_USE_MODEL_PATH = 'start-using-model';
export const HELP_PATH = 'help';
export const WALLET_PATH = 'wallet';
export const APPEALS_PATH = 'appeals';
export const SUPPORT_PATH = 'support';
export const SETTINGS_PATH = 'settings';

export const ALLIANCES_TABS = {
  MY: 'my',
  OPEN: 'open',
  CREATED: 'created',
  INVITATIONS: 'invitations',
  REQUESTS: 'requests',
};

export const ALLIANCE_OWNER_TABS = {
  ALLIANCE_MODEL: 'model',
  INFO: 'info',
  MEMBERS: 'members',
  FL: 'flearning',
};

export const ALLIANCE_MODEL_TABS = {
  SUB_MODEL: 'sub_model',
  ML_REQUESTS: 'ml_requests',
  WEIGHT_REQUESTS: 'weights_requests',
};

export const ALLIANCE_FL_TABS = { CURRENT: 'current', HISTORY: 'history' };

export const ALLIANCE_EMPLOYEE_TABS = {
  ALL: 'all',
  JOIN_REQUESTS: 'requests',
  JOIN_INVITATIONS: 'invitations',
  BLOCKED: 'blocked',
};

export const MODELS_TABS = {
  MY: 'my',
  OPEN: 'open',
  ADDED: 'added',
  WEIGHTS: 'weights',
};

export const MODEL_TABS = {
  INFO: 'info',
  MODEL_WEIGHTS: 'weights',
  SOURCES: 'sources',
};

export const PROFILE_TABS = {
  INFO: 'info',
  CV: 'cv',
  ORGANIZATION: 'organization',
  PORTFOLIO: 'portfolio',
};

export const EMPLOYEES_TABS = {
  ALL: 'all',
  WAITING: 'waiting',
  VERIFIED: 'verified',
  DECLINED: 'declined',
};

export const ADMIN_USER_TABS = {
  ALLIANCES: 'u_al',
  MODELS: 'u_mls',
  DEVELOPER: 'u_dev',
  BADMINISTRATOR: 'u_ba',
};

export const ADMIN_USER_ALLIANCES_TABS = {
  MY: 'my',
  CREATED: 'created',
  INVITATIONS: 'invitations',
  REQUESTS: 'requests',
};

export const ADMIN_USER_MODELS_TABS = {
  MY: 'my',
  ADDED: 'added',
  WEIGHTS: 'weights',
};

export const ADMIN_USERS_TABS = {
  ALL: 'all',
  COMMON: 'common',
  BA: 'ba',
  DEV: 'dev',
};

export const SANDBOX_TABS = {
  MY: 'my',
  DEVELOPERS: 'developers',
};

export const DEV_TABS = {
  CV: 'cv',
  PORTFOLIO: 'portfolio',
};

export const FAVORITES_TABS = {
  F_ALLIANCE: 'f_al',
  F_MODELS: 'f_mls',
  F_DEVELOPERS: 'f_dev',
};

export const WALLET_TABS = {
  RECEIPTS: 'receipts',
  WITHDRAW: 'withdraws',
};

export const APPEALS_TABS = {
  ALL: 'all',
  OPEN: 'open',
  IN_PROCESS: 'in_process',
  SUSPENDED: 'suspended',
  RESOLVED: 'resolved',
};

export const SUPPORT_TABS = {
  CURRENT: 'current',
  RESOLVED: 'resolved',
};

export const SETTINGS_TABS = {
  SECURITY: 'security',
  NOTIFICATIONS: 'notifications',
};
